import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import classes from "./AppPage.module.css";

function AppPage({ children }) {
    const dataLoading = useSelector((state) => state.ui.isLoading);
    const [firstLoad, setFirstLoad] = useState(true);

    useEffect(() => {
        if (firstLoad) {
            // Wait 100ms before allowing removing blind
            setTimeout(() => {
                setFirstLoad(false);
            }, 100);
        }
    }, [firstLoad]);

    return (
        <div className={classes.app_page}>
            <div
                className={`${classes.loader_blind} ${
                    dataLoading || firstLoad
                        ? classes.opaque
                        : classes.transparent
                }`}
            />
            {children}
        </div>
    );
}

export default AppPage;
