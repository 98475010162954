import { LegendSection, LegendItem } from "../../../controls/Legend";
import { PercentileClassIndicator } from "../../indicators";
import player_development_classes from "../../../app-pages/TeamPlayerDevelopment.module.css";

const PlayerDevelopmentTileSection = () => {
    return (
        <LegendSection
            title="Player Development Tile"
            description="The player development tiles gives an overview of the player's development status for the current season. It will have a number of active development priorities pills, each representing a different priority."
        >
            <LegendItem
                symbol={<PercentileClassIndicator value={0.5} />}
                description="A priority that has had no sessions on this season yet."
            />
            <LegendItem
                symbol={<PercentileClassIndicator value={0.6} />}
                description="A priority that has had at least one session this season."
            />
            <LegendItem
                symbol={
                    <div className={player_development_classes.season_sessions_count}>
                        #
                    </div>
                }
                description="The total count of development sessions this player has participated in this season."
            />
        </LegendSection>
    );
};

export default PlayerDevelopmentTileSection;
