import classes from "./DatePicker.module.css";
import tiClasses from "./TextInput.module.css";

const DatePicker = ({
    value,
    handleDateChange,
    readOnly,
    mode,
    ...additionalProps
}) => {
    const inputClass = `${tiClasses.text_input} ${
        mode === "dark" ? tiClasses.dark_mode : tiClasses.light_mode
    } ${classes.date_picker_input}`;

    // Ensure handleDateChange correctly extracts the date string from the event object
    const handleChange = (event) => {
        const selectedDate = event.target.value; // Extract the date string
        if (handleDateChange) {
            handleDateChange(selectedDate); // Pass the date string to the handler
        }
    };

    const componentProps = {
        type: value ? "date" : "text",
        placeholder: "-",
        value,
        onChange: handleChange,
        className: inputClass,
        readOnly,
        ...(readOnly && { style: { backgroundColor: "inherit" } }),
        ...additionalProps,
    };

    return (
        <div className={classes.date_picker}>
            <input {...componentProps} />
        </div>
    );
};

export default DatePicker;
