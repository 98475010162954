import { useEffect, useRef } from "react";
import classes from "./CompressiblePanel.module.css";

function CompressiblePanel({
    children,
    compressWidth,
    onWidthChange,
    onHeightChange,
}) {
    const elemRef = useRef(null);

    useEffect(() => {
        const checkLayoutSize = () => {
            if (elemRef.current) {
                const width = elemRef.current.offsetWidth;
                onWidthChange && onWidthChange(width);
                const height = window.innerHeight;
                onHeightChange && onHeightChange(height);
            }
        };

        // Create a ResizeObserver instance and provide the callback
        const resizeObserver = new ResizeObserver(checkLayoutSize);

        // If there's an element to observe, start observing it
        if (elemRef.current) {
            resizeObserver.observe(elemRef.current);
        }

        // On unmount, disconnect the observer
        return () => {
            resizeObserver.disconnect();
        };
    }, [onWidthChange, onHeightChange]);

    const style = compressWidth
        ? { width: `calc(100% - ${compressWidth}px)` }
        : undefined;

    return (
        <div ref={elemRef} className={classes.compressible_panel} style={style}>
            {children}
        </div>
    );
}

export default CompressiblePanel;
