import { useSelector } from "react-redux";

export const useSalaryCap = () => {
    const contractViewsSeasonId = useSelector(
        (state) => state.ui.systemConfiguration?.contractViewsSeasonId
    );

    const currentSalaryCapLimits = useSelector(
        (state) => state.competitions.seasondatastashes[1]?.salary_cap_limits?.[contractViewsSeasonId]
    );

    const CURRENT_SALARY_CAP = currentSalaryCapLimits?.salary_cap;
    const CURRENT_SALARY_FLOOR = currentSalaryCapLimits?.salary_floor;
    const CURRENT_BURIED_CAP_THRESHOLD = currentSalaryCapLimits?.buried_cap_threshold;

    const getPlayerCapBase = (player, capHit) => {
        return (
            player &&
            (!player.is_cleared_non_roster
                ? capHit
                : (player.is_in_ahl && !player.has_two_way_contract) ||
                  player.is_on_loan
                ? Math.max(0, capHit - CURRENT_BURIED_CAP_THRESHOLD)
                : 0)
        );
    };

    const getPlayerBuriedCap = (player, capHit) => {
        return player && capHit - getPlayerCapBase(player, capHit);
    };

    return {
        CURRENT_SALARY_CAP,
        CURRENT_SALARY_FLOOR,
        CURRENT_BURIED_CAP_THRESHOLD,
        getPlayerCapBase,
        getPlayerBuriedCap,
    };
};
