import { useState, useEffect, useCallback } from "react";

// Hook to get window dimensions and classes based on width ranges
// Returns an object with the following properties:
// - windowDimensions: { width, height }
// - widthClasses: [{ min, max, matches }]
// - handleResize: function to handle window resize
// widthRanges: array of objects with min and max properties
// Example: [{ min: 0, max: 600 }, { min: 601, max: 1024 }, { min: 1025 }]

const useWindowDimensions = (widthRanges = []) => {
    const getDimensions = () => ({
        width: window.innerWidth,
        height: window.innerHeight,
    });

    const getWidthClasses = useCallback(
        (width) => {
            return widthRanges.map((range) => ({
                min: range.min,
                max: range.max,
                matches:
                    width >= (range.min || 0) &&
                    width <= (range.max || Infinity),
            }));
        },
        [widthRanges]
    );

    const [windowDimensions, setWindowDimensions] = useState(getDimensions());
    const [widthClasses, setWidthClasses] = useState(
        getWidthClasses(window.innerWidth)
    );

    const handleResize = useCallback(() => {
        const dimensions = getDimensions();
        setWindowDimensions(dimensions);
        setWidthClasses(getWidthClasses(dimensions.width));
    }, [getWidthClasses]);

    useEffect(() => {
        window.addEventListener("resize", handleResize);
        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, [handleResize]);

    return { windowDimensions, widthClasses, handleResize };
};

export default useWindowDimensions;
