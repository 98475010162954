import classes from "./HorizontalDistributionIndicator.module.css";
import { getPerformanceTierFromPercentile, formatToiSeconds } from "../utils";

const HorizontalDistributionIndicator = ({
    value,
    dataPoints,
    percentile,
    performanceTier,
    inverseColors = false,
    includeLabel,
    decimalPlaces = 2,
    isDuration = false,
    negativeDirectionality = false,
    labelColor = "var(--tw-gray-500)",
    showAverage = false,
    isExample = false,
}) => {
    let tierClass = null;

    if (percentile) {
        tierClass =
            classes[
                getPerformanceTierFromPercentile(percentile).tier + "_tier"
            ];
    } else if (performanceTier) {
        tierClass = classes[performanceTier.toLowerCase() + "_tier"];
    }

    const minValue =
        dataPoints && Math.min(...dataPoints.map((dp) => dp.value));
    const maxValue =
        dataPoints && Math.max(...dataPoints.map((dp) => dp.value));
    const avg =
        dataPoints &&
        dataPoints.reduce((acc, dp) => acc + dp.value, 0) / dataPoints.length;
    const amplitude = Math.max(avg - minValue, maxValue - avg);

    const sampleStdDev =
        dataPoints &&
        Math.sqrt(
            dataPoints.reduce(
                (acc, dp) => acc + Math.pow(dp.value - avg, 2),
                0
            ) / dataPoints.length
        );

    return (
        <div
            className={classes.horizontal_distribution_indicator_container}
            style={{
                width: `calc(100%${includeLabel ? " - 32px" : ""})`,
            }}
        >
            <div className={classes.horizontal_distribution_indicator}>
                {showAverage && (
                    <div className={classes.horizontal_distribution_avg_label}>
                        {avg &&
                            "avg " +
                                (isExample
                                    ? avg
                                    : isDuration
                                    ? formatToiSeconds(avg)
                                    : avg.toFixed(decimalPlaces))}
                    </div>
                )}
                <div
                    className={
                        classes.horizontal_distribution_indicator_content
                    }
                >
                    <div
                        className={classes.horizontal_distribution_std_dev_bar}
                        style={{
                            width: `calc((100%) * ${
                                isExample ? 1.0 : sampleStdDev / amplitude
                            })`,
                            left: `calc(50% - ${
                                (sampleStdDev / amplitude) * 50
                            }%)`,
                        }}
                    ></div>
                    {dataPoints &&
                        dataPoints
                            .filter((dp) => Math.abs(dp.zscore) > 1.0)
                            .map((dp, i) => (
                                <div
                                    key={i}
                                    className={
                                        classes.horizontal_distribution_data_point
                                    }
                                    style={{
                                        left: `calc(${
                                            isExample
                                                ? 100
                                                : (negativeDirectionality
                                                      ? 1 -
                                                        (dp.value -
                                                            (avg - amplitude)) /
                                                            (2 * amplitude)
                                                      : (dp.value -
                                                            (avg - amplitude)) /
                                                        (2 * amplitude)) * 100
                                        }% - 2px)`,
                                    }}
                                ></div>
                            ))}
                    {showAverage && <div className={classes.separator} />}
                    <div
                        className={
                            classes.horizontal_distribution_value +
                            (tierClass ? " " + tierClass : "") +
                            (inverseColors ? " " + classes.inverse : "")
                        }
                        style={{
                            left: `calc(${
                                isExample
                                    ? 100
                                    : (negativeDirectionality
                                          ? 1 -
                                            (value - (avg - amplitude)) /
                                                (2 * amplitude)
                                          : (value - (avg - amplitude)) /
                                            (2 * amplitude)) * 100
                            }% - 4px)`,
                        }}
                    ></div>
                </div>
            </div>
            {includeLabel && (
                <div
                    className={classes.horizontal_distribution_label}
                    style={{
                        color: labelColor,
                    }}
                >
                    {value &&
                        (isExample
                            ? value
                            : isDuration
                            ? formatToiSeconds(value)
                            : value.toFixed(decimalPlaces))}
                </div>
            )}
        </div>
    );
};

export default HorizontalDistributionIndicator;
