import { PercentileClassIndicator } from "../../../components/indicators";
import { LegendSection, LegendItem } from "../../../controls/Legend";

const PerformanceTiersSection = () => {
    return (
        <LegendSection
            title="Performance Tiers"
            description="Performance indicators show the percentile rank tier of the player. Percentile ranks are always relative to league and main position (forwards, defensemen or goalies)."
        >
            <LegendItem
                symbol={
                    <PercentileClassIndicator value={0.92} diff_value={0.92} />
                }
                description="Elite level. &gt; 90.0 perc."
            />
            <LegendItem
                symbol={
                    <PercentileClassIndicator value={0.8} diff_value={0.8} />
                }
                description="Top level. 75.0 - 90.0 perc."
            />
            <LegendItem
                symbol={
                    <PercentileClassIndicator value={0.6} diff_value={0.6} />
                }
                description="High level. 55.0 - 75.0 perc."
            />
            <LegendItem
                symbol={
                    <PercentileClassIndicator value={0.4} diff_value={0.4} />
                }
                description="Mid level. 30.0 - 55.0 perc."
            />
            <LegendItem
                symbol={
                    <PercentileClassIndicator value={0.2} diff_value={0.2} />
                }
                description="Low level. &lt; 30.0 perc."
            />
        </LegendSection>
    );
};

export default PerformanceTiersSection;
