import { useEffect, useState, useCallback, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";
import { uiActions } from "../store/ui-slice";

export const EMPTY_VIEW_CONTEXT = {
    playerId: null,
    competitionId: null,
    primaryPosition: null,
    metricContext: {
        stack: null,
        activeMetric: null,
    },
};

// Convert selection to URLSearchParams
const _selectionToSearchParams = (selection) => {
    const params = new URLSearchParams();
    if (selection.playerId)
        params.set("viewCtxPlayerId", selection.playerId.toString());
    if (selection.competitionId)
        params.set("viewCtxCompetitionId", selection.competitionId.toString());
    if (selection.primaryPosition)
        params.set("viewCtxPrimaryPosition", selection.primaryPosition.toString());
    if (
        selection.metricContext &&
        selection.metricContext.stack &&
        selection.metricContext.activeMetric
    ) {
        params.set(
            "viewCtxMetricStack",
            selection.metricContext.stack.join(",")
        );
        params.set(
            "viewCtxSelectedMetric",
            selection.metricContext.activeMetric
        );
    }
    return params;
};

const useCrossPageViewContext = (standAlone = false) => {
    const dispatch = useDispatch();

    const reduxViewContext = useSelector((state) => state.ui.viewContext);
    const reduxSetViewContext = useCallback(
        (selection) => {
            dispatch(uiActions.setViewContext(selection));
        },
        [dispatch]
    );

    const [stateViewContext, setStateViewContext] = useState(
        EMPTY_VIEW_CONTEXT
    );

    const viewContext = standAlone
        ? stateViewContext
        : reduxViewContext;
    const setViewContext = standAlone
        ? setStateViewContext
        : reduxSetViewContext;

    const [searchParams, setSearchParams] = useSearchParams();
    const initializationDoneRef = useRef(false);

    // Update the URL with the new search parameters without navigating
    const updateUrlSearchParams = useCallback(
        (newParams) => {
            setSearchParams(newParams, { replace: true });
        },
        [setSearchParams]
    );

    // Effect to initialize Redux store from URL parameters on first load
    useEffect(() => {
        if (!initializationDoneRef.current && searchParams.toString()) {
            const initialSelection = {
                playerId:
                    parseInt(searchParams.get("viewCtxPlayerId")) || undefined,
                competitionId:
                    parseInt(searchParams.get("viewCtxCompetitionId")) ||
                    undefined,
                primaryPosition:
                    searchParams.get("viewCtxPrimaryPosition") ||
                    undefined,
                metricContext: {
                    stack:
                        searchParams.get("viewCtxMetricStack")?.split(",") ||
                        [],
                    activeMetric:
                        searchParams.get("viewCtxSelectedMetric") || "",
                },
            };
            setViewContext(initialSelection);
            initializationDoneRef.current = true; // Mark initialization as done
        }
    }, [setViewContext, searchParams]); // Keeping searchParams to ensure logic runs after they are available
    // Effect to update URL when viewContext changes
    useEffect(() => {
        // Only update URL after initial load
        const params = _selectionToSearchParams(viewContext);
        updateUrlSearchParams(params);
    }, [viewContext, updateUrlSearchParams]);

    return {
        viewContext,
        setViewContext,
    };
};

export default useCrossPageViewContext;
