import mixpanel from "mixpanel-browser";

const MIXPANEL_HOSTNAME = "habs-hub.com";
const MIXPANEL_PROJECT_TOKEN = "07d068277cef62422787e5fdbbcd4bca";
const MIXPANEL_PROXY_DOMAIN = "https://" + MIXPANEL_HOSTNAME + "/mixpanel";

mixpanel.init(MIXPANEL_PROJECT_TOKEN, { api_host: MIXPANEL_PROXY_DOMAIN });

fetch("/api/users/me/")
    .then((response) => response.json())
    .then((data) => {
        console.log(`Mixpanel identify as ${data.first_name} ${data.last_name} ${data.email}`);
        if (window.location.hostname === MIXPANEL_HOSTNAME) {
            mixpanel.identify(data.email);
            mixpanel.register({
                first_name: data.first_name,
                last_name: data.last_name,
                full_name: data.first_name + " " + data.last_name,
            });
        }
    })
    .catch((error) => console.error("Error:", error));

export const mp_track = (event_name, properties, app_module="general") => {
    properties = {
        ...properties,
        app_module: app_module,
    };

    if (window.location.hostname === MIXPANEL_HOSTNAME) {
        mixpanel.track(event_name, properties);
    } else {
        console.log("Mixpanel event: " + event_name);
        console.log(properties);
    }
};
