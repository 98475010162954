import { LegendSection, LegendItem } from "../../../controls/Legend";
import { PlayerTileBox } from "../../playertile/Playertile";
import tile_classes from "../../playertile/Playertile.module.css";
import classes from "./PlayerFreeAgencyTileLegendSection.module.css";

const PlayerFreeAgencyTileLegendSection = () => {
    return (
        <LegendSection
            title="Upcoming Free Agency Status"
            description="The background color of the player tile indicates the projected end of season free agency status of the player."
        >
            <div className={classes.tiles_section}>
                <LegendItem
                    description={
                        <div className={classes.tile_container}>
                            <PlayerTileBox mainClass={tile_classes.basetile}>
                                Long term (&gt;1 year) contract signed.
                            </PlayerTileBox>
                        </div>
                    }
                />
                <LegendItem
                    description={
                        <div className={classes.tile_container}>
                            <PlayerTileBox
                                mainClass={tile_classes.projected_ufa}
                            >
                                Projected UFA at end of season.
                            </PlayerTileBox>
                        </div>
                    }
                />
                <LegendItem
                    description={
                        <div className={classes.tile_container}>
                            <PlayerTileBox
                                mainClass={tile_classes.projected_rfa}
                            >
                                Projected RFA at end of season.
                            </PlayerTileBox>
                        </div>
                    }
                />
                <LegendItem
                    description={
                        <div className={classes.tile_container}>
                            <PlayerTileBox mainClass={tile_classes.ten_2c}>
                                Projected 10.2c at end of season.
                            </PlayerTileBox>
                        </div>
                    }
                />
                <LegendItem
                    description={
                        <div className={classes.tile_container}>
                            <PlayerTileBox
                                mainClass={tile_classes.ahl_contract}
                            >
                                AHL only contract.
                            </PlayerTileBox>
                        </div>
                    }
                />
                <LegendItem
                    description={
                        <div className={classes.tile_container}>
                            <PlayerTileBox
                                mainClass={
                                    tile_classes.ahl_contract +
                                    " " +
                                    tile_classes.unsigned
                                }
                            >
                                AHL only contract, player is also on NHL reserve
                                list.
                            </PlayerTileBox>
                        </div>
                    }
                />
                <LegendItem
                    description={
                        <div className={classes.tile_container}>
                            <PlayerTileBox mainClass={tile_classes.unsigned}>
                                Unsigned reserve list player.
                            </PlayerTileBox>
                        </div>
                    }
                />
            </div>
        </LegendSection>
    );
};

export default PlayerFreeAgencyTileLegendSection;
