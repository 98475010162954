import { useEffect, useMemo, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { fetchTeam } from "../store/teams-actions";
import { getPrimaryPosition } from "../utils";
import { PercentileClassIndicator } from "./indicators";
import HorizontalBarIndicator from "../controls/HorizontalBarIndicator";
import { MIN_ES_5V5_THRESHOLD } from "../constants";
import TieredTeamPlayers from "./TieredTeamPlayers";
import useTeamTieredPlayers from "../hooks/use-team-tiered-players";
import usePlayerDatastash from "../hooks/use-player-datastashes";

import classes from "./TeamPlayerSeasonPerformanceView.module.css";
import { mp_track } from "../mixpanel";

const team_player_season_performance_mp_track = (
    team,
    event,
    properties = null
) => {
    properties = {
        ...properties,
        team_id: team.id,
        team_name: team.display_name,
    };

    mp_track(event, properties);
};

const GoalieTradStatBar = ({
    SavePercentage,
    percentile,
    inverseColors,
    isFullCoverage = true,
    narrowLayout = false,
    labelColor = "var(--tw-gray-500)",
}) => {
    return (
        <div
            className={
                classes.trad_stat +
                " " +
                classes.goalie_stat +
                (narrowLayout ? " " + classes.narrow : "")
            }
        >
            <div className={classes.trad_stat_label}>es&nbsp;sv</div>
            <div className={classes.trad_stat_value}>
                <HorizontalBarIndicator
                    value={SavePercentage}
                    percentile={percentile}
                    barShowsPercentile={true}
                    includeLabel={true}
                    decimalPlaces={3}
                    inverseColors={inverseColors}
                    labelColor={
                        isFullCoverage ? labelColor : "var(--tw-orange-500)"
                    }
                />
            </div>
        </div>
    );
};

const SkaterTradStatBar = ({
    Goals,
    GoalsPercentile,
    Assists,
    AssistsPercentile,
    Points,
    PointsPercentile,
    inverseColors,
    isFullCoverage = true,
    narrowLayout = false,
    labelColor = "var(--tw-gray-500)",
}) => {
    return (
        <>
            {narrowLayout && (
                <>
                    <div
                        className={
                            classes.trad_stat +
                            (narrowLayout ? " " + classes.narrow : "")
                        }
                    >
                        <PercentileClassIndicator
                            label={"g"}
                            value={GoalsPercentile}
                            inverseColors={inverseColors}
                            pillLayout={isFullCoverage}
                        />
                        <div
                            className={
                                classes.trad_stat_value +
                                (isFullCoverage
                                    ? ""
                                    : " " + classes.partial_coverage)
                            }
                        >
                            {Goals.toFixed(isFullCoverage ? 0 : 2)}
                        </div>
                    </div>
                    <div
                        className={
                            classes.trad_stat +
                            (narrowLayout ? " " + classes.narrow : "")
                        }
                    >
                        <PercentileClassIndicator
                            label={"a"}
                            value={AssistsPercentile}
                            inverseColors={inverseColors}
                            pillLayout={isFullCoverage}
                        />
                        <div
                            className={
                                classes.trad_stat_value +
                                (isFullCoverage
                                    ? ""
                                    : " " + classes.partial_coverage)
                            }
                        >
                            {Assists.toFixed(isFullCoverage ? 0 : 2)}
                        </div>
                    </div>
                    <div
                        className={
                            classes.trad_stat +
                            (narrowLayout ? " " + classes.narrow : "")
                        }
                    >
                        <PercentileClassIndicator
                            label={"p"}
                            value={PointsPercentile}
                            inverseColors={inverseColors}
                            pillLayout={isFullCoverage}
                        />
                        <div
                            className={
                                classes.trad_stat_value +
                                (isFullCoverage
                                    ? ""
                                    : " " + classes.partial_coverage)
                            }
                        >
                            {Points.toFixed(isFullCoverage ? 0 : 2)}
                        </div>
                    </div>
                </>
            )}
            {!narrowLayout && (
                <>
                    <div className={classes.trad_stat}>
                        <div className={classes.trad_stat_label}>g</div>
                        <div className={classes.trad_stat_value}>
                            <HorizontalBarIndicator
                                value={Goals}
                                percentile={GoalsPercentile}
                                barShowsPercentile={true}
                                includeLabel={true}
                                decimalPlaces={isFullCoverage ? 0 : 2}
                                inverseColors={inverseColors}
                                labelColor={
                                    isFullCoverage
                                        ? labelColor
                                        : "var(--tw-orange-500)"
                                }
                            />
                        </div>
                    </div>
                    <div className={classes.trad_stat}>
                        <div className={classes.trad_stat_label}>a</div>
                        <div className={classes.trad_stat_value}>
                            <HorizontalBarIndicator
                                value={Assists}
                                percentile={AssistsPercentile}
                                barShowsPercentile={true}
                                includeLabel={true}
                                decimalPlaces={isFullCoverage ? 0 : 2}
                                inverseColors={inverseColors}
                                labelColor={
                                    isFullCoverage
                                        ? labelColor
                                        : "var(--tw-orange-500)"
                                }
                            />
                        </div>
                    </div>
                    <div className={classes.trad_stat}>
                        <div className={classes.trad_stat_label}>p</div>
                        <div className={classes.trad_stat_value}>
                            <HorizontalBarIndicator
                                value={Points}
                                percentile={PointsPercentile}
                                barShowsPercentile={true}
                                includeLabel={true}
                                decimalPlaces={isFullCoverage ? 0 : 2}
                                inverseColors={inverseColors}
                                labelColor={
                                    isFullCoverage
                                        ? labelColor
                                        : "var(--tw-orange-500)"
                                }
                            />
                        </div>
                    </div>
                </>
            )}
        </>
    );
};

const SeasonPerformancePlayerTile = ({
    player,
    tileData,
    position,
    tier,
    mainContentWidth,
}) => {
    return (
        <>
            <div className={classes.player_trad_stats}>
                {position === "G" && (
                    <GoalieTradStatBar
                        SavePercentage={tileData.trad_stats.metrics.SP}
                        percentile={tileData.trad_stats.metrics.SPperc}
                        inverseColors={
                            tileData.metrics.es_5v5_toi <
                            MIN_ES_5V5_THRESHOLD[position]
                        }
                        isFullCoverage={tileData.metrics.full_coverage}
                        labelColor={"var(--tw-gray-500)"}
                        narrowLayout={mainContentWidth < 500}
                    />
                )}
                {position !== "G" && (
                    <SkaterTradStatBar
                        Goals={
                            tileData.metrics?.full_coverage
                                ? tileData.trad_stats?.trad_stats[
                                      "Total Regular Goals"
                                  ]
                                : tileData.trad_stats.metrics.G
                        }
                        GoalsPercentile={tileData.trad_stats?.metrics.Gperc}
                        Assists={
                            tileData.metrics?.full_coverage
                                ? tileData.trad_stats?.trad_stats[
                                      "Total Assists"
                                  ]
                                : tileData.trad_stats.metrics.A
                        }
                        AssistsPercentile={tileData.trad_stats?.metrics.Aperc}
                        Points={
                            tileData.metrics?.full_coverage
                                ? tileData.trad_stats?.trad_stats[
                                      "Total Points"
                                  ]
                                : tileData.trad_stats.metrics.P
                        }
                        PointsPercentile={tileData.trad_stats?.metrics.Pperc}
                        isFullCoverage={tileData.metrics?.full_coverage}
                        inverseColors={
                            tileData.metrics &&
                            tileData.metrics.es_5v5_toi <
                                MIN_ES_5V5_THRESHOLD[position]
                        }
                        labelColor={"var(--tw-gray-500)"}
                        narrowLayout={mainContentWidth < 500}
                    />
                )}
            </div>
        </>
    );
};

const playerSort = (a, b, tier, position) => {
    if (!a || !b) {
        return b ? 1 : a ? -1 : 0;
    }

    // Compare ES Save %
    if (position === "G") {
        return b.data.trad_stats.metrics.SP - a.data.trad_stats.metrics.SP;
    }

    // Compare Total Points, then Total Regular Goals
    else if (!["JCE"].includes(tier)) {
        return a.data.trad_stats.trad_stats["Total Points"] ===
            b.data.trad_stats.trad_stats["Total Points"]
            ? b.data.trad_stats.trad_stats["Total Regular Goals"] -
                  a.data.trad_stats.trad_stats["Total Regular Goals"]
            : b.data.trad_stats.trad_stats["Total Points"] -
                  a.data.trad_stats.trad_stats["Total Points"];
    }

    // Compare Points per 60
    else {
        return b.trad_stats.metrics.P - a.trad_stats.metrics.P;
    }
};

const TeamPlayerSeasonPerformanceView = ({
    teamId,
    metricsSeasonId = null,
    onPlayerClick,
    mainContentWidth,
    onContentLoading,
}) => {
    const dispatch = useDispatch();

    const players = useSelector((state) => state.players.players);
    const teams = useSelector((state) => state.teams.teams);

    const {
        setTeamId,
        setPlayersData,
        isLoading: tieredPlayersIsloading,
        tieredPlayersData,
    } = useTeamTieredPlayers(teamId);

    const team = teams && teams[teamId];

    const currentSeasonId = useSelector(
        (state) => state.ui.systemConfiguration?.currentSeasonId
    );
    const seasonId = metricsSeasonId ? metricsSeasonId : currentSeasonId;

    const teamIdRef = useRef(null);
    useEffect(() => {
        if (team && teamIdRef.current !== team.id) {
            team_player_season_performance_mp_track(
                team,
                "View NHL Team Player Season Performance"
            );
            teamIdRef.current = team.id;
            setTeamId(team.id);
        }
    }, [team, setTeamId]);

    const teams_reserveListPlayerIds = useSelector(
        (state) => state.teams.reserveListPlayerIds[teamId]
    );

    const all_requiredPlayerIds = useMemo(() => {
        // Ensure teams_reserveListPlayerIds is an array, even when null
        const safeTeamsReserveListPlayerIds = teams_reserveListPlayerIds || [];
        return safeTeamsReserveListPlayerIds;
    }, [teams_reserveListPlayerIds]);

    const players_seasondatastashes = useSelector(
        (state) => state.players.seasondatastashes
    );
    const players_datastashes = useSelector(
        (state) => state.players.datastashes
    );

    const {
        setRequiredPlayerIds,
        setSeasonId,
        addRequiredDatastash,
        completedDatastashes,
        addRequiredSeasonDatastash,
        completedSeasonDatastashes,
    } = usePlayerDatastash();

    useEffect(() => {
        setSeasonId(seasonId);
    }, [seasonId, setSeasonId]);

    useEffect(() => {
        setRequiredPlayerIds(all_requiredPlayerIds);
    }, [all_requiredPlayerIds, setRequiredPlayerIds]);

    useEffect(() => {
        addRequiredDatastash("career_performance_summary");
        addRequiredSeasonDatastash("latest_position_metrics");
    }, [addRequiredSeasonDatastash, addRequiredDatastash]);

    useEffect(() => {
        if (completedDatastashes?.career_performance_summary === false) {
            onContentLoading && onContentLoading(true);
        }
    }, [
        completedDatastashes.career_performance_summary,
        onContentLoading,
        dispatch,
    ]);

    useEffect(() => {
        if (completedSeasonDatastashes?.latest_position_metrics === false) {
            onContentLoading && onContentLoading(true);
        }
    }, [
        completedSeasonDatastashes.latest_position_metrics,
        onContentLoading,
        dispatch,
    ]);

    useEffect(() => {
        if (
            teams_reserveListPlayerIds &&
            completedSeasonDatastashes.latest_position_metrics &&
            completedDatastashes.career_performance_summary
        ) {
            setPlayersData(
                teams_reserveListPlayerIds.reduce((playersMap, playerId) => {
                    const metrics =
                        players_seasondatastashes[playerId]
                            ?.latest_position_metrics &&
                        players_seasondatastashes[playerId]
                            .latest_position_metrics[seasonId] &&
                        players_seasondatastashes[playerId]
                            .latest_position_metrics[seasonId];
                    const trad_stats =
                        players_datastashes[playerId]
                            ?.career_performance_summary &&
                        players_datastashes[playerId].career_performance_summary
                            .competitions &&
                        players_datastashes[
                            playerId
                        ].career_performance_summary.competitions.reduce(
                            (competitionsMap, competitionData) => {
                                competitionsMap[competitionData.id] =
                                    competitionData.seasons.filter((season) => {
                                        return season.season_id === seasonId;
                                    });
                                if (
                                    competitionsMap[competitionData.id]
                                        .length === 0
                                ) {
                                    delete competitionsMap[competitionData.id];
                                } else {
                                    competitionsMap[competitionData.id] =
                                        competitionsMap[competitionData.id][0];
                                }

                                return competitionsMap;
                            },
                            {}
                        );

                    const playerCompetitionIds = new Set([
                        ...Object.keys(metrics ?? {}),
                        ...Object.keys(trad_stats ?? {}),
                    ]);

                    const mergedMap = Array.from(playerCompetitionIds).reduce(
                        (accumulator, competitionId) => {
                            // Extract the metric and trad_stats for the current competition id
                            const metric = metrics?.[competitionId];
                            const trad_stat = trad_stats?.[competitionId];

                            // Create a new entry in the accumulator for this competition id
                            // If either metric or trad_stat is undefined, it won't be added to the merged object
                            if (metric && trad_stat) {
                                accumulator[competitionId] = {
                                    ...{ metrics: metric },
                                    ...{ trad_stats: trad_stat },
                                    partialCoverage: !metric.full_coverage,
                                    gameCount: trad_stat.games_played,
                                };
                            }

                            return accumulator;
                        },
                        {}
                    );

                    playersMap[playerId] = mergedMap;
                    return playersMap;
                }, {})
            );
        } else {
            setPlayersData({});
        }
    }, [
        completedSeasonDatastashes.latest_position_metrics,
        completedDatastashes.career_performance_summary,
        players_datastashes,
        teams_reserveListPlayerIds,
        players_seasondatastashes,
        seasonId,
        setPlayersData,
    ]);

    // Fetch required data
    useEffect(() => {
        if (!teams[teamId]) {
            dispatch(fetchTeam(teamId));
            onContentLoading && onContentLoading(true);
        }
    }, [teamId, teams, onContentLoading, dispatch]);

    useEffect(() => {
        if (teams && team && !teams[team.affiliations[0]]) {
            dispatch(fetchTeam(team.affiliations[0]));
            onContentLoading && onContentLoading(true);
        }
    }, [team, teams, onContentLoading, dispatch]);

    useEffect(() => {
        if (tieredPlayersIsloading) {
            onContentLoading && onContentLoading(true);
        }
    }, [tieredPlayersIsloading, onContentLoading, dispatch]);

    useEffect(() => {
        if (
            team &&
            completedDatastashes.career_performance_summary &&
            completedSeasonDatastashes.latest_position_metrics &&
            !tieredPlayersIsloading
        ) {
            onContentLoading && onContentLoading(false);
        }
    }, [
        team,
        completedDatastashes.career_performance_summary,
        completedSeasonDatastashes.latest_position_metrics,
        tieredPlayersIsloading,
        onContentLoading,
        dispatch,
    ]);

    const getPeerData = (tier, playerId) => {
        const tierData = tieredPlayersData.find(
            (tierData) => tierData.tier === tier
        );
        if (!tierData) return null;

        const primaryPosition = getPrimaryPosition(players[playerId].position);
        const positionData = tierData.data.find(
            (positionData) => positionData.position === primaryPosition
        );
        if (!positionData) return null;

        return positionData.players.map((player) => {
            return player.data;
        });
    };

    const handlePlayerClicked = (playerId, competitionId) => {
        const peerData =
            competitionId === 1
                ? getPeerData("NHL", playerId)
                : competitionId === 2
                ? getPeerData("AHL", playerId)
                : null;
        onPlayerClick(playerId, competitionId, peerData);
    };

    return (
        <TieredTeamPlayers
            teamId={teamId}
            tieredPlayersData={tieredPlayersData}
            playerSort={playerSort}
            TileComponent={SeasonPerformancePlayerTile}
            mainContentWidth={mainContentWidth}
            onPlayerClick={handlePlayerClicked}
            prefixPositionLabels={true}
        />
    );
};

export default TeamPlayerSeasonPerformanceView;
