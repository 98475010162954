import { useDispatch } from "react-redux";
import { useState, useEffect, useRef, useCallback } from "react";

function useMultiResourceLoader(isLoadingHandler) {
    const dispatch = useDispatch();
    const [activeLoaders, setActiveLoaders] = useState([]);
    const previousLoadersCountRef = useRef(activeLoaders.length);

    const removeLoader = useCallback((loader) => {
        setActiveLoaders((prevState) => {
            return prevState.filter((activeLoader) => activeLoader !== loader);
        });
    }, []);

    const addLoader = useCallback(
        (loader, action) => {
            setActiveLoaders((prevState) => {
                if (!prevState.includes(loader)) {
                    if (action) {
                        const result = dispatch(action);
                        if (result instanceof Promise) {
                            result.then(() => {
                                removeLoader(loader);
                            });
                        }
                    }
                    return [...prevState, loader];
                } else {
                    return prevState;
                }
            });
        },
        [dispatch, removeLoader]
    );

    useEffect(() => {
        const previousLoadersCount = previousLoadersCountRef.current;
        const currentLoadersCount = activeLoaders.length;

        if (previousLoadersCount === 0 && currentLoadersCount > 0) {
            isLoadingHandler && isLoadingHandler(true);
        }
        if (previousLoadersCount > 0 && currentLoadersCount === 0) {
            isLoadingHandler && isLoadingHandler(false);
        }

        // Update the previous loaders count for the next effect run
        previousLoadersCountRef.current = currentLoadersCount;
    }, [activeLoaders, isLoadingHandler]);

    return { activeLoaders, addLoader, removeLoader };
}

export default useMultiResourceLoader;
