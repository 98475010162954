const COLORS = {
    primary: {
        low_tier: "var(--tw-red-500)",
        mid_tier: "var(--tw-slate-400)",
        high_tier: "var(--ch-blue-500)",
        top_tier: "var(--tw-green-500)",
        elite_tier: "var(--tw-yellow-500)",
        example_tier: "var(--tw-gray-500)",
    },
    inverse: {
        low_tier: "var(--tw-red-200)",
        mid_tier: "var(--tw-slate-200)",
        high_tier: "var(--ch-blue-200)",
        top_tier: "var(--tw-green-200)",
        example_tier: "var(--tw-gray-200)",
    },
};

export const TriangleDirectionalMarker = ({
    size = 10,
    direction,
    tierClass,
    inverseColors,
}) => {
    // Calculate coordinates for equilateral triangle
    const height = (size * Math.sqrt(3)) / 2;
    const offset = (size - height) / 2;
    const center = size / 2;
    let points;

    switch (direction) {
        case "up":
            points = `0,${offset + height} ${center},${offset} ${size},${
                offset + height
            }`;
            break;
        case "down":
            points = `0,${offset} ${center},${
                offset + height
            } ${size},${offset}`;
            break;
        case "left":
            points = `${offset},${center} ${offset + height},0 ${
                offset + height
            },${size}`;
            break;
        case "right":
            points = `${
                offset + height
            },${center} ${offset},0 ${offset},${size}`;
            break;
        default:
            points = `0,${offset + height} ${center},${offset} ${size},${
                offset + height
            }`;
            break;
    }

    const colorType = inverseColors ? "inverse" : "primary";
    const fill = COLORS[colorType][tierClass];

    return (
        <svg height={size} width={size}>
            <polygon points={points} fill={fill} />
        </svg>
    );
};

export const TrendIndicator = ({ size = 10, isTrendingUp, inverseColors }) => {
    const direction = isTrendingUp ? "up" : "down";
    const tierClass = isTrendingUp ? "top_tier" : "low_tier";

    return (
        <TriangleDirectionalMarker
            size={size}
            direction={direction}
            tierClass={tierClass}
            inverseColors={inverseColors}
        />
    );
};
