import { useEffect, useState } from "react";
import SearchInput from "./SearchInput";

const PlayerSearchInput = ({
    players,
    selectedPlayerId,
    onResultSelected,
    maxListLength = 5,
}) => {
    const [selectedPlayer, setSelectedPlayer] = useState(null);

    useEffect(() => {
        setSelectedPlayer(
            players.find((player) => player.id === selectedPlayerId)
        );
    }, [selectedPlayerId, players]);

    const handleSearchChange = (lcQuery) => {
        const results = Object.values(players)
            .filter((player) => {
                return (
                    player.known_name.toLowerCase().includes(lcQuery) ||
                    player.first_name.toLowerCase().includes(lcQuery) ||
                    player.last_name.toLowerCase().includes(lcQuery)
                );
            })
            .map((player) => {
                return {
                    id: player.id,
                    label: player.known_name,
                };
            });
        return results;
    };

    return (
        <SearchInput
            placeHolderText="Search player..."
            searchHandler={handleSearchChange}
            currentSelection={{
                id: selectedPlayer?.id,
                label: selectedPlayer?.known_name,
            }}
            onResultSelected={onResultSelected}
            maxListLength={maxListLength}
        />
    );
};

export default PlayerSearchInput;
