import classes from "./SquareButton.module.css";

const SquareButton = ({
    label = "+",
    useMaterialIcon = false,
    smallIcons = false,
    isSelected = false,
    isDisabled = false,
    isRotated = false,
    onClick,
    ...props
}) => {
    const handleClick = (e) => {
        if (!isDisabled && onClick) {
            onClick(e);
        }
    };

    return (
        <div
            onClick={handleClick}
            {...props}
            className={
                classes.base_button +
                (useMaterialIcon ? " " + classes.material : "") +
                (isSelected ? " " + classes.selected : "") +
                (isDisabled ? " " + classes.disabled : "") +
                (isRotated ? " " + classes.rotated : "")
            }
        >
            {!useMaterialIcon && (
                <div className={classes.base_button_label}>{label}</div>
            )}
            {useMaterialIcon && (
                <span
                    className={
                        "material-symbols-outlined" +
                        (smallIcons ? " " + classes.material_icons_small : "")
                    }
                >
                    {label}
                </span>
            )}
        </div>
    );
};

export default SquareButton;
