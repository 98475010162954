import { useEffect, useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
    fetchPlayerDevelopmentPriorityOutcomes,
    fetchPlayerDevelopmentSessionTypes,
} from "../store/playerdevelopmentoptions-actions";

export function usePlayerDevelopmentPriorityOutcomes() {
    const dispatch = useDispatch();

    const playerDevelopmentPriorityOutcomes = useSelector(
        (state) => state.playerdevelopmentoptions.developmentpriorityoutcomes
    );

    const playerDevelopmentPriorityOutcomesValues = useMemo(
        () =>
            Object.values(playerDevelopmentPriorityOutcomes).sort(
                (a, b) => a.order - b.order
            ),
        [playerDevelopmentPriorityOutcomes]
    );

    useEffect(() => {
        if (Object.keys(playerDevelopmentPriorityOutcomes).length === 0) {
            dispatch(fetchPlayerDevelopmentPriorityOutcomes());
        }
    }, [dispatch, playerDevelopmentPriorityOutcomes]);
    return {
        playerDevelopmentPriorityOutcomes,
        playerDevelopmentPriorityOutcomesValues,
    };
}

export function usePlayerDevelopmentSessionTypes() {
    const dispatch = useDispatch();

    const playerDevelopmentSessionTypes = useSelector(
        (state) => state.playerdevelopmentoptions.sessiontypes
    );

    const playerDevelopmentSessionTypesValues = useMemo(
        () =>
            Object.values(playerDevelopmentSessionTypes).sort(
                (a, b) => a.order - b.order
            ),
        [playerDevelopmentSessionTypes]
    );

    useEffect(() => {
        if (Object.keys(playerDevelopmentSessionTypes).length === 0) {
            dispatch(fetchPlayerDevelopmentSessionTypes());
        }
    }, [dispatch, playerDevelopmentSessionTypes]);

    return {
        playerDevelopmentSessionTypes,
        playerDevelopmentSessionTypesValues,
    };
}
