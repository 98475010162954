import { createSlice } from "@reduxjs/toolkit";

const seasonsSlice = createSlice({
    name: "seasons",
    initialState: {
        seasons: {},
    },
    reducers: {
        updateSeasons: (state, action) => {
            action.payload.forEach((season) => {
                state.seasons[season.id] = season;
            });
        },
    },
});

export const seasonsActions = seasonsSlice.actions;

export default seasonsSlice;
