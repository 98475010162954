import { player_layout_positions } from "../constants";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import classes from "./Playergrid.module.css";
import Playertile, { PlayerTileWidthLayouts } from "./playertile/Playertile";
import { useState, useEffect, useRef, useMemo } from "react";
import { PlayerTileModes } from "../store/ui-slice";
import useWindowDimensions from "../hooks/use-window-dimensions";

const InsertRowControl = ({ rowIndex, onInsertRow }) => {
    // row index is 0-based, so we add 1 to make it 1-based.
    const targetRow = rowIndex + 1;

    const [isVisible, setIsVisible] = useState(false);
    const [isHighlighted, setIsHighlighted] = useState(false);
    const controlRef = useRef(null);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (
                controlRef.current &&
                !controlRef.current.contains(event.target)
            ) {
                setIsVisible(false);
            }
        };

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    const handleAddRowClick = (e) => {
        e.stopPropagation();
        if (isVisible) {
            onInsertRow && onInsertRow(targetRow);
            setIsHighlighted(true);
            setTimeout(() => {
                setIsHighlighted(false);
                setIsVisible(false);
            }, 500); // 500 milliseconds for the highlight
        }
    };

    return (
        <div
            ref={controlRef}
            className={`${classes.insert_row_control} ${
                isVisible ? classes.visible : ""
            } ${isHighlighted ? classes.highlight : ""}`}
            onMouseEnter={() => setIsVisible(true)}
            onMouseLeave={() => setIsVisible(false)}
            onClick={() => setIsVisible(true)}
        >
            <div className={classes.insert_row_spacer}></div>
            <div
                className={classes.insert_row_button}
                onClick={handleAddRowClick}
            >
                <div className={classes.insert_row_button_label}>+</div>
            </div>
        </div>
    );
};

const RemoveRowControl = ({ rowIndex, onRemoveRow }) => {
    // row index is 0-based, so we add 1 to make it 1-based.
    const targetRow = rowIndex + 1;

    const [isVisible, setIsVisible] = useState(false);
    const [isHighlighted, setIsHighlighted] = useState(false);
    const controlRef = useRef(null);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (
                controlRef.current &&
                !controlRef.current.contains(event.target)
            ) {
                setIsVisible(false);
            }
        };

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    const handleRemoveRowClick = (e) => {
        e.stopPropagation();
        if (isVisible) {
            onRemoveRow && onRemoveRow(targetRow);
            setIsHighlighted(true);
            setTimeout(() => {
                setIsHighlighted(false);
                setIsVisible(false);
            }, 500); // 500 milliseconds for the highlight
        }
    };

    return (
        <div
            ref={controlRef}
            className={`${classes.remove_row_control} ${
                isVisible ? classes.visible : ""
            } ${isHighlighted ? classes.highlight : ""}`}
            onMouseEnter={() => setIsVisible(true)}
            onMouseLeave={() => setIsVisible(false)}
            onClick={() => setIsVisible(true)}
        >
            <div
                className={classes.remove_row_button}
                onClick={handleRemoveRowClick}
            >
                <div className={classes.remove_row_button_label}>x</div>
            </div>
        </div>
    );
};

const Playergrid = ({
    grid_content,
    updatesAllowed = false,
    onTileMoved,
    onTileClicked,
    onInsertRow,
    onRemoveRow,
    tileView = PlayerTileModes.PERFORMANCE,
    tightLayout = false,
    stackView = false,
    showProjectedFreeAgencyStatus = false,
    showDraftTeamOverlay = false,
    showOwningTeamOverlay = false,
    alternateColumnLabels = null,
    rowCountersGroupSize = null,
}) => {
    const { widthClasses } = useWindowDimensions([
        { min: 1430, max: Infinity }, // Wide layout
        { min: 1375, max: 1430 }, // Standard / Strong Overlay layout
        { min: 0, max: 1170 }, // Narrow layout
    ]);

    const [tileWidthLayout, setTileWidthLayout] = useState(
        widthClasses[0]?.matches
            ? PlayerTileWidthLayouts.WIDE
            : widthClasses[1]?.matches
            ? PlayerTileWidthLayouts.STANDARD_STRONG_OVERLAY
            : widthClasses[2]?.matches
            ? PlayerTileWidthLayouts.NARROW
            : PlayerTileWidthLayouts.STANDARD
    );

    useEffect(() => {
        setTileWidthLayout(
            widthClasses[0]?.matches
                ? PlayerTileWidthLayouts.WIDE
                : widthClasses[1]?.matches
                ? PlayerTileWidthLayouts.STANDARD_STRONG_OVERLAY
                : widthClasses[2]?.matches
                ? PlayerTileWidthLayouts.NARROW
                : PlayerTileWidthLayouts.STANDARD
        );
    }, [widthClasses]);

    // This function is called when the drag ends.
    const handleDragEnd = (result) => {
        const { destination, source, draggableId } = result;

        // Ignore if no destination is available
        if (!destination) {
            return;
        }

        // Ignore if the source and destination are the same
        if (
            destination.droppableId === source.droppableId &&
            destination.index === source.index
        ) {
            return;
        }

        // Move player to the new cell and shift other players.
        // This function should be implemented to match your specific data structure.
        // movePlayer(source, destination, draggableId);

        // Call the callback function
        const [src_row, src_position] = source.droppableId.split("-");
        const [dest_row, dest_position] = destination.droppableId.split("-");

        onTileMoved(
            draggableId,
            parseInt(dest_row),
            dest_position,
            parseInt(src_row),
            src_position
        );
    };

    const row_count_labels = useMemo(() => {
        if (rowCountersGroupSize) {
            let prevCount = 0;
            let currentCount = 0;

            return grid_content.reduce((acc, row, idx) => {
                prevCount += currentCount;
                // Current count is the number of values that are not null in the row
                currentCount = Object.values(row).filter((v) => v).length;
                if (
                    Math.floor(prevCount / rowCountersGroupSize) !==
                        Math.floor(
                            (prevCount + currentCount) / rowCountersGroupSize
                        ) ||
                    idx === grid_content.length - 1
                ) {
                    acc[idx] = prevCount + currentCount;
                }
                return acc;
            }, {});
        }
        return null;
    }, [grid_content, rowCountersGroupSize]);

    return (
        <div
            className={
                classes.playergrid +
                (tightLayout ? " " + classes.tight_layout : "")
            }
        >
            <div
                className={
                    classes.playergrid_row + " " + classes.playergrid_header
                }
            >
                {player_layout_positions.map((position, idx) => {
                    return (
                        <div className={classes.playergrid_cell} key={position}>
                            {!stackView ||
                            grid_content.some((row) => row[position]) ? (
                                <div className={classes.position_label}>
                                    {alternateColumnLabels
                                        ? alternateColumnLabels[idx]
                                        : position}
                                </div>
                            ) : (
                                ""
                            )}
                        </div>
                    );
                })}
                <div
                    className={
                        classes.row_count_container +
                        (rowCountersGroupSize ? "" : " " + classes.no_count)
                    }
                >
                    <div className={classes.row_count}></div>
                </div>
            </div>
            <DragDropContext onDragEnd={handleDragEnd}>
                {grid_content.map((row, rowIndex) => {
                    return (
                        <div className={classes.playergrid_row} key={rowIndex}>
                            {player_layout_positions.map((position) => {
                                const nameDecoration =
                                    row[position] &&
                                    row[position].nameDecoration
                                        ? row[position].nameDecoration
                                        : null;
                                const nameDecorationClass =
                                    row[position] &&
                                    row[position].nameDecorationClass
                                        ? row[position].nameDecorationClass
                                        : null;
                                return (
                                    <Droppable
                                        droppableId={`${
                                            rowIndex + 1
                                        }-${position}`}
                                        key={`${rowIndex + 1}-${position}`}
                                        isDropDisabled={!updatesAllowed}
                                    >
                                        {(provided, snapshot) => (
                                            <div
                                                ref={provided.innerRef}
                                                {...provided.droppableProps}
                                                className={`${
                                                    classes.playergrid_cell +
                                                    (stackView
                                                        ? " " +
                                                          classes.stack_view
                                                        : "") +
                                                    (onTileMoved
                                                        ? " " +
                                                          classes.dnd_layout
                                                        : "")
                                                } ${
                                                    row[position]
                                                        ? ""
                                                        : classes.empty_cell
                                                } droppable ${
                                                    snapshot.isDraggingOver
                                                        ? classes.droppable_isDraggingOver
                                                        : ""
                                                }`}
                                            >
                                                {row[position] && (
                                                    <Draggable
                                                        draggableId={row[
                                                            position
                                                        ].id.toString()}
                                                        index={0}
                                                        isDragDisabled={
                                                            !updatesAllowed
                                                        }
                                                    >
                                                        {(
                                                            provided,
                                                            snapshot
                                                        ) => (
                                                            <div
                                                                ref={
                                                                    provided.innerRef
                                                                }
                                                                {...provided.draggableProps}
                                                                {...provided.dragHandleProps}
                                                                className={`${
                                                                    classes.playertile_container
                                                                } ${
                                                                    updatesAllowed &&
                                                                    classes.playertile_draggable
                                                                } draggable ${
                                                                    snapshot.isDragging
                                                                        ? classes.draggable_isDragging
                                                                        : ""
                                                                }`}
                                                            >
                                                                <Playertile
                                                                    player={
                                                                        row[
                                                                            position
                                                                        ]
                                                                    }
                                                                    namedecoration={
                                                                        nameDecoration
                                                                    }
                                                                    namedecorationclass={
                                                                        nameDecorationClass
                                                                    }
                                                                    tileView={
                                                                        tileView
                                                                    }
                                                                    onClick={
                                                                        onTileClicked
                                                                    }
                                                                    overlayDraftedTeam={
                                                                        showDraftTeamOverlay
                                                                    }
                                                                    overlayOwningTeam={
                                                                        showOwningTeamOverlay
                                                                    }
                                                                    showProjectedFreeAgencyStatus={
                                                                        showProjectedFreeAgencyStatus
                                                                    }
                                                                    widthLayout={
                                                                        tileWidthLayout
                                                                    }
                                                                />
                                                            </div>
                                                        )}
                                                    </Draggable>
                                                )}
                                                {provided.placeholder}
                                            </div>
                                        )}
                                    </Droppable>
                                );
                            })}
                            {updatesAllowed && (
                                <>
                                    {onInsertRow && (
                                        <InsertRowControl
                                            rowIndex={rowIndex}
                                            onInsertRow={onInsertRow}
                                        />
                                    )}
                                    {onRemoveRow &&
                                        !Object.values(row).some((v) => v) && (
                                            <RemoveRowControl
                                                rowIndex={rowIndex}
                                                onRemoveRow={onRemoveRow}
                                            />
                                        )}
                                </>
                            )}
                            <div
                                className={
                                    classes.row_count_container +
                                    (rowCountersGroupSize
                                        ? ""
                                        : " " + classes.no_count)
                                }
                            >
                                <div className={classes.row_count}>
                                    {row_count_labels?.[rowIndex]}
                                </div>
                            </div>
                        </div>
                    );
                })}
            </DragDropContext>
        </div>
    );
};

export default Playergrid;
