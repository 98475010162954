import Legend, { LegendBlock } from "../../controls/Legend";
import PlayerNameLineLegendSection from "./sections/PlayerNameLineLegendSection";
import PerformanceTrendsSection from "./sections/PerformanceTrendsSection";
import SkatersIndicatorsSection from "./sections/SkatersIndicatorsSection";
import PlayerProfileLegendSection from "./sections/PlayerProfileLegendSection";
import GoaliesIndicatorsSection from "./sections/GoaliesIndicatorsSection";
import PlayerFreeAgencyTileLegendSection from "./sections/PlayerFreeAgencyTileLegendSection";

const PlayerTradStatsTileLegend = ({
    includeTrends = false,
    includeFreeAgency = false,
    zIndex = 1000,
}) => {
    return (
        <Legend zIndex={zIndex}>
            <LegendBlock>
                {includeTrends && <PerformanceTrendsSection />}
                <PlayerNameLineLegendSection />
            </LegendBlock>
            <LegendBlock>
                <SkatersIndicatorsSection tradStats={true} />
                <PlayerProfileLegendSection />
            </LegendBlock>
            <LegendBlock>
                <GoaliesIndicatorsSection tradStats={true} />
                {includeFreeAgency && <PlayerFreeAgencyTileLegendSection />}
            </LegendBlock>
        </Legend>
    );
};

export default PlayerTradStatsTileLegend;
