import { useEffect, useState, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { fetchTeam, fetchTeamDatastash } from "../store/teams-actions";
import { fetchCompetitionActiveTeams } from "../store/competitions-actions";
import { uiActions } from "../store/ui-slice";
import AppPage from "../layout/AppPage";
import OrgHeader from "../layout/OrgHeader";
import MainContent from "../layout/MainContent";
import CompressiblePanel from "../layout/CompressiblePanel";
import MainBox from "../layout/MainBox";
import PreScoutLegend from "../components/legends/PreScoutLegend";
import Toggle from "../controls/buttons/Toggle";
import { TeamTileOverlay } from "../components/playertile/Playertile";
import { formatDateMonthDay } from "../utils";
import NavigationBar, {
    NavigationSetting,
    TeamSelector,
} from "../controls/NavigationBar";

import classes from "./PreScout.module.css";
import { mp_track } from "../mixpanel";

const pre_scout_mp_track = (team, showAHLTeam, event, properties = null) => {
    properties = {
        ...properties,
        team_id: team.id,
        team_name: team.display_name,
        show_ahl_team: showAHLTeam,
    };

    mp_track(event, properties, "team_prescout");
};

const PreScout = ({ initialTeamId, onMenuOpen }) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const error = useSelector((state) => state.ui.notification);

    const teams = useSelector((state) => state.teams.teams);
    // State to keep track of the selected team
    const [selectedTeamId, setSelectedTeamId] = useState(initialTeamId);

    const team = teams && teams[selectedTeamId];
    // If we are past the first few weeks of the 2024-25 season,
    // we can get rid of this, as no team will have a recent game
    // against the Arizona Coyotes anymore.
    const arizona_team_id = 2;
    const arizona_historical = useSelector(
        (state) => state.teams.teams[arizona_team_id]
    );
    const ahlTeamId = team && team.affiliations[0];
    const ahlTeam = team && teams[ahlTeamId];

    const nhl_active_teams = useSelector(
        (state) => state.competitions.activeTeams[1]
    );
    useEffect(() => {
        if (!nhl_active_teams) {
            dispatch(fetchCompetitionActiveTeams(1));
        }
    }, [nhl_active_teams, dispatch]);
    const ahl_active_teams = useSelector(
        (state) => state.competitions.activeTeams[2]
    );
    useEffect(() => {
        if (!ahl_active_teams) {
            dispatch(fetchCompetitionActiveTeams(2));
        }
    }, [ahl_active_teams, dispatch]);

    const all_active_teams = nhl_active_teams && ahl_active_teams;

    const [showAHLTeam, setShowAHLTeam] = useState(false);

    // Handler for team selection
    const handleSelectTeamId = (teamId) => {
        // Wait for the loader to show before changing the draft
        setTimeout(() => {
            setSelectedTeamId(teamId);
            // Update the navigation URL, and create a new entry in the browser history
            navigate(`/app/prescout/${teamId}`);
        }, 100);
    };

    const teamIdRef = useRef(null);
    useEffect(() => {
        if (team && teamIdRef.current !== team.id) {
            pre_scout_mp_track(team, showAHLTeam, "View NHL Pre Scout");
            teamIdRef.current = team.id;
        }
    }, [team, showAHLTeam]);

    const toggleShowAHLTeam = () => {
        setShowAHLTeam(!showAHLTeam);
        pre_scout_mp_track(
            team,
            !showAHLTeam,
            "Toggle Show AHL Team Pre Scout"
        );
    };

    const teams_datastashes = useSelector((state) => state.teams.datastashes);
    const pre_scout_report_metrics =
        teams_datastashes &&
        teams_datastashes[selectedTeamId] &&
        teams_datastashes[selectedTeamId].pre_scout_report_metrics;
    const ahl_pre_scout_report_metrics =
        teams_datastashes &&
        ahlTeamId &&
        teams_datastashes[ahlTeamId] &&
        teams_datastashes[ahlTeamId].pre_scout_report_metrics;
    const depth_chart_config =
        teams_datastashes &&
        teams_datastashes[selectedTeamId] &&
        teams_datastashes[selectedTeamId].depth_chart_config;

    const displayedMetrics = showAHLTeam
        ? ahl_pre_scout_report_metrics
        : pre_scout_report_metrics;

    const [selectedHelpMetric, setSelectedHelpMetric] = useState(null);
    const [metricHelpIsOpen, setMetricHelpIsOpen] = useState(false);

    const handleMetricHelpOpen = (metric) => {
        setSelectedHelpMetric(metric);
        setMetricHelpIsOpen(true);
    };

    const displayedTeam = showAHLTeam ? ahlTeam : team;

    // Fetch required team datastashes
    useEffect(() => {
        if (!teams[selectedTeamId]) {
            dispatch(fetchTeam(selectedTeamId));
            dispatch(uiActions.showLoader());
        }

        if (!arizona_historical) {
            dispatch(fetchTeam(arizona_team_id));
            dispatch(uiActions.showLoader());
        }

        if (!pre_scout_report_metrics) {
            dispatch(
                fetchTeamDatastash(selectedTeamId, "pre_scout_report_metrics")
            );
            dispatch(uiActions.showLoader());
        }

        if (!ahlTeam && ahlTeamId && teams && !teams[ahlTeamId]) {
            dispatch(fetchTeam(ahlTeamId));
            dispatch(uiActions.showLoader());
        }

        if (ahlTeamId && !ahl_pre_scout_report_metrics) {
            dispatch(fetchTeamDatastash(ahlTeamId, "pre_scout_report_metrics"));
            dispatch(uiActions.showLoader());
        }

        if (!depth_chart_config) {
            dispatch(fetchTeamDatastash(selectedTeamId, "depth_chart_config"));
            dispatch(uiActions.showLoader());
        }
    }, [
        selectedTeamId,
        teams,
        pre_scout_report_metrics,
        ahlTeamId,
        ahlTeam,
        ahl_pre_scout_report_metrics,
        depth_chart_config,
        arizona_historical,
        arizona_team_id,
        dispatch,
    ]);

    useEffect(() => {
        if (
            team &&
            pre_scout_report_metrics &&
            ahlTeam &&
            ahl_pre_scout_report_metrics &&
            arizona_historical &&
            depth_chart_config
        ) {
            dispatch(uiActions.hideLoader());
        }
    }, [
        team,
        pre_scout_report_metrics,
        ahlTeam,
        ahl_pre_scout_report_metrics,
        depth_chart_config,
        arizona_historical,
        dispatch,
    ]);

    const [mainContentWidth, setMainContentWidth] = useState(null);
    const [mainContentHeight, setMainContentHeight] = useState(null);

    return (
        // Setting a key on the AppPage component forces it to re-mount when
        // the teamId changes, which is necessary trigger the transition
        // effects.
        <AppPage key={"PreScout_" + selectedTeamId}>
            {displayedMetrics && (
                <PreScoutLegend zIndex={4} metrics={displayedMetrics} />
            )}
            <PreScoutLegend
                zIndex={4}
                metric={selectedHelpMetric}
                isOpen={metricHelpIsOpen}
                onClose={() => setMetricHelpIsOpen(false)}
            />
            <CompressiblePanel
                onWidthChange={setMainContentWidth}
                onHeightChange={setMainContentHeight}
            >
                <OrgHeader
                    fullTitle={
                        (team ? team.location + " " + team.name : "") +
                        " Pre Scout"
                    }
                    mediumTitle={(team ? team.location : "") + " Pre Scout"}
                    shortTitle={"Pre Scout"}
                    onOpen={onMenuOpen}
                    bgColor={team ? team.primary_colour : "#000"}
                    imgUrl={team ? team.alt_logo_url : ""}
                    imgPosition={
                        depth_chart_config
                            ? depth_chart_config.header_object_position
                            : "50% 50%"
                    }
                    parentWidth={mainContentWidth}
                    parentHeight={mainContentHeight}
                />
                <MainContent>
                    <div className={classes.prescout_report_container}>
                        {all_active_teams &&
                        displayedTeam &&
                        displayedMetrics ? (
                            <>
                                <NavigationBar>
                                    <TeamSelector
                                        selectedTeamId={selectedTeamId}
                                        onSelectTeam={handleSelectTeamId}
                                    />
                                    <NavigationSetting
                                        label={
                                            "Show " +
                                            ahlTeam.location +
                                            " " +
                                            ahlTeam.name +
                                            " (AHL)"
                                        }
                                    >
                                        <Toggle
                                            isChecked={showAHLTeam}
                                            onToggle={toggleShowAHLTeam}
                                        />
                                    </NavigationSetting>
                                </NavigationBar>

                                <MainBox>
                                    <div className={classes.pre_scout_header}>
                                        {displayedTeam.location}{" "}
                                        {displayedTeam.name} Best Opponents
                                        (Last{" "}
                                        {
                                            displayedMetrics[0]?.opponent_values
                                                .length
                                        }{" "}
                                        Games)
                                    </div>
                                    {displayedMetrics.length === 0 && (
                                        <div className={classes.no_data}>
                                            No data available
                                        </div>
                                    )}
                                    {displayedMetrics.map((metric) => (
                                        <div
                                            key={metric.metric}
                                            className={classes.pre_scout_metric}
                                        >
                                            <div
                                                className={
                                                    classes.pre_scout_metric_header
                                                }
                                            >
                                                {metric.metric.replace(
                                                    "{}",
                                                    displayedTeam.shorthand
                                                )}
                                            </div>
                                            <div
                                                className={
                                                    classes.pre_scout_metric_help_button
                                                }
                                                onClick={() =>
                                                    handleMetricHelpOpen(metric)
                                                }
                                            >
                                                <div
                                                    className={
                                                        classes.pre_scout_metric_help_button_label
                                                    }
                                                >
                                                    ?
                                                </div>
                                            </div>
                                            <div
                                                className={
                                                    classes.pre_scout_metric_content
                                                }
                                            >
                                                {metric.opponent_values
                                                    .slice(0, 3)
                                                    .map(
                                                        (
                                                            opponent_value,
                                                            index
                                                        ) => (
                                                            <div
                                                                key={index}
                                                                className={
                                                                    classes.pre_scout_metric_opponent
                                                                }
                                                            >
                                                                <TeamTileOverlay
                                                                    teamOverlay={
                                                                        teams[
                                                                            opponent_value
                                                                                .opponent_team_id
                                                                        ]
                                                                    }
                                                                    forceSmall={
                                                                        true
                                                                    }
                                                                />
                                                                <div
                                                                    className={
                                                                        classes.pre_scout_metric_tile_content
                                                                    }
                                                                >
                                                                    <div
                                                                        className={
                                                                            classes.pre_scout_metric_opponent_name
                                                                        }
                                                                    >
                                                                        {
                                                                            teams[
                                                                                opponent_value
                                                                                    .opponent_team_id
                                                                            ]
                                                                                .shorthand
                                                                        }
                                                                    </div>
                                                                    <div
                                                                        className={
                                                                            classes.pre_scout_metric_game_date
                                                                        }
                                                                    >
                                                                        {formatDateMonthDay(
                                                                            opponent_value.date
                                                                        )}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        )
                                                    )}
                                            </div>
                                        </div>
                                    ))}
                                </MainBox>
                            </>
                        ) : error ? (
                            <section>
                                <div>{error.title}</div>
                                <div>{error.message}</div>
                            </section>
                        ) : (
                            <div>Loading...</div>
                        )}
                    </div>
                    <div className={classes.spacer} />
                </MainContent>
            </CompressiblePanel>
        </AppPage>
    );
};

export default PreScout;
