import { useSelector } from "react-redux";
import OverheadTitleSection from "../layout/OverheadTitleSection";
import MainBox from "../layout/MainBox";
import HorizontalSliderIndicator from "../controls/HorizontalSliderIndicator";
import classes from "./TieredTeamPlayers.module.css";

const TieredTeamPlayers = ({
    tieredPlayersData,
    TileComponent,
    playerSort,
    mainContentWidth,
    onPlayerClick,
    prefixPositionLabels = false,
    wideTileContent = false,
}) => {
    const error = useSelector((state) => state.ui.notification);

    // The tier selection is currently not used, but may be used in the future.
    // For now, we just set it to "All", and we can change the constant to a
    // useState if we want to make it dynamic.
    const tierSelection = "All";

    const positionLabels = {
        F: "Forwards",
        D: "Defencemen",
        G: "Goalies",
    };

    return tieredPlayersData.length > 0 ? (
        <div className={classes.team_tiers}>
            {tieredPlayersData
                .filter(
                    (tier) => tierSelection === "All" || tier === tierSelection
                )
                .map((currentTier) => {
                    return (
                        <MainBox key={currentTier.tier}>
                            {currentTier.label && (
                                <div className={classes.tier_label}>
                                    {currentTier.label}
                                </div>
                            )}
                            <div
                                className={
                                    classes.all_positions_container +
                                    (mainContentWidth >= 850
                                        ? " " + classes.wide
                                        : "")
                                }
                            >
                                {currentTier.data.map((positionData) => {
                                    return (
                                        <div
                                            className={`${
                                                classes.section_container
                                            } ${
                                                classes[
                                                    "pos_" +
                                                        positionData.position
                                                ]
                                            }`}
                                            key={positionData.position}
                                        >
                                            <OverheadTitleSection
                                                title={
                                                    (prefixPositionLabels
                                                        ? (currentTier.tier !==
                                                          "JCE"
                                                              ? currentTier.tier
                                                              : "Other") + " "
                                                        : "") +
                                                    positionLabels[
                                                        positionData.position
                                                    ]
                                                }
                                                maxWidth={"500px"}
                                            >
                                                <div
                                                    className={
                                                        classes.position_chart_layout
                                                    }
                                                >
                                                    <div
                                                        className={
                                                            classes.position_chart_container
                                                        }
                                                    >
                                                        {positionData.weighedPositionAverage && (
                                                            <div
                                                                className={
                                                                    classes.position_chart_player_container +
                                                                    " " +
                                                                    classes.position_chart_header
                                                                }
                                                            >
                                                                <div
                                                                    className={
                                                                        classes.position_chart_player_details +
                                                                        (wideTileContent
                                                                            ? " " +
                                                                              classes.narrow
                                                                            : "")
                                                                    }
                                                                ></div>
                                                                <div
                                                                    className={
                                                                        classes.position_chart_player_index
                                                                    }
                                                                >
                                                                    <HorizontalSliderIndicator
                                                                        title={
                                                                            "Avg " +
                                                                            positionData.weighedPositionAverage.value.toFixed(
                                                                                2
                                                                            )
                                                                        }
                                                                        value={
                                                                            positionData
                                                                                .weighedPositionAverage
                                                                                .value
                                                                        }
                                                                        tierClass={
                                                                            positionData
                                                                                .weighedPositionAverage
                                                                                .tierClass
                                                                        }
                                                                    />
                                                                </div>
                                                            </div>
                                                        )}
                                                        {positionData.players
                                                            .length === 0 && (
                                                            <div
                                                                className={
                                                                    classes.no_players
                                                                }
                                                            >
                                                                No qualifying
                                                                players.
                                                            </div>
                                                        )}
                                                        {positionData.players
                                                            .sort((a, b) => {
                                                                return playerSort(
                                                                    a,
                                                                    b,
                                                                    currentTier,
                                                                    positionData.position
                                                                );
                                                            })
                                                            .map((player) => {
                                                                return (
                                                                    <div
                                                                        className={
                                                                            classes.position_chart_player_container +
                                                                            " " +
                                                                            classes.clickable +
                                                                            (player
                                                                                .player
                                                                                .nhl_rights_owner_team_id
                                                                                ? ""
                                                                                : " " +
                                                                                  classes.ahl_contract)
                                                                        }
                                                                        key={
                                                                            player
                                                                                .player
                                                                                .id
                                                                        }
                                                                        onClick={() => {
                                                                            onPlayerClick &&
                                                                                onPlayerClick(
                                                                                    player
                                                                                        .player
                                                                                        .id,
                                                                                    player
                                                                                        .player
                                                                                        .targetCompetition
                                                                                );
                                                                        }}
                                                                    >
                                                                        <div
                                                                            className={
                                                                                classes.position_chart_player_details +
                                                                                (wideTileContent
                                                                                    ? " " +
                                                                                      classes.narrow
                                                                                    : "")
                                                                            }
                                                                        >
                                                                            <div
                                                                                className={
                                                                                    classes.position_chart_player_name
                                                                                }
                                                                            >
                                                                                {
                                                                                    player
                                                                                        .player
                                                                                        .known_name
                                                                                }
                                                                            </div>
                                                                            {![
                                                                                "NHL",
                                                                                "OFF_ROSTER",
                                                                                "AHL",
                                                                            ].includes(
                                                                                currentTier.tier
                                                                            ) && (
                                                                                <div
                                                                                    className={
                                                                                        classes.namedecoration +
                                                                                        (player
                                                                                            .data
                                                                                            ?.partialCoverage
                                                                                            ? " " +
                                                                                              classes.partial_coverage
                                                                                            : "")
                                                                                    }
                                                                                >
                                                                                    <div
                                                                                        className={
                                                                                            classes.competition_name
                                                                                        }
                                                                                    >
                                                                                        {
                                                                                            player
                                                                                                .player
                                                                                                .JCECompetition
                                                                                                .shorthand
                                                                                        }
                                                                                    </div>
                                                                                    <div
                                                                                        className={
                                                                                            classes.games_indicator +
                                                                                            (player
                                                                                                .data
                                                                                                ?.partialCoverage
                                                                                                ? " " +
                                                                                                  classes.partial_coverage
                                                                                                : "")
                                                                                        }
                                                                                    >
                                                                                        {player
                                                                                            .data
                                                                                            ?.gameCount &&
                                                                                            (player
                                                                                                .data
                                                                                                ?.partialCoverage
                                                                                                ? "gwd "
                                                                                                : "gp ") +
                                                                                                player
                                                                                                    .data
                                                                                                    .gameCount}
                                                                                    </div>
                                                                                </div>
                                                                            )}
                                                                        </div>
                                                                        <TileComponent
                                                                            player={
                                                                                player.player
                                                                            }
                                                                            tier={
                                                                                currentTier.tier
                                                                            }
                                                                            position={
                                                                                positionData.position
                                                                            }
                                                                            tileData={
                                                                                player.data
                                                                            }
                                                                            mainContentWidth={
                                                                                mainContentWidth
                                                                            }
                                                                        />
                                                                    </div>
                                                                );
                                                            })}
                                                    </div>
                                                </div>
                                            </OverheadTitleSection>
                                        </div>
                                    );
                                })}
                            </div>
                        </MainBox>
                    );
                })}
        </div>
    ) : error ? (
        <section>
            <div>{error.title}</div>
            <div>{error.message}</div>
        </section>
    ) : (
        <div>Loading...</div>
    );
};

export default TieredTeamPlayers;
