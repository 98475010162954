import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { fetchCompetitionSeasonsDatastash } from "../store/competitions-actions";
import useMultiResourceLoader from "../hooks/use-multi-resource-loader";
import TimeSeriesChart from "../controls/TimeSeriesChart";
import { earliestFullSeasonYearCompetitionMap } from "../constants";

import classes from "./PlayerMetricCareerProgressionChart.module.css";

const TIER_BANDS_COLORS = {
    elite: "var(--tw-yellow-400)",
    top: "var(--tw-green-500)",
    high: "var(--ch-blue-500)",
    mid: "var(--tw-slate-400)",
    low: "var(--ch-red-500)",
};

const CompetitionMetricHistoricalTiersTrendChart = ({
    competitionId,
    primaryPosition,
    metric,
    width,
    height,
    margin,
    autoScaleYAxis = false,
    isLoadingHandler,
}) => {
    const { addLoader } = useMultiResourceLoader(isLoadingHandler);

    const dispatch = useDispatch();
    const seasons = useSelector((state) => state.seasons.seasons);

    const competition_seasondatastashes = useSelector(
        (state) => state.competitions.seasondatastashes[competitionId]
    );
    const competition_completeseasondatastashes = useSelector(
        (state) => state.competitions.completeseasondatastashes[competitionId]
    );

    const latest_metrics_tiers_thresholds =
        competition_completeseasondatastashes &&
        competition_completeseasondatastashes.latest_position_metrics_tiers_thresholds &&
        competition_seasondatastashes &&
        competition_seasondatastashes.latest_position_metrics_tiers_thresholds &&
        Object.entries(
            competition_seasondatastashes.latest_position_metrics_tiers_thresholds
        ).reduce((acc, [k, v]) => {
            if (v[primaryPosition].latest) {
                acc[k] = v[primaryPosition];
            }
            return acc;
        }, {});

    useEffect(() => {
        if (competitionId && !latest_metrics_tiers_thresholds) {
            addLoader("latest_metrics_tiers_thresholds", () =>
                dispatch(
                    fetchCompetitionSeasonsDatastash(
                        competitionId,
                        "latest_position_metrics_tiers_thresholds"
                    )
                )
            );
        }
    }, [dispatch, competitionId, latest_metrics_tiers_thresholds, addLoader]);

    // We may use this later
    const no_data = false;

    // Get the current date and time. The current season year is the current
    // year if we are past July 1st, otherwise it is the previous year.
    const today = new Date();
    const currentSeasonYear =
        today.getMonth() >= 6 ? today.getFullYear() : today.getFullYear() - 1;

    const earliestSeasonYear = Math.min(
        ...Object.values(earliestFullSeasonYearCompetitionMap)
    );

    // We add 1 to the max seasonyear, since we will set dates to Jan 1st
    // of each year.
    const maxSeasonYear = currentSeasonYear + 1;

    const minDate = earliestSeasonYear && new Date(earliestSeasonYear, 0, 1);
    const maxDate = maxSeasonYear && new Date(maxSeasonYear, 0, 1);

    const dataBands =
        latest_metrics_tiers_thresholds &&
        Object.values(latest_metrics_tiers_thresholds)[0].tier_categories.map(
            (tier_category, idx) => {
                return {
                    name: "tierband_" + tier_category,
                    color: TIER_BANDS_COLORS[tier_category],
                    showPoints: true,
                    data: Object.entries(
                        latest_metrics_tiers_thresholds
                    ).reduce((acc, [seasonId, data]) => {
                        const metricIdx = data.metrics.indexOf(metric);
                        if (metricIdx === -1) {
                            return acc;
                        }

                        const thresholds = data.latest.thresholds[metricIdx];

                        acc.push({
                            x: new Date(
                                seasons[seasonId].name.substring(0, 4),
                                6,
                                1
                            ),
                            y_min: Math.min(
                                thresholds[idx],
                                thresholds[idx + 1]
                            ),
                            y_max: Math.max(
                                thresholds[idx],
                                thresholds[idx + 1]
                            ),
                        });

                        acc.push({
                            x: new Date(
                                seasons[seasonId].name.substring(5, 9),
                                6,
                                1
                            ),
                            y_min: Math.min(
                                thresholds[idx],
                                thresholds[idx + 1]
                            ),
                            y_max: Math.max(
                                thresholds[idx],
                                thresholds[idx + 1]
                            ),
                        });

                        return acc;
                    }, []),
                };
            }
        );

    const timeAreas = [];

    // Horizontal ticks are an array of objects. There is one entry per year between earliestSeasonYear and maxSeasonYear.
    // Each entry is an object with the following properties:
    // - x: The date of the tick, which is Jul 1st of the year.
    // - label: The label to display for the tick, which is the player's age that season.
    // - id: an id for the tick, which is also the player's age that season.
    const horizontalTicks = [];
    for (let year = earliestSeasonYear; year < maxSeasonYear; year++) {
        horizontalTicks.push({
            x: new Date(year, 6, 1),
            // label is the last two digits of the year.
            label:
                year.toString().substring(2, 4) +
                "-" +
                (year + 1).toString().substring(2, 4),
            id: year,
        });
    }

    return (
        <>
            {no_data && <div className={classes.no_data}>No Data</div>}
            {!no_data && (
                <TimeSeriesChart
                    width={width}
                    height={height}
                    margin={margin}
                    minY={autoScaleYAxis ? null : 0}
                    maxY={autoScaleYAxis ? null : 1}
                    stepsY={4}
                    bands={dataBands}
                    timeAreas={timeAreas}
                    minDate={minDate}
                    maxDate={maxDate}
                    horizontalTicks={horizontalTicks}
                    includeHorizontalTicksGrid={true}
                />
            )}
        </>
    );
};

export default CompetitionMetricHistoricalTiersTrendChart;
