import React, { useEffect, useState, useCallback } from "react";
import { useDispatch } from "react-redux";
import AppPage from "../layout/AppPage";
import OrgHeader from "../layout/OrgHeader";
import MainContent from "../layout/MainContent";
import MainBox from "../layout/MainBox";
import { uiActions } from "../store/ui-slice";
import { mp_track } from "../mixpanel";
import classes from "./PlayerSearchPage.module.css";
import { useNavigate } from "react-router-dom";
import PlayerSearchResultsList from "../controls/PlayerSearchResultsList";

const player_search_page_mp_track = (query, event, properties = null) => {
    properties = {
        ...properties,
        query: query,
    };

    mp_track(event, properties);
};

const PlayerSearchPage = ({ query = "", onQueryUpdate, onMenuOpen }) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [searchQuery, setSearchQuery] = useState(query);
    const [formQuery, setFormQuery] = useState(query);
    const [searchResults, setSearchResults] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        player_search_page_mp_track(null, "Player Search Page Loaded");
        dispatch(uiActions.hideLoader());
    }, [dispatch]);

    const handleSearch = useCallback(async () => {
        if (!searchQuery.trim()) return;
        setIsLoading(true);
        player_search_page_mp_track(searchQuery, "Player Search Query");
        try {
            const response = await fetch(
                process.env.REACT_APP_HABSHUB_API_BASE_URL +
                    `hockey_analytics/search/players?query=${searchQuery}`
            );
            if (!response.ok) {
                throw new Error("Failed to fetch search results");
            }
            const data = await response.json();
            setSearchResults(data || []);
        } catch (error) {
            console.error("Failed to fetch search results:", error);
        } finally {
            setIsLoading(false);
        }
    }, [searchQuery]);

    // Function to navigate to the individual player's page or the search page
    const handleResultSelect = (playerId) => {
        navigate(`/app/players/${playerId}/performance`); // Update this URL to your player detail page URL
    };

    useEffect(() => {
        setFormQuery(query);
        setSearchQuery(query);
    }, [query]);

    useEffect(() => {
        if (searchQuery) {
            handleSearch();
        }
    }, [searchQuery, handleSearch]);

    const handleSubmit = (event) => {
        event.preventDefault();
        if (onQueryUpdate) {
            onQueryUpdate({
                query: formQuery,
            });
        }
        setSearchQuery(formQuery);
    };

    return (
        <AppPage>
            <OrgHeader
                fullTitle={"Player Search"}
                mediumTitle={"Player Search"}
                shortTitle={"Player Search"}
                onOpen={onMenuOpen}
                imgUrl={
                    "https://s3.amazonaws.com/slmediaimages/leagues/NHL.svg"
                }
                imgPosition={"50% 50%"}
            />
            <MainContent>
                <MainBox>
                    <div className={classes.searchPageContent}>
                        <form
                            onSubmit={handleSubmit}
                            className={classes.searchForm}
                        >
                            <input
                                type="text"
                                value={formQuery}
                                onChange={(e) => setFormQuery(e.target.value)}
                                placeholder="Search players..."
                                className={classes.searchInput}
                            />
                            <button
                                type="submit"
                                className={classes.searchButton}
                                disabled={!formQuery.trim() || isLoading}
                            >
                                {isLoading ? "..." : "Search"}
                            </button>
                        </form>
                        <div className={classes.results_container}>
                            <PlayerSearchResultsList
                                query={searchQuery.trim()}
                                searchResults={searchResults}
                                onResultSelected={handleResultSelect}
                                lightMode={true}
                            />
                        </div>
                    </div>
                </MainBox>
            </MainContent>
        </AppPage>
    );
};

export default PlayerSearchPage;
