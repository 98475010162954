import { useState, useEffect, useMemo, useCallback } from "react";

const useTradeMarkersManager = () => {
    const [rawTradeDateRanges, setRawTradeDateRanges] = useState([]);
    const [tradeMarkerDates, setTradeMarkerDates] = useState([]);

    const resetTradeMarkers = useCallback(() => {
        setRawTradeDateRanges([]);
        setTradeMarkerDates([]);
    }, []);

    const handleNewTradeDateRange = useCallback(
        (newTradeDateRange) => {
            // If the new trade date range is NOT in the list of raw trade date ranges, add it and process it
            // date ranges are an array of two dates, the start and end of the range, so the check needs to compare the two dates
            if (
                !rawTradeDateRanges.some(
                    (rawTradeDateRange) =>
                        rawTradeDateRange[0].getTime() ===
                            newTradeDateRange[0].getTime() &&
                        rawTradeDateRange[1].getTime() ===
                            newTradeDateRange[1].getTime()
                )
            ) {
                // Add the new trade date range to the list of raw trade date ranges
                setRawTradeDateRanges((prevRawTradeDateRanges) => {
                    return [...prevRawTradeDateRanges, newTradeDateRange];
                });
            }
        },
        [rawTradeDateRanges]
    );

    const tradeDateRanges = useMemo(() => {
        return rawTradeDateRanges.reduce(
            (prevTradeDateRanges, rawTradeDate) => {
                // Date ranges are arrays of two dates, the start and end of the range.
                // A add new date range, we need to check if the new range overlaps with any existing ranges.
                // If it does, we need to merge the ranges, to the new range is the intersection of the two ranges.
                // If it doesn't, we just add the new range to the list of ranges.

                // Check if the new range overlaps with any existing ranges.
                const overlappingRanges = prevTradeDateRanges.filter(
                    (tradeDateRange) => {
                        const [tradeDateRangeStart, tradeDateRangeEnd] =
                            tradeDateRange;
                        const [newTradeDateRangeStart, newTradeDateRangeEnd] =
                            rawTradeDate;
                        return (
                            newTradeDateRangeEnd >= tradeDateRangeStart &&
                            tradeDateRangeEnd >= newTradeDateRangeStart
                        );
                    }
                );

                // If there are no overlapping ranges, just add the new range to the list of ranges.
                if (overlappingRanges.length === 0) {
                    return [...prevTradeDateRanges, rawTradeDate];
                } else {
                    // Take the first overlapping range, in case there are multiple.
                    // Merge the new range with the overlapping range, so the new range is the intersection of the two ranges.
                    const [overlappingRange] = overlappingRanges;
                    const [overlappingRangeStart, overlappingRangeEnd] =
                        overlappingRange;
                    const [newTradeDateRangeStart, newTradeDateRangeEnd] =
                        rawTradeDate;
                    const newTradeDateRangeStartMerged = new Date(
                        Math.max(overlappingRangeStart, newTradeDateRangeStart)
                    );
                    const newTradeDateRangeEndMerged = new Date(
                        Math.min(overlappingRangeEnd, newTradeDateRangeEnd)
                    );
                    const newTradeDateRangeMerged = [
                        newTradeDateRangeStartMerged,
                        newTradeDateRangeEndMerged,
                    ];
                    return [
                        ...prevTradeDateRanges.filter(
                            (tradeDateRange) =>
                                tradeDateRange !== overlappingRange
                        ),
                        newTradeDateRangeMerged,
                    ];
                }
            },
            []
        );
    }, [rawTradeDateRanges]);

    useEffect(() => {
        setTradeMarkerDates(
            tradeDateRanges.map((tradeDateRange) => {
                // Find the mid point of the range, to use as the trade marker date.
                const [tradeDateRangeStart, tradeDateRangeEnd] = tradeDateRange;
                const tradeDateRangeMid = new Date(
                    (tradeDateRangeStart.getTime() +
                        tradeDateRangeEnd.getTime()) /
                        2
                );
                return tradeDateRangeMid;
            })
        );
    }, [tradeDateRanges]);

    return [tradeMarkerDates, handleNewTradeDateRange, resetTradeMarkers];
};

export default useTradeMarkersManager;
