import Legend, { LegendBlock } from "../../controls/Legend";
import PerformanceTiersSection from "./sections/PerformanceTiersSection";
import PlayerNameLineLegendSection from "./sections/PlayerNameLineLegendSection";
import PerformanceTrendsSection from "./sections/PerformanceTrendsSection";
import SkatersIndicatorsSection from "./sections/SkatersIndicatorsSection";
import PlayerProfileLegendSection from "./sections/PlayerProfileLegendSection";
import GoaliesIndicatorsSection from "./sections/GoaliesIndicatorsSection";
import PlayerFreeAgencyTileLegendSection from "./sections/PlayerFreeAgencyTileLegendSection";

const PlayerPerformanceTileLegend = ({
    includeTrends = false,
    includeFreeAgency = false,
    zIndex = 1000,
}) => {
    return (
        <Legend zIndex={zIndex}>
            <LegendBlock>
                <PerformanceTiersSection />
                {includeTrends && <PerformanceTrendsSection />}
                <PlayerNameLineLegendSection />
            </LegendBlock>
            <LegendBlock>
                <SkatersIndicatorsSection />
                <PlayerProfileLegendSection />
            </LegendBlock>
            <LegendBlock>
                <GoaliesIndicatorsSection />
                {includeFreeAgency && <PlayerFreeAgencyTileLegendSection />}
            </LegendBlock>
        </Legend>
    );
};

export default PlayerPerformanceTileLegend;
