import Legend, { LegendBlock } from "../../controls/Legend";
import CapHitSection from "./sections/CapHitSection";
import AdditionalContractDetailsSection from "./sections/AdditionalContractDetailsSection";
import PlayerProfileLegendSection from "./sections/PlayerProfileLegendSection";
import PlayerFreeAgencyTileLegendSection from "./sections/PlayerFreeAgencyTileLegendSection";
import PlayerNameLineLegendSection from "./sections/PlayerNameLineLegendSection";

const PlayerCapTileLegend = ({ zIndex = 1000 }) => {
    return (
        <Legend zIndex={zIndex}>
            <LegendBlock>
                <CapHitSection />
            </LegendBlock>
            <LegendBlock>
                <AdditionalContractDetailsSection />
                <PlayerProfileLegendSection />
            </LegendBlock>
            <LegendBlock>
                <PlayerFreeAgencyTileLegendSection />
                <PlayerNameLineLegendSection />
            </LegendBlock>
        </Legend>
    );
};

export default PlayerCapTileLegend;
