import { useState, useEffect, useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";
import { fetchPlayerSeasonDatastash } from "../store/players-actions";
import { fetchTeam, fetchTeamSeasonDatastash } from "../store/teams-actions";
import { fetchCompetitionSeasonDatastash } from "../store/competitions-actions";
import useMultiResourceLoader from "../hooks/use-multi-resource-loader";
import { getPrimaryPosition } from "../utils";

import classes from "./PlayerMetricSeasonTrendChart.module.css";
import TimeSeriesChart from "../controls/TimeSeriesChart";

const SEASONAL_COLOR = "var(--ch-blue-800)";
const SEASONAL_HIGHLIGHT_COLOR = "var(--ch-blue-300)";
const TEAM_SEASONAL_COLOR = "var(--tw-slate-500)";
const SHORT_TERM_COLOR = "var(--ch-red-800)";
const SHORT_HIGHLIGHT_TERM_COLOR = "var(--ch-red-300)";
const TRADE_MARKER_COLOR = "var(--tw-gray-500)";
const DEVELOPMENT_SESSION_COLOR = "var(--tw-orange-400)";

const TIER_BANDS_COLORS = {
    elite: "var(--tw-yellow-400)",
    top: "var(--tw-green-500)",
    high: "var(--ch-blue-500)",
    mid: "var(--tw-slate-400)",
    low: "var(--ch-red-500)",
};

const PlayerMetricSeasonTrendChart = ({
    playerId,
    currentTeamId,
    competitionId,
    seasonId,
    metric,
    width,
    height,
    margin,
    autoScaleYAxis = false,
    autoScaleYAxisMax = false,
    isPercentage = false,
    isSeconds = false,
    displayTeamAverage = true,
    tradeMarkerDates,
    developmentEvents,
    tradeDateRangeHandler,
    highlightDates,
    selectedHighlightDate,
    onHighlightDateSelected,
    isLoadingHandler,
    transitionBeginDuration = 200,
    minDate = null,
    border = true,
}) => {
    const { activeLoaders, addLoader } =
        useMultiResourceLoader(isLoadingHandler);

    const dispatch = useDispatch();
    const player = useSelector((state) => state.players.players[playerId]);
    const player_seasondatastashes = useSelector(
        (state) => state.players.seasondatastashes[playerId]
    );
    const season_metric_series =
        player_seasondatastashes &&
        player_seasondatastashes.position_metrics_series &&
        player_seasondatastashes.position_metrics_series[seasonId];

    const competition_seasondatastashes = useSelector(
        (state) => state.competitions.seasondatastashes[competitionId]
    );
    const position_metrics_series_tiers_thresholds =
        competition_seasondatastashes &&
        competition_seasondatastashes.position_metrics_series_tiers_thresholds &&
        competition_seasondatastashes.position_metrics_series_tiers_thresholds[
            seasonId
        ];

    const teams = useSelector((state) => state.teams.teams);
    const teams_seasondatastashes = useSelector(
        (state) => state.teams.seasondatastashes
    );

    const pp = player && getPrimaryPosition(player.position);

    const player_metrics_series_tiers_thresholds =
        position_metrics_series_tiers_thresholds &&
        player &&
        position_metrics_series_tiers_thresholds[pp] &&
        position_metrics_series_tiers_thresholds[pp].metrics.includes(metric) &&
        position_metrics_series_tiers_thresholds[pp];

    const [activePlayerId, setActivePlayerId] = useState(playerId);
    const [activeCompetitionId, setActiveCompetitionId] =
        useState(competitionId);
    const [activeSeasonId, setActiveSeasonId] = useState(seasonId);
    const [activeMetric, setActiveMetric] = useState(metric);
    const [inTransition, setInTransition] = useState(null);
    const [transitionBeginComplete, setTransitionBeginComplete] =
        useState(true);

    useEffect(() => {
        if (
            !inTransition &&
            (playerId !== activePlayerId ||
                competitionId !== activeCompetitionId ||
                seasonId !== activeSeasonId ||
                metric !== activeMetric)
        ) {
            setInTransition({
                data: true,
                timeline:
                    playerId !== activePlayerId ||
                    competitionId !== activeCompetitionId ||
                    seasonId !== activeSeasonId,
                timeSpanSelection: false,
            });
            setTransitionBeginComplete(false);
            setTimeout(() => {
                setActivePlayerId(playerId);
                setActiveCompetitionId(competitionId);
                setActiveSeasonId(seasonId);
                setActiveMetric(metric);
                setTransitionBeginComplete(true);
            }, transitionBeginDuration);
        }
    }, [
        inTransition,
        playerId,
        activePlayerId,
        competitionId,
        activeCompetitionId,
        seasonId,
        activeSeasonId,
        metric,
        activeMetric,
        transitionBeginDuration,
    ]);

    useEffect(() => {
        if (transitionBeginComplete && inTransition && !activeLoaders.length) {
            setInTransition(null);
        }
    }, [transitionBeginComplete, inTransition, activeLoaders]);

    const [teamsPlayed, setTeamsPlayed] = useState([]);
    const [latestTeamPlayed, setLatestTeamPlayed] = useState(null);

    useEffect(() => {
        if (playerId && seasonId && !season_metric_series) {
            addLoader(`season_metric_series_${playerId}_${seasonId}`, () =>
                dispatch(
                    fetchPlayerSeasonDatastash(
                        playerId,
                        seasonId,
                        "position_metrics_series"
                    )
                )
            );
        }
    }, [playerId, season_metric_series, dispatch, seasonId, addLoader]);

    useEffect(() => {
        if (
            competitionId &&
            seasonId &&
            !position_metrics_series_tiers_thresholds
        ) {
            addLoader(
                `position_metrics_series_tiers_thresholds_${competitionId}_${seasonId}`,
                () =>
                    dispatch(
                        fetchCompetitionSeasonDatastash(
                            competitionId,
                            seasonId,
                            "position_metrics_series_tiers_thresholds"
                        )
                    )
            );
        }
    }, [
        dispatch,
        competitionId,
        seasonId,
        position_metrics_series_tiers_thresholds,
        addLoader,
    ]);

    const no_data =
        season_metric_series &&
        // metric has to be in the season_metric_series[competitionId].metrics array
        (!season_metric_series[competitionId] ||
            season_metric_series[competitionId].series.length === 0 ||
            !season_metric_series[competitionId].metrics.includes(metric));

    const [playerTimeSeries, setPlayerTimeSeries] = useState([]);
    const [teamTimeSeries, setTeamTimeSeries] = useState([]);
    const [timeSeries, setTimeSeries] = useState([]);

    const [tradeRanges, setTradeRanges] = useState([]);
    const [timelineMarkers, setTimelineMarkers] = useState([]);

    useEffect(() => {
        if (displayTeamAverage) {
            setTimeSeries([...playerTimeSeries, ...teamTimeSeries]);
        } else {
            setTimeSeries(playerTimeSeries);
        }
    }, [playerTimeSeries, teamTimeSeries, displayTeamAverage]);

    useEffect(() => {
        if (
            playerId === activePlayerId &&
            competitionId === activeCompetitionId &&
            seasonId === activeSeasonId &&
            metric === activeMetric
        ) {
            if (season_metric_series && !no_data) {
                const metricIndex =
                    season_metric_series[competitionId].metrics.indexOf(metric);
                const newTimeSeries = [];
                const newTeamsPlayed = [];
                let curTeamId = null;
                let seriesIndex = 0;
                let curSeasonalSeries = {
                    name: "Seasonal_" + seriesIndex,
                    color: SEASONAL_COLOR,
                    data: [],
                    showPoints: true,
                };
                let curShortTermSeries = {
                    name: "ShortTerm_" + seriesIndex,
                    color: SHORT_TERM_COLOR,
                    data: [],
                    showPoints: true,
                };

                season_metric_series[competitionId].series.forEach((d) => {
                    if (d.new_series || d.team_id !== curTeamId) {
                        if (curSeasonalSeries.data.length > 0) {
                            newTimeSeries.push(curSeasonalSeries);
                        }
                        if (curShortTermSeries.data.length > 0) {
                            newTimeSeries.push(curShortTermSeries);
                        }
                        seriesIndex++;
                        curSeasonalSeries = {
                            name: "Seasonal_" + seriesIndex,
                            color: SEASONAL_COLOR,
                            highlight_color: SEASONAL_HIGHLIGHT_COLOR,
                            data: [],
                            showPoints: true,
                        };
                        curShortTermSeries = {
                            name: "ShortTerm_" + seriesIndex,
                            color: SHORT_TERM_COLOR,
                            highlight_color: SHORT_HIGHLIGHT_TERM_COLOR,
                            data: [],
                            showPoints: true,
                        };
                    }
                    curTeamId = d.team_id;
                    // If curTeamId is not in newTeamsPlayed, add it
                    if (!newTeamsPlayed.includes(curTeamId)) {
                        newTeamsPlayed.push(curTeamId);
                    }

                    d.metrics[metricIndex].seasonal &&
                        curSeasonalSeries.data.push({
                            x: new Date(d.date + "T00:00:00"),
                            y: d.metrics[metricIndex].seasonal,
                        });
                    d.metrics[metricIndex].short_term &&
                        curShortTermSeries.data.push({
                            x: new Date(d.date + "T00:00:00"),
                            y: d.metrics[metricIndex].short_term,
                        });
                });

                if (curSeasonalSeries.data.length > 0) {
                    newTimeSeries.push(curSeasonalSeries);
                }
                if (curShortTermSeries.data.length > 0) {
                    newTimeSeries.push(curShortTermSeries);
                }

                setPlayerTimeSeries(newTimeSeries);
                setTeamsPlayed(newTeamsPlayed);
                setLatestTeamPlayed(curTeamId);
            } else {
                setPlayerTimeSeries([]);
                setTeamsPlayed([]);
                setLatestTeamPlayed(null);
            }
        }
    }, [
        playerId,
        activePlayerId,
        competitionId,
        activeCompetitionId,
        seasonId,
        activeSeasonId,
        metric,
        activeMetric,
        season_metric_series,
        no_data,
    ]);

    const [positionThresholdBands, setPositionThresholdBands] = useState([]);

    useEffect(() => {
        if (
            playerId === activePlayerId &&
            competitionId === activeCompetitionId &&
            seasonId === activeSeasonId &&
            metric === activeMetric
        ) {
            if (
                player_metrics_series_tiers_thresholds &&
                season_metric_series &&
                !no_data
            ) {
                // Map the player trends data by date for easy lookup
                const trendsDataByDate = season_metric_series[
                    competitionId
                ].series.reduce((acc, cur) => {
                    acc[cur.date] = cur;
                    return acc;
                }, {});

                const metricIndex =
                    player_metrics_series_tiers_thresholds.metrics.indexOf(
                        metric
                    );
                const newPositionThresholdBands = [];

                // Create an object with an array for each category as keys
                const categories =
                    player_metrics_series_tiers_thresholds.tier_categories &&
                    player_metrics_series_tiers_thresholds.tier_categories.reduce(
                        (acc, cur) => {
                            acc[cur] = {
                                active: false,
                                name: cur,
                                color: TIER_BANDS_COLORS[cur]
                                    ? TIER_BANDS_COLORS[cur]
                                    : "#000",
                                data: [],
                            };
                            return acc;
                        },
                        {}
                    );

                const negativeDirectionality =
                    player_metrics_series_tiers_thresholds.series &&
                    player_metrics_series_tiers_thresholds.series.length > 0 &&
                    player_metrics_series_tiers_thresholds.series[0].thresholds[
                        metricIndex
                    ][0] <
                        player_metrics_series_tiers_thresholds.series[0]
                            .thresholds[metricIndex][1];

                player_metrics_series_tiers_thresholds.series.forEach((d) => {
                    d.thresholds[metricIndex].forEach((hidx_val, idx) => {
                        if (idx > 0) {
                            const lidx_val = d.thresholds[metricIndex][idx - 1];
                            const category =
                                player_metrics_series_tiers_thresholds
                                    .tier_categories[idx - 1];
                            const min = negativeDirectionality
                                ? lidx_val
                                : hidx_val;
                            const max = negativeDirectionality
                                ? hidx_val
                                : lidx_val;

                            // If the category is not active yet, and player's Seasonal
                            // value is within the threshold range, set the category to active
                            if (
                                !categories[category].active &&
                                trendsDataByDate[d.date] &&
                                trendsDataByDate[d.date].metrics[metricIndex]
                                    .seasonal >= min &&
                                trendsDataByDate[d.date].metrics[metricIndex]
                                    .seasonal <= max
                            ) {
                                categories[category].active = true;
                            }

                            categories[category].data.push({
                                x: new Date(d.date + "T00:00:00"),
                                y_min: min,
                                y_max: max,
                            });
                        }
                    });
                });

                Object.keys(categories).forEach((k) => {
                    if (categories[k].data.length > 0 && categories[k].active) {
                        newPositionThresholdBands.push(categories[k]);
                    }
                });

                setPositionThresholdBands(newPositionThresholdBands);
            }
        }
    }, [
        playerId,
        activePlayerId,
        competitionId,
        activeCompetitionId,
        seasonId,
        activeSeasonId,
        metric,
        activeMetric,
        player_metrics_series_tiers_thresholds,
        season_metric_series,
        no_data,
    ]);

    const teamsPlayedData = useMemo(() => {
        // Create an object by team id key for each team's played, with the team details and the all_position_metrics_series datastash for that team
        return teamsPlayed.reduce(
            (acc, cur) => {
                if (
                    !teams[cur] ||
                    !teams_seasondatastashes[cur]
                        ?.all_position_metrics_series ||
                    !teams_seasondatastashes[cur].all_position_metrics_series[
                        seasonId
                    ]
                ) {
                    acc.complete = false;
                }
                acc.teams[cur] = {
                    team: teams[cur],
                    all_position_metrics_series:
                        !teams_seasondatastashes[cur]
                            ?.all_position_metrics_series ||
                        !teams_seasondatastashes[cur]
                            .all_position_metrics_series[seasonId]
                            ? null
                            : teams_seasondatastashes[cur]
                                  .all_position_metrics_series[seasonId][
                                  competitionId
                              ] &&
                              teams_seasondatastashes[cur]
                                  .all_position_metrics_series[seasonId][
                                  competitionId
                              ][pp]
                            ? teams_seasondatastashes[cur]
                                  .all_position_metrics_series[seasonId][
                                  competitionId
                              ][pp]
                            : [],
                };
                return acc;
            },
            {
                complete: teamsPlayed.length > 0 ? true : false,
                teams: {},
            }
        );
    }, [
        teamsPlayed,
        teams,
        teams_seasondatastashes,
        competitionId,
        seasonId,
        pp,
    ]);

    useEffect(() => {
        teamsPlayed.forEach((teamId) => {
            if (!teamsPlayedData.teams[teamId].team) {
                addLoader(`team_${teamId}`, () => dispatch(fetchTeam(teamId)));
            }
            if (
                seasonId &&
                !teamsPlayedData.teams[teamId].all_position_metrics_series
            ) {
                addLoader(`team_all_position_metrics_series_${teamId}`, () =>
                    dispatch(
                        fetchTeamSeasonDatastash(
                            teamId,
                            seasonId,
                            "all_position_metrics_series"
                        )
                    )
                );
            }
        });
    }, [teamsPlayed, teamsPlayedData, seasonId, dispatch, addLoader]);

    useEffect(() => {
        if (
            playerId === activePlayerId &&
            competitionId === activeCompetitionId &&
            seasonId === activeSeasonId &&
            metric === activeMetric
        ) {
            if (teamsPlayedData.complete && season_metric_series && !no_data) {
                const teamsSeriesMaps = teamsPlayed.reduce((map, teamId) => {
                    if (
                        teamsPlayedData.teams[teamId]
                            .all_position_metrics_series.metrics
                    ) {
                        const metricIndex =
                            teamsPlayedData.teams[
                                teamId
                            ].all_position_metrics_series.metrics.indexOf(
                                metric
                            );
                        map[teamId] = teamsPlayedData.teams[
                            teamId
                        ].all_position_metrics_series.series.reduce(
                            (acc, cur) => {
                                acc[cur.date] =
                                    cur.metrics[metricIndex].seasonal;
                                return acc;
                            },
                            {}
                        );
                    }
                    return map;
                }, {});

                const newTimeSeries = [];
                const newTradeRanges = [];

                let curTeamSeries = {
                    name: "Team",
                    data: [],
                };
                let curTeamId = null;
                let seriesIndex = 0;
                let lastPoint = null;

                season_metric_series[competitionId].series.forEach((d) => {
                    if (curTeamId !== d.team_id) {
                        if (curTeamId) {
                            newTimeSeries.push(curTeamSeries);
                        }
                        curTeamId = d.team_id;
                        seriesIndex++;
                        curTeamSeries = {
                            name: `Team_${curTeamId}_${seriesIndex}`,
                            color: TEAM_SEASONAL_COLOR,
                            data: [],
                            showPoints: false,
                        };
                    }
                    // In player switch cases the teamsSeriesMaps does not hold thge current team id yet.
                    if (teamsSeriesMaps[curTeamId]) {
                        if (lastPoint && lastPoint.team_id === d.team_id) {
                            // Filter the team date keyx between the last point and the current point
                            const teamDateKeys = Object.keys(
                                teamsSeriesMaps[curTeamId]
                            ).filter((k) => k > lastPoint.date && k < d.date);

                            // Add the filtered team date keys to the current team series
                            teamDateKeys.forEach((k) => {
                                curTeamSeries.data.push({
                                    x: new Date(k + "T00:00:00"),
                                    y: teamsSeriesMaps[curTeamId][k],
                                });
                            });
                        } else if (
                            lastPoint &&
                            lastPoint.team_id !== d.team_id
                        ) {
                            // Add a new trade date range
                            tradeDateRangeHandler &&
                                tradeDateRangeHandler([
                                    new Date(lastPoint.date + "T00:00:00"),
                                    new Date(d.date + "T00:00:00"),
                                ]);
                            newTradeRanges.push({
                                sourceTeam: lastPoint.team_id,
                                destinationTeam: d.team_id,
                                dateRange: [
                                    new Date(lastPoint.date + "T00:00:00"),
                                    new Date(d.date + "T00:00:00"),
                                ],
                            });
                        }
                        lastPoint = d;

                        teamsSeriesMaps[curTeamId][d.date] &&
                            curTeamSeries.data.push({
                                x: new Date(d.date + "T00:00:00"),
                                y: teamsSeriesMaps[curTeamId][d.date],
                            });
                    }
                });

                if (curTeamId && curTeamSeries.data.length > 0) {
                    newTimeSeries.push(curTeamSeries);
                }

                if (newTimeSeries.length > 0) {
                    setTeamTimeSeries(newTimeSeries);
                }

                if (newTradeRanges.length > 0) {
                    setTradeRanges(newTradeRanges);
                }
            } else {
                setTeamTimeSeries([]);
                setTradeRanges([]);
            }
        }
    }, [
        playerId,
        activePlayerId,
        competitionId,
        activeCompetitionId,
        seasonId,
        activeSeasonId,
        metric,
        activeMetric,
        teamsPlayed,
        teamsPlayedData,
        season_metric_series,
        no_data,
        tradeDateRangeHandler,
    ]);

    useEffect(() => {
        const newTimelineMarkers = [];
        if (tradeMarkerDates && tradeMarkerDates.length > 0) {
            tradeMarkerDates.forEach((d) => {
                // Find a trade date range that contains this marker date
                const tradeRange = tradeRanges.find(
                    (r) => r.dateRange[0] <= d && r.dateRange[1] >= d
                );

                // If there is a trade date range, add a trade marker
                if (tradeRange) {
                    newTimelineMarkers.push({
                        name: `Trade_${d.getTime()}`,
                        label: `${teams[tradeRange.sourceTeam].shorthand} ◀`,
                        color: TRADE_MARKER_COLOR,
                        x: d,
                    });
                }
            });
        }

        if (developmentEvents && developmentEvents.length > 0) {
            developmentEvents.forEach((d, idx) => {
                if (d.event_type === "development_priority_start") {
                    newTimelineMarkers.push({
                        name: `Dev_${idx}`,
                        label: `Start`,
                        color: TRADE_MARKER_COLOR,
                        x: d.date,
                    });
                } else if (d.event_type === "development_priority_end") {
                    newTimelineMarkers.push({
                        name: `Dev_${idx}`,
                        label: `End`,
                        color: TRADE_MARKER_COLOR,
                        x: d.date,
                    });
                } else if (d.event_type === "session") {
                    newTimelineMarkers.push({
                        name: `Dev_${idx}`,
                        type: "arrowhead",
                        direction: "bottom",
                        color: DEVELOPMENT_SESSION_COLOR,
                        x: d.date,
                    });
                }
            });
        }

        setTimelineMarkers(newTimelineMarkers);
    }, [tradeRanges, tradeMarkerDates, developmentEvents, teams]);

    return (
        <>
            {no_data && <div className={classes.no_data}>No Data</div>}
            {!no_data && (
                <TimeSeriesChart
                    width={width}
                    height={height}
                    margin={margin}
                    minY={autoScaleYAxis ? null : 0}
                    maxY={autoScaleYAxis || autoScaleYAxisMax ? null : 1}
                    isPercentage={isPercentage}
                    isSeconds={isSeconds}
                    stepsY={4}
                    series={timeSeries}
                    bands={positionThresholdBands}
                    gridLabel={
                        currentTeamId !== latestTeamPlayed && {
                            label: teams[latestTeamPlayed]?.shorthand,
                            color: TRADE_MARKER_COLOR,
                        }
                    }
                    timelineMarkers={timelineMarkers}
                    highlightDates={highlightDates}
                    selectedHighlightDate={selectedHighlightDate}
                    onHighlightDateSelected={onHighlightDateSelected}
                    transitionContent={inTransition}
                    fadeOutTransitionDuration={transitionBeginDuration}
                    minDate={minDate}
                    border={border}
                />
            )}
        </>
    );
};

export default PlayerMetricSeasonTrendChart;
