import { useState, useEffect } from "react";
import classes from "./SlideInPanel.module.css";
import useWindowDimensions from "../hooks/use-window-dimensions";

function SlideInPanel({
    isOpen,
    isLoading,
    onClose,
    targetWidth,
    onWidthChange,
    children,
    side = "right",
    showCloseButton = true,
    zIndex = 3,
    noPadding = false,
    bgColor = "white",
}) {
    const [loadingWaitActive, setLoadingWaitActive] = useState(false);

    const { widthClasses, windowDimensions } = useWindowDimensions([
        { min: 660, max: Infinity },
    ]);
    // Initialize state based on initial hook values
    const initialWideLayout = widthClasses[0]?.matches || false;
    const initialEffectiveWidth = initialWideLayout
        ? `${targetWidth}px`
        : "100%";

    const [effectiveWidth, setEffectiveWidth] = useState(initialEffectiveWidth);
    const [wideLayout, setWideLayout] = useState(initialWideLayout);

    useEffect(() => {
        const newWidth = widthClasses[0]?.matches ? `${targetWidth}px` : "100%";
        setEffectiveWidth(newWidth);
        setWideLayout(newWidth !== "100%");
        if (onWidthChange) {
            onWidthChange(
                newWidth === "100%" ? windowDimensions.width : targetWidth
            );
        }
    }, [widthClasses, targetWidth, onWidthChange, windowDimensions.width]);

    useEffect(() => {
        if (isLoading) {
            setLoadingWaitActive(true);
            setTimeout(() => {
                setLoadingWaitActive(false);
            }, 250);
        }
    }, [isLoading]);

    const panelContainerStyle = {
        width: effectiveWidth, // Use effective width here
        zIndex: zIndex,
        [side]: 0,
    };

    const panelStyle = {
        width: effectiveWidth, // Use effective width here
        backgroundColor: bgColor,
    };

    const buttonContainerStyle = !wideLayout
        ? {
              backgroundColor: bgColor,
          }
        : {};

    return (
        <div
            className={
                classes.panel_container +
                (isOpen ? " " + classes.open : "") +
                (side === "right" ? " " + classes.is_right : "") +
                (wideLayout ? " " + classes.is_wide : "")
            }
            style={panelContainerStyle}
        >
            {showCloseButton && (
                <div
                    className={classes.button_container}
                    style={buttonContainerStyle}
                >
                    <div className={classes.close_button} onClick={onClose}>
                        &times;
                    </div>
                </div>
            )}
            <div
                className={
                    classes.panel + (noPadding ? " " + classes.no_padding : "")
                }
                style={panelStyle}
            >
                {children}
                <div className={classes.bottom_spacer} />
            </div>
            <div
                className={
                    classes.loader_blind +
                    " " +
                    (isLoading || loadingWaitActive
                        ? classes.opaque
                        : classes.transparent)
                }
            ></div>
        </div>
    );
}

export default SlideInPanel;
