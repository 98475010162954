import classes from "./OptionsMultiselect.module.css";

export const OptionsMultiselect = ({
    options,
    selectedOptions,
    onSelect,
    OptionComponent,
}) => {
    const selectedOptionIds = selectedOptions.map((option) => option.id);

    const handleSelect = (option) => {
        if (selectedOptionIds && selectedOptionIds.includes(option.id)) {
            onSelect(selectedOptions.filter((o) => o.id !== option.id));
        } else {
            onSelect([...selectedOptions, option]);
        }
    };

    return (
        <div className={classes.multiselect_control}>
            {options.map((option) => (
                <div
                    key={option.id}
                    className={
                        classes.multiselect_item +
                        (selectedOptionIds &&
                        selectedOptionIds.includes(option.id)
                            ? " " + classes.selected
                            : "")
                    }
                    onClick={() => handleSelect(option)}
                >
                    <div className={classes.multiselect_item_label}>
                        <OptionComponent optionValue={option} />
                    </div>
                    <div
                        className={classes.multiselect_item_checkbox_container}
                    >
                        <div className={classes.multiselect_item_checkbox}>
                            {selectedOptionIds &&
                                selectedOptionIds.includes(option.id) && (
                                    <span
                                        className={
                                            "material-symbols-outlined " +
                                            classes.check
                                        }
                                    >
                                        check
                                    </span>
                                )}
                        </div>
                    </div>
                </div>
            ))}
        </div>
    );
};

export default OptionsMultiselect;
