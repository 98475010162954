import { LegendSection, LegendItem } from "../../../controls/Legend";
import {
    TOP_CAP_HIT,
    CapHitBar,
    BaseSalaryNumber,
    BonusNumber,
    AhlSalaryNumber,
} from "../../../components/CapHitIndicator";

const SYMBOL_WIDTH = "52px";

const CapHitSection = () => {
    return (
        <LegendSection
            title="Cap Hit"
            description="The cap hit indicator shows a bar of the current cap hit of the player, in respect to the highest salary in the league. The label gives more detailed numbers on the player's cap hit."
        >
            <LegendItem
                symbol={
                    <CapHitBar
                        annualAverageValue={TOP_CAP_HIT}
                        capBase={TOP_CAP_HIT}
                    />
                }
                symbolWidth={SYMBOL_WIDTH}
                description="Average annual salary cap hit."
            />
            <LegendItem
                symbol={
                    <CapHitBar
                        annualAverageValue={TOP_CAP_HIT}
                        capBase={0}
                        buriedCap={TOP_CAP_HIT}
                        isNonRoster={true}
                    />
                }
                symbolWidth={SYMBOL_WIDTH}
                description="Off cap NHL average annual salary."
            />
            <LegendItem
                symbol={
                    <CapHitBar
                        annualAverageValue={TOP_CAP_HIT}
                        capBase={TOP_CAP_HIT}
                        buriedCap={4000000}
                        isNonRoster={true}
                    />
                }
                symbolWidth={SYMBOL_WIDTH}
                description="Off roster cap hit with buried cap."
            />
            <LegendItem
                symbol={
                    <CapHitBar
                        annualAverageValue={TOP_CAP_HIT}
                        capBase={TOP_CAP_HIT - 4000000}
                        bonus={4000000}
                    />
                }
                symbolWidth={SYMBOL_WIDTH}
                description="Base average annual salary with bonus. Bonuses are indicated as maximum possible bonus, not actual bonus earned."
            />
            <LegendItem
                symbol={
                    <CapHitBar
                        annualAverageValue={TOP_CAP_HIT}
                        capBase={0}
                        buriedCap={TOP_CAP_HIT - 4000000}
                        isNonRoster={true}
                        bonus={4000000}
                    />
                }
                symbolWidth={SYMBOL_WIDTH}
                description="Off cap base salary with bonus."
            />
            <LegendItem
                symbol={<BaseSalaryNumber salary="#.##" isExample={true} />}
                symbolWidth={SYMBOL_WIDTH}
                description="Base salary cap hit. If the salary has a buried cap hit component, it is still shown as the full cap hit number that would be applicable if the player was on the NHL roster."
            />
            <LegendItem
                symbol={
                    <BaseSalaryNumber
                        salary="#.##"
                        isNonRoster={true}
                        isExample={true}
                    />
                }
                symbolWidth={SYMBOL_WIDTH}
                description="Off cap NHL average annual salary."
            />
            <LegendItem
                symbol={<BonusNumber bonus="##k" isExample={true} />}
                symbolWidth={SYMBOL_WIDTH}
                description="Total earnable bonus for player."
            />
            <LegendItem
                symbol={<AhlSalaryNumber salary="###k" isExample={true} />}
                symbolWidth={SYMBOL_WIDTH}
                description="AHL salary of player, if on a two-way NHL or an AHL-only contract."
            />
        </LegendSection>
    );
};

export default CapHitSection;
