import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import classes from "./MetricTopPlayersList.module.css";
import { formatValue, getMetricContent } from "../utils";
import { PlayerIndexMetrics } from "../constants";
import { fetchCompetitionSeasonDatastash } from "../store/competitions-actions.js";
import { fetchTeams } from "../store/teams-actions.js";
import SquareButton from "../controls/buttons/SquareButton.js";

const MetricTopPlayersList = ({
    competitionId,
    seasonId,
    primaryPosition,
    metricName,
    onPlayerClick,
}) => {
    const dispatch = useDispatch();

    const competitions_sds = useSelector(
        (state) => state.competitions.seasondatastashes
    );

    const competition_latest_position_metrics_tiers_thresholds =
        competitions_sds &&
        competitions_sds[competitionId] &&
        competitions_sds[competitionId]
            .latest_position_metrics_tiers_thresholds &&
        competitions_sds[competitionId]
            .latest_position_metrics_tiers_thresholds[seasonId] &&
        competitions_sds[competitionId]
            .latest_position_metrics_tiers_thresholds[seasonId][
            primaryPosition
        ];
    useEffect(() => {
        if (
            competitionId &&
            seasonId &&
            !competition_latest_position_metrics_tiers_thresholds
        ) {
            dispatch(
                fetchCompetitionSeasonDatastash(
                    competitionId,
                    seasonId,
                    "latest_position_metrics_tiers_thresholds"
                )
            );
        }
    }, [
        competitionId,
        seasonId,
        competition_latest_position_metrics_tiers_thresholds,
        dispatch,
    ]);

    const metricTopPlayers =
        competition_latest_position_metrics_tiers_thresholds &&
        competition_latest_position_metrics_tiers_thresholds.top_players &&
        metricName &&
        getMetricContent(
            metricName,
            competition_latest_position_metrics_tiers_thresholds.metrics,
            competition_latest_position_metrics_tiers_thresholds.top_players
        );

    const teams = useSelector((state) => state.teams.teams);
    const [missingTeamsLoading, setMissingTeamsLoading] = useState(false);
    useEffect(() => {
        // If the metric top players are available, and we are not currently
        // fetching missing teams, then check if there are any teams missing
        // from the top players data, and if so fetch them.
        if (metricTopPlayers && !missingTeamsLoading) {
            // Find teams missing from the top players data. Ids must be
            // integers. If they are a string starting with sl_ then they are
            // not a team id.
            const missingTeamIds = new Set();

            metricTopPlayers.forEach((player) => {
                if (
                    player.team_id &&
                    !isNaN(player.team_id) &&
                    !teams[player.team_id]
                ) {
                    missingTeamIds.add(player.team_id);
                }
            });

            const missingTeams = Array.from(missingTeamIds);

            if (missingTeams.length > 0) {
                setMissingTeamsLoading(true);
                dispatch(fetchTeams(missingTeams)).finally(() => {
                    setMissingTeamsLoading(false);
                });
            }
        }
    }, [metricTopPlayers, teams, dispatch, missingTeamsLoading]);

    return (
        <div className={classes.metric_top_players}>
            <ol className={classes.player_ranking}>
                {metricTopPlayers &&
                    metricTopPlayers.map((rankedPlayer, index) => {
                        const displayValue = formatValue(
                            rankedPlayer.value,
                            PlayerIndexMetrics[metricName].decimalPlaces,
                            PlayerIndexMetrics[metricName].isPercentage,
                            PlayerIndexMetrics[metricName].isSeconds
                        );
                        return (
                            <li
                                key={index}
                                className={classes.player_ranking_entry}
                            >
                                <div
                                    className={classes.player_ranking_position}
                                >
                                    {index + 1}.
                                </div>
                                {teams &&
                                teams[rankedPlayer.team_id] &&
                                teams[rankedPlayer.team_id].logo_url ? (
                                    <div
                                        className={
                                            classes.player_ranking_team_logo
                                        }
                                    >
                                        <img
                                            src={
                                                teams[rankedPlayer.team_id]
                                                    .logo_url
                                            }
                                            alt={
                                                teams[rankedPlayer.team_id]
                                                    .team_name
                                            }
                                        />
                                    </div>
                                ) : (
                                    rankedPlayer.team_shorthand && (
                                        <div
                                            className={
                                                classes.player_ranking_team
                                            }
                                        >
                                            {rankedPlayer.team_shorthand}
                                        </div>
                                    )
                                )}
                                <div className={classes.player_ranking_name}>
                                    {rankedPlayer.known_name}
                                </div>
                                <div className={classes.player_ranking_value}>
                                    {displayValue}
                                </div>
                                <div className={classes.player_page_link}>
                                    {
                                        // Only display link button if
                                        // rankedPlayer.player_id is an
                                        // integer and not a string starting
                                        // with sl_.
                                        rankedPlayer.player_id &&
                                            !isNaN(rankedPlayer.player_id) && (
                                                <SquareButton
                                                    label={"analytics"}
                                                    useMaterialIcon={true}
                                                    smallIcons={true}
                                                    onClick={() =>
                                                        onPlayerClick &&
                                                        onPlayerClick(
                                                            rankedPlayer,
                                                            index
                                                        )
                                                    }
                                                />
                                            )
                                    }
                                </div>
                            </li>
                        );
                    })}
            </ol>
        </div>
    );
};

export default MetricTopPlayersList;
