import classes from "./NavMenuOpenButton.module.css";

const NavMenuOpenButton = ({ onClick, darkMode = false }) => {
    return (
        <div
            className={
                classes.nav_menu_open_button +
                (darkMode ? " " + classes.dark : "")
            }
            onClick={onClick}
        >
            ☰
        </div>
    );
};

export default NavMenuOpenButton;
