import { useState, useEffect, useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";
import { fetchPlayerSeasonsDatastash } from "../store/players-actions";
import { fetchCompetitionsSeasonsDatastash } from "../store/competitions-actions";
import useMultiResourceLoader from "../hooks/use-multi-resource-loader";
import {
    getPrimaryPosition,
    getValuePerformanceTier,
    getPlayerSeasonAge,
} from "../utils";
import TimeSeriesChart from "../controls/TimeSeriesChart";
import {
    FREE_AGENCY_CLASS_COLORS,
    FREE_AGENCY_CLASS_LABELS,
} from "../constants";

import classes from "./PlayerMetricCareerProgressionChart.module.css";

const SEASONAL_COLOR = "var(--ch-blue-800)";

const TIER_BANDS_COLORS = {
    elite: "var(--tw-yellow-400)",
    top: "var(--tw-green-500)",
    high: "var(--ch-blue-500)",
    mid: "var(--tw-slate-400)",
    low: "var(--ch-red-500)",
};

const PlayerMetricCareerProgressionChart = ({
    playerId,
    competitionId,
    selectedSeasonId,
    metric,
    width,
    height,
    margin,
    autoScaleYAxis = false,
    autoScaleYAxisMax = false,
    isPercentage = false,
    isSeconds = false,
    onSeasonSelectionHandler,
    isLoadingHandler,
    transitionBeginDuration = 200,
}) => {
    const { activeLoaders, addLoader } =
        useMultiResourceLoader(isLoadingHandler);

    const dispatch = useDispatch();
    const seasons = useSelector((state) => state.seasons.seasons);
    const player = useSelector((state) => state.players.players[playerId]);

    const player_seasondatastashes = useSelector(
        (state) => state.players.seasondatastashes[playerId]
    );
    const player_completeseasondatastashes = useSelector(
        (state) => state.players.completeseasondatastashes[playerId]
    );
    const career_latest_metrics = useMemo(() => {
        return (
            player_completeseasondatastashes &&
            player_completeseasondatastashes.latest_position_metrics &&
            Object.keys(
                player_seasondatastashes.latest_position_metrics
            ).reduce((acc, cur) => {
                if (
                    player_seasondatastashes.latest_position_metrics[cur][
                        competitionId
                    ]
                ) {
                    acc[cur] =
                        player_seasondatastashes.latest_position_metrics[cur];
                }
                return acc;
            }, {})
        );
    }, [
        player_completeseasondatastashes,
        player_seasondatastashes,
        competitionId,
    ]);

    const competition_seasondatastashes = useSelector(
        (state) => state.competitions.seasondatastashes[competitionId]
    );
    const pp = player && getPrimaryPosition(player.position);

    const [activePlayerId, setActivePlayerId] = useState(playerId);
    const [activeCompetitionId, setActiveCompetitionId] =
        useState(competitionId);
    const [activeSeasonId, setActiveSeasonId] = useState(selectedSeasonId);
    const [activeMetric, setActiveMetric] = useState(metric);
    const [inTransition, setInTransition] = useState(null);
    const [transitionBeginComplete, setTransitionBeginComplete] =
        useState(true);

    useEffect(() => {
        if (
            !inTransition &&
            (playerId !== activePlayerId ||
                competitionId !== activeCompetitionId ||
                selectedSeasonId !== activeSeasonId ||
                metric !== activeMetric)
        ) {
            setInTransition({
                data:
                    playerId !== activePlayerId ||
                    competitionId !== activeCompetitionId ||
                    metric !== activeMetric,
                timeline: playerId !== activePlayerId,
                timeSpanSelection: selectedSeasonId !== activeSeasonId,
            });
            setTransitionBeginComplete(false);
            setTimeout(() => {
                setActivePlayerId(playerId);
                setActiveCompetitionId(competitionId);
                setActiveSeasonId(selectedSeasonId);
                setActiveMetric(metric);
                setTransitionBeginComplete(true);
            }, transitionBeginDuration);
        }
    }, [
        inTransition,
        playerId,
        activePlayerId,
        competitionId,
        activeCompetitionId,
        selectedSeasonId,
        activeSeasonId,
        metric,
        activeMetric,
        transitionBeginDuration,
    ]);

    useEffect(() => {
        if (transitionBeginComplete && inTransition && !activeLoaders.length) {
            setInTransition(null);
        }
    }, [transitionBeginComplete, inTransition, activeLoaders]);

    const latest_metrics_tiers_thresholds = useMemo(() => {
        return (
            competition_seasondatastashes &&
            competition_seasondatastashes.latest_position_metrics_tiers_thresholds &&
            Object.entries(
                competition_seasondatastashes.latest_position_metrics_tiers_thresholds
            ).reduce((acc, [k, v]) => {
                acc[k] = v[pp];
                return acc;
            }, {})
        );
    }, [competition_seasondatastashes, pp]);

    const missingCompetitionSeasonDatastashes = useMemo(() => {
        return (
            career_latest_metrics &&
            Object.keys(career_latest_metrics).reduce((acc, cur) => {
                if (
                    !latest_metrics_tiers_thresholds ||
                    !latest_metrics_tiers_thresholds[cur]
                ) {
                    acc.push(cur);
                }
                return acc;
            }, [])
        );
    }, [career_latest_metrics, latest_metrics_tiers_thresholds]);

    useEffect(() => {
        if (playerId && !career_latest_metrics) {
            addLoader("career_latest_metrics", () =>
                dispatch(
                    fetchPlayerSeasonsDatastash(
                        playerId,
                        "latest_position_metrics"
                    )
                )
            );
        }
        if (
            competitionId &&
            missingCompetitionSeasonDatastashes &&
            missingCompetitionSeasonDatastashes.length > 0
        ) {
            addLoader("latest_metrics_tiers_thresholds", () =>
                dispatch(
                    fetchCompetitionsSeasonsDatastash(
                        "latest_position_metrics_tiers_thresholds",
                        [competitionId],
                        missingCompetitionSeasonDatastashes
                    )
                )
            );
        }
    }, [
        playerId,
        career_latest_metrics,
        dispatch,
        competitionId,
        latest_metrics_tiers_thresholds,
        missingCompetitionSeasonDatastashes,
        addLoader,
    ]);

    const no_data =
        career_latest_metrics &&
        // None of the season have the competition,
        // or the competition data must have the metric
        Object.keys(career_latest_metrics).every(
            (seasonId) =>
                !career_latest_metrics[seasonId][competitionId] ||
                !career_latest_metrics[seasonId][
                    competitionId
                ].metrics.includes(metric)
        );

    const [seriesData, setSeriesData] = useState([]);

    useEffect(() => {
        if (
            playerId === activePlayerId &&
            competitionId === activeCompetitionId &&
            selectedSeasonId === activeSeasonId &&
            metric === activeMetric
        ) {
            setSeriesData(
                career_latest_metrics &&
                    latest_metrics_tiers_thresholds &&
                    missingCompetitionSeasonDatastashes &&
                    missingCompetitionSeasonDatastashes.length === 0 &&
                    Object.keys(career_latest_metrics).reduce(
                        (acc, seasonId) => {
                            if (
                                career_latest_metrics[seasonId][competitionId]
                            ) {
                                const metricIndex =
                                    career_latest_metrics[seasonId][
                                        competitionId
                                    ].metrics.indexOf(metric);

                                if (metricIndex !== -1) {
                                    acc.push({
                                        seasonId: seasonId,
                                        seasonName: seasons[seasonId].name,
                                        age: getPlayerSeasonAge(
                                            player,
                                            seasons[seasonId]
                                        ),
                                        seasonal:
                                            career_latest_metrics[seasonId][
                                                competitionId
                                            ].latest[metricIndex].seasonal,
                                        tier: getValuePerformanceTier(
                                            career_latest_metrics[seasonId][
                                                competitionId
                                            ].latest[metricIndex].seasonal,
                                            latest_metrics_tiers_thresholds[
                                                seasonId
                                            ].latest.thresholds[metricIndex],
                                            latest_metrics_tiers_thresholds[
                                                seasonId
                                            ].tier_categories
                                        ),
                                        es_5v5_toi:
                                            career_latest_metrics[seasonId][
                                                competitionId
                                            ].es_5v5_toi,
                                        thresholds:
                                            latest_metrics_tiers_thresholds[
                                                seasonId
                                            ].latest.thresholds[metricIndex],
                                    });
                                }
                            }
                            return acc;
                        },
                        []
                    )
            );
        }
    }, [
        career_latest_metrics,
        latest_metrics_tiers_thresholds,
        missingCompetitionSeasonDatastashes,
        playerId,
        activePlayerId,
        competitionId,
        activeCompetitionId,
        selectedSeasonId,
        activeSeasonId,
        metric,
        activeMetric,
        player,
        seasons,
    ]);

    // Get the current date and time. The current season year is the current
    // year if we are past July 1st, otherwise it is the previous year.
    const today = new Date();
    const currentSeasonYear =
        today.getMonth() >= 6 ? today.getFullYear() : today.getFullYear() - 1;

    // Add player.contract_years and player.contract_extension to the current
    // season year, and subtract 1 to get the last contract season year.
    // Both values can be null, in which case they should count as zero.
    const lastContractSeasonYear =
        player &&
        currentSeasonYear +
            (player.contract_years || 0) +
            (player.contract_extension || 0) -
            1;

    // The last contract season age is determined by adding the difference between
    // the last data point's season year and the contract end season year to the
    // player's last data point age.
    const lastContractSeasonAge =
        seriesData &&
        seriesData.length > 0 &&
        seriesData[seriesData.length - 1].age +
            (lastContractSeasonYear -
                parseInt(
                    seriesData[seriesData.length - 1].seasonName.substring(0, 4)
                ));

    // We will show 20 years of data.
    // If the player is older than 38, or his current contract extends him
    // beyond age 38, we will show the last 20 years of data, up to and
    // including his contract end.
    // The earliest season to show is age 18 season by default. If we have data
    // from younger years, then we will show the earliest season with data.
    const ageRange = 15;
    const defaultStartAge = 18;

    const earliestSeasonAge = useMemo(() => {
        return (
            seriesData &&
            seriesData.length > 0 &&
            (lastContractSeasonAge > defaultStartAge + ageRange
                ? lastContractSeasonAge - ageRange
                : seriesData[0].age < defaultStartAge &&
                  lastContractSeasonAge - seriesData[0].age < ageRange
                ? seriesData[0].age
                : defaultStartAge)
        );
    }, [seriesData, lastContractSeasonAge]);

    const earliestSeasonYear =
        earliestSeasonAge &&
        seriesData[0].seasonName.substring(0, 4) -
            (seriesData[0].age - earliestSeasonAge);

    const maxSeasonYear =
        earliestSeasonYear && earliestSeasonYear + ageRange + 1;

    const minDate = useMemo(() => {
        return earliestSeasonYear && new Date(earliestSeasonYear, 0, 1);
    }, [earliestSeasonYear]);
    const maxDate = useMemo(() => {
        return maxSeasonYear && new Date(maxSeasonYear + 1, 0, 1);
    }, [maxSeasonYear]);

    const [timeSeriesData, setTimeSeriesData] = useState([]);
    const [dataBands, setDataBands] = useState([]);

    useEffect(() => {
        if (
            playerId === activePlayerId &&
            competitionId === activeCompetitionId &&
            selectedSeasonId === activeSeasonId &&
            metric === activeMetric
        ) {
            const newTimeSeriesData = [];
            const newDataBands = [];

            if (seriesData) {
                seriesData.reduce(
                    (acc, cur) => {
                        const curTimeSeries =
                            acc.data.length > 0 &&
                            parseInt(cur.seasonId) ===
                                parseInt(acc.prevSeasonId) + 1
                                ? acc.data[acc.data.length - 1]
                                : {
                                      name: "Seasonal_" + acc.seriesIndex,
                                      color: SEASONAL_COLOR,
                                      data: [],
                                      showPoints: true,
                                  };

                        if (
                            acc.data.length === 0 ||
                            parseInt(cur.seasonId) !==
                                parseInt(acc.prevSeasonId) + 1
                        ) {
                            acc.data.push(curTimeSeries);
                            acc.seriesIndex++;
                        }

                        acc.prevSeasonId = cur.seasonId;

                        // Add data point at Jan 1st of the season later year.
                        curTimeSeries.data.push({
                            x: new Date(cur.seasonName.substring(5, 9), 0, 1),
                            y: cur.seasonal,
                        });

                        newDataBands.push({
                            name: "tierband_" + cur.seasonId,
                            color: TIER_BANDS_COLORS[cur.tier.tier],
                            data: [
                                {
                                    x: new Date(
                                        cur.seasonName.substring(0, 4),
                                        6,
                                        1
                                    ),
                                    y_min: cur.tier.tier_min,
                                    y_max: cur.tier.tier_max,
                                },
                                {
                                    x: new Date(
                                        cur.seasonName.substring(5, 9),
                                        6,
                                        1
                                    ),
                                    y_min: cur.tier.tier_min,
                                    y_max: cur.tier.tier_max,
                                },
                            ],
                        });

                        return acc;
                    },
                    {
                        prevSeasonId: null,
                        seriesIndex: 0,
                        data: newTimeSeriesData,
                    }
                );
            }

            setTimeSeriesData(newTimeSeriesData);
            setDataBands(newDataBands);
        }
    }, [
        playerId,
        activePlayerId,
        competitionId,
        activeCompetitionId,
        selectedSeasonId,
        activeSeasonId,
        metric,
        activeMetric,
        seriesData,
    ]);

    const timeSpanSelections = useMemo(() => {
        return (
            selectedSeasonId &&
            career_latest_metrics &&
            Object.keys(career_latest_metrics).map((seasonId) => {
                const season = seasons[seasonId];
                return {
                    id: parseInt(seasonId),
                    data: [
                        {
                            x: new Date(season.name.substring(0, 4), 6, 1),
                        },
                        {
                            x: new Date(season.name.substring(5, 9), 6, 1),
                        },
                    ],
                };
            })
        );
    }, [career_latest_metrics, seasons, selectedSeasonId]);

    // We start the band at the latest of currentSeasonYear and most recent
    // season year in data + 1
    const contractBandStartYear =
        seriesData &&
        seriesData.length > 0 &&
        Math.max(
            currentSeasonYear,
            parseInt(
                seriesData[seriesData.length - 1].seasonName.substring(0, 4)
            ) + 1
        );

    const { timeAreas, timelineMarkers } = useMemo(() => {
        const timeAreas = [];
        const timelineMarkers = [];

        if (
            contractBandStartYear &&
            lastContractSeasonYear >= currentSeasonYear
        ) {
            timeAreas.push({
                name: "Free Agency",
                color: FREE_AGENCY_CLASS_COLORS[
                    player.projected_free_agency_class
                ],
                data: [
                    {
                        x: new Date(lastContractSeasonYear + 1, 6, 1),
                    },
                    {
                        x: new Date(maxSeasonYear + 1, 6, 1),
                    },
                ],
            });
            timelineMarkers.push({
                name: "Contract End",
                color: "var(--tw-slate-600)",
                x: new Date(lastContractSeasonYear + 1, 6, 1),
                label:
                    FREE_AGENCY_CLASS_LABELS[
                        player.projected_free_agency_class
                    ] +
                    " " +
                    (lastContractSeasonYear + 1) +
                    "-" +
                    ((lastContractSeasonYear + 2) % 100),
                labelOnRight:
                    maxSeasonYear - lastContractSeasonYear > 4 ? true : false,
            });
        }

        return { timeAreas, timelineMarkers };
    }, [
        contractBandStartYear,
        currentSeasonYear,
        lastContractSeasonYear,
        maxSeasonYear,
        player,
    ]);

    // Horizontal ticks are an array of objects. There is one entry per year between earliestSeasonYear and maxSeasonYear.
    // Each entry is an object with the following properties:
    // - x: The date of the tick, which is Jul 1st of the year.
    // - label: The label to display for the tick, which is the player's age that season.
    // - id: an id for the tick, which is also the player's age that season.
    const horizontalTicks = useMemo(() => {
        const horizontalTicks = [];
        for (let year = earliestSeasonYear; year <= maxSeasonYear; year++) {
            // Show ticks for earliest season year, and every 5 years after that.
            if (
                year === earliestSeasonYear ||
                year === maxSeasonYear ||
                year === lastContractSeasonYear + 1 ||
                (year - earliestSeasonYear + earliestSeasonAge) % 5 === 0
            ) {
                horizontalTicks.push({
                    x: new Date(year, 6, 1),
                    label: year - earliestSeasonYear + earliestSeasonAge,
                    id: year - earliestSeasonYear,
                });
            }
        }

        return horizontalTicks;
    }, [
        earliestSeasonAge,
        earliestSeasonYear,
        lastContractSeasonYear,
        maxSeasonYear,
    ]);

    return (
        <>
            {no_data && <div className={classes.no_data}>No Data</div>}
            {!seriesData && <div className={classes.no_data}>Loading...</div>}
            {seriesData && !no_data && (
                <TimeSeriesChart
                    width={width}
                    height={height}
                    margin={margin}
                    minY={autoScaleYAxis ? null : 0}
                    maxY={autoScaleYAxis || autoScaleYAxisMax ? null : 1}
                    stepsY={4}
                    isPercentage={isPercentage}
                    isSeconds={isSeconds}
                    series={timeSeriesData}
                    bands={dataBands}
                    timeAreas={timeAreas}
                    timelineMarkers={timelineMarkers}
                    timeSpanSelections={timeSpanSelections}
                    onTimeSpanSelected={onSeasonSelectionHandler}
                    selectedTimeSpanId={activeSeasonId}
                    minDate={minDate}
                    maxDate={maxDate}
                    horizontalTicks={horizontalTicks}
                    includeHorizontalTicksGrid={true}
                    transitionContent={inTransition}
                />
            )}
        </>
    );
};

export default PlayerMetricCareerProgressionChart;
