import { useEffect, useState } from "react";
import { PlayerIndexMetrics } from "../../constants";
import SearchInput from "./SearchInput";

const MetricSearchInput = ({
    metricName,
    primaryPosition,
    onResultSelected,
    maxListLength = 5,
}) => {
    const [metricNameLabel, setMetricNameLabel] = useState("");

    useEffect(() => {
        // find the metric that matches the base name,
        // and set the search query to the metric's label
        const metric = Object.values(PlayerIndexMetrics).find(
            (metric) => metric.name === metricName
        );
        if (metric) {
            setMetricNameLabel(metric.label);
        } else {
            setMetricNameLabel("");
        }
    }, [metricName]);

    const handleSearchChange = (lcQuery) => {
        const results = Object.values(PlayerIndexMetrics)
            .filter((metric) => {
                return (
                    metric.positionScope.includes(primaryPosition) &&
                    (metric.label.toLowerCase().includes(lcQuery) ||
                        metric.name.toLowerCase().includes(lcQuery))
                );
            })
            .map((metric) => {
                return {
                    id: metric.name,
                    label: metric.label,
                };
            });
        return results;
    };

    return (
        <SearchInput
            placeHolderText="Search metric..."
            searchHandler={handleSearchChange}
            currentSelection={{
                id: metricName,
                label: metricNameLabel,
            }}
            onResultSelected={onResultSelected}
            maxListLength={maxListLength}
        />
    );
};

export default MetricSearchInput;
