import { uiActions } from "./ui-slice";
import { fetchData } from "../utils";
import { playerStatusCategoriesActions } from "./playerstatuscategories-slice";

let ongoingRequests = {
    fetchPlayerStatusCategories: false,
};

export const fetchPlayerStatusCategories = () => {
    return async (dispatch) => {
        if (!ongoingRequests.fetchPlayerStatusCategories) {
            ongoingRequests.fetchPlayerStatusCategories = fetchData(
                process.env.REACT_APP_HABSHUB_API_BASE_URL +
                    "hockey_analytics/playerstatuscategories/",
                "Could not fetch player status categories data!"
            )
                .then((playerStatusCategoriesData) => {
                    dispatch(
                        playerStatusCategoriesActions.updatePlayerStatusCategories(
                            playerStatusCategoriesData
                        )
                    );
                    ongoingRequests.fetchPlayerStatusCategories = false;
                    return playerStatusCategoriesData;
                })
                .catch((error) => {
                    dispatch(
                        uiActions.showNotification({
                            status: "error",
                            title: "Error!",
                            message:
                                "Fetching all player status categories data failed!",
                        })
                    );
                    ongoingRequests.fetchPlayerStatusCategories = false;
                    throw error;
                });
        }

        return ongoingRequests.fetchPlayerStatusCategories;
    };
};
