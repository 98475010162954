import { LegendSection, LegendItem } from "../../../controls/Legend";
import {
    PlayerFreeAgencyPill,
    PlayerWaiversExemptionPill,
} from "../../playertile/Playertile";
import tile_classes from "../../../components/playertile/Playertile.module.css";

const SYMBOL_WIDTH = "52px";

const AdditionalContractDetailsSection = () => {
    return (
        <LegendSection title="Additional Contract Details">
            <LegendItem
                symbol={<div>#y +#</div>}
                symbolWidth={SYMBOL_WIDTH}
                description="Current contract duration (including current season), plus extension if applicable."
            />
            <LegendItem
                symbol={
                    <PlayerFreeAgencyPill
                        freeAgencyClass={tile_classes.projected_ufa}
                    />
                }
                symbolWidth={SYMBOL_WIDTH}
                description="Player is projected UFA at the end of his current contract and extension."
            />
            <LegendItem
                symbol={
                    <PlayerFreeAgencyPill
                        freeAgencyClass={tile_classes.projected_rfa}
                        isArbitrationEligible={true}
                    />
                }
                symbolWidth={SYMBOL_WIDTH}
                description="Player is projected RFA with arbitration rights at the end of his current contract and extension."
            />
            <LegendItem
                symbol={
                    <PlayerFreeAgencyPill
                        freeAgencyClass={tile_classes.projected_rfa}
                    />
                }
                symbolWidth={SYMBOL_WIDTH}
                description="Player is projected RFA without arbitration rights at the end of his current contract and extension."
            />
            <LegendItem
                symbol={
                    <PlayerFreeAgencyPill
                        freeAgencyClass={tile_classes.ten_2c}
                    />
                }
                symbolWidth={SYMBOL_WIDTH}
                description="Player is projected 10.2c at the end of his current contract and extension."
            />
            <LegendItem
                symbol={
                    <PlayerFreeAgencyPill freeAgencyClass={tile_classes.pto} />
                }
                symbolWidth={SYMBOL_WIDTH}
                description="Player is an AHL PTO."
            />
            <LegendItem
                symbol={
                    <PlayerFreeAgencyPill freeAgencyClass={tile_classes.ato} />
                }
                symbolWidth={SYMBOL_WIDTH}
                description="Player is an AHL ATO."
            />
            <LegendItem
                symbol={<PlayerWaiversExemptionPill />}
                symbolWidth={SYMBOL_WIDTH}
                description="Player is exempted from waivers."
            />
        </LegendSection>
    );
};

export default AdditionalContractDetailsSection;
