import classes from "./FadeablePanel.module.css";

const FadeablePanel = ({ isFaded, onClick, zIndex, children }) => {
    return (
        <div className={classes.fadeable_panel}>
            <div
                className={classes.fader + " " + (isFaded && classes.faded)}
                onClick={() => onClick()}
            />
            <div className={classes.fadeable_content}>
                {children}
            </div>
        </div>
    );
};

export default FadeablePanel;
