import { TriangleDirectionalMarker } from "./DirectionalMarkerIndicators";
import classes from "./HorizontalSliderIndicator.module.css";

function HorizontalSliderIndicator({ title, value, tierClass, isExample }) {
    return (
        <div
            className={classes.horizontal_slider_container}
            style={{
                left: `calc((100% - 32px) * ${isExample ? 0.5 : value})`,
            }}
        >
            <div className={classes.title}>{title}</div>
            <TriangleDirectionalMarker
                tierClass={tierClass}
                direction={"down"}
                size={15}
            />
        </div>
    );
}

export default HorizontalSliderIndicator;
