import { uiActions } from "./ui-slice";
import { fetchData } from "../utils";
import { competitionsActions } from "./competitions-slice";
import { teamsActions } from "./teams-slice";

let ongoingRequests = {
    fetchCompetition: {},
    fetchCompetitions: null, // This will be either null or a Promise
    fetchCompetitionActiveTeams: {},
    fetchCompetitionDatastash: {},
    fetchCompetitionsDatastash: {},
    fetchCompetitionSeasonDatastash: {},
    fetchCompetitionSeasonsDatastash: {},
    fetchCompetitionsSeasonsDatastash: {},
};

export const fetchCompetition = (competitionId) => {
    return async (dispatch) => {
        if (!ongoingRequests.fetchCompetition[competitionId]) {
            ongoingRequests.fetchCompetition[competitionId] = fetchData(
                process.env.REACT_APP_HABSHUB_API_BASE_URL +
                    "hockey_analytics/competitions/" +
                    competitionId +
                    "/",
                "Could not fetch competition data!"
            )
                .then((competitionData) => {
                    dispatch(
                        competitionsActions.updateCompetition(competitionData)
                    );
                    delete ongoingRequests.fetchCompetition[competitionId];
                    return competitionData;
                })
                .catch((error) => {
                    dispatch(
                        uiActions.showNotification({
                            status: "error",
                            title: "Error!",
                            message: `Fetching competition data for competition ${competitionId} failed!`,
                        })
                    );
                    delete ongoingRequests.fetchCompetition[competitionId];
                    throw error;
                });
        }

        return ongoingRequests.fetchCompetition[competitionId];
    };
};

export const fetchCompetitions = () => {
    return async (dispatch) => {
        if (!ongoingRequests.fetchCompetitions) {
            ongoingRequests.fetchCompetitions = fetchData(
                process.env.REACT_APP_HABSHUB_API_BASE_URL +
                    "hockey_analytics/competitions/",
                "Could not fetch competitions data!"
            )
                .then((competitionsData) => {
                    dispatch(
                        competitionsActions.updateCompetitions(competitionsData)
                    );
                    ongoingRequests.fetchCompetitions = null;
                    return competitionsData;
                })
                .catch((error) => {
                    dispatch(
                        uiActions.showNotification({
                            status: "error",
                            title: "Error!",
                            message: "Fetching all competitions data failed!",
                        })
                    );
                    ongoingRequests.fetchCompetitions = null;
                    throw error;
                });
        }

        return ongoingRequests.fetchCompetitions;
    };
};

export const fetchCompetitionActiveTeams = (competitionId) => {
    return async (dispatch) => {
        if (!ongoingRequests.fetchCompetitionActiveTeams[competitionId]) {
            ongoingRequests.fetchCompetitionActiveTeams[competitionId] =
                fetchData(
                    process.env.REACT_APP_HABSHUB_API_BASE_URL +
                        "hockey_analytics/competitions/" +
                        competitionId +
                        "/activeteams/",
                    "Could not fetch competition active teams!"
                )
                    .then((competitionActiveTeams) => {
                        dispatch(
                            competitionsActions.updateCompetitionActiveTeams({
                                competitionId: competitionId,
                                activeTeams: competitionActiveTeams,
                            })
                        );
                        dispatch(
                            teamsActions.updateTeams(competitionActiveTeams)
                        );
                        ongoingRequests.fetchCompetitionActiveTeams[
                            competitionId
                        ] = null;
                        return competitionActiveTeams;
                    })
                    .catch((error) => {
                        dispatch(
                            uiActions.showNotification({
                                status: "error",
                                title: "Error!",
                                message:
                                    "Fetching competition active teams failed!",
                            })
                        );
                        ongoingRequests.fetchCompetitionActiveTeams[
                            competitionId
                        ] = null;
                        throw error;
                    });
        }

        return ongoingRequests.fetchCompetitionActiveTeams[competitionId];
    };
};

export const fetchCompetitionDatastash = (competitionId, datastashName) => {
    return async (dispatch) => {
        const key = `${competitionId}-${datastashName}`; // Create a unique key for each competitionId and datastashName combination

        if (!ongoingRequests.fetchCompetitionDatastash[key]) {
            ongoingRequests.fetchCompetitionDatastash[key] = fetchData(
                process.env.REACT_APP_HABSHUB_API_BASE_URL +
                    "hockey_analytics/datastashes/competitions/" +
                    datastashName +
                    "/?competition_ids=" +
                    competitionId,
                "Could not fetch competition data!"
            )
                .then((data) => {
                    const dataPlayload = data.length > 0 ? data[0].data : [];
                    dispatch(
                        competitionsActions.updateCompetitionDatastash({
                            competitionId: competitionId,
                            datastashName: datastashName,
                            data: dataPlayload,
                        })
                    );
                    delete ongoingRequests.fetchCompetitionDatastash[key];
                    return dataPlayload;
                })
                .catch((error) => {
                    dispatch(
                        uiActions.showNotification({
                            status: "error",
                            title: "Error!",
                            message: `Fetching competition data stash ${datastashName} for competition ${competitionId} failed!`,
                        })
                    );
                    delete ongoingRequests.fetchCompetitionDatastash[key];
                    throw error;
                });
        }

        return ongoingRequests.fetchCompetitionDatastash[key];
    };
};

export const fetchCompetitionsDatastash = (competitionIds, datastashName) => {
    return async (dispatch) => {
        // Create a unique key for each competitionIds and datastashName combination
        const key = `${competitionIds.join(",")}-${datastashName}`;

        if (!ongoingRequests.fetchCompetitionsDatastash[key]) {
            ongoingRequests.fetchCompetitionsDatastash[key] = fetchData(
                process.env.REACT_APP_HABSHUB_API_BASE_URL +
                    "hockey_analytics/datastashes/competitions/" +
                    datastashName +
                    "/?" +
                    competitionIds
                        .map((id) => `competition_ids=${id}`)
                        .join("&"),
                "Could not fetch competitions data!"
            )
                .then((data) => {
                    dispatch(
                        competitionsActions.updateCompetitionsDatastash({
                            datastashName: datastashName,
                            data: data, // As we are receiving array of objects for all competition ids
                        })
                    );
                    delete ongoingRequests.fetchCompetitionsDatastash[key];
                    return data;
                })
                .catch((error) => {
                    dispatch(
                        uiActions.showNotification({
                            status: "error",
                            title: "Error!",
                            message: `Fetching competition data stash ${datastashName} for competitions ${competitionIds.join(
                                ","
                            )} failed!`,
                        })
                    );
                    delete ongoingRequests.fetchCompetitionsDatastash[key];
                    throw error;
                });
        }

        return ongoingRequests.fetchCompetitionsDatastash[key];
    };
};

export const fetchCompetitionSeasonDatastash = (
    competitionId,
    seasonId,
    datastashName
) => {
    return async (dispatch) => {
        // Create a unique key for each competitionId, seasonId and datastashName combination
        const key = `${competitionId}-${seasonId}-${datastashName}`;

        if (!ongoingRequests.fetchCompetitionSeasonDatastash[key]) {
            ongoingRequests.fetchCompetitionSeasonDatastash[key] = fetchData(
                process.env.REACT_APP_HABSHUB_API_BASE_URL +
                    "hockey_analytics/seasondatastashes/competitions/" +
                    datastashName +
                    "/?competition_ids=" +
                    competitionId +
                    "&season_ids=" +
                    seasonId,
                "Could not fetch competition data!"
            )
                .then((data) => {
                    const dataPlayload = data.length > 0 ? data[0].data : [];
                    dispatch(
                        competitionsActions.updateCompetitionSeasonDatastash({
                            competitionId: competitionId,
                            datastashName: datastashName,
                            seasonId: seasonId,
                            data: dataPlayload,
                        })
                    );
                    delete ongoingRequests.fetchCompetitionSeasonDatastash[key];
                    return dataPlayload;
                })
                .catch((error) => {
                    dispatch(
                        uiActions.showNotification({
                            status: "error",
                            title: "Error!",
                            message: `Fetching competition data stash ${datastashName} for competition ${competitionId}, season ${seasonId} failed!`,
                        })
                    );
                    delete ongoingRequests.fetchCompetitionSeasonDatastash[key];
                    throw error;
                });
        }
        return ongoingRequests.fetchCompetitionSeasonDatastash[key];
    };
};

export const fetchCompetitionSeasonsDatastash = (
    competitionId,
    datastashName
) => {
    return async (dispatch) => {
        const key = `${competitionId}-${datastashName}`;

        if (!ongoingRequests.fetchCompetitionSeasonsDatastash[key]) {
            ongoingRequests.fetchCompetitionSeasonsDatastash[key] = fetchData(
                process.env.REACT_APP_HABSHUB_API_BASE_URL +
                    "hockey_analytics/seasondatastashes/competitions/" +
                    datastashName +
                    `?competition_ids=${competitionId}`,
                "Could not fetch competitions data!"
            )
                .then((data) => {
                    dispatch(
                        competitionsActions.updateCompetitionSeasonsDatastash({
                            competitionId: competitionId,
                            datastashName: datastashName,
                            data: data, // As we are receiving array of objects for a competition and all season ids
                        })
                    );
                    delete ongoingRequests.fetchCompetitionSeasonsDatastash[
                        key
                    ];
                    return data;
                })
                .catch((error) => {
                    dispatch(
                        uiActions.showNotification({
                            status: "error",
                            title: "Error!",
                            message: `Fetching competition data stash ${datastashName} for competitions ${competitionId}, all seasons failed!`,
                        })
                    );
                    delete ongoingRequests.fetchCompetitionSeasonsDatastash[
                        key
                    ];
                    throw error;
                });
        }

        return ongoingRequests.fetchCompetitionSeasonsDatastash[key];
    };
};

export const fetchCompetitionsSeasonsDatastash = (
    datastashName,
    competitionIds = null,
    seasonIds = null
) => {
    return async (dispatch) => {
        // Create a unique key for each competitionIds. seasonIds list and datastashName combination
        const key = `${competitionIds ? competitionIds.join(",") : ""}-${
            seasonIds ? seasonIds.join(",") : ""
        }-${datastashName}`;

        if (!ongoingRequests.fetchCompetitionsSeasonsDatastash[key]) {
            const params = [
                competitionIds &&
                    competitionIds
                        .map((id) => `competition_ids=${id}`)
                        .join("&"),
                seasonIds &&
                    seasonIds.map((id) => `season_ids=${id}`).join("&"),
            ]
                .filter(Boolean)
                .join("&");

            ongoingRequests.fetchCompetitionsSeasonsDatastash[key] = fetchData(
                process.env.REACT_APP_HABSHUB_API_BASE_URL +
                    "hockey_analytics/seasondatastashes/competitions/" +
                    datastashName +
                    (params ? `?${params}` : ""),
                "Could not fetch competitions data!"
            )
                .then((data) => {
                    dispatch(
                        competitionsActions.updateCompetitionsSeasonsDatastash({
                            datastashName: datastashName,
                            data: data, // As we are receiving array of objects for all competition and season ids
                        })
                    );
                    delete ongoingRequests.fetchCompetitionsSeasonsDatastash[
                        key
                    ];
                    return data;
                })
                .catch((error) => {
                    dispatch(
                        uiActions.showNotification({
                            status: "error",
                            title: "Error!",
                            message: `Fetching competition data stash ${datastashName} for ${
                                competitionIds
                                    ? "competitions " + competitionIds.join(",")
                                    : "all competitions"
                            }, ${
                                seasonIds
                                    ? "seasons " + seasonIds.join(",")
                                    : "all seasons"
                            } failed!`,
                        })
                    );
                    delete ongoingRequests.fetchCompetitionsSeasonsDatastash[
                        key
                    ];
                    throw error;
                });
        }

        return ongoingRequests.fetchCompetitionsSeasonsDatastash[key];
    };
};
