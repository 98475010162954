import { useSelector, useDispatch } from "react-redux";
import { useEffect, useState, useCallback } from "react";
import {
    fetchPlayersSeasonsDatastash,
    fetchPlayersDatastash,
} from "../store/players-actions";

const usePlayerDatastash = () => {
    const dispatch = useDispatch();
    const players_seasondatastashes = useSelector(
        (state) => state.players.seasondatastashes
    );
    const players_datastashes = useSelector(
        (state) => state.players.datastashes
    );

    const [requiredPlayerIds, setRequiredPlayerIdsState] = useState([]);
    const [requiredDatastashes, setRequiredDatastashes] = useState([]);
    const [completedDatastashes, setCompletedDatastashes] = useState({});
    const [seasonId, setSeasonIdState] = useState(null);
    const [requiredSeasonDatastashes, setRequiredSeasonDatastashes] = useState(
        []
    );
    const [completedSeasonDatastashes, setCompletedSeasonDatastashes] =
        useState({});

    const MIN_METRICS_COUNT = 25;

    const checkDatastashMissing = useCallback(
        (requiredPlayerIds, datastashes, datastashName, seasonId) => {
            if (!requiredPlayerIds || requiredPlayerIds.length === 0) {
                return true;
            }

            const playersWithDatastashCount = requiredPlayerIds.reduce(
                (acc, playerId) => {
                    return (
                        acc +
                        ((
                            seasonId
                                ? datastashes[playerId]?.[datastashName]?.[
                                      seasonId
                                  ]
                                : datastashes[playerId]?.[datastashName]
                        )
                            ? 1
                            : 0)
                    );
                },
                0
            );

            return playersWithDatastashCount < MIN_METRICS_COUNT;
        },
        [MIN_METRICS_COUNT]
    );

    const addRequiredDatastash = useCallback(
        (datastashName) => {
            const isMissing = checkDatastashMissing(
                requiredPlayerIds,
                players_datastashes,
                datastashName
            );

            setRequiredDatastashes((prev) => [...prev, datastashName]);
            setCompletedDatastashes((prev) => ({
                ...prev,
                [datastashName]: !isMissing,
            }));
        },
        [players_datastashes, requiredPlayerIds, checkDatastashMissing]
    );

    useEffect(() => {
        const fetchDatastashes = () => {
            if (requiredPlayerIds.length > 0) {
                requiredDatastashes.forEach((datastashName) => {
                    if (
                        completedDatastashes[datastashName] ||
                        !checkDatastashMissing(
                            requiredPlayerIds,
                            players_datastashes,
                            datastashName
                        )
                    ) {
                        return;
                    }

                    dispatch(
                        fetchPlayersDatastash(requiredPlayerIds, datastashName)
                    ).then(() => {
                        setCompletedDatastashes((prev) => ({
                            ...prev,
                            [datastashName]: true,
                        }));
                    });
                });
            }
        };

        fetchDatastashes();
    }, [
        requiredDatastashes,
        requiredPlayerIds,
        completedDatastashes,
        checkDatastashMissing,
        players_datastashes,
        dispatch,
    ]);

    const addRequiredSeasonDatastash = useCallback(
        (datastashName, seasonId) => {
            const isMissing = checkDatastashMissing(
                requiredPlayerIds,
                players_seasondatastashes,
                datastashName,
                seasonId
            );

            setRequiredSeasonDatastashes((prev) => [...prev, datastashName]);
            setCompletedSeasonDatastashes((prev) => ({
                ...prev,
                [datastashName]: !isMissing,
            }));
        },
        [players_seasondatastashes, requiredPlayerIds, checkDatastashMissing]
    );

    useEffect(() => {
        const fetchDatastashes = () => {
            if (requiredPlayerIds.length > 0) {
                requiredSeasonDatastashes.forEach((datastashName) => {
                    if (
                        completedSeasonDatastashes[datastashName] ||
                        !checkDatastashMissing(
                            requiredPlayerIds,
                            players_seasondatastashes,
                            datastashName,
                            seasonId
                        )
                    ) {
                        return;
                    }

                    dispatch(
                        fetchPlayersSeasonsDatastash(
                            datastashName,
                            requiredPlayerIds,
                            [seasonId]
                        )
                    ).then(() => {
                        setCompletedSeasonDatastashes((prev) => ({
                            ...prev,
                            [datastashName]: true,
                        }));
                    });
                });
            }
        };

        fetchDatastashes();
    }, [
        requiredSeasonDatastashes,
        completedSeasonDatastashes,
        requiredPlayerIds,
        seasonId,
        players_seasondatastashes,
        checkDatastashMissing,
        dispatch,
    ]);

    const setRequiredPlayerIds = useCallback(
        (playerIds) => {
            setRequiredPlayerIdsState(playerIds);
            setCompletedDatastashes((prev) => {
                const newCompletedDatastashes = {};
                requiredDatastashes.forEach((datastashName) => {
                    const isMissing = checkDatastashMissing(
                        playerIds,
                        players_datastashes,
                        datastashName
                    );
                    newCompletedDatastashes[datastashName] = !isMissing;
                });
                return newCompletedDatastashes;
            });

            setCompletedSeasonDatastashes((prev) => {
                const newCompletedSeasonDatastashes = {};
                requiredSeasonDatastashes.forEach((datastashName) => {
                    const isMissing = checkDatastashMissing(
                        playerIds,
                        players_seasondatastashes,
                        datastashName,
                        seasonId
                    );
                    newCompletedSeasonDatastashes[datastashName] = !isMissing;
                });
                return newCompletedSeasonDatastashes;
            });
        },
        [
            seasonId,
            requiredDatastashes,
            requiredSeasonDatastashes,
            players_datastashes,
            players_seasondatastashes,
            checkDatastashMissing,
        ]
    );

    const setSeasonId = useCallback(
        (id) => {
            setSeasonIdState(id);
            setCompletedSeasonDatastashes((prev) => {
                const newCompletedSeasonDatastashes = {};
                requiredSeasonDatastashes.forEach((datastashName) => {
                    const isMissing = checkDatastashMissing(
                        requiredPlayerIds,
                        players_seasondatastashes,
                        datastashName,
                        id
                    );
                    newCompletedSeasonDatastashes[datastashName] = !isMissing;
                });
                return newCompletedSeasonDatastashes;
            });
        },
        [
            requiredPlayerIds,
            requiredSeasonDatastashes,
            players_seasondatastashes,
            checkDatastashMissing,
        ]
    );

    return {
        setRequiredPlayerIds,
        setSeasonId,
        addRequiredDatastash,
        completedDatastashes,
        addRequiredSeasonDatastash,
        completedSeasonDatastashes,
    };
};

export default usePlayerDatastash;
