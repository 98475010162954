import classes from "./PushablePanel.module.css";

const DIRECTIONS = {
  left: 'translateX(-%s)',
  right: 'translateX(%s)',
  top: 'translateY(-%s)',
  bottom: 'translateY(%s)'
};

const PushablePanel = ({ isPushed, pushAmount = '150px', pushDirection = 'right', children }) => {
    let style = isPushed ? { transform: DIRECTIONS[pushDirection].replace('%s', pushAmount) } : {};

    return (
        <div className={classes.pushable_panel} style={style}>
            {children}
        </div>
    );
};

export default PushablePanel;
