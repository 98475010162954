import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { isEqual } from "lodash";
import { fetchData } from "../utils";

const COOLDOWN_PERIOD = 5000; // 5 seconds in milliseconds

export const fetchSystemConfiguration = createAsyncThunk(
    "ui/fetchSystemConfiguration",
    async (_, { rejectWithValue }) => {
        try {
            const data = await fetchData(
                process.env.REACT_APP_HABSHUB_API_BASE_URL +
                    "hockey_analytics/configurations/",
                "Could not fetch system configuration"
            );

            // Convert data into settings map
            const configurations = {};
            data.forEach((configuration) => {
                configurations[configuration.key] = configuration.value;
            });

            return configurations;
        } catch (error) {
            return rejectWithValue("Failed to fetch system configuration");
        }
    },
    {
        condition: (_, { getState }) => {
            const { ui } = getState();
            const lastFetchTime = ui.lastFetchTimes.systemConfiguration;
            const currentTime = Date.now();

            // Allow fetch if there's no last fetch time or if it's been more than 5 seconds
            return (
                !lastFetchTime || currentTime - lastFetchTime > COOLDOWN_PERIOD
            );
        },
    }
);

export const fetchUserSettings = createAsyncThunk(
    "ui/fetchUserSettings",
    async (_, { rejectWithValue }) => {
        try {
            const data = await fetchData(
                process.env.REACT_APP_HABSHUB_API_BASE_URL +
                    "users/me/settings/",
                "Could not fetch user settings"
            );

            // Convert data into settings map
            const settings = {};
            data.forEach((setting) => {
                settings[setting.key] = setting.value;
            });

            return settings;
        } catch (error) {
            return rejectWithValue("Failed to fetch user settings");
        }
    },
    {
        condition: (_, { getState }) => {
            const { ui } = getState();
            const lastFetchTime = ui.lastFetchTimes.userSettings;
            const currentTime = Date.now();

            // Allow fetch if there's no last fetch time or if it's been more than 5 seconds
            return (
                !lastFetchTime || currentTime - lastFetchTime > COOLDOWN_PERIOD
            );
        },
    }
);

export const updatePlayerTileMode = createAsyncThunk(
    "ui/updatePlayerTileMode",
    async (mode, thunkAPI) => {
        await fetchData(
            process.env.REACT_APP_HABSHUB_API_BASE_URL +
                "users/me/settings/default_player_tile_mode/",
            "Could not fetch user settings",
            "PUT",
            { value: mode }
        );

        return mode;
    }
);

export const PlayerTileModes = {
    PERFORMANCE: "performance",
    SCOUTING: "scouting",
    TRAD_STATS: "trad_stats",
    CAP: "cap",
};

const uiSlice = createSlice({
    name: "ui",
    initialState: {
        systemConfiguration: null,
        userSettings: null,
        notification: null,
        isLoading: true,
        playerTileMode: PlayerTileModes.TRAD_STATS,
        viewContext: {
            playerId: null,
            competitionId: null,
            metricContext: {
                stack: null,
                activeMetric: null,
            },
        },
        lastFetchTimes: {
            systemConfiguration: null,
            userSettings: null,
        },
    },
    reducers: {
        updateSystemConfiguration(state, action) {
            // Convert data into settings map
            const configurations = {};
            action.payload.forEach((configuration) => {
                configurations[configuration.key] = configuration.value;
            });
            state.systemConfiguration = configurations;
        },
        showNotification(state, action) {
            state.notification = {
                status: action.payload.status,
                title: action.payload.title,
                message: action.payload.message,
            };
        },
        showLoader(state) {
            state.isLoading = true;
        },
        hideLoader(state) {
            state.isLoading = false;
        },
        setStorePlayerTileMode(state, action) {
            state.playerTileMode = action.payload;
        },
        setViewContext(state, action) {
            state.viewContext.playerId = action.payload.playerId;
            state.viewContext.competitionId = action.payload.competitionId;
            if (
                !isEqual(
                    state.viewContext.metricContext.stack,
                    action.payload.metricContext.stack
                )
            ) {
                state.viewContext.metricContext.stack =
                    action.payload.metricContext.stack;
            }
            state.viewContext.metricContext.activeMetric =
                action.payload.metricContext.activeMetric;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchSystemConfiguration.pending, (state) => {
                state.lastFetchTimes.systemConfiguration = Date.now();
            })
            .addCase(fetchSystemConfiguration.fulfilled, (state, action) => {
                state.systemConfiguration = action.payload;
            })
            .addCase(fetchUserSettings.pending, (state) => {
                state.lastFetchTimes.userSettings = Date.now();
            })
            .addCase(fetchUserSettings.fulfilled, (state, action) => {
                state.userSettings = action.payload;
                state.playerTileMode =
                    action.payload.default_player_tile_mode ||
                    PlayerTileModes.TRAD_STATS;
            })
            .addCase(updatePlayerTileMode.fulfilled, (state, action) => {
                state.playerTileMode = action.payload;
            });
    },
});

// Enhanced reducer to handle playerTileMode setting
export const setPlayerTileMode = (mode) => (dispatch, getState) => {
    const { playerTileMode } = getState().ui;
    if (playerTileMode !== mode) {
        // Update the setting if valid
        if (Object.values(PlayerTileModes).includes(mode)) {
            dispatch(uiSlice.actions.setStorePlayerTileMode(mode));
            dispatch(updatePlayerTileMode(mode));
        }
    }
};

export const uiActions = {
    ...uiSlice.actions,
    fetchSystemConfiguration,
    fetchUserSettings,
    updatePlayerTileMode,
    setPlayerTileMode,
};

export default uiSlice;
