import Modal from "./Modal";
import HelpModal from "./HelpModal";
import classes from "./Legend.module.css";

export const LegendItem = ({ header, symbol, symbolWidth, description }) => {
    return (
        <>
            {header && (
                <div className={classes.legend_item_header}>{header}</div>
            )}
            <div className={classes.legend_item}>
                {symbol && (
                    <div
                        className={classes.legend_symbol}
                        style={symbolWidth && { minWidth: symbolWidth }}
                    >
                        {symbol}
                    </div>
                )}
                <div className={classes.legend_item_description}>
                    {description}
                </div>
            </div>
        </>
    );
};

export const LegendBlock = ({ children }) => {
    return <div className={classes.legend_block}>{children}</div>;
};

export const LegendSection = ({ title, description, children }) => {
    return (
        <div className={classes.legend_section}>
            <div className={classes.legend_section_title}>{title}</div>
            {description && (
                <div className={classes.legend_section_description}>
                    {description}
                </div>
            )}
            <div className={classes.legend_section_content}>{children}</div>
        </div>
    );
};

const Legend = ({
    zIndex = 1000,
    isOpen = null,
    onClose = null,
    showLegendTitle = true,
    children,
}) => {
    return (
        <>
            {onClose && (
                <Modal isOpen={isOpen} onClose={onClose} zIndex={zIndex}>
                    <>
                        {showLegendTitle && (
                            <div className={classes.legend_title}>Legend</div>
                        )}
                        <div className={classes.legend_container}>
                            {children}
                        </div>
                    </>
                </Modal>
            )}
            {!onClose && (
                <HelpModal zIndex={zIndex}>
                    <>
                        {showLegendTitle && (
                            <div className={classes.legend_title}>Legend</div>
                        )}
                        <div className={classes.legend_container}>
                            {children}
                        </div>
                    </>
                </HelpModal>
            )}
        </>
    );
};

export default Legend;
