import { useCallback, useEffect, useState } from "react";
import classes from "./SearchInput.module.css";
import TextInput from "../TextInput";

/**
 * `SearchInput` Component
 *
 * The `SearchInput` component provides an interactive search input field that dynamically displays
 * search results as the user types. The component allows for keyboard navigation of search results
 * and a callback mechanism for when a result is selected.
 *
 * Props:
 *  - `placeHolderText` (string): A placeholder text to display in the search input until the user starts typing.
 *  - `searchHandler` (function): A function that takes a search query (string) and returns an array of search result objects.
 *  - `currentSelection` (object|null): The currently selected search result object or null if there is no selection.
 *  - `onResultSelected` (function): A callback function that is called when a search result is selected.
 *                                   Receives the selected result object as its argument.
 *  - `maxListLength` (number): Optional. The maximum number of search results to display at once. Defaults to 5.
 *
 * State:
 *  - `searchResults` (array): The current array of search results to be displayed.
 *  - `searchQuery` (string): The current value of the search input field.
 *
 * The component is divided into two main parts:
 * 1. An input field (`TextInput` component) for entering the search query.
 * 2. A results list (`SearchResultsList` component) that displays the filtered results based on the query.
 *
 * The `SearchResultsList` component is nested within `SearchInput` and takes the following props:
 *  - `searchResults` (array): Array of search result objects, each containing at least `id` and `label` properties.
 *  - `currentSelection` (number|string): The id of the currently selected result.
 *  - `maxListLength` (number): Maximum number of results to display.
 *  - `onResultSelected` (function): Function to call when a result is selected.
 *
 * Each search result object should have the following structure:
 *  - `id` (number|string): A unique identifier for the result.
 *  - `label` (string): The display text for the result.
 *
 * Usage:
 * ```
 * <SearchInput
 *   placeHolderText="Search..."
 *   searchHandler={yourSearchFunction}
 *   currentSelection={selectedResult}
 *   onResultSelected={handleResultSelection}
 *   maxListLength={10}
 * />
 * ```
 *
 * The `SearchInput` component is designed for reuse in any application requiring a search functionality
 * with interactive and accessible features. It is styled via CSS modules and assumes the presence of certain
 * CSS classes for styling.
 */

const SearchResultsList = ({
    searchResults,
    currentSelection,
    maxListLength,
    onResultSelected,
}) => {
    const handleSearchResultSelected = (result) => {
        onResultSelected && onResultSelected(result);
    };

    // Keyboard navigation for search results
    const handleKeyDown = (event, result) => {
        if (event.key === "Enter") {
            handleSearchResultSelected(result);
        }
    };

    const listLength = maxListLength || searchResults.length;

    return (
        <div className={classes.resultsListContainer}>
            <div className={classes.resultsList}>
                {searchResults.slice(0, listLength).map((result, index) => {
                    return (
                        <div
                            key={result.id}
                            tabIndex={0}
                            className={
                                classes.search_result_item +
                                " " +
                                (currentSelection === result.id
                                    ? classes.selected
                                    : "")
                            }
                            onClick={() => handleSearchResultSelected(result)}
                            onKeyDown={(event) => handleKeyDown(event, result)}
                            role="button"
                            aria-pressed="false"
                        >
                            <div className={classes.searchResultDetails}>
                                <div className={classes.searchResultLabel}>
                                    {result.label}
                                </div>
                            </div>
                        </div>
                    );
                })}
            </div>
        </div>
    );
};

const SearchInput = ({
    placeHolderText,
    searchHandler,
    currentSelection,
    onResultSelected,
    maxListLength = 5,
}) => {
    const [searchResults, setSearchResults] = useState([]);
    const [searchQuery, setSearchQuery] = useState(currentSelection?.label);
    const [inputFocused, setInputFocused] = useState(false);

    useEffect(() => {
        if (!inputFocused) {
            // Revert to displaying the label of the current selection when input loses focus
            setSearchQuery(currentSelection?.label || "");
        }
    }, [inputFocused, currentSelection]);

    useEffect(() => {
        setSearchQuery(currentSelection?.label);
    }, [currentSelection]);

    const handleResultSelected = useCallback(
        (result) => {
            onResultSelected(result);
            setSearchQuery(result.label);
            setSearchResults([]);
            setInputFocused(false);
        },
        [onResultSelected]
    );

    const handleSearchChange = (event) => {
        setInputFocused(true);
        const query = event.target.value;
        setSearchQuery(query);

        const lcQuery = query.toLowerCase();

        if (
            query.length > 0 &&
            lcQuery !== currentSelection.label?.toLowerCase()
        ) {
            setSearchResults(searchHandler(lcQuery));
        } else {
            setSearchResults([]);
        }
    };

    const handleBlur = () => {
        setInputFocused(false);
    };

    return (
        <div className={classes.searchInputContainer}>
            <div className={classes.textInputContainer}>
                <TextInput
                    placeholder={placeHolderText}
                    value={searchQuery || ""}
                    onChange={handleSearchChange}
                    onBlur={handleBlur}
                    onFocus={() => setInputFocused(true)}
                />
            </div>
            {searchResults.length > 0 && (
                <SearchResultsList
                    currentSelection={currentSelection?.id}
                    searchResults={searchResults}
                    maxListLength={maxListLength}
                    onResultSelected={handleResultSelected}
                />
            )}
        </div>
    );
};

export default SearchInput;
