import { createSlice } from "@reduxjs/toolkit";

const playerDevelopmentOptionsSlice = createSlice({
    name: "playerdevelopmentoptions",
    initialState: {
        developmentpriorityoutcomes: {},
        sessiontypes: {},
    },
    reducers: {
        updatePlayerDevelopmentPriorityOutcomes(state, action) {
            const developmentPriorityOutcomes = action.payload;

            developmentPriorityOutcomes.forEach(
                (developmentPriorityOutcome) => {
                    state.developmentpriorityoutcomes[
                        developmentPriorityOutcome.id
                    ] = developmentPriorityOutcome;
                }
            );
        },
        updatePlayerDevelopmentSessionTypes(state, action) {
            const sessionTypes = action.payload;

            sessionTypes.forEach((sessionType) => {
                state.sessiontypes[sessionType.id] = sessionType;
            });
        },
    },
});

export const playerDevelopmentOptionsActions =
    playerDevelopmentOptionsSlice.actions;

export default playerDevelopmentOptionsSlice;
