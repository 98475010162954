import React, { useEffect, useState, useCallback } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import AppPage from "../layout/AppPage";
import OrgHeader from "../layout/OrgHeader";
import MainContent from "../layout/MainContent";
import CompressiblePanel from "../layout/CompressiblePanel";
import MainBox from "../layout/MainBox";
import { uiActions } from "../store/ui-slice";
import { mp_track } from "../mixpanel";
import classes from "./HistoricalTrends.module.css";
import CompetitionMetricSeasonTiersTrendChart from "../components/CompetitionMetricSeasonTiersTrendChart";
import CompetitionMetricHistoricalTiersTrendChart from "../components/CompetitionMetricHistoricalTiersTrendChart";
import { PlayerIndexMetrics } from "../constants";
import useMetricsDrilldownBreadcrumbManager from "../hooks/use-metrics-drilldown-breadcrumb-manager";
import MetricTopPlayersList from "../components/MetricTopPlayersList";
import { EMPTY_VIEW_CONTEXT } from "../hooks/use-cross-page-view-context";

const historical_trends_mp_track = (
    competitionId,
    competitionName,
    seasonId,
    seasonName,
    primaryPosition,
    metricName,
    event,
    properties = null
) => {
    properties = {
        ...properties,
        competition_id: competitionId,
        competition_name: competitionName,
        season_id: seasonId,
        season_name: seasonName,
        position: primaryPosition,
        metric_name: metricName,
    };

    mp_track(event, properties, "historical_trends");
};

const ButtonSelector = ({ selectionOptions, selectionId, setSelectionId }) => {
    const handleSelectionClick = (selectionId) => {
        setSelectionId(selectionId);
    };

    return (
        <div className={classes.button_selector}>
            {selectionOptions.map((selection) => (
                <div
                    key={selection.id}
                    className={
                        classes.selector_button +
                        (selection.id === selectionId
                            ? " " + classes.selected
                            : "")
                    }
                    onClick={() => handleSelectionClick(selection.id)}
                >
                    {selection.label}
                </div>
            ))}
        </div>
    );
};

const CompetitionSelector = ({ competitionId, setCompetitionId }) => {
    const competitions = useSelector(
        (state) => state.competitions.competitions
    );

    const competitionsArray = Object.values(competitions).filter(
        // Only competitions that have at least one SL season of complete data
        (competition) =>
            [
                1, // NHL
                2, // AHL
                3, // QMJHL
                4, // OHL
                5, // WHL
                9, // SHL
                10, // HockeyAllsvenskan
                13, // NCAA
                14, // USHL
            ].includes(competition.id)
    );

    return (
        <>
            <div className={classes.selector_header}>League</div>
            <ButtonSelector
                selectionOptions={competitionsArray.map((competition) => ({
                    id: competition.id,
                    label: competition.display_name,
                }))}
                selectionId={competitionId}
                setSelectionId={setCompetitionId}
            />
        </>
    );
};

const SeasonSelector = ({ allSeasons, seasonId, setSeasonId }) => {
    const seasons = useSelector((state) => state.seasons.seasons);

    const seasonsArray =
        allSeasons && allSeasons.map((seasonId) => seasons[seasonId]);

    return (
        <>
            <div className={classes.selector_header}>Season</div>
            {seasonsArray && (
                <ButtonSelector
                    selectionOptions={seasonsArray.map((season) => ({
                        id: season.id,
                        label: season.shorthand,
                    }))}
                    selectionId={seasonId}
                    setSelectionId={setSeasonId}
                />
            )}
        </>
    );
};

const PositionSelector = ({ position, setPosition }) => {
    const positionsArray = [
        {
            id: "F",
            label: "Forwards",
        },
        {
            id: "D",
            label: "Defencemen",
        },
        {
            id: "G",
            label: "Goalies",
        },
    ];

    return (
        <>
            <div className={classes.selector_header}>Position</div>
            <ButtonSelector
                selectionOptions={positionsArray}
                selectionId={position}
                setSelectionId={setPosition}
            />
        </>
    );
};

const MetricBreadCrumbSelector = ({
    metricStack,
    primaryPosition,
    onMetricClick,
}) => {
    const breadCrumbArray = metricStack.map((metric) => ({
        id: metric,
        label: PlayerIndexMetrics[metric].label,
    }));

    const breakdownArray = PlayerIndexMetrics[
        metricStack[metricStack.length - 1]
    ].componentMetrics
        .filter((metric) =>
            PlayerIndexMetrics[metric].positionScope.includes(primaryPosition)
        )
        .map((metric) => ({
            id: metric,
            label: PlayerIndexMetrics[metric].label,
        }));

    return (
        <>
            <div className={classes.selector_header}>Metrics</div>
            <ButtonSelector
                selectionOptions={breadCrumbArray}
                selectionId={metricStack[metricStack.length - 1]}
                setSelectionId={onMetricClick}
            />
            <div className={classes.selector_header}>Breakdown</div>
            <ButtonSelector
                selectionOptions={breakdownArray}
                selectionId={null}
                setSelectionId={onMetricClick}
            />
        </>
    );
};

const HistoricalTrends = ({
    viewContextProp = EMPTY_VIEW_CONTEXT,
    onViewContextChange,
    onMenuOpen,
}) => {
    const navigate = useNavigate();
    const location = useLocation();

    const defaultCompetition = useSelector(
        (state) => state.competitions.competitions[1]
    );
    const defaultSeason = useSelector(
        (state) =>
            state.seasons.seasons[state.ui.systemConfiguration?.currentSeasonId]
    );
    const defaultPosition = "D";
    const defaultMetric = "Player Index";

    const [competitionId, setCompetitionId] = useState(
        (viewContextProp && viewContextProp.competitionId) ||
            defaultCompetition.id
    );
    const [seasonId, setSeasonId] = useState(
        (viewContextProp && viewContextProp.seasonId) || defaultSeason.id
    );
    const [primaryPosition, setPrimaryPosition] = useState(
        (viewContextProp && viewContextProp.primaryPosition) || defaultPosition
    );

    const panelWidth = 450;

    const competitions = useSelector(
        (state) => state.competitions.competitions
    );
    const competition = useSelector(
        (state) => state.competitions.competitions[competitionId]
    );
    const competition_seasondatastashes = useSelector(
        (state) => state.competitions.seasondatastashes[competitionId]
    );

    const seasons = useSelector((state) => state.seasons.seasons);

    const season = useSelector((state) => state.seasons.seasons[seasonId]);

    const allSeasons =
        competition_seasondatastashes &&
        competition_seasondatastashes.latest_position_metrics_tiers_thresholds &&
        Object.keys(
            competition_seasondatastashes.latest_position_metrics_tiers_thresholds
        ).map((seasonId) => parseInt(seasonId));

    useEffect(() => {
        if (allSeasons) {
            if (!allSeasons.includes(seasonId)) {
                setSeasonId(allSeasons[allSeasons.length - 1]);
            }
        }
    }, [allSeasons, seasonId]);

    const dispatch = useDispatch();

    useEffect(() => {
        historical_trends_mp_track(
            defaultCompetition.id,
            defaultCompetition.display_name,
            defaultSeason.id,
            defaultSeason.name,
            defaultPosition,
            defaultMetric,
            "Historical Trend Viewer Loaded"
        );
        dispatch(uiActions.hideLoader());
    }, [
        defaultCompetition,
        defaultSeason,
        defaultPosition,
        defaultMetric,
        dispatch,
    ]);

    const [initialContextLoaded, setInitialContextLoaded] = useState(
        !(
            viewContextProp.metricContext &&
            viewContextProp.metricContext.stack &&
            viewContextProp.metricContext.activeMetric
        )
    );

    const [activeMetricContext, setActiveMetricContext] = useState({
        stack:
            viewContextProp &&
            viewContextProp.metricContext &&
            viewContextProp.metricContext.stack,
        activeMetric:
            viewContextProp &&
            viewContextProp.metricContext &&
            viewContextProp.metricContext.activeMetric,
    });

    const handleCompetitionSelect = (competitionId) => {
        setCompetitionId(competitionId);
        const competition = competitions[competitionId];

        onViewContextChange &&
            onViewContextChange({
                competitionId: competitionId, // New state
                seasonId: seasonId, // Current state
                primaryPosition: primaryPosition, // Current state
                metricContext: activeMetricContext, // Current state
            });

        historical_trends_mp_track(
            competitionId,
            competition.display_name,
            seasonId,
            season.name,
            primaryPosition,
            metricStack.activeMetric,
            "Historical Trend Viewer - Selected Competition"
        );
    };

    const handleSeasonSelect = (seasonId) => {
        setSeasonId(seasonId);
        const season = seasons[seasonId];

        onViewContextChange &&
            onViewContextChange({
                competitionId: competitionId, // Current state
                seasonId: seasonId, // New state
                primaryPosition: primaryPosition, // Current state
                metricContext: activeMetricContext, // Current state
            });

        historical_trends_mp_track(
            competitionId,
            competition.display_name,
            seasonId,
            season.name,
            primaryPosition,
            metricStack.activeMetric,
            "Historical Trend Viewer - Selected Season"
        );
    };

    const handleMetricContextChange = useCallback((metricContext) => {
        setActiveMetricContext(metricContext);
    }, []);

    const {
        metricStack,
        initiateSingleMetricTransition,
        initiateFullMetricStackTransition,
        setPrimaryPosition: setBCMgrPrimaryPosition,
    } = useMetricsDrilldownBreadcrumbManager(
        PlayerIndexMetrics,
        defaultMetric,
        handleMetricContextChange
    );

    useEffect(() => {
        if (primaryPosition) {
            setBCMgrPrimaryPosition(primaryPosition);
        }
    }, [primaryPosition, setBCMgrPrimaryPosition]);

    const handlePositionSelect = (position) => {
        setPrimaryPosition(position);
        onViewContextChange &&
            onViewContextChange({
                competitionId: competitionId, // Current state
                seasonId: seasonId, // Current state
                primaryPosition: position, // New state
                metricContext: activeMetricContext, // Current state
            });

        historical_trends_mp_track(
            competitionId,
            competition.display_name,
            seasonId,
            season.name,
            position,
            metricStack.activeMetric,
            "Historical Trend Viewer - Selected Position"
        );
    };

    useEffect(() => {
        onViewContextChange &&
            onViewContextChange({
                competitionId: competitionId, // Current state
                seasonId: seasonId, // Current state
                primaryPosition: primaryPosition, // Current state
                metricContext: activeMetricContext, // New state
            });
    }, [
        activeMetricContext,
        competitionId,
        seasonId,
        primaryPosition,
        onViewContextChange,
    ]);

    useEffect(() => {
        if (
            !initialContextLoaded &&
            viewContextProp.metricContext &&
            viewContextProp.metricContext.stack &&
            viewContextProp.metricContext.activeMetric !==
                metricStack.activeMetric
        ) {
            initiateFullMetricStackTransition(
                viewContextProp.metricContext.stack,
                viewContextProp.metricContext.activeMetric
            );
            setInitialContextLoaded(true);
        }
    }, [
        viewContextProp,
        metricStack,
        initialContextLoaded,
        initiateFullMetricStackTransition,
    ]);

    const [mainContentWidth, setMainContentWidth] = useState(null);
    const [mainContentHeight, setMainContentHeight] = useState(null);

    return (
        <AppPage>
            <CompressiblePanel
                onWidthChange={setMainContentWidth}
                onHeightChange={setMainContentHeight}
            >
                <OrgHeader
                    fullTitle={
                        competition.display_name + " - Historical Trends Viewer"
                    }
                    mediumTitle={
                        competition.display_name + " - Historical Trends"
                    }
                    shortTitle={"Historical Trends"}
                    onOpen={onMenuOpen}
                    imgUrl={
                        "https://s3.amazonaws.com/slmediaimages/leagues/NHL.svg"
                    }
                    imgPosition={"50% 50%"}
                    bgColor={"#000"}
                    parentWidth={mainContentWidth}
                    parentHeight={mainContentHeight}
                />
                <MainContent>
                    <MainBox>
                        <CompetitionSelector
                            competitionId={competitionId}
                            setCompetitionId={handleCompetitionSelect}
                        />
                        <SeasonSelector
                            allSeasons={allSeasons}
                            seasonId={seasonId}
                            setSeasonId={handleSeasonSelect}
                        />
                        <PositionSelector
                            position={primaryPosition}
                            setPosition={handlePositionSelect}
                        />
                        <MetricBreadCrumbSelector
                            metricStack={metricStack.stack}
                            primaryPosition={primaryPosition}
                            onMetricClick={(metric) => {
                                initiateSingleMetricTransition(metric);
                                historical_trends_mp_track(
                                    competitionId,
                                    competition.display_name,
                                    seasonId,
                                    season.name,
                                    primaryPosition,
                                    metric,
                                    "Historical Trend Viewer - Selected Metric"
                                );
                            }}
                        />
                    </MainBox>
                    <div className={classes.all_charts_container}>
                        <div className={classes.chart_container}>
                            <div className={classes.chart_header}>
                                {
                                    PlayerIndexMetrics[metricStack.activeMetric]
                                        .label
                                }
                                {" - "}
                                {competition.display_name}
                                {" - "}
                                {primaryPosition}
                                {" - "}
                                Historical Trend
                            </div>
                            <div className={classes.button_selector}>
                                <CompetitionMetricHistoricalTiersTrendChart
                                    competitionId={competitionId}
                                    primaryPosition={primaryPosition}
                                    metric={metricStack.activeMetric}
                                    width={panelWidth}
                                    height={250}
                                    margin={[10, 20, 24, 38]}
                                    autoScaleYAxis={
                                        PlayerIndexMetrics[
                                            metricStack.activeMetric
                                        ].autoScale ||
                                        PlayerIndexMetrics[
                                            metricStack.activeMetric
                                        ].autoScaleMax
                                    }
                                    isLoadingHandler={null}
                                />
                            </div>
                        </div>
                        <div className={classes.chart_container}>
                            <div className={classes.chart_header}>
                                {
                                    PlayerIndexMetrics[metricStack.activeMetric]
                                        .label
                                }
                                {" - "}
                                {competition.display_name}
                                {" - "}
                                {primaryPosition}
                                {" - "}
                                {season.name} Trend
                            </div>
                            <div className={classes.button_selector}>
                                <CompetitionMetricSeasonTiersTrendChart
                                    competitionId={competitionId}
                                    seasonId={seasonId}
                                    primaryPosition={primaryPosition}
                                    metric={metricStack.activeMetric}
                                    width={panelWidth}
                                    height={250}
                                    margin={[10, 20, 24, 38]}
                                    autoScaleYAxis={
                                        PlayerIndexMetrics[
                                            metricStack.activeMetric
                                        ].autoScale ||
                                        PlayerIndexMetrics[
                                            metricStack.activeMetric
                                        ].autoScaleMax
                                    }
                                    isLoadingHandler={null}
                                />
                            </div>
                        </div>
                        <div className={classes.chart_container}>
                            <div className={classes.chart_header}>
                                {
                                    PlayerIndexMetrics[metricStack.activeMetric]
                                        .label
                                }
                                {" - "}
                                {competition.display_name}
                                {" - "}
                                {primaryPosition}
                                {" - "}
                                {season.name} Top 10 Players
                            </div>
                            <div className={classes.button_selector}>
                                <MetricTopPlayersList
                                    competitionId={competitionId}
                                    seasonId={seasonId}
                                    primaryPosition={primaryPosition}
                                    metricName={metricStack.activeMetric}
                                    onPlayerClick={(player, index) => {
                                        historical_trends_mp_track(
                                            competitionId,
                                            competition.display_name,
                                            seasonId,
                                            season.name,
                                            primaryPosition,
                                            metricStack.activeMetric,
                                            "Historical Trend Viewer - Top 10 Player Click",
                                            {
                                                player_id: player.id,
                                                player_name: player.known_name,
                                                player_team:
                                                    player.team_shorthand,
                                                player_value: player.value,
                                                player_index: index,
                                            }
                                        );
                                        navigate(
                                            "/app/players/" +
                                                player.player_id +
                                                "/performance?competitionId=" +
                                                competitionId +
                                                "&seasonId=" +
                                                seasonId +
                                                "&metricStack=" +
                                                metricStack.stack +
                                                "&selectedMetric=" +
                                                metricStack.activeMetric +
                                                "&backNavOrigin=" +
                                                encodeURIComponent(
                                                    location.pathname +
                                                        location.search
                                                )
                                        );
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                </MainContent>
            </CompressiblePanel>
        </AppPage>
    );
};

export default HistoricalTrends;
