import classes from "./DialogButton.module.css";

const DialogButton = ({
    label = "Button",
    disabled = false,
    onClick,
    ...props
}) => {
    // Conditionally handle the click event
    const handleClick = (event) => {
        if (disabled) {
            // Prevent the default action and stop propagation
            event.preventDefault();
            event.stopPropagation();
            return; // Do nothing if the button is disabled
        }

        // If onClick prop is provided and button is not disabled, call it
        if (onClick) {
            onClick(event);
        }
    };

    return (
        <div
            {...props}
            className={
                classes.dialog_button + (disabled ? " " + classes.disabled : "")
            }
            onClick={handleClick}
        >
            {label}
        </div>
    );
};

export default DialogButton;
