import classes from "./IconButton.module.css";
import SquareButton from "./SquareButton";

const IconButton = ({
    label,
    materialIcon,
    isSelected = false,
    isDisabled = false,
    smallIcons = false,
    onClick,
    ...props
}) => {
    const handleClick = (e) => {
        if (!isDisabled && onClick) {
            onClick(e);
        }
    };

    return (
        <div
            onClick={handleClick}
            className={
                classes.icon_button + (isSelected ? " " + classes.selected : "")
            }
            {...props}
        >
            {label && <div className={classes.icon_button_label}>{label}</div>}
            <SquareButton
                label={materialIcon}
                useMaterialIcon={true}
                smallIcons={smallIcons}
                isSelected={isSelected}
                isDisabled={isDisabled}
            />
        </div>
    );
};

export default IconButton;
