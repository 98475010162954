import { useState, useRef, useEffect } from "react";
import SquareButton from "../controls/buttons/SquareButton";
import classes from "./ExpandableSection.module.css";

const ExpandableState = {
    Collapsing: "collapsing",
    Collapsed: "collapsed",
    Expanding: "expanding",
    Expanded: "expanded",
};

export const ExpandableContainer = ({ isExpanded, fast = false, children }) => {
    const contentRef = useRef(null);
    const [viewState, setViewState] = useState(
        isExpanded ? ExpandableState.Expanded : ExpandableState.Collapsed
    );

    useEffect(() => {
        if (isExpanded) {
            setViewState(ExpandableState.Expanding);
            setTimeout(() => {
                setViewState(ExpandableState.Expanded);
            }, 500);
        } else {
            setViewState(ExpandableState.Collapsing);
            requestAnimationFrame(() => {
                requestAnimationFrame(() => {
                    setViewState(ExpandableState.Collapsed);
                });
            });
        }
    }, [isExpanded]);

    useEffect(() => {
        // Dynamically adjust max-height for the wrapper
        const maxHeight = ["collapsing", "expanding"].includes(viewState)
            ? `${contentRef.current.scrollHeight}px`
            : viewState === "collapsed"
            ? "0px"
            : "none";
        contentRef.current.parentNode.style.maxHeight = maxHeight;
    }, [viewState]);

    return (
        <div
            className={
                classes.expandable_section_wrapper +
                (viewState === "expanding"
                    ? " " + classes.expanding
                    : viewState === "expanded"
                    ? " " + classes.expanded
                    : "") +
                (fast ? " " + classes.fast : "")
            }
        >
            <div
                ref={contentRef}
                className={classes.expandable_section_content}
            >
                {children}
            </div>
        </div>
    );
};

const ExpandableSection = ({
    label,
    children,
    onExpandToggle,
    border = false,
}) => {
    const [isExpanded, setIsExpanded] = useState(false);

    const toggleExpansion = () => {
        setIsExpanded(!isExpanded);
        if (onExpandToggle) {
            onExpandToggle(!isExpanded);
        }
    };

    return (
        <div
            className={
                classes.expandable_section +
                (border ? " " + classes.bordered : "")
            }
        >
            <div
                className={classes.expandable_section_header}
                onClick={toggleExpansion}
            >
                {label}
                <SquareButton
                    label={"chevron_left"}
                    useMaterialIcon={true}
                    smallIcons={true}
                    isRotated={isExpanded}
                />
            </div>
            <ExpandableContainer isExpanded={isExpanded}>
                {children}
            </ExpandableContainer>
        </div>
    );
};

export default ExpandableSection;
