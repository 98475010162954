import MainBox from "../layout/MainBox";
import classes from "./Modal.module.css";

export const Modal = ({
    children,
    isOpen,
    onClose,
    visibleOverflow = false,
    zIndex = 1000,
}) => {
    return (
        <>
            {isOpen && (
                <div
                    className={classes.overlay}
                    style={{ zIndex: zIndex }}
                    onClick={onClose}
                >
                    <div
                        className={classes.modalWrapper}
                        onClick={(e) => e.stopPropagation()}
                    >
                        <MainBox>
                            <div
                                className={
                                    classes.modalContent +
                                    (visibleOverflow
                                        ? " " + classes.visibleOverflow
                                        : "")
                                }
                            >
                                {children}
                            </div>
                        </MainBox>
                    </div>
                </div>
            )}
        </>
    );
};

export default Modal;
