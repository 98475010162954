import { useEffect, useState, useMemo, useRef, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import { fetchTeam, fetchTeamDatastash } from "../store/teams-actions";
import {
    fetchTeamPlayerDevelopmentPriorities,
    fetchSeasonPlayerDevelopmentSessions,
} from "../store/players-actions";
import useAuth from "../hooks/use-auth";
import { uiActions } from "../store/ui-slice";
import AppPage from "../layout/AppPage";
import OrgHeader from "../layout/OrgHeader";
import MainContent from "../layout/MainContent";
import ResponsiveContainer, {
    PlaceholderResponsiveContainerChild,
} from "../layout/ResponsiveContainer";
import CompressiblePanel from "../layout/CompressiblePanel";
import PlayerDevelopmentPanel from "../components/player-side-panels/PlayerDevelopmentPanel";
import playersSlice from "../store/players-slice";
import TieredTeamPlayers from "../components/TieredTeamPlayers";
import useTeamTieredPlayers from "../hooks/use-team-tiered-players";
import { EMPTY_VIEW_CONTEXT } from "../hooks/use-cross-page-view-context";
import IconButton from "../controls/buttons/IconButton";
import {
    PlayerDevelopmentSessionDialog,
    player_development_mp_track,
} from "../components/PlayerDevelopmentDialogs";
import { usePlayerDevelopmentSessionTypes } from "../hooks/enum-helpers";
import TeamPlayerDevelopmentLegend from "../components/legends/TeamPlayerDevelopmentLegend";

import classes from "./TeamPlayerDevelopment.module.css";
import { LAYOUT_LIMITS } from "../layout/constants";
import { PercentileClassIndicator } from "../components/indicators";
import { mp_track } from "../mixpanel";

const team_player_development_mp_track = (
    team,
    tier,
    event,
    properties = null
) => {
    properties = {
        ...properties,
        team_id: team.id,
        team_name: team.display_name,
        tier: tier,
    };

    mp_track(event, properties, "player_development");
};

const subTiers = {
    NHL: {
        label: "NHL Active Roster",
    },
    OFF_ROSTER: {
        label: "Injured Reserve",
    },
    AHL: {
        label: "AHL Roster",
    },
    JCE: {
        label: "Juniors, College & European Players",
    },
};

const ResponsiveButton = ({
    label,
    materialIcon,
    onClick,
    responsiveContainerLayoutMode,
}) => {
    return (
        <div
            className={
                classes.add_session_button +
                " " +
                classes[responsiveContainerLayoutMode]
            }
        >
            <IconButton
                label={label}
                materialIcon={materialIcon}
                onClick={onClick}
            />
        </div>
    );
};

const PlayerDevelopmentTile = ({ player, tileData, position, tier }) => {
    return (
        <div className={classes.development_priorities_tile_decoration}>
            <div className={classes.development_priorities_container}>
                {tileData.active_development_priorities.map(
                    (developmentPriority) => {
                        // If any of this season's sessions are associated with this development priority,
                        // mark the percentile value as 0.6 (blue), otherwise 0.5 (gray).
                        const percentileValue = tileData.season_sessions?.some(
                            (session) =>
                                session.developmentpriority_ids.includes(
                                    developmentPriority.id
                                )
                        )
                            ? 0.6
                            : 0.5;

                        return (
                            <div
                                key={developmentPriority.id}
                                className={classes.development_priority}
                            >
                                <div
                                    className={
                                        classes.development_priority_title
                                    }
                                >
                                    {developmentPriority.title}
                                </div>
                                <PercentileClassIndicator
                                    value={percentileValue}
                                />
                            </div>
                        );
                    }
                )}
            </div>
            {tileData.season_sessions && (
                <div className={classes.season_sessions_container}>
                    <div className={classes.season_sessions_count}>
                        {tileData.season_sessions.length}
                    </div>
                </div>
            )}
        </div>
    );
};

const playerSort = (a, b, tier, position) => {
    if (!a || !b) {
        return b ? 1 : a ? -1 : 0;
    }
    return b.player.depth_sort_index - a.player.depth_sort_index;
};

const TeamPlayerDevelopment = ({
    teamId,
    tier,
    viewContextProp = {
        playerId: null,
        competitionId: null,
        metricContext: {
            stack: null,
            activeMetric: null,
        },
    },
    onViewContextChange,
    onMenuOpen,
}) => {
    const { isAuthenticated, checkPermission } = useAuth();

    const canViewPlayerDevelopment = checkPermission(
        "core.can_view_player_development_priorities"
    );
    const canAddUpdatePlayerDevelopmentSessions = checkPermission(
        "core.can_add_update_player_development_sessions_and_notes"
    );

    const dispatch = useDispatch();

    const { playerDevelopmentSessionTypesValues } =
        usePlayerDevelopmentSessionTypes();

    const [selectedSession, setSelectedSession] = useState(null);
    const [isSessionDialogOpen, setIsSessionDialogOpen] = useState(false);

    const seasons = useSelector((state) => state.seasons.seasons);

    const currentSeasonId = useMemo(() => {
        // Find the season that has the latest start date, but no later than today.
        const today = new Date();
        return Object.values(seasons).reduce((currentSeasonId, season) => {
            if (
                season.start_date &&
                new Date(season.start_date) <= today &&
                (!currentSeasonId ||
                    season.start_date > seasons[currentSeasonId].start_date)
            ) {
                return season.id;
            }
            return currentSeasonId;
        }, null);
    }, [seasons]);

    // Memoize the tier selection options, so that it doesn't
    // change on every render.
    const tierSelectionOptions = useMemo(() => {
        return ["NHL", "OFF_ROSTER", "AHL", "JCE"];
    }, []);

    const {
        setTeamId,
        setPlayersData,
        tieredPlayers,
        isLoading: tieredPlayersIsloading,
        tieredPlayersData,
    } = useTeamTieredPlayers(teamId, tierSelectionOptions, true, true);

    const teams = useSelector((state) => state.teams.teams);
    const team = teams && teams[teamId];
    const ahlTeamId = team && team.affiliations[0];

    const [viewContext, setViewContext] = useState(viewContextProp);

    const [drilldownOpen, setDrilldownOpen] = useState(false);

    const teamIdRef = useRef(null);
    const [initialLoad, setInitialLoad] = useState(true);

    useEffect(() => {
        if (canViewPlayerDevelopment && team) {
            if (teamIdRef.current !== team.id) {
                team_player_development_mp_track(
                    team,
                    tier,
                    "View NHL Team Player Development"
                );
                teamIdRef.current = team.id;
                setTeamId(team.id);

                // Show the loader for a short time to give the user feedback that
                // the page is loading, and hide initial render flicker.
                dispatch(uiActions.showLoader());
            }
        }
    }, [canViewPlayerDevelopment, team, tier, setTeamId, dispatch]);

    // Clear loader after 500ms on initial mount. This assumes the teamId
    // never changes, otherwise we would need to do this process on every
    // team id change.
    useEffect(() => {
        const timeout = setTimeout(() => {
            setInitialLoad(false);
        }, 500);
        return () => clearTimeout(timeout);
    }, []);

    const teams_datastashes = useSelector((state) => state.teams.datastashes);
    const depth_chart_config =
        teams_datastashes &&
        teams_datastashes[teamId] &&
        teams_datastashes[teamId].depth_chart_config;

    const teams_reserveListPlayerIds = useSelector(
        (state) => state.teams.reserveListPlayerIds[teamId]
    );

    const playerDevelopmentSessions = useSelector(
        (state) => state.players.developmentsessions
    );

    useEffect(() => {
        if (canViewPlayerDevelopment) {
            dispatch(fetchSeasonPlayerDevelopmentSessions(currentSeasonId));
        }
    }, [canViewPlayerDevelopment, currentSeasonId, dispatch]);

    // Using a map to manage multiple WebSocket connections
    const [webSockets, setWebSockets] = useState(new Map());

    // Function to setup event listeners for a WebSocket
    const setupWebSocketEvents = useCallback(
        (websocket, entity, updateFunc) => {
            websocket.onopen = () => {
                console.log(`${entity} Updates WebSocket connected.`);
            };

            websocket.onmessage = (event) => {
                const data = JSON.parse(event.data);
                console.log(
                    `${entity} WebSocket update received for update ${data.id}.`
                );

                dispatch(updateFunc([data]));
            };

            websocket.onclose = () => {
                console.log(`${entity} WebSocket disconnected.`);
            };
        },
        [dispatch]
    );

    const [shouldUpdateWebSockets, setShouldUpdateWebSockets] = useState(false);

    // Determine when to update the WebSockets
    useEffect(() => {
        if (isAuthenticated && canViewPlayerDevelopment) {
            setShouldUpdateWebSockets(true);
        }
    }, [isAuthenticated, canViewPlayerDevelopment]);

    // Effect for setting up WebSocket connections
    useEffect(() => {
        if (shouldUpdateWebSockets) {
            // Close existing connections
            webSockets.forEach((ws) => ws.close());
            const newWebSockets = new Map();

            // Create new connections
            const developmentPrioritiesWebsocket = new WebSocket(
                process.env.REACT_APP_HABSHUB_WS_BASE_URL +
                    `playerdevelopmentpriorities/`
            );

            // Setup event listeners for each WebSocket
            setupWebSocketEvents(
                developmentPrioritiesWebsocket,
                "Player Development Priorities",
                playersSlice.actions.updatePlayerDevelopmentPriorities
            );

            newWebSockets.set(1, developmentPrioritiesWebsocket);

            const sessionsWebsocket = new WebSocket(
                process.env.REACT_APP_HABSHUB_WS_BASE_URL +
                    `playerdevelopmentsessions/`
            );

            // Setup event listeners for each WebSocket
            setupWebSocketEvents(
                sessionsWebsocket,
                "Player Development Sessions",
                playersSlice.actions.updatePlayerDevelopmentSessions
            );

            newWebSockets.set(2, sessionsWebsocket);

            setWebSockets(newWebSockets);
            setShouldUpdateWebSockets(false); // Reset the trigger
        }

        // Cleanup function to close all connections
        return () => {
            webSockets.forEach((ws) => ws.close());
        };
    }, [shouldUpdateWebSockets, setupWebSocketEvents, webSockets]);

    // Fetch the players' active development priorities for the current team and tier
    useEffect(() => {
        if (canViewPlayerDevelopment && team && tier) {
            dispatch(fetchTeamPlayerDevelopmentPriorities(team.id, tier, true));
        }
    }, [canViewPlayerDevelopment, team, tier, dispatch]);

    const playersDevelopmentPriorities = useSelector(
        (state) => state.players.developmentpriorities
    );

    const [allActiveDevelopmentPriorities, setAllActiveDevelopmentPriorities] =
        useState(null);
    const playersActiveDevelopmentPrioritiesMap = useMemo(() => {
        if (playersDevelopmentPriorities) {
            const allActiveDevelopmentPriorities = [];
            const playersActiveDevelopmentPrioritiesMap = Object.entries(
                playersDevelopmentPriorities
            ).reduce((playersMap, [playerId, playersDevelopmentPriorities]) => {
                // Filter development priorities for active (i.e. end_date is null).
                const activeDevelopmentPriorities =
                    playersDevelopmentPriorities.filter(
                        (developmentPriority) => !developmentPriority.end_date
                    );

                if (activeDevelopmentPriorities.length > 0) {
                    playersMap[playerId] = activeDevelopmentPriorities;
                    allActiveDevelopmentPriorities.push(
                        ...activeDevelopmentPriorities
                    );
                }
                return playersMap;
            }, {});
            setAllActiveDevelopmentPriorities(allActiveDevelopmentPriorities);
            return playersActiveDevelopmentPrioritiesMap;
        }
        return {};
    }, [playersDevelopmentPriorities]);

    useEffect(() => {
        if (!teams[teamId]) {
            dispatch(fetchTeam(teamId));
            dispatch(uiActions.showLoader());
        }
    }, [teamId, teams, dispatch]);

    useEffect(() => {
        if (teams && ahlTeamId && !teams[ahlTeamId]) {
            dispatch(fetchTeam(ahlTeamId));
            dispatch(uiActions.showLoader());
        }
    }, [ahlTeamId, teams, dispatch]);

    useEffect(() => {
        if (!depth_chart_config) {
            dispatch(fetchTeamDatastash(teamId, "depth_chart_config"));
            dispatch(uiActions.showLoader());
        }
    }, [teamId, depth_chart_config, dispatch]);

    useEffect(() => {
        if (tieredPlayersIsloading) {
            dispatch(uiActions.showLoader());
        }
    }, [tieredPlayersIsloading, dispatch]);

    const allCompetitionIds = useMemo(() => {
        let ids = tieredPlayers
            .filter((tierData) => tierData.tier === "JCE")
            .reduce((accumulator, jceTierData) => {
                return jceTierData.data.reduce((accumulator, positionData) => {
                    return positionData.players.reduce(
                        (accumulator, playerData) => {
                            return accumulator.add(
                                playerData.player.targetCompetition
                            );
                        },
                        accumulator
                    );
                }, accumulator);
            }, new Set());
        ids.add(1);
        ids.add(2);
        return Array.from(ids).sort((a, b) => a - b);
    }, [tieredPlayers]);

    useEffect(() => {
        if (teams_reserveListPlayerIds) {
            setPlayersData(
                teams_reserveListPlayerIds.reduce((playersMap, playerId) => {
                    const mergedMap = allCompetitionIds.reduce(
                        (accumulator, competitionId) => {
                            accumulator[competitionId] = {
                                season_sessions: playerDevelopmentSessions[
                                    playerId
                                ]?.filter(
                                    (session) =>
                                        session.season_id === currentSeasonId
                                ),
                                active_development_priorities:
                                    playersActiveDevelopmentPrioritiesMap[
                                        playerId
                                    ] || [],
                            };

                            return accumulator;
                        },
                        {}
                    );

                    playersMap[playerId] = mergedMap;
                    return playersMap;
                }, {})
            );
        } else {
            setPlayersData({});
        }
    }, [
        allCompetitionIds,
        teams_reserveListPlayerIds,
        playersActiveDevelopmentPrioritiesMap,
        playerDevelopmentSessions,
        currentSeasonId,
        setPlayersData,
    ]);

    const decoratedTieredPlayersData = useMemo(() => {
        return tieredPlayersData.map((tierData) => {
            return {
                ...tierData,
                label: subTiers[tierData.tier].label,
            };
        });
    }, [tieredPlayersData]);

    const [playersPool, setPlayersPool] = useState(null);

    useEffect(() => {
        const playersPool = tieredPlayersData.reduce(
            (accumulator, tierData) => {
                return tierData.data.reduce((accumulator, positionData) => {
                    return positionData.players.reduce(
                        (accumulator, playerData) => {
                            accumulator.push(playerData.player);
                            return accumulator;
                        },
                        accumulator
                    );
                }, accumulator);
            },
            []
        );
        setPlayersPool(playersPool);
    }, [tieredPlayersData]);

    useEffect(() => {
        if (
            !initialLoad &&
            team &&
            depth_chart_config &&
            !tieredPlayersIsloading
        ) {
            dispatch(uiActions.hideLoader());
        }
    }, [
        initialLoad,
        team,
        depth_chart_config,
        tieredPlayersIsloading,
        dispatch,
    ]);

    const handlePlayerClicked = (playerId, competitionId) => {
        onViewContextChange &&
            onViewContextChange({
                ...EMPTY_VIEW_CONTEXT,
                playerId: playerId,
                competitionId: competitionId,
                metricContext: {
                    stack: null,
                    activeMetric: null,
                },
            });
        setViewContext({
            ...EMPTY_VIEW_CONTEXT,
            playerId: playerId,
            competitionId: competitionId,
            metricContext: {
                stack: null,
                activeMetric: null,
            },
        });
        setDrilldownOpen(true);
    };

    const onPlayerIndexDrilldownClosed = () => {
        // Wait .3s, then set viewContext to null
        setDrilldownOpen(false);
        setTimeout(() => {
            onViewContextChange && onViewContextChange(EMPTY_VIEW_CONTEXT);
            setViewContext(EMPTY_VIEW_CONTEXT);
        }, 300);
    };

    useEffect(() => {
        if (viewContextProp) {
            setViewContext(viewContextProp);
        }
        if (viewContextProp && viewContextProp.playerId) {
            setDrilldownOpen(true);
        }
    }, [viewContextProp]);

    const [sidePanelWidth, setSidePanelWidth] = useState(null);
    const [mainContentWidth, setMainContentWidth] = useState(null);
    const [mainContentHeight, setMainContentHeight] = useState(null);

    return (
        // Setting a key on the AppPage component forces it to re-mount when
        // the teamId changes, which is necessary trigger the transition
        // effects.
        <AppPage key={"TeamPlayerDevelopment_" + teamId}>
            <TeamPlayerDevelopmentLegend zIndex={4} />
            <PlayerDevelopmentSessionDialog
                playersPool={playersPool}
                developmentPriorities={allActiveDevelopmentPriorities}
                session={selectedSession}
                isOpen={isSessionDialogOpen}
                onCloseHandler={() => {
                    setIsSessionDialogOpen(false);
                }}
                sessionTypes={playerDevelopmentSessionTypesValues}
            />
            <PlayerDevelopmentPanel
                playerId={viewContext.playerId}
                competitionId={viewContext.competitionId}
                isOpen={drilldownOpen}
                onCloseHandler={onPlayerIndexDrilldownClosed}
                targetWidthHandler={setSidePanelWidth}
            />
            <CompressiblePanel
                compressWidth={sidePanelWidth}
                onWidthChange={setMainContentWidth}
                onHeightChange={setMainContentHeight}
            >
                <OrgHeader
                    fullTitle={
                        (team ? team.location + " " + team.name : "") +
                        " Player Development"
                    }
                    mediumTitle={
                        (team ? team.location : "") + " Player Development"
                    }
                    shortTitle={"Player Development"}
                    onOpen={onMenuOpen}
                    bgColor={team ? team.primary_colour : "#000"}
                    imgUrl={team ? team.alt_logo_url : ""}
                    imgPosition={
                        depth_chart_config
                            ? depth_chart_config.header_object_position
                            : "50% 50%"
                    }
                    parentWidth={mainContentWidth}
                    parentHeight={mainContentHeight}
                />
                <MainContent>
                    {!canViewPlayerDevelopment && (
                        <div className={classes.no_access}>
                            You do not have access to view player development.
                        </div>
                    )}
                    {canViewPlayerDevelopment &&
                        (decoratedTieredPlayersData.length > 0 ? (
                            <ResponsiveContainer
                                toggleWidth={LAYOUT_LIMITS.WIDTH.NARROW}
                                useParentWidth={true}
                            >
                                <PlaceholderResponsiveContainerChild>
                                    <div
                                        className={
                                            classes.add_session_button_container
                                        }
                                    >
                                        {canAddUpdatePlayerDevelopmentSessions && (
                                            <ResponsiveButton
                                                label="Add Session"
                                                materialIcon={"calendar_add_on"}
                                                onClick={
                                                    canAddUpdatePlayerDevelopmentSessions &&
                                                    (() => {
                                                        setSelectedSession(
                                                            null
                                                        );
                                                        setIsSessionDialogOpen(
                                                            true
                                                        );
                                                        player_development_mp_track(
                                                            "Open Add General Session Dialog"
                                                        );
                                                    })
                                                }
                                            />
                                        )}
                                    </div>
                                </PlaceholderResponsiveContainerChild>
                                <TieredTeamPlayers
                                    teamId={teamId}
                                    tieredPlayersData={
                                        decoratedTieredPlayersData
                                    }
                                    playerSort={playerSort}
                                    mainContentWidth={mainContentWidth}
                                    TileComponent={PlayerDevelopmentTile}
                                    onPlayerClick={handlePlayerClicked}
                                />
                            </ResponsiveContainer>
                        ) : (
                            <div className={classes.no_data}>
                                No player data available.
                            </div>
                        ))}
                </MainContent>
            </CompressiblePanel>
        </AppPage>
    );
};

export default TeamPlayerDevelopment;
