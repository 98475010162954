import React, { useEffect, useState, useCallback, useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";
import { fetchTeamsDatastash } from "../store/teams-actions";
import { fetchCompetitionSeasonDatastash } from "../store/competitions-actions";
import AppPage from "../layout/AppPage";
import OrgHeader from "../layout/OrgHeader";
import MainContent from "../layout/MainContent";
import CompressiblePanel from "../layout/CompressiblePanel";
import PlayerIndexDrilldownPanel from "../components/player-side-panels/PlayerIndexDrilldownPanel";
import PlayerTradStatsTileLegend from "../components/legends/PlayerTradStatsTileLegend";
import ScoutingTileLegend from "../components/legends/ScoutingTileLegend";
import PlayerPerformanceTileLegend from "../components/legends/PlayerPerformanceTileLegend";
import Playerboard from "../components/Playerboard";
import { EMPTY_VIEW_CONTEXT } from "../hooks/use-cross-page-view-context";

const PlayerboardPage = ({
    boardMode, // "Roster", "Trade" or "Draft", this is used to have distinct MixPanel events depending on the mode
    playerboardId,
    boardCompetitionId, // Will force the player tiles to show data from a specific competition
    viewContextProp = EMPTY_VIEW_CONTEXT,
    onViewContextChange,
    onMenuOpen,
    bgColor,
    imgUrl,
    imgPosition,
    metricsSeason = null,
    showProjectedFreeAgencyStatus = true,
    showDraftTeamOverlay = false,
    showOwningTeamOverlay = false,
    NavigationWidget = null,
    showFullPageLoader = true,
}) => {
    const dispatch = useDispatch();
    const currentSeasonId = useSelector(
        (state) => state.ui.systemConfiguration?.currentSeasonId
    );
    const seasonId = metricsSeason || currentSeasonId;

    const showCombineData = boardMode === "Draft";

    const nhlSeasonDatatashes = useSelector(
        (state) => state.competitions.seasondatastashes[1]
    );

    const [tileViewMode, setTileViewMode] = useState(null);

    useEffect(() => {
        if (
            showCombineData &&
            metricsSeason &&
            (!nhlSeasonDatatashes ||
                !nhlSeasonDatatashes?.combine_data?.[metricsSeason])
        ) {
            dispatch(
                fetchCompetitionSeasonDatastash(
                    1,
                    metricsSeason,
                    "combine_data"
                )
            );
        }
    }, [showCombineData, metricsSeason, nhlSeasonDatatashes, dispatch]);

    const combineData = useMemo(() => {
        if (showCombineData && metricsSeason) {
            // Map the combine data by player id
            return nhlSeasonDatatashes?.combine_data?.[metricsSeason]?.reduce(
                (combineData, playerData) => {
                    combineData[playerData.player_id] = playerData;
                    return combineData;
                },
                {}
            );
        }
        return null;
    }, [nhlSeasonDatatashes, metricsSeason, showCombineData]);

    const board = useSelector(
        (state) => state.playerboards.playerboards[playerboardId]
    );

    const [viewContext, setViewContext] = useState(viewContextProp);
    const [drilldownOpen, setDrilldownOpen] = useState(false);

    const [activeMetricContext, setActiveMetricContext] = useState({
        stack:
            viewContextProp &&
            viewContextProp.metricContext &&
            viewContextProp.metricContext.stack,
        activeMetric:
            viewContextProp &&
            viewContextProp.metricContext &&
            viewContextProp.metricContext.activeMetric,
    });

    // Fetch all teams depth chart config data stash
    useEffect(() => {
        dispatch(fetchTeamsDatastash("depth_chart_config"));
    }, [dispatch]);

    // Use the Montreal Canadiens logo and colours as a fallback is mode is Roster.
    // In Draft mode, use NHL if no logo and colours are provided.
    const BG_COLOR = ["Roster", "Trade"].includes(boardMode)
        ? "#A6192E"
        : "#000000";
    const IMG_URL = ["Roster", "Trade"].includes(boardMode)
        ? "https://s3.amazonaws.com/slmediaimages/teams/NHL/MTL.svg"
        : "https://s3.amazonaws.com/slmediaimages/leagues/NHL.svg";
    const IMG_POSITION = "50% 50%";

    const handlePlayerSelected = useCallback(
        (playerId, competitionId) => {
            onViewContextChange &&
                onViewContextChange({
                    ...EMPTY_VIEW_CONTEXT,
                    playerId: playerId,
                    competitionId: competitionId,
                    metricContext: activeMetricContext,
                });
            setViewContext({
                ...EMPTY_VIEW_CONTEXT,
                playerId: playerId,
                competitionId: competitionId,
                metricContext: activeMetricContext,
            });
            setDrilldownOpen(true);
        },
        [onViewContextChange, activeMetricContext]
    );

    const handleMetricContextChange = (metricContext) => {
        setActiveMetricContext(metricContext);
        onViewContextChange &&
            onViewContextChange({
                ...EMPTY_VIEW_CONTEXT,
                playerId: viewContextProp.playerId,
                competitionId: viewContextProp.competitionId,
                metricContext: metricContext,
            });
    };

    const onPlayerIndexDrilldownClosed = useCallback(() => {
        // Wait .3s, then set viewContext to null
        setDrilldownOpen(false);
        setTimeout(() => {
            onViewContextChange && onViewContextChange(EMPTY_VIEW_CONTEXT);
            setViewContext(EMPTY_VIEW_CONTEXT);
            setActiveMetricContext({
                stack: null,
                activeMetric: null,
            });
        }, 300);
    }, [onViewContextChange]);

    useEffect(() => {
        if (viewContextProp) {
            setViewContext(viewContextProp);

            if (viewContextProp.playerId) {
                setDrilldownOpen(true);
            }

            if (
                viewContextProp.metricContext &&
                viewContextProp.metricContext.activeMetric !==
                    activeMetricContext.activeMetric
            ) {
                setActiveMetricContext({
                    stack: viewContextProp.metricContext.stack,
                    activeMetric: viewContextProp.metricContext.activeMetric,
                });
            }
        }
    }, [viewContextProp, activeMetricContext.activeMetric]);

    const [mainContentWidth, setMainContentWidth] = useState(null);
    const [mainContentHeight, setMainContentHeight] = useState(null);

    return (
        <AppPage>
            {tileViewMode === "trad_stats" && (
                <PlayerTradStatsTileLegend
                    zIndex={4}
                    includeFreeAgency={showProjectedFreeAgencyStatus}
                />
            )}
            {tileViewMode === "scouting" && (
                <ScoutingTileLegend
                    zIndex={4}
                    includeFreeAgency={showProjectedFreeAgencyStatus}
                />
            )}
            {tileViewMode === "performance" && (
                <PlayerPerformanceTileLegend
                    zIndex={4}
                    includeFreeAgency={showProjectedFreeAgencyStatus}
                />
            )}
            <PlayerIndexDrilldownPanel
                playerId={viewContext.playerId}
                competitionId={viewContext.competitionId}
                seasonId={seasonId}
                metricContext={activeMetricContext}
                onMetricContextChange={handleMetricContextChange}
                isOpen={drilldownOpen}
                onCloseHandler={onPlayerIndexDrilldownClosed}
                allowUseDraftedTeam={boardMode === "Draft"}
                showScoutingSummary={true}
                scoutingSummaryMode={boardMode === "Draft" ? "draft" : "pro"}
                combineData={combineData}
            />
            <CompressiblePanel
                onWidthChange={setMainContentWidth}
                onHeightChange={setMainContentHeight}
            >
                <OrgHeader
                    fullTitle={board ? board.name : ""}
                    onOpen={onMenuOpen}
                    bgColor={bgColor || BG_COLOR}
                    imgUrl={imgUrl || IMG_URL}
                    imgPosition={imgPosition || IMG_POSITION}
                    parentWidth={mainContentWidth}
                    parentHeight={mainContentHeight}
                />
                <MainContent>
                    {NavigationWidget && <NavigationWidget />}
                    <Playerboard
                        playerboardId={playerboardId}
                        boardCompetitionId={boardCompetitionId}
                        onPlayerSelected={handlePlayerSelected}
                        onTileViewModeChange={setTileViewMode}
                        metricsSeason={metricsSeason}
                        showProjectedFreeAgencyStatus={
                            showProjectedFreeAgencyStatus
                        }
                        showDraftTeamOverlay={showDraftTeamOverlay}
                        showOwningTeamOverlay={showOwningTeamOverlay}
                        combineData={combineData}
                        capTileModeAvailable={boardMode !== "Draft"}
                        alternateColumnLabels={
                            boardMode === "Draft"
                                ? [
                                      "G",
                                      "Offensive D",
                                      "Other D",
                                      "LW",
                                      "C",
                                      "RW",
                                  ]
                                : null
                        }
                        rowCountersGroupSize={boardMode === "Draft" ? 10 : null}
                        boardEventsPrefix={boardMode}
                        showLoader={showFullPageLoader}
                    />
                </MainContent>
            </CompressiblePanel>
        </AppPage>
    );
};

export default PlayerboardPage;
