import classes from "./VerticalBarIndicator.module.css";

const VerticalBarContainer = ({
    value,
    maxValue,
    tierClass,
    inverseColors = false,
    includeLabel,
    isPercentage = false,
    decimalPlaces,
    singleEndRounding = false,
    labelColor = "var(--ch-blue)",
    isExample = false,
}) => {
    const amplitude =
        Math.abs(value) > maxValue ? 1 : Math.abs(value) / maxValue;
    const flipped = value < 0;

    return (
        <div
            className={classes.vertical_bar_container}
            style={flipped ? { flexDirection: "column" } : {}}
        >
            <div
                className={
                    classes.vertical_bar +
                    (tierClass ? " " + tierClass : "") +
                    (inverseColors ? " " + classes.inverse : "") +
                    (singleEndRounding
                        ? " " + classes.single_end_rounding
                        : "") +
                    (flipped ? " " + classes.flipped : "")
                }
                style={{
                    height: `calc((100%${includeLabel ? " - 20px" : ""}) * ${
                        isExample ? 1.0 : amplitude
                    })`,
                }}
            ></div>
            {includeLabel && (
                <div
                    className={
                        classes.vertical_bar_label +
                        (flipped ? " " + classes.flipped : "")
                    }
                    style={{
                        color: labelColor,
                    }}
                >
                    {value !== null &&
                        (isExample ? (
                            value
                        ) : isPercentage ? (
                            <>
                                {(value * 100).toFixed(decimalPlaces)}
                                <span
                                    className={
                                        classes.vertical_bar_label_percentage
                                    }
                                >
                                    %
                                </span>
                            </>
                        ) : (
                            value.toFixed(decimalPlaces)
                        ))}
                </div>
            )}
        </div>
    );
};

const VerticalBarIndicator = ({
    value,
    maxValue,
    isSigned = false,
    tierClass,
    inverseColors = false,
    includeLabel,
    isPercentage = false,
    decimalPlaces,
    labelColor = "var(--ch-blue)",
    isExample = false,
}) => {
    return (
        <div className={classes.vertical_bar_indicator}>
            <>
                <VerticalBarContainer
                    value={value >= 0 ? value : null}
                    maxValue={maxValue}
                    tierClass={tierClass}
                    inverseColors={inverseColors}
                    includeLabel={includeLabel}
                    isPercentage={isPercentage}
                    decimalPlaces={decimalPlaces}
                    labelColor={labelColor}
                    singleEndRounding={isSigned}
                    isExample={isExample}
                />
                {isSigned && (
                    <>
                        <div className={classes.separator} />
                        <VerticalBarContainer
                            value={value < 0 ? value : null}
                            maxValue={maxValue}
                            tierClass={tierClass}
                            inverseColors={inverseColors}
                            includeLabel={includeLabel}
                            isPercentage={isPercentage}
                            decimalPlaces={decimalPlaces}
                            labelColor={labelColor}
                            singleEndRounding={true}
                            isExample={isExample}
                        />
                    </>
                )}
            </>
        </div>
    );
};

export default VerticalBarIndicator;
