import { useEffect, useCallback, useMemo, useRef, useState } from "react";
import { getMagnitudeTicks, getToiMinutesTicks } from "../utils";

import classes from "./TimeSeriesChart.module.css";

const DEFAULT_VERTICAL_PADDING = 7;
const DEFAULT_MARGIN = 32;
const AXES_STROKE_WIDTH = 0.75;

const BACKGROUNG_COLOR = "var(--tw-slate-200)";
const GRID_COLOR = "var(--tw-gray-50)";

const DATE_HIGHLIGHT_COLOR = "#FFF";
const DATE_HIGHLIGHT_RADIUS = 3;
const SELECTED_DATE_HIGHLIGHT_FILL_COLOR = "var(--tw-yellow-400)";
const SELECTED_DATE_HIGHLIGHT_STROKE_COLOR = "var(--tw-yellow-500)";
const MIN_CLICKABLE_RADIUS = 15;

const ARROWHEAD_MARKER_HEIGHT = 7;
const ARROWHEAD_MARKER_OFFSET = 5;
const ARROWHEAD_MARKER_HALF_WIDTH = 3;

const SELECTED_TIME_SPAN_COLOR = "var(--ch-blue-300)";

const BANDS_OPACITY = 0.3;

const LINES = {
    thin: {
        strokeWidth: 0.75,
        pointsRadius: 1.75,
    },
    medium: {
        strokeWidth: 1.5,
        pointsRadius: 2.5,
    },
    thick: {
        strokeWidth: 2.5,
        pointsRadius: 3.5,
    },
};

const AXES_FONT_FAMILY =
    'AtlasGrotesk,"Helvetica Neue",Helvetica,Arial,sans-serif';
const AXES_LABEL_DEFAULT_STYLE = {
    fontSize: 11,
    fontWeight: "normal",
    fontStyle: "normal",
    fontFamily: AXES_FONT_FAMILY,
    stroke: "var(--chart-text-color)",
};

const TimeSeriesChart = ({
    width,
    height,
    margin = [DEFAULT_MARGIN],
    verticalPadding = DEFAULT_VERTICAL_PADDING,
    minY = null,
    maxY = null,
    stepsY = 4,
    isPercentage = false,
    isSeconds = false,
    series,
    bands,
    timeAreas,
    gridLabel,
    timelineMarkers,
    highlightDates,
    selectedHighlightDate,
    onHighlightDateSelected,
    timeSpanSelections,
    selectedTimeSpanId,
    onTimeSpanSelected,
    minDate,
    maxDate,
    horizontalTicks,
    includeHorizontalTicksGrid = true,
    transitionContent = null,
    fadeOutTransitionDuration = 200,
    border = true,
}) => {
    // We create the margins object, of the form: {top: 10, right: 20, bottom: 30, left: 40}
    // If the margin prop only has one value, all margins are set to that
    // value.
    // If the margin prop has two values, the first value is used for the
    // top and bottom margins, and the second value is used for the left and
    // right margins.
    // If the margin prop has four values, the first value is used for the top
    // margin, the second value is used for the right margin, the third value
    // is used for the bottom margin, and the fourth value is used for the left
    // margin.
    const margins = {
        top: margin ? margin[0] : 0,
        right: margin ? (margin.length > 1 ? margin[1] : margin[0]) : 0,
        bottom: margin ? (margin.length > 2 ? margin[2] : margin[0]) : 0,
        left: margin
            ? margin.length > 1
                ? margin.length > 2
                    ? margin[3]
                    : margin[1]
                : margin[0]
            : 0,
    };

    const full_width = width + margins.left + margins.right;
    const full_height = height + margins.top + margins.bottom;
    const plot_height = height - 2 * verticalPadding;

    const fadeInTransitionDuration = 300;

    const finalizedTickValues = useMemo(() => {
        let y_min =
            minY !== null
                ? minY
                : Math.min(
                      ...(series || [])
                          .filter((s) => s.data && s.data.length > 0)
                          .map((s) =>
                              Math.min(
                                  ...s.data.map((d) => d.y),
                                  Number.MAX_VALUE
                              )
                          ),
                      ...(bands || [])
                          .filter((b) => b.data && b.data.length > 0)
                          .map((b) =>
                              Math.min(
                                  ...b.data.map((d) => d.y_min),
                                  Number.MAX_VALUE
                              )
                          )
                  );

        let y_max =
            maxY !== null
                ? maxY
                : Math.max(
                      ...(series || [])
                          .filter((s) => s.data && s.data.length > 0)
                          .map((s) =>
                              Math.max(
                                  ...s.data.map((d) => d.y),
                                  Number.MIN_VALUE
                              )
                          ),
                      ...(bands || [])
                          .filter((b) => b.data && b.data.length > 0)
                          .map((b) =>
                              Math.max(
                                  ...b.data.map((d) => d.y_max),
                                  Number.MIN_VALUE
                              )
                          )
                  );

        // if y_min and y_max are of different signs, we need to garantee that
        // the mid point will be zero. So, in this case, they become the opposite
        // signed values of the max of the absolute value of the two.
        if (y_min * y_max < 0) {
            y_min = -Math.max(Math.abs(y_min), Math.abs(y_max));
            y_max = Math.max(Math.abs(y_min), Math.abs(y_max));
        }

        if (isSeconds) {
            return getToiMinutesTicks(y_min, y_max, stepsY, minY === null);
        }
        return getMagnitudeTicks(y_min, y_max, stepsY);
    }, [minY, maxY, stepsY, series, bands, isSeconds]);

    const chartArea = useMemo(() => {
        const newChartArea = {
            x_off: 0,
            x_scale: width,
            y_off: 0,
            y_scale: plot_height,
        };
        // If the min date is not provided, find the minimum and maximum x
        // values in all series + bands.
        // The min value is the start of the month of the first data point.
        // The max value is the first day of the month after the last data point.
        if ((series && series.length > 0) || (bands && bands.length > 0)) {
            newChartArea.x_min = minDate
                ? minDate
                : new Date(
                      Math.min(
                          ...(series || [])
                              .filter((s) => s.data && s.data.length > 0)
                              .map((s) =>
                                  new Date(
                                      s.data[0].x.getFullYear(),
                                      s.data[0].x.getMonth(),
                                      1 // Explicitly set the day as the first day of the month
                                  ).getTime()
                              ),
                          ...(bands || [])
                              .filter((b) => b.data && b.data.length > 0)
                              .map((b) =>
                                  new Date(
                                      b.data[0].x.getFullYear(),
                                      b.data[0].x.getMonth(),
                                      1 // Explicitly set the day as the first day of the month
                                  ).getTime()
                              )
                      )
                  );
            newChartArea.x_off = newChartArea.x_min;

            newChartArea.x_max = maxDate
                ? maxDate
                : new Date(
                      Math.max(
                          ...[
                              ...(series && series.length > 0
                                  ? [series[series.length - 1]]
                                  : []),
                              ...(bands && bands.length > 0 ? [bands[0]] : []),
                          ]
                              .filter(
                                  (item) => item.data && item.data.length > 0
                              )
                              .map((item) => {
                                  const date =
                                      item.data[item.data.length - 1].x;
                                  const year = date.getFullYear();
                                  const month = date.getMonth();

                                  // If the month is December, increment the year and set month as January
                                  if (month === 11) {
                                      return new Date(year + 1, 0, 1).getTime();
                                  }

                                  // Otherwise, increment the month and keep the year
                                  return new Date(year, month + 1, 1).getTime();
                              })
                      )
                  );
            newChartArea.x_scale = newChartArea.x_max - newChartArea.x_min;

            newChartArea.y_off = finalizedTickValues[0];
            newChartArea.y_scale =
                finalizedTickValues[finalizedTickValues.length - 1] -
                newChartArea.y_off;
        }

        return newChartArea;
    }, [
        series,
        bands,
        finalizedTickValues,
        minDate,
        maxDate,
        width,
        plot_height,
    ]);

    const plotX = useCallback(
        (x, customChartArea) => {
            const calcChartArea = customChartArea || chartArea;
            return ((x - calcChartArea.x_off) / calcChartArea.x_scale) * width;
        },
        [chartArea, width]
    );

    const plotY = useCallback(
        (y, customChartArea) => {
            const calcChartArea = customChartArea || chartArea;
            return (
                plot_height - // Flip the y-axis so that the origin is at the bottom
                ((y - calcChartArea.y_off) / calcChartArea.y_scale) *
                    plot_height +
                verticalPadding
            );
        },
        [chartArea, plot_height, verticalPadding]
    );

    const defaultHorizontalTicks = useMemo(() => {
        if (!chartArea.x_min || !chartArea.x_max) {
            return [];
        }
        // For each month between the min and max x values, create a tick.
        // The month difference can be accross multiple years.
        const monthDiff =
            chartArea.x_max.getMonth() -
            chartArea.x_min.getMonth() +
            12 *
                (chartArea.x_max.getFullYear() - chartArea.x_min.getFullYear());

        return (
            // The label is the month in 3-letter format.
            Array.from(Array(monthDiff + 1).keys()).map((i) => {
                const tickDate = new Date(
                    chartArea.x_min.getFullYear(),
                    chartArea.x_min.getMonth() + i
                );
                return {
                    x: plotX(
                        i === 0
                            ? chartArea.x_min
                            : i === monthDiff
                            ? chartArea.x_max
                            : tickDate
                    ),
                    label: tickDate.toLocaleString("en-US", { month: "short" }),
                    id: i,
                };
            })
        );
    }, [chartArea, plotX]);

    const finalHorizontalTicks = horizontalTicks
        ? // We must compute the x coordinate of each tick, based on the chart area.
          horizontalTicks.map((tick) => {
              return {
                  ...tick,
                  x: plotX(tick.x),
              };
          })
        : defaultHorizontalTicks;

    const pathFromData = useCallback(
        (data, customChartArea) => {
            const calcChartArea = customChartArea || chartArea;
            const path = data.reduce(
                (acc, point, i) =>
                    i === 0
                        ? `M ${plotX(point.x, calcChartArea)} ${plotY(
                              point.y,
                              calcChartArea
                          )}`
                        : `${acc} L ${plotX(point.x, calcChartArea)} ${plotY(
                              point.y,
                              calcChartArea
                          )}`,
                ""
            );
            return path;
        },
        [chartArea, plotX, plotY]
    );

    const polygonPointsFromData = useCallback(
        (data, customChartArea) => {
            const calcChartArea = customChartArea || chartArea;
            const points =
                data.reduce(
                    (acc, point, i) =>
                        `${acc} ${plotX(point.x, calcChartArea)},${
                            point.y_max !== undefined
                                ? plotY(point.y_max, calcChartArea)
                                : full_height
                        }`,
                    ""
                ) +
                [...data]
                    .reverse()
                    .reduce(
                        (acc, point, i) =>
                            `${acc} ${plotX(point.x, calcChartArea)},${
                                point.y_min !== undefined
                                    ? plotY(point.y_min, calcChartArea)
                                    : 0
                            }`,
                        " "
                    );
            return points;
        },
        [chartArea, full_height, plotX, plotY]
    );

    const verticalTicks = useMemo(() => {
        return finalizedTickValues.map((value) => {
            const y = plotY(value);
            return {
                label: isPercentage
                    ? parseFloat((value * 100).toFixed(1))
                    : isSeconds
                    ? parseFloat(value / 60)
                    : value,
                labelSuffix: isPercentage ? "%" : "",
                y,
            };
        });
    }, [isPercentage, isSeconds, finalizedTickValues, plotY]);

    useEffect(() => {
        if (series && series.length > 0) {
            series.forEach((s) => {
                s.data.forEach((d) => {
                    if (highlightDates && highlightDates.length > 0) {
                        d.isHighlighted = highlightDates.some(
                            (date) => date.getTime() === d.x.getTime()
                        );
                    }

                    if (selectedHighlightDate) {
                        d.isSelected =
                            selectedHighlightDate.getTime() === d.x.getTime();
                    }
                });
            });
        }
    }, [series, highlightDates, selectedHighlightDate]);

    // Fade in and fade out animations
    const bandsFadeInAnimationRef = useRef(null);
    const bandsFadeOutAnimationRef = useRef(null);
    const plotFadeInAnimationRef = useRef(null);
    const plotFadeOutAnimationRef = useRef(null);
    const verticalAxisFadeInAnimationRef = useRef(null);
    const verticalAxisFadeOutAnimationRef = useRef(null);
    const horizontalAxisFadeInAnimationRef = useRef(null);
    const horizontalAxisFadeOutAnimationRef = useRef(null);
    const gridFadeInAnimationRef = useRef(null);
    const gridFadeOutAnimationRef = useRef(null);
    const gridDecorationsFadeInAnimationRef = useRef(null);
    const gridDecorationsFadeOutAnimationRef = useRef(null);
    const timeSpanSelectionFadeInAnimationRef = useRef(null);
    const timeSpanSelectionFadeOutAnimationRef = useRef(null);

    const [dataTransition, setDataTransition] = useState(null);
    const [timelineTransition, setTimelineTransition] = useState(null);
    const [timeSpanSelectionTransition, setTimeSpanSelectionTransition] =
        useState(null);

    useEffect(() => {
        if (transitionContent && transitionContent.data) {
            setDataTransition(true);
            if (bandsFadeOutAnimationRef.current) {
                bandsFadeOutAnimationRef.current.beginElement();
            }
            if (plotFadeOutAnimationRef.current) {
                plotFadeOutAnimationRef.current.beginElement();
            }
            if (verticalAxisFadeOutAnimationRef.current) {
                verticalAxisFadeOutAnimationRef.current.beginElement();
            }
        } else if (!transitionContent && dataTransition) {
            setDataTransition(false);
            if (bandsFadeInAnimationRef.current) {
                bandsFadeInAnimationRef.current.beginElement();
            }
            if (plotFadeInAnimationRef.current) {
                plotFadeInAnimationRef.current.beginElement();
            }
            if (verticalAxisFadeInAnimationRef.current) {
                verticalAxisFadeInAnimationRef.current.beginElement();
            }
        }
    }, [transitionContent, dataTransition]);

    useEffect(() => {
        if (transitionContent && transitionContent.timeline) {
            setTimelineTransition(true);
            if (gridFadeOutAnimationRef.current) {
                gridFadeOutAnimationRef.current.beginElement();
            }
            if (gridDecorationsFadeOutAnimationRef.current) {
                gridDecorationsFadeOutAnimationRef.current.beginElement();
            }
            if (horizontalAxisFadeOutAnimationRef.current) {
                horizontalAxisFadeOutAnimationRef.current.beginElement();
            }
        } else if (!transitionContent && timelineTransition) {
            setTimelineTransition(false);
            if (gridFadeInAnimationRef.current) {
                gridFadeInAnimationRef.current.beginElement();
            }
            if (gridDecorationsFadeInAnimationRef.current) {
                gridDecorationsFadeInAnimationRef.current.beginElement();
            }
            if (horizontalAxisFadeInAnimationRef.current) {
                horizontalAxisFadeInAnimationRef.current.beginElement();
            }
        }
    }, [transitionContent, timelineTransition]);

    useEffect(() => {
        if (transitionContent && transitionContent.timeSpanSelection) {
            setTimeSpanSelectionTransition(true);
            if (timeSpanSelectionFadeOutAnimationRef.current) {
                timeSpanSelectionFadeOutAnimationRef.current.beginElement();
            }
        } else if (!transitionContent && timeSpanSelectionTransition) {
            setTimeSpanSelectionTransition(false);
            if (timeSpanSelectionFadeInAnimationRef.current) {
                timeSpanSelectionFadeInAnimationRef.current.beginElement();
            }
        }
    }, [transitionContent, timeSpanSelectionTransition]);

    return (
        <div
            className={
                classes.trend_chart + (border ? " " + classes.bordered : "")
            }
        >
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width={full_width}
                height={full_height}
                viewBox={`${-margins.left} ${-margins.top} ${full_width} ${full_height}`}
            >
                {/* Background Layer */}
                <g>
                    <rect
                        width={width}
                        height={height}
                        fill={BACKGROUNG_COLOR}
                    />
                </g>

                {/* Background Zones Layer */}
                <g>
                    {chartArea &&
                        timeAreas &&
                        timeAreas.map((area) => (
                            <polygon
                                key={area.name}
                                points={polygonPointsFromData(area.data)}
                                fill={area.color}
                                fillOpacity={BANDS_OPACITY}
                            />
                        ))}
                    <g>
                        <animate
                            attributeName="opacity"
                            from="1"
                            to="0"
                            dur={fadeOutTransitionDuration + "ms"}
                            begin="indefinite"
                            fill="freeze"
                            ref={timeSpanSelectionFadeOutAnimationRef}
                        />
                        <animate
                            attributeName="opacity"
                            from="0"
                            to="1"
                            dur={fadeInTransitionDuration + "ms"}
                            begin="indefinite"
                            fill="freeze"
                            ref={timeSpanSelectionFadeInAnimationRef}
                        />
                        {chartArea &&
                            timeSpanSelections &&
                            selectedTimeSpanId &&
                            // Display the selected time span
                            timeSpanSelections
                                .filter((t) => t.id === selectedTimeSpanId)
                                .map((area) => {
                                    return (
                                        <polygon
                                            key={area.id}
                                            points={polygonPointsFromData(
                                                area.data
                                            )}
                                            fill={SELECTED_TIME_SPAN_COLOR}
                                            fillOpacity={BANDS_OPACITY}
                                        />
                                    );
                                })}
                    </g>
                    <g>
                        <animate
                            attributeName="opacity"
                            from="1"
                            to="0"
                            dur={fadeOutTransitionDuration + "ms"}
                            begin="indefinite"
                            fill="freeze"
                            ref={bandsFadeOutAnimationRef}
                        />
                        <animate
                            attributeName="opacity"
                            from="0"
                            to="1"
                            dur={fadeInTransitionDuration + "ms"}
                            begin="indefinite"
                            fill="freeze"
                            ref={bandsFadeInAnimationRef}
                        />
                        {chartArea &&
                            bands &&
                            bands.map((band) => (
                                <polygon
                                    key={band.name}
                                    points={polygonPointsFromData(band.data)}
                                    fill={band.color}
                                    fillOpacity={BANDS_OPACITY}
                                />
                            ))}
                    </g>
                </g>

                {/* Timeline Grid Decoration Layer */}
                <g>
                    <animate
                        attributeName="opacity"
                        from="1"
                        to="0"
                        dur={fadeOutTransitionDuration + "ms"}
                        begin="indefinite"
                        fill="freeze"
                        ref={gridFadeOutAnimationRef}
                    />
                    <animate
                        attributeName="opacity"
                        from="0"
                        to="1"
                        dur={fadeInTransitionDuration + "ms"}
                        begin="indefinite"
                        fill="freeze"
                        ref={gridFadeInAnimationRef}
                    />
                    {verticalTicks.map((tick) => (
                        <line
                            key={tick.y}
                            x1={0}
                            y1={tick.y}
                            x2={width}
                            y2={tick.y}
                            stroke={GRID_COLOR}
                            strokeWidth={1}
                        />
                    ))}
                    {includeHorizontalTicksGrid &&
                        finalHorizontalTicks.map((tick) => (
                            <line
                                key={tick.id}
                                x1={tick.x}
                                y1={0}
                                x2={tick.x}
                                y2={height}
                                stroke={GRID_COLOR}
                                strokeWidth={1}
                            />
                        ))}
                </g>

                {/* Plot Layer */}
                <g>
                    <animate
                        attributeName="opacity"
                        from="1"
                        to="0"
                        dur={fadeOutTransitionDuration + "ms"}
                        begin="indefinite"
                        fill="freeze"
                        ref={plotFadeOutAnimationRef}
                    />
                    <animate
                        attributeName="opacity"
                        from="0"
                        to="1"
                        dur={fadeInTransitionDuration + "ms"}
                        begin="indefinite"
                        fill="freeze"
                        ref={plotFadeInAnimationRef}
                    />
                    {chartArea &&
                        series &&
                        series.map((s) => (
                            <g key={s.name}>
                                <path
                                    d={pathFromData(s.data)}
                                    fill="none"
                                    stroke={s.color}
                                    strokeWidth={
                                        LINES[
                                            s.line_style ? s.line_style : "thin"
                                        ].strokeWidth
                                    }
                                />
                                {s.showPoints &&
                                    s.data.map((d) => (
                                        <circle
                                            key={d.x}
                                            cx={plotX(d.x)}
                                            cy={plotY(d.y)}
                                            r={
                                                LINES[
                                                    s.line_style
                                                        ? s.line_style
                                                        : "thin"
                                                ].pointsRadius
                                            }
                                            fill={s.color}
                                        />
                                    ))}
                            </g>
                        ))}
                </g>

                {/* Chart Decoration Layer */}
                <g>
                    {/* Blinds to cut plot area bleed when we zoom in. */}
                    <rect
                        width={margins.left}
                        height={full_height}
                        x={-margins.left}
                        y={-margins.top}
                        fill="#FFF"
                    />
                    <rect
                        width={margins.right}
                        height={full_height}
                        x={width}
                        y={-margins.top}
                        fill="#FFF"
                    />
                    <rect
                        width={full_width}
                        height={margins.top}
                        x={-margins.left}
                        y={-margins.top}
                        fill="#FFF"
                    />
                    <rect
                        width={full_width}
                        height={margins.bottom}
                        x={-margins.left}
                        y={height}
                        fill="#FFF"
                    />

                    {/* Chart Axes. */}
                    <line
                        x1={0}
                        y1={0}
                        x2={0}
                        y2={height + 1}
                        stroke={"var(--chart-axis-color)"}
                        strokeWidth={AXES_STROKE_WIDTH}
                    />
                    <g>
                        <animate
                            attributeName="opacity"
                            from="1"
                            to="0"
                            dur={fadeOutTransitionDuration + "ms"}
                            begin="indefinite"
                            fill="freeze"
                            ref={verticalAxisFadeOutAnimationRef}
                        />
                        <animate
                            attributeName="opacity"
                            from="0"
                            to="1"
                            dur={fadeInTransitionDuration + "ms"}
                            begin="indefinite"
                            fill="freeze"
                            ref={verticalAxisFadeInAnimationRef}
                        />

                        {verticalTicks.map((tick) => (
                            <g key={tick.y}>
                                <line
                                    x1={-5}
                                    y1={tick.y}
                                    x2={0}
                                    y2={tick.y}
                                    stroke={"var(--chart-axis-color)"}
                                    strokeWidth={AXES_STROKE_WIDTH}
                                />
                                <text
                                    textAnchor={"end"}
                                    x={-8}
                                    y={tick.y + 4}
                                    {...AXES_LABEL_DEFAULT_STYLE}
                                >
                                    <tspan>{tick.label}</tspan>
                                    {tick.labelSuffix && (
                                        <tspan fontSize="8">
                                            {tick.labelSuffix}
                                        </tspan>
                                    )}
                                </text>
                            </g>
                        ))}
                    </g>
                    <line
                        x1={0}
                        y1={height}
                        x2={width}
                        y2={height}
                        stroke={"var(--chart-axis-color)"}
                        strokeWidth={AXES_STROKE_WIDTH}
                    />
                    <g>
                        <animate
                            attributeName="opacity"
                            from="1"
                            to="0"
                            dur={fadeOutTransitionDuration + "ms"}
                            begin="indefinite"
                            fill="freeze"
                            ref={horizontalAxisFadeOutAnimationRef}
                        />
                        <animate
                            attributeName="opacity"
                            from="0"
                            to="1"
                            dur={fadeInTransitionDuration + "ms"}
                            begin="indefinite"
                            fill="freeze"
                            ref={horizontalAxisFadeInAnimationRef}
                        />
                        {finalHorizontalTicks.map((tick) => (
                            <g key={tick.id}>
                                <line
                                    x1={tick.x}
                                    y1={height}
                                    x2={tick.x}
                                    y2={height + 5}
                                    stroke={"var(--chart-axis-color)"}
                                    strokeWidth={AXES_STROKE_WIDTH}
                                />
                                <text
                                    textAnchor={"middle"}
                                    x={tick.x}
                                    y={height + 16}
                                    {...AXES_LABEL_DEFAULT_STYLE}
                                >
                                    <tspan>{tick.label}</tspan>
                                </text>
                            </g>
                        ))}
                    </g>
                </g>

                {/* Grid Decoration Layer */}
                <g>
                    <animate
                        attributeName="opacity"
                        from="1"
                        to="0"
                        dur={fadeOutTransitionDuration + "ms"}
                        begin="indefinite"
                        fill="freeze"
                        ref={gridDecorationsFadeOutAnimationRef}
                    />
                    <animate
                        attributeName="opacity"
                        from="0"
                        to="1"
                        dur={fadeInTransitionDuration + "ms"}
                        begin="indefinite"
                        fill="freeze"
                        ref={gridDecorationsFadeInAnimationRef}
                    />
                    {gridLabel && (
                        <text
                            textAnchor={"end"}
                            x={width - 4}
                            y={14}
                            fill={gridLabel.color}
                            {...AXES_LABEL_DEFAULT_STYLE}
                            stroke={gridLabel.color}
                            opacity={0.5}
                        >
                            <tspan>{gridLabel.label}</tspan>
                        </text>
                    )}
                    {timelineMarkers &&
                        timelineMarkers.map((marker) => {
                            const markerMarkup =
                                !marker.type || marker.type === "line" ? (
                                    <g key={marker.name}>
                                        <line
                                            x1={plotX(marker.x)}
                                            y1={verticalPadding - 3}
                                            x2={plotX(marker.x)}
                                            y2={
                                                plot_height +
                                                verticalPadding +
                                                3
                                            }
                                            stroke={marker.color}
                                            strokeWidth={0.75}
                                            strokeDasharray="3,1"
                                        />
                                        <text
                                            textAnchor={
                                                marker.labelOnRight
                                                    ? "start"
                                                    : "end"
                                            }
                                            x={
                                                plotX(marker.x) +
                                                (marker.labelOnRight ? 4 : -4)
                                            }
                                            y={plotX(marker.x) > 45 ? 14 : 28}
                                            fill={marker.color}
                                            {...AXES_LABEL_DEFAULT_STYLE}
                                            stroke={marker.color}
                                            opacity={0.5}
                                        >
                                            <tspan>{marker.label}</tspan>
                                        </text>
                                    </g>
                                ) : marker.type === "arrowhead" ? (
                                    // Arrow head will create a triangle pointing to the top / bottom, depending on the direction
                                    // attribute. The x attribute will be the center of the triangle, and the y attribute will be
                                    // the top of the triangle.
                                    <g key={marker.name}>
                                        <polygon
                                            points={`${
                                                plotX(marker.x) -
                                                ARROWHEAD_MARKER_HALF_WIDTH
                                            },${
                                                marker.direction === "top"
                                                    ? verticalPadding -
                                                      ARROWHEAD_MARKER_OFFSET
                                                    : plot_height +
                                                      verticalPadding +
                                                      ARROWHEAD_MARKER_OFFSET
                                            } ${
                                                plotX(marker.x) +
                                                ARROWHEAD_MARKER_HALF_WIDTH
                                            },${
                                                marker.direction === "top"
                                                    ? verticalPadding -
                                                      ARROWHEAD_MARKER_OFFSET
                                                    : plot_height +
                                                      verticalPadding +
                                                      ARROWHEAD_MARKER_OFFSET
                                            } ${plotX(marker.x)},${
                                                marker.direction === "top"
                                                    ? verticalPadding -
                                                      ARROWHEAD_MARKER_OFFSET +
                                                      ARROWHEAD_MARKER_HEIGHT
                                                    : plot_height +
                                                      verticalPadding +
                                                      (ARROWHEAD_MARKER_OFFSET -
                                                          ARROWHEAD_MARKER_HEIGHT)
                                            }`}
                                            fill={marker.color}
                                        />
                                        {marker.label && (
                                            <text
                                                textAnchor={"middle"}
                                                x={plotX(marker.x)}
                                                y={
                                                    marker.direction === "top"
                                                        ? verticalPadding - 14
                                                        : plot_height +
                                                          verticalPadding +
                                                          14
                                                }
                                                fill={marker.color}
                                                {...AXES_LABEL_DEFAULT_STYLE}
                                                stroke={marker.color}
                                                opacity={0.5}
                                            >
                                                <tspan>{marker.label}</tspan>
                                            </text>
                                        )}
                                    </g>
                                ) : null;

                            return markerMarkup;
                        })}
                </g>

                {/* Timeline Axis Decoration Layer */}
                <g></g>

                {/* Clickable Layer */}
                <g>
                    {chartArea &&
                        timeSpanSelections &&
                        selectedTimeSpanId &&
                        onTimeSpanSelected &&
                        // Display the selected time span
                        timeSpanSelections
                            .filter((t) => t.id !== selectedTimeSpanId)
                            .map((area) => {
                                return (
                                    <polygon
                                        key={area.id}
                                        points={polygonPointsFromData(
                                            area.data
                                        )}
                                        fillOpacity={0}
                                        onClick={() => {
                                            if (onTimeSpanSelected)
                                                onTimeSpanSelected(area.id);
                                        }}
                                        style={{
                                            pointerEvents: "auto",
                                        }}
                                    />
                                );
                            })}
                    {chartArea && highlightDates && (
                        <>
                            <g>
                                {series.map(
                                    (s) =>
                                        s.showPoints && (
                                            <g key={s.name}>
                                                {s.data.map((d) => {
                                                    return (
                                                        <circle
                                                            key={d.x}
                                                            cx={plotX(d.x)}
                                                            cy={plotY(d.y)}
                                                            r={
                                                                MIN_CLICKABLE_RADIUS
                                                            }
                                                            fillOpacity={0}
                                                            fill={
                                                                DATE_HIGHLIGHT_COLOR
                                                            }
                                                            onClick={
                                                                d.isHighlighted
                                                                    ? () => {
                                                                          if (
                                                                              onHighlightDateSelected
                                                                          )
                                                                              onHighlightDateSelected(
                                                                                  d.x
                                                                              );
                                                                      }
                                                                    : undefined
                                                            }
                                                            style={{
                                                                pointerEvents:
                                                                    d.isHighlighted
                                                                        ? "auto"
                                                                        : "none",
                                                            }}
                                                        />
                                                    );
                                                })}
                                            </g>
                                        )
                                )}
                            </g>
                            <g>
                                {series.map(
                                    (s) =>
                                        s.showPoints && (
                                            <g key={s.name}>
                                                {s.data.map((d) => (
                                                    <circle
                                                        key={d.x}
                                                        cx={plotX(d.x)}
                                                        cy={plotY(d.y)}
                                                        r={
                                                            DATE_HIGHLIGHT_RADIUS
                                                        }
                                                        fillOpacity={
                                                            d.isHighlighted
                                                                ? 1
                                                                : 0
                                                        }
                                                        fill={s.highlight_color}
                                                        stroke={
                                                            d.isHighlighted
                                                                ? DATE_HIGHLIGHT_COLOR
                                                                : undefined
                                                        }
                                                        onClick={
                                                            d.isHighlighted
                                                                ? () => {
                                                                      if (
                                                                          onHighlightDateSelected
                                                                      )
                                                                          onHighlightDateSelected(
                                                                              d.x
                                                                          );
                                                                  }
                                                                : undefined
                                                        }
                                                        style={{
                                                            transition:
                                                                "fill-opacity 0.3s",
                                                            pointerEvents:
                                                                d.isHighlighted
                                                                    ? "auto"
                                                                    : "none",
                                                        }}
                                                    />
                                                ))}
                                            </g>
                                        )
                                )}
                            </g>
                        </>
                    )}
                </g>

                {/* Priority Clickable Layer */}
                <g>
                    {chartArea &&
                        selectedHighlightDate &&
                        series.map(
                            (s) =>
                                s.showPoints && (
                                    <g key={s.name}>
                                        {s.data.map((d) => (
                                            <circle
                                                key={d.x}
                                                cx={plotX(d.x)}
                                                cy={plotY(d.y)}
                                                r={DATE_HIGHLIGHT_RADIUS}
                                                fillOpacity={
                                                    d.isSelected ? 1 : 0
                                                }
                                                fill={
                                                    SELECTED_DATE_HIGHLIGHT_FILL_COLOR
                                                }
                                                stroke={
                                                    d.isSelected
                                                        ? SELECTED_DATE_HIGHLIGHT_STROKE_COLOR
                                                        : undefined
                                                }
                                                style={{
                                                    transition:
                                                        "fill-opacity 0.3s",
                                                    pointerEvents: d.isSelected
                                                        ? "auto"
                                                        : "none",
                                                }}
                                            />
                                        ))}
                                    </g>
                                )
                        )}
                </g>
            </svg>
        </div>
    );
};

export default TimeSeriesChart;
