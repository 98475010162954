import React, {
    useEffect,
    useState,
    useCallback,
    useRef,
    useMemo,
} from "react";
import { useSwipeable } from "react-swipeable";
import { useSelector, useDispatch } from "react-redux";
import {
    fetchPlayerDatastash,
    fetchPlayerRinknetReports,
} from "../store/players-actions";
import MainBox from "../layout/MainBox";
import { get_age_at_date } from "../utils";
import ArrowButton from "../controls/buttons/ArrowButton";
import useMultiResourceLoader from "../hooks/use-multi-resource-loader";
import useAuth from "../hooks/use-auth";

import classes from "./PlayerScoutingReports.module.css";

import { mp_track } from "../mixpanel";

const player_scouting_reports_mp_track = (
    event,
    player,
    report,
    properties = null
) => {
    properties = {
        ...properties,
        player_id: player.id,
        player_name: player.known_name,
        report_id: report.rinknet_id,
        report_date: report.date,
    };

    mp_track(event, properties);
};

const PlayerScoutingReport = ({ player, reportVisible, report }) => {
    return (
        <div
            className={
                classes.scouting_report +
                (!reportVisible ? " " + classes.not_visible : "")
            }
        >
            <div className={classes.scouting_report_header}>
                {report.date} - {report.roster_info.position} -{" "}
                {report.roster_info.team_name} -{" "}
                {get_age_at_date(
                    new Date(player?.birth_date),
                    new Date(report.date)
                ) + " yo"}
            </div>
            <div className={classes.ratings_group}>
                {Object.keys(report.player_ratings).length > 0 && (
                    <div
                        className={
                            classes.ratings_section +
                            " " +
                            classes.player_ratings
                        }
                    >
                        <div className={classes.ratings_section_header}>
                            Ratings
                        </div>
                        {Object.keys(report.player_ratings)
                            .sort()
                            .map((label) => {
                                return (
                                    <div
                                        className={classes.rating_item}
                                        key={label}
                                    >
                                        <div className={classes.rating_label}>
                                            {label}
                                        </div>
                                        <div className={classes.rating_value}>
                                            {report.player_ratings[label]}
                                        </div>
                                    </div>
                                );
                            })}
                    </div>
                )}
                {Object.keys(report.skill_ratings).length > 0 && (
                    <div
                        className={
                            classes.ratings_section +
                            " " +
                            classes.skill_ratings
                        }
                    >
                        <div className={classes.ratings_section_header}>
                            Skills
                        </div>
                        {Object.keys(report.skill_ratings)
                            .sort()
                            .filter((label) => {
                                return report.skill_ratings[label] !== "--";
                            })
                            .map((label) => {
                                return (
                                    <div
                                        className={classes.rating_item}
                                        key={label}
                                    >
                                        <div className={classes.rating_label}>
                                            {label}
                                        </div>
                                        <div className={classes.rating_value}>
                                            {report.skill_ratings[label]}
                                        </div>
                                    </div>
                                );
                            })}
                    </div>
                )}
            </div>
            <p className={classes.scouting_report_notes}>{report.notes}</p>
            <p className={classes.scouting_report_scout}>
                - {report.scout.FirstName} {report.scout.LastName}
            </p>
        </div>
    );
};

const PlayerScoutingReports = ({
    playerId,
    isLoadingHandler,
    selectedReportId,
    onReportChange,
    onReportListChange,
}) => {
    const { checkPermission } = useAuth();
    const canViewScoutingReports = checkPermission(
        "core.can_view_rinknet_reports"
    );

    const { addLoader } = useMultiResourceLoader(isLoadingHandler);
    const dispatch = useDispatch();
    const player_datastashes = useSelector(
        (state) => state.players.datastashes[playerId]
    );
    const player = useSelector((state) => state.players.players[playerId]);
    const [selectedReportIndex, setSelectedReportIndex] = useState(-1);

    const latest_rinknet_report =
        playerId &&
        player_datastashes &&
        player_datastashes.latest_rinknet_report;

    useEffect(() => {
        setSelectedReportIndex(-1);
    }, [playerId]);

    useEffect(() => {
        if (canViewScoutingReports && playerId && !latest_rinknet_report) {
            addLoader("latest_rinknet_report", () =>
                dispatch(
                    fetchPlayerDatastash(playerId, "latest_rinknet_report")
                )
            );
        }
    }, [
        canViewScoutingReports,
        playerId,
        latest_rinknet_report,
        dispatch,
        addLoader,
    ]);

    const all_rinknet_reports = useSelector(
        (state) => state.players.rinknet_reports[playerId]
    );

    const gameReports = useMemo(() => {
        return (
            all_rinknet_reports &&
            all_rinknet_reports.filter((report) => !report.is_profile_report)
        );
    }, [all_rinknet_reports]);

    useEffect(() => {
        if (canViewScoutingReports && playerId && !all_rinknet_reports) {
            dispatch(fetchPlayerRinknetReports(playerId));
        }
    }, [canViewScoutingReports, playerId, all_rinknet_reports, dispatch]);

    const [PlayerScoutingReports, setPlayerScoutingReports] = useState([]);

    useEffect(() => {
        const reports =
            gameReports ||
            (latest_rinknet_report && [latest_rinknet_report]) ||
            [];

        if (onReportListChange) {
            onReportListChange(
                reports.map((r) => {
                    return {
                        id: r.rinknet_id,
                        date: r.date,
                    };
                })
            );
        }
        setPlayerScoutingReports(reports);
    }, [gameReports, latest_rinknet_report, onReportListChange]);

    useEffect(() => {
        if (selectedReportIndex === -1 && PlayerScoutingReports.length > 0) {
            setSelectedReportIndex(0);
            onReportChange && onReportChange(PlayerScoutingReports[0]);
        }
    }, [selectedReportIndex, PlayerScoutingReports, onReportChange]);

    useEffect(() => {
        if (PlayerScoutingReports.length > 0) {
            if (onReportListChange) {
                onReportListChange(
                    PlayerScoutingReports.map((r) => {
                        return {
                            id: r.rinknet_id,
                            date: r.date,
                        };
                    })
                );
            }
        }
    }, [PlayerScoutingReports, onReportListChange]);

    const lastSelectedReportId = useRef(selectedReportId);
    const [reportChanging, setReportChanging] = useState(false);

    useEffect(() => {
        if (lastSelectedReportId.current !== selectedReportId) {
            setReportChanging(true);
            // Wait for report to change before setting report changing to false
            setTimeout(() => {
                lastSelectedReportId.current = selectedReportId;
                if (selectedReportId) {
                    const index = PlayerScoutingReports.findIndex(
                        (r) => r.rinknet_id === selectedReportId
                    );
                    if (index !== -1 && index !== selectedReportIndex) {
                        setSelectedReportIndex(index);
                    }
                }
                setReportChanging(false);
            }, 350);
        }
    }, [selectedReportId, selectedReportIndex, PlayerScoutingReports]);

    const selected_report = PlayerScoutingReports[selectedReportIndex];

    // Navigate to next/previous report
    const nextReport = useCallback(() => {
        if (selectedReportIndex > 0) {
            onReportChange &&
                onReportChange(PlayerScoutingReports[selectedReportIndex - 1]);
            player_scouting_reports_mp_track(
                "Player Scouting Report Selection - Next",
                player,
                PlayerScoutingReports[selectedReportIndex - 1]
            );
        }
    }, [selectedReportIndex, player, PlayerScoutingReports, onReportChange]);

    const prevReport = useCallback(() => {
        if (selectedReportIndex < PlayerScoutingReports.length - 1) {
            onReportChange &&
                onReportChange(PlayerScoutingReports[selectedReportIndex + 1]);
            player_scouting_reports_mp_track(
                "Player Scouting Report Selection - Previous",
                player,
                PlayerScoutingReports[selectedReportIndex + 1]
            );
        }
    }, [selectedReportIndex, player, PlayerScoutingReports, onReportChange]);

    const handlers = useSwipeable({
        onSwipedLeft: () => nextReport,
        onSwipedRight: () => prevReport,
    });

    return (
        <>
            {!canViewScoutingReports && (
                <MainBox>
                    <div className={classes.no_data}>Not Allowed</div>
                </MainBox>
            )}
            {!latest_rinknet_report && <div>Loading...</div>}
            {latest_rinknet_report && !latest_rinknet_report.rinknet_id && (
                <MainBox>
                    <div className={classes.no_data}>No Data</div>
                </MainBox>
            )}
            {latest_rinknet_report &&
                latest_rinknet_report.rinknet_id &&
                selected_report && (
                    <MainBox fillHeight={true}>
                        <div
                            className={classes.scouting_report_wrapper}
                            {...handlers}
                        >
                            {PlayerScoutingReports.length > 1 &&
                                selectedReportIndex <
                                    PlayerScoutingReports.length - 1 && (
                                    <ArrowButton
                                        color={"white"}
                                        boxColor={"var(--ch-blue-400)"}
                                        size={40}
                                        direction={"left"}
                                        className={
                                            classes.navigate_button +
                                            " " +
                                            classes.prev_button
                                        }
                                        onClick={prevReport}
                                    />
                                )}
                            <PlayerScoutingReport
                                player={player}
                                reportVisible={!reportChanging}
                                report={selected_report}
                            />
                            {PlayerScoutingReports.length > 1 &&
                                selectedReportIndex > 0 && (
                                    <ArrowButton
                                        color={"white"}
                                        boxColor={"var(--ch-blue-400)"}
                                        size={40}
                                        direction={"right"}
                                        className={
                                            classes.navigate_button +
                                            " " +
                                            classes.next_button
                                        }
                                        onClick={nextReport}
                                    />
                                )}
                        </div>
                    </MainBox>
                )}
        </>
    );
};

export { PlayerScoutingReport };

export default PlayerScoutingReports;
