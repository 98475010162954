import { LegendSection, LegendItem } from "../../../controls/Legend";
import { PlayerTileBox } from "../../playertile/Playertile";
import tile_classes from "../../playertile/Playertile.module.css";
import classes from "./PlayerFreeAgencyTileLegendSection.module.css";

const PlayerProfileLegendSection = () => {
    return (
        <LegendSection title="Player Profile">
            <div className={classes.tiles_section}>
                <LegendItem
                    description={
                        <div className={classes.tile_container}>
                            <PlayerTileBox
                                mainClass={
                                    tile_classes.basetile +
                                    " " +
                                    tile_classes.lefty
                                }
                            >
                                Player shoots/catches left.
                            </PlayerTileBox>
                        </div>
                    }
                />
                <LegendItem
                    description={
                        <div className={classes.tile_container}>
                            <PlayerTileBox
                                mainClass={
                                    tile_classes.basetile +
                                    " " +
                                    tile_classes.righty
                                }
                            >
                                Player shoots/catches right.
                            </PlayerTileBox>
                        </div>
                    }
                />
            </div>
        </LegendSection>
    );
};

export default PlayerProfileLegendSection;
