import classes from "./HorizontalBarIndicator.module.css";
import { getPerformanceTierFromPercentile } from "../utils";

const HorizontalBarContainer = ({
    value,
    percentile,
    maxValue,
    tierClass,
    inverseColors = false,
    includeLabel,
    decimalPlaces,
    barShowsPercentile,
    singleEndRounding = false,
    labelWidth = "24px",
    labelColor = "var(--tw-gray-500)",
    isExample = false,
}) => {
    const amplitude =
        Math.abs(value) > maxValue ? 1 : Math.abs(value) / maxValue;
    const flipped = value < 0;

    return (
        <div
            className={classes.horizontal_bar_container}
            style={flipped ? { flexDirection: "row-reverse" } : {}}
        >
            <div
                className={
                    classes.horizontal_bar +
                    (tierClass ? " " + tierClass : "") +
                    (inverseColors ? " " + classes.inverse : "") +
                    (singleEndRounding
                        ? " " + classes.single_end_rounding
                        : "") +
                    (flipped ? " " + classes.flipped : "")
                }
                style={{
                    width: `calc((100%${
                        includeLabel ? " - " + labelWidth : ""
                    }) * ${
                        isExample
                            ? 1.0
                            : barShowsPercentile
                            ? percentile
                            : amplitude
                    })`,
                }}
            ></div>
            {includeLabel && (
                <div
                    className={
                        classes.horizontal_bar_label +
                        (flipped ? " " + classes.flipped : "")
                    }
                    style={{
                        color: labelColor,
                        minWidth: labelWidth,
                    }}
                >
                    {value &&
                        (isExample ? value : value.toFixed(decimalPlaces))}
                </div>
            )}
        </div>
    );
};

const HorizontalBarIndicator = ({
    value,
    maxValue,
    isSigned = false,
    percentile,
    performanceTier,
    inverseColors = false,
    barShowsPercentile = false,
    includeLabel,
    decimalPlaces,
    labelWidth = "24px",
    labelColor = "var(--tw-gray-500)",
    isExample = false,
}) => {
    let tierClass = null;

    if (percentile) {
        tierClass =
            classes[
                getPerformanceTierFromPercentile(percentile).tier + "_tier"
            ];
    } else if (performanceTier) {
        tierClass = classes[performanceTier.toLowerCase() + "_tier"];
    }

    return (
        <div className={classes.horizontal_bar_indicator}>
            <>
                {isSigned && (
                    <>
                        <HorizontalBarContainer
                            value={value < 0 ? value : null}
                            maxValue={maxValue}
                            tierClass={tierClass}
                            inverseColors={inverseColors}
                            includeLabel={includeLabel}
                            decimalPlaces={decimalPlaces}
                            labelWidth={labelWidth}
                            labelColor={labelColor}
                            singleEndRounding={true}
                            isExample={isExample}
                        />
                        <div className={classes.separator} />
                    </>
                )}
                <HorizontalBarContainer
                    value={value >= 0 ? value : null}
                    percentile={percentile}
                    maxValue={maxValue}
                    tierClass={tierClass}
                    inverseColors={inverseColors}
                    includeLabel={includeLabel}
                    decimalPlaces={decimalPlaces}
                    labelWidth={labelWidth}
                    labelColor={labelColor}
                    singleEndRounding={isSigned}
                    barShowsPercentile={barShowsPercentile}
                    isExample={isExample}
                />
            </>
        </div>
    );
};

export default HorizontalBarIndicator;
