import Legend, { LegendBlock } from "../../controls/Legend";
import PlayerDevelopmentTileSection from "./sections/PlayerDevelopmentTileSection";

const TeamPlayerDevelopmentLegend = ({
    zIndex = 1000,
}) => {
    return (
        <Legend zIndex={zIndex}>
            <LegendBlock>
                <PlayerDevelopmentTileSection />
            </LegendBlock>
        </Legend>
    );
};

export default TeamPlayerDevelopmentLegend;
