import { useState, useEffect, useCallback, useMemo } from "react";
import { useDispatch } from "react-redux";
import classes from "./PlayerDevelopmentDialogs.module.css";
import {
    postPlayerDevelopmentPriority,
    updatePlayerDevelopmentPriority,
    postPlayerDevelopmentSession,
    patchPlayerDevelopmentSession,
    closePlayerDevelopmentPriority,
} from "../store/players-actions";
import TextInput from "../controls/TextInput";
import DatePicker from "../controls/DatePicker";
import SquareButton from "../controls/buttons/SquareButton";
import {
    DialogControl,
    PostModalDialog,
} from "../controls/dialogs/ModalDialog";
import OptionDropdown from "../controls/OptionDropdown";
import OptionsMultiselect from "../controls/OptionsMultiselect";
import Toggle from "../controls/buttons/Toggle";
import PlayerSearchInput from "../controls/search-inputs/PlayerSearchInput";
import MetricSearchInput from "../controls/search-inputs/MetricSearchInput";
import { getPrimaryPosition } from "../utils";
import { trim } from "lodash";

import { mp_track } from "../mixpanel";

export const player_development_mp_track = (
    event,
    player,
    properties = null
) => {
    if (player) {
        properties = {
            ...properties,
            player_id: player.id,
            player_name: player.known_name,
        };
    }

    mp_track(event, properties, "player_development");
};

const TextOption = ({ optionValue }) => {
    return <div className={classes.text_option}>{optionValue?.label}</div>;
};

const ExtraPlayer = ({ player, onRemove }) => {
    return (
        <div className={classes.extra_player}>
            <div className={classes.extra_player_name}>{player.label}</div>
            <SquareButton
                label={"cancel"}
                useMaterialIcon={true}
                onClick={onRemove}
            />
        </div>
    );
};

export const PlayerDevelopmentSessionDialog = ({
    player,
    playersPool,
    priorityId,
    developmentPriorities,
    session,
    isOpen,
    onCloseHandler,
    sessionTypes,
}) => {
    const dispatch = useDispatch();

    const [activeDevelopmentPriorityIds, setActiveDevelopmentPriorityIds] =
        useState([]);

    const [sessionPlayer, setSessionPlayer] = useState(player);
    const [sessionExtraPlayers, setSessionExtraPlayers] = useState([]);
    const [sessionText, setSessionText] = useState("");
    const [sessionDate, setSessionDate] = useState(
        new Date().toISOString().split("T")[0]
    );
    const [sessionTypeId, setSessionTypeId] = useState(null);
    const [sessionDevelopmentPriorities, setSessionDevelopmentPriorities] =
        useState([]);
    const [sessionType, setSessionType] = useState(null);
    const [sessionDuration, setSessionDuration] = useState(null);
    const [sessionPlayerInstigatedFlag, setSessionPlayerInstigatedFlag] =
        useState(false);

    useEffect(() => {
        if (isOpen) {
            setActiveDevelopmentPriorityIds(
                developmentPriorities
                    .filter(
                        (developmentPriority) => !developmentPriority.end_date
                    )
                    .map((dp) => dp.id)
            );
        }
    }, [isOpen, developmentPriorities, player]);

    useEffect(() => {
        if (isOpen && player) {
            setSessionPlayer(player);
        }
    }, [player, isOpen]);

    useEffect(() => {
        if (sessionTypeId) {
            setSessionType(sessionTypes.find((st) => st.id === sessionTypeId));
        }
    }, [sessionTypeId, sessionTypes]);

    const [isPostDisabled, setIsPostDisabled] = useState(true);

    useEffect(() => {
        const shouldDisable = false;
        setIsPostDisabled(shouldDisable);
    }, []);

    const sessionDurationOptions = useMemo(() => {
        return [
            { id: 1, duration: 15, label: "15 min" },
            { id: 2, duration: 30, label: "30 min" },
            { id: 3, duration: 45, label: "45 min" },
            { id: 4, duration: 60, label: "1 hr" },
        ];
    }, []);

    const developmentPriorityOptions = useMemo(() => {
        return (
            isOpen &&
            developmentPriorities
                ?.filter(
                    (developmentPriority) =>
                        developmentPriority.player_id === sessionPlayer?.id &&
                        (activeDevelopmentPriorityIds.includes(
                            developmentPriority.id
                        ) ||
                            sessionDevelopmentPriorities.find(
                                (t) => t.id === developmentPriority.id
                            ))
                )
                .map((developmentPriority) => {
                    return {
                        id: developmentPriority.id,
                        label: developmentPriority.title,
                    };
                })
        );
    }, [
        developmentPriorities,
        sessionPlayer,
        isOpen,
        activeDevelopmentPriorityIds,
        sessionDevelopmentPriorities,
    ]);

    useEffect(() => {
        if (isOpen) {
            if (session) {
                setSessionText(session.text);
                setSessionDate(session.date);
                setSessionTypeId(session.session_type.id || sessionTypes[0].id);
                setSessionDevelopmentPriorities(
                    session?.developmentpriority_ids
                        ? developmentPriorities.filter((t) =>
                              session.developmentpriority_ids.includes(t.id)
                          )
                        : []
                );
                setSessionDuration(session.duration_minutes);
                setSessionPlayerInstigatedFlag(session.was_player_instigated);
            } else {
                // Set date to today by default
                setSessionText("");
                setSessionExtraPlayers([]);
                setSessionDate(new Date().toISOString().split("T")[0]);
                setSessionTypeId(
                    sessionTypes &&
                        sessionTypes.length > 0 &&
                        sessionTypes[0].id
                );
                setSessionDevelopmentPriorities(
                    priorityId && developmentPriorities
                        ? developmentPriorities.filter(
                              (t) => priorityId === t.id
                          )
                        : []
                );
                setSessionDuration(sessionDurationOptions[0].duration);
                setSessionPlayerInstigatedFlag(false);
            }
        }
    }, [
        session,
        sessionTypes,
        sessionDurationOptions,
        isOpen,
        developmentPriorities,
        priorityId,
    ]);

    // Function to handle cancel/close
    const handleClose = useCallback(() => {
        setSessionPlayer(null);
        setSessionExtraPlayers([]);
        setSessionText("");
        setSessionDuration(null);
        setSessionPlayerInstigatedFlag(false);
        onCloseHandler && onCloseHandler();
    }, [onCloseHandler]);

    const handleCancel = () => {
        handleClose();
        player_development_mp_track(
            "Player Development Session Dialog Cancelled",
            player
        );
    };

    // Function to handle post
    const handlePost = useCallback(() => {
        if (!session) {
            dispatch(
                postPlayerDevelopmentSession(
                    sessionPlayer.id,
                    sessionExtraPlayers.map((p) => p.id),
                    sessionTypeId,
                    sessionDevelopmentPriorities.map((t) => t.id),
                    sessionDate,
                    sessionDuration,
                    sessionPlayerInstigatedFlag,
                    sessionText
                )
            );

            handleClose();

            const mixpanelData = {
                date: sessionDate,
                session_type_id: sessionTypeId,
                session_type: sessionType.label,
                priority_ids: sessionDevelopmentPriorities.map((t) => t.id),
                duration: sessionDuration,
                player_instigated: sessionPlayerInstigatedFlag,
            };

            player_development_mp_track(
                "Create Player Development Session",
                sessionPlayer,
                mixpanelData
            );
        } else {
            dispatch(
                patchPlayerDevelopmentSession(
                    session.id,
                    sessionTypeId,
                    sessionDevelopmentPriorities.map((t) => t.id),
                    sessionDate,
                    sessionDuration,
                    sessionPlayerInstigatedFlag,
                    sessionText
                )
            );

            handleClose();

            const mixpanelData = {
                session_id: session.id,
                priority_ids: sessionDevelopmentPriorities.map((t) => t.id),
                date: sessionDate,
                session_type_id: sessionTypeId,
                session_type: sessionType.label,
                duration: sessionDuration,
                player_instigated: sessionPlayerInstigatedFlag,
            };

            player_development_mp_track(
                "Update Player Development Session",
                sessionPlayer,
                mixpanelData
            );
        }
    }, [
        dispatch,
        sessionPlayer,
        sessionText,
        sessionDate,
        sessionTypeId,
        sessionDevelopmentPriorities,
        sessionDuration,
        sessionPlayerInstigatedFlag,
        sessionType,
        session,
        handleClose,
        sessionExtraPlayers,
    ]);

    useEffect(() => {
        const shouldDisable =
            !sessionText.trim() ||
            !sessionDate.trim() ||
            (session &&
                session.text === sessionText &&
                session.date === sessionDate &&
                session.duration_minutes === sessionDuration &&
                session.session_type.id === sessionTypeId &&
                session.was_player_instigated === sessionPlayerInstigatedFlag &&
                session.priority_ids &&
                // items in sessionDevelopmentPriorities and session.priority_ids are the same
                sessionDevelopmentPriorities.length ===
                    session.priority_ids.length &&
                sessionDevelopmentPriorities.every((t) =>
                    session.priority_ids.includes(t.id)
                ));

        setIsPostDisabled(shouldDisable);
    }, [
        sessionText,
        sessionDate,
        sessionDuration,
        sessionTypeId,
        sessionDevelopmentPriorities,
        sessionPlayerInstigatedFlag,
        session,
    ]);

    const extraPlayersPool = useMemo(() => {
        return playersPool?.filter(
            (p) =>
                p.id !== sessionPlayer?.id &&
                !sessionExtraPlayers.find((sp) => sp.id === p.id)
        );
    }, [playersPool, sessionPlayer, sessionExtraPlayers]);

    return (
        <PostModalDialog
            title={(session ? "Update" : "Add") + " Development Session"}
            isOpen={isOpen}
            handlePost={handlePost}
            handleCancel={handleCancel}
            isPostDisabled={isPostDisabled}
            postLabel={session ? "Save" : "Add"}
        >
            <>
                {player && (
                    <DialogControl label={"Player"}>
                        <div className={classes.read_only_control}>
                            {player.known_name}
                        </div>
                    </DialogControl>
                )}
                {playersPool && (
                    <>
                        <DialogControl label={"Player"}>
                            <PlayerSearchInput
                                players={playersPool}
                                selectedPlayerId={sessionPlayer?.id}
                                onResultSelected={(player) => {
                                    setSessionPlayer(player);
                                    setSessionDevelopmentPriorities([]);
                                    setSessionExtraPlayers((prev) => {
                                        return prev.filter((sp) => {
                                            return sp.id !== player.id;
                                        });
                                    });
                                }}
                            />
                        </DialogControl>
                        {sessionPlayer && (
                            <>
                                <DialogControl label={"Priorities"}>
                                    <>
                                        {developmentPriorityOptions.length ===
                                            0 && (
                                            <div
                                                className={
                                                    classes.read_only_control
                                                }
                                            >
                                                No active development priorities
                                            </div>
                                        )}
                                        {developmentPriorityOptions.length >
                                            0 && (
                                            <OptionsMultiselect
                                                options={
                                                    developmentPriorityOptions
                                                }
                                                selectedOptions={
                                                    sessionDevelopmentPriorities
                                                }
                                                onSelect={(
                                                    selectedDevelopmentPriorities
                                                ) =>
                                                    setSessionDevelopmentPriorities(
                                                        selectedDevelopmentPriorities
                                                    )
                                                }
                                                OptionComponent={TextOption}
                                            />
                                        )}
                                    </>
                                </DialogControl>
                                <DialogControl label={"Add Player"}>
                                    <div className={classes.extra_players}>
                                        <PlayerSearchInput
                                            players={extraPlayersPool}
                                            onResultSelected={(player) => {
                                                setSessionExtraPlayers(
                                                    (prev) => {
                                                        if (
                                                            prev.find(
                                                                (p) =>
                                                                    p.id ===
                                                                    player.id
                                                            )
                                                        ) {
                                                            return prev;
                                                        }
                                                        return [
                                                            ...prev,
                                                            player,
                                                        ];
                                                    }
                                                );
                                            }}
                                        />
                                        {sessionExtraPlayers.length > 0 && (
                                            <div
                                                className={
                                                    classes.extra_players
                                                }
                                            >
                                                {sessionExtraPlayers.map(
                                                    (p) => (
                                                        <ExtraPlayer
                                                            key={p.id}
                                                            player={p}
                                                            onRemove={() =>
                                                                setSessionExtraPlayers(
                                                                    (prev) =>
                                                                        prev.filter(
                                                                            (
                                                                                sp
                                                                            ) =>
                                                                                sp.id !==
                                                                                p.id
                                                                        )
                                                                )
                                                            }
                                                        />
                                                    )
                                                )}
                                            </div>
                                        )}
                                    </div>
                                </DialogControl>
                            </>
                        )}
                    </>
                )}
                <DialogControl label={"Date"}>
                    <DatePicker
                        id={"session-date"}
                        value={sessionDate}
                        handleDateChange={(date) => setSessionDate(date)}
                    />
                </DialogControl>
                <DialogControl label={"Type"}>
                    <OptionDropdown
                        options={sessionTypes}
                        selectedOption={sessionType}
                        onSelect={(option) => setSessionTypeId(option.id)}
                        OptionComponent={TextOption}
                    />
                </DialogControl>
                <DialogControl label={"Duration"}>
                    <OptionDropdown
                        options={sessionDurationOptions}
                        selectedOption={sessionDurationOptions.find(
                            (o) => o.duration === sessionDuration
                        )}
                        onSelect={(option) =>
                            setSessionDuration(option.duration)
                        }
                        OptionComponent={TextOption}
                    />
                </DialogControl>
                {player && (
                    <DialogControl label={"Priorities"}>
                        {developmentPriorityOptions.length === 0 && (
                            <div className={classes.read_only_control}>
                                No active development priorities
                            </div>
                        )}
                        {developmentPriorityOptions.length > 0 && (
                            <OptionsMultiselect
                                options={developmentPriorityOptions}
                                selectedOptions={sessionDevelopmentPriorities}
                                onSelect={(selectedDevelopmentPriorities) =>
                                    setSessionDevelopmentPriorities(
                                        selectedDevelopmentPriorities
                                    )
                                }
                                OptionComponent={TextOption}
                            />
                        )}
                    </DialogControl>
                )}
                {player && (
                    <DialogControl label={"Player Instigated"}>
                        <Toggle
                            id={"session-player-instigated"}
                            isChecked={sessionPlayerInstigatedFlag}
                            onToggle={(e) =>
                                setSessionPlayerInstigatedFlag(
                                    e.target.checked ? true : false
                                )
                            }
                        />
                    </DialogControl>
                )}
                <DialogControl label={"Note"}>
                    <TextInput
                        id={"session-text"}
                        value={sessionText}
                        handleInputChange={(e) =>
                            setSessionText(e.target.value)
                        }
                        placeholder={"Enter session details"}
                        multiline={true}
                    />
                </DialogControl>
            </>
        </PostModalDialog>
    );
};

export const PlayerDevelopmentPriorityDialog = ({
    player,
    competitionId,
    seasonId,
    developmentPriority,
    isOpen,
    updateModeComplete = false,
    onCloseHandler,
    outcomes,
}) => {
    const dispatch = useDispatch();

    const [developmentPriorityTitle, setDevelopmentPriorityTitle] =
        useState("");
    const [developmentPriorityDescription, setDevelopmentPriorityDescription] =
        useState("");
    const [
        developmentPriorityPerformanceMetricName,
        setDevelopmentPriorityPerformanceMetricName,
    ] = useState("");
    const [developmentPriorityStartDate, setDevelopmentPriorityStartDate] =
        useState(new Date().toISOString().split("T")[0]);
    const [developmentPriorityEndDate, setDevelopmentPriorityEndDate] =
        useState(new Date().toISOString().split("T")[0]);
    const [developmentPriorityOutcomeId, setDevelopmentPriorityOutcomeId] =
        useState(null);
    const [developmentPriorityOutcome, setDevelopmentPriorityOutcome] =
        useState(null);
    const [developmentPriorityClosingNote, setDevelopmentPriorityClosingNote] =
        useState("");

    useEffect(() => {
        if (developmentPriorityOutcomeId) {
            setDevelopmentPriorityOutcome(
                outcomes.find((o) => o.id === developmentPriorityOutcomeId)
            );
        }
    }, [developmentPriorityOutcomeId, outcomes]);

    const [isPostDisabled, setIsPostDisabled] = useState(true);

    useEffect(() => {
        const shouldDisable = false;
        setIsPostDisabled(shouldDisable);
    }, []);

    useEffect(() => {
        if (developmentPriority) {
            setDevelopmentPriorityTitle(developmentPriority.title);
            setDevelopmentPriorityDescription(developmentPriority.description);
            setDevelopmentPriorityPerformanceMetricName(
                developmentPriority.performance_metric_name
            );
            setDevelopmentPriorityStartDate(developmentPriority.start_date);
            setDevelopmentPriorityEndDate(
                developmentPriority.end_date
                    ? developmentPriority.end_date
                    : new Date().toISOString().split("T")[0]
            );
            setDevelopmentPriorityOutcomeId(
                developmentPriority.outcome || outcomes[0].id
            );
        } else {
            setDevelopmentPriorityTitle("");
            setDevelopmentPriorityDescription("");
            setDevelopmentPriorityPerformanceMetricName("");
            // Set default start date to today by default
            setDevelopmentPriorityStartDate(
                new Date().toISOString().split("T")[0]
            );
            setDevelopmentPriorityEndDate("");
            setDevelopmentPriorityOutcomeId(
                outcomes && outcomes.length > 0 && outcomes[0].id
            );
            setDevelopmentPriorityClosingNote("");
        }
    }, [developmentPriority, outcomes]);

    // Function to handle cancel/close
    const handleClose = useCallback(() => {
        setDevelopmentPriorityTitle("");
        setDevelopmentPriorityDescription("");
        setDevelopmentPriorityPerformanceMetricName("");
        setDevelopmentPriorityClosingNote("");
        onCloseHandler && onCloseHandler();
    }, [onCloseHandler]);

    const handleCancel = () => {
        handleClose();
        player_development_mp_track(
            "Player Development Priority Dialog Cancelled",
            player
        );
    };

    // Function to handle post
    const handlePost = useCallback(() => {
        if (!developmentPriority) {
            dispatch(
                postPlayerDevelopmentPriority(
                    player.id,
                    competitionId,
                    seasonId,
                    developmentPriorityTitle,
                    developmentPriorityDescription,
                    developmentPriorityStartDate,
                    developmentPriorityPerformanceMetricName
                )
            );

            handleClose();

            const mixpanelData = {
                title: developmentPriorityTitle,
                metric: developmentPriorityPerformanceMetricName,
                start_date: developmentPriorityStartDate,
            };

            player_development_mp_track(
                "Create Player Development Priority",
                player,
                mixpanelData
            );
        } else if (updateModeComplete) {
            dispatch(
                closePlayerDevelopmentPriority(
                    developmentPriority.id,
                    developmentPriorityOutcomeId,
                    developmentPriorityEndDate,
                    developmentPriorityClosingNote
                )
            );

            handleClose();

            const mixpanelData = {
                priority_id: developmentPriority.id,
                title: developmentPriorityTitle,
                metric: developmentPriorityPerformanceMetricName,
                start_date: developmentPriorityStartDate,
                outcome_type_id: developmentPriorityOutcomeId,
                outcome: developmentPriorityOutcome.name,
                end_date: developmentPriorityEndDate,
                has_note: !!trim(developmentPriorityClosingNote),
            };

            player_development_mp_track(
                "Close Player Development Priority",
                player,
                mixpanelData
            );
        } else {
            dispatch(
                updatePlayerDevelopmentPriority(
                    developmentPriority.id,
                    developmentPriorityTitle,
                    developmentPriorityDescription,
                    developmentPriorityStartDate,
                    developmentPriorityPerformanceMetricName
                )
            );

            handleClose();

            const mixpanelData = {
                priority_id: developmentPriority.id,
                title: developmentPriorityTitle,
                metric: developmentPriorityPerformanceMetricName,
                start_date: developmentPriorityStartDate,
            };

            player_development_mp_track(
                "Update Player Development Priority",
                player,
                mixpanelData
            );
        }
    }, [
        dispatch,
        player,
        competitionId,
        seasonId,
        developmentPriorityTitle,
        developmentPriorityDescription,
        developmentPriorityStartDate,
        developmentPriorityPerformanceMetricName,
        developmentPriority,
        handleClose,
        updateModeComplete,
        developmentPriorityOutcomeId,
        developmentPriorityOutcome,
        developmentPriorityEndDate,
        developmentPriorityClosingNote,
    ]);

    useEffect(() => {
        const shouldDisable =
            !developmentPriorityTitle.trim() ||
            !developmentPriorityDescription.trim() ||
            !developmentPriorityStartDate.trim() ||
            (developmentPriority &&
                !updateModeComplete &&
                developmentPriority.title === developmentPriorityTitle &&
                developmentPriority.description ===
                    developmentPriorityDescription &&
                developmentPriority.performance_metric_name ===
                    developmentPriorityPerformanceMetricName &&
                developmentPriority.start_date ===
                    developmentPriorityStartDate);

        setIsPostDisabled(shouldDisable);
    }, [
        developmentPriorityTitle,
        developmentPriorityDescription,
        developmentPriorityPerformanceMetricName,
        developmentPriorityStartDate,
        developmentPriority,
        updateModeComplete,
    ]);

    return (
        <PostModalDialog
            title={
                (developmentPriority
                    ? updateModeComplete
                        ? "Complete"
                        : "Update"
                    : "Create") + " Player Development Priority"
            }
            isOpen={isOpen}
            handlePost={handlePost}
            handleCancel={handleCancel}
            isPostDisabled={isPostDisabled}
            postLabel={
                developmentPriority
                    ? updateModeComplete
                        ? "Complete"
                        : "Save"
                    : "Create"
            }
        >
            {player && !updateModeComplete && (
                <>
                    <DialogControl label={"Title"}>
                        <TextInput
                            id={"development-priority-title"}
                            value={developmentPriorityTitle}
                            handleInputChange={(e) =>
                                setDevelopmentPriorityTitle(e.target.value)
                            }
                            placeholder={"Enter priority title"}
                        />
                    </DialogControl>
                    <DialogControl label={"Details"}>
                        <TextInput
                            id={"development-priority-description"}
                            value={developmentPriorityDescription}
                            handleInputChange={(e) =>
                                setDevelopmentPriorityDescription(
                                    e.target.value
                                )
                            }
                            placeholder={"Enter priority description"}
                            multiline={true}
                        />
                    </DialogControl>
                    <DialogControl label={"Metric"} isOptional={true}>
                        <MetricSearchInput
                            id={"development-priority-performance-metric-name"}
                            primaryPosition={getPrimaryPosition(
                                player.position
                            )}
                            metricName={
                                developmentPriorityPerformanceMetricName
                            }
                            onResultSelected={(selection) =>
                                setDevelopmentPriorityPerformanceMetricName(
                                    selection.id
                                )
                            }
                            placeholder={"Enter performance metric name"}
                        />
                    </DialogControl>
                    <DialogControl label={"Start"}>
                        <DatePicker
                            id={"development-priority-start-date"}
                            value={developmentPriorityStartDate}
                            handleDateChange={(date) =>
                                setDevelopmentPriorityStartDate(date)
                            }
                        />
                    </DialogControl>
                </>
            )}
            {developmentPriority && updateModeComplete && (
                <>
                    <DialogControl label={"Outcome"}>
                        <OptionDropdown
                            options={outcomes}
                            selectedOption={developmentPriorityOutcome}
                            onSelect={(option) =>
                                setDevelopmentPriorityOutcomeId(option.id)
                            }
                            OptionComponent={TextOption}
                        />
                    </DialogControl>
                    <DialogControl label={"End"}>
                        <DatePicker
                            id={"development-priority-end-date"}
                            value={developmentPriorityEndDate}
                            handleDateChange={(date) =>
                                setDevelopmentPriorityEndDate(date)
                            }
                        />
                    </DialogControl>
                    <DialogControl label={"Note"}>
                        <TextInput
                            id={"development-priority-description"}
                            value={developmentPriorityClosingNote}
                            handleInputChange={(e) =>
                                setDevelopmentPriorityClosingNote(
                                    e.target.value
                                )
                            }
                            placeholder={"Optional notes"}
                            multiline={true}
                        />
                    </DialogControl>
                </>
            )}
        </PostModalDialog>
    );
};
