import Legend, { LegendBlock } from "../../controls/Legend";
import PerformanceTiersSection from "./sections/PerformanceTiersSection";
import PlayerNameLineLegendSection from "./sections/PlayerNameLineLegendSection";
import ScoutingLegendSection from "./sections/ScoutingLegendSection";
import PlayerProfileLegendSection from "./sections/PlayerProfileLegendSection";
import PlayerFreeAgencyTileLegendSection from "./sections/PlayerFreeAgencyTileLegendSection";

const ScoutingTileLegend = ({ includeFreeAgency = false, zIndex = 1000 }) => {
    return (
        <Legend zIndex={zIndex}>
            <LegendBlock>
                <PerformanceTiersSection />
                <PlayerNameLineLegendSection />
            </LegendBlock>
            <LegendBlock>
                <ScoutingLegendSection />
                <PlayerProfileLegendSection />
            </LegendBlock>
            {includeFreeAgency && (
                <LegendBlock>
                    <PlayerFreeAgencyTileLegendSection />
                </LegendBlock>
            )}
        </Legend>
    );
};

export default ScoutingTileLegend;
