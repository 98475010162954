import { LegendSection, LegendItem } from "../../../controls/Legend";
import playertile_classes from "../../playertile/Playertile.module.css";

const PlayerNameLineLegendSection = () => {
    return (
        <LegendSection
            title="Player Name Line"
            description="The player name line includes the following additional details on the player:"
        >
            <LegendItem
                symbol={<div className={playertile_classes.playerage}>##</div>}
                description="The player's age (immediately at the right of the player's name)."
            />
            <LegendItem
                symbol={
                    <div className={playertile_classes.namedecoration}>IR</div>
                }
                description="The player is currently on Injury Reserve."
            />
            <LegendItem
                symbol={
                    <div className={playertile_classes.namedecoration}>
                        LTIR
                    </div>
                }
                description="The player is currently on Long Term Injury Reserve."
            />
            <LegendItem
                symbol={
                    <div className={playertile_classes.namedecoration}>
                        LOAN
                    </div>
                }
                description="The player is currently on loan."
            />
            <LegendItem
                symbol={
                    <div className={playertile_classes.namedecoration}>OTH</div>
                }
                description="The player is currently off roster for other reasons."
            />
            <LegendItem
                symbol={
                    <div className={playertile_classes.namedecoration}>
                        XXHL
                    </div>
                }
                description="Current primary league of player outside of the NHL or AHL."
            />
            <LegendItem
                symbol={
                    <div
                        className={
                            playertile_classes.namedecoration +
                            " " +
                            playertile_classes.light
                        }
                    >
                        XXHL
                    </div>
                }
                description="Primary league of player has incomplete data coverage."
            />
        </LegendSection>
    );
};

export default PlayerNameLineLegendSection;
