import classes from "./ArrowButton.module.css";

const ArrowButton = ({
    color = "black",
    boxColor = "white",
    size = 24,
    direction = "right",
    ...props
}) => {
    const isRight = direction === "right";

    const arrowPath = isRight ? "M10 6 14 12 10 18" : "M14 18 10 12 14 6";

    return (
        <button {...props} style={{ border: "none", background: "none" }}>
            <div className={classes.svgContainer}>
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width={size}
                    height={size}
                    viewBox="0 0 24 24"
                >
                    <rect
                        x="2"
                        y="2"
                        width="20"
                        height="20"
                        rx="5"
                        fill={boxColor}
                    />
                    <path
                        fill="none"
                        stroke={color}
                        strokeWidth="2"
                        d={arrowPath}
                    />
                </svg>
            </div>
        </button>
    );
};

export default ArrowButton;
