import classes from "./OverheadTitleSection.module.css";

function OverheadTitleSection({ title, maxWidth, children }) {
    return (
        <>
            <div
                className={classes.section_title}
                style={maxWidth && { maxWidth: maxWidth }}
            >
                {title}
            </div>
            {children}
        </>
    );
}

export default OverheadTitleSection;
