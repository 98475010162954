import HelpModal from "../../controls/HelpModal";
import HorizontalBarIndicator from "../../controls/HorizontalBarIndicator";
import HorizontalSliderIndicator from "../../controls/HorizontalSliderIndicator";
import classes from "./TeamPlayerIndexLegend.module.css";

const TeamPlayerIndexLegend = ({ includeTrends = false, zIndex = 1000 }) => {
    return (
        <HelpModal zIndex={zIndex}>
            <div>
                <div className={classes.legend_title}>Legend</div>
                <div className={classes.legend_container}>
                    <div className={classes.legend_section}>
                        <div className={classes.legend_section_title}>
                            Player Index
                        </div>
                        <div className={classes.legend_section_content}>
                            <div className={classes.legend_section_description}>
                                <p>
                                    The Player Index score is made up of 4
                                    components, the weight of each component
                                    varies by position:
                                </p>
                                <h4>Skaters</h4>
                                <ol>
                                    <li>
                                        Defensive Kills & Puck Battles (F: 20%
                                        D: 35%)
                                    </li>
                                    <li>Touch Efficiency (40%)</li>
                                    <li>Chance Creation (F: 30% D: 20%)</li>
                                    <li>Scoring (F: 10% D: 5%)</li>
                                </ol>
                                <h4>Goalies</h4>
                                <ol>
                                    <li>Goaltending (60%)</li>
                                    <li>Puck Handling (10%)</li>
                                    <li>Quality Starts (20%)</li>
                                    <li>No Rebound % (10%)</li>
                                </ol>
                                <p>
                                    The final score is between 0 and 1, and the
                                    colour of the bar indicates the percentile
                                    rank of the player at their position in the
                                    league:
                                </p>
                            </div>
                            <div className={classes.legend_item}>
                                <div className={classes.legend_symbol}>
                                    <HorizontalBarIndicator
                                        value={"#.##"}
                                        performanceTier={"elite"}
                                        labelColor={"var(--tw-gray-500)"}
                                        isExample={true}
                                    />
                                </div>
                                <div
                                    className={classes.legend_item_description}
                                >
                                    Elite level. &gt; 90.0 perc.
                                </div>
                            </div>
                            <div className={classes.legend_item}>
                                <div className={classes.legend_symbol}>
                                    <HorizontalBarIndicator
                                        value={"#.##"}
                                        performanceTier={"top"}
                                        labelColor={"var(--tw-gray-500)"}
                                        isExample={true}
                                    />
                                </div>
                                <div
                                    className={classes.legend_item_description}
                                >
                                    Top level. 75.0 - 90.0 perc.
                                </div>
                            </div>
                            <div className={classes.legend_item}>
                                <div className={classes.legend_symbol}>
                                    <HorizontalBarIndicator
                                        value={"#.##"}
                                        performanceTier={"high"}
                                        labelColor={"var(--tw-gray-500)"}
                                        isExample={true}
                                    />
                                </div>
                                <div
                                    className={classes.legend_item_description}
                                >
                                    High level. 55.0 - 75.0 perc.
                                </div>
                            </div>
                            <div className={classes.legend_item}>
                                <div className={classes.legend_symbol}>
                                    <HorizontalBarIndicator
                                        value={"#.##"}
                                        performanceTier={"mid"}
                                        labelColor={"var(--tw-gray-500)"}
                                        isExample={true}
                                    />
                                </div>
                                <div
                                    className={classes.legend_item_description}
                                >
                                    Mid level. 30.0 - 55.0 perc.
                                </div>
                            </div>
                            <div className={classes.legend_item}>
                                <div className={classes.legend_symbol}>
                                    <HorizontalBarIndicator
                                        value={"#.##"}
                                        performanceTier={"low"}
                                        labelColor={"var(--tw-gray-500)"}
                                        isExample={true}
                                    />
                                </div>
                                <div
                                    className={classes.legend_item_description}
                                >
                                    Low level. &lt; 30.0 perc.
                                </div>
                            </div>
                            <div
                                className={classes.legend_items_separator}
                            ></div>
                            <div className={classes.legend_item}>
                                <div className={classes.legend_symbol}>
                                    <HorizontalBarIndicator
                                        value={"#.##"}
                                        performanceTier={"example"}
                                        labelColor={"var(--tw-gray-500)"}
                                        isExample={true}
                                    />
                                </div>
                                <div
                                    className={classes.legend_item_description}
                                >
                                    Dark: player has at least 75 minutes of ES
                                    5v5 TOI.
                                </div>
                            </div>
                            <div className={classes.legend_item}>
                                <div className={classes.legend_symbol}>
                                    <HorizontalBarIndicator
                                        value={"#.##"}
                                        performanceTier={"example"}
                                        inverseColors={true}
                                        labelColor={"var(--tw-gray-500)"}
                                        isExample={true}
                                    />
                                </div>
                                <div
                                    className={classes.legend_item_description}
                                >
                                    Light: player has less than 75 minutes of ES
                                    5v5 TOI.
                                </div>
                            </div>
                        </div>
                        <div className={classes.legend_section_title}>
                            Pro-Rated Average
                        </div>
                        <div className={classes.legend_section_content}>
                            <div className={classes.legend_item}>
                                <div
                                    className={
                                        classes.legend_symbol +
                                        " " +
                                        classes.weighed_average
                                    }
                                >
                                    <HorizontalSliderIndicator
                                        title={"Avg #.##"}
                                        isExample={true}
                                        tierClass={"example_tier"}
                                    />
                                </div>
                                <div
                                    className={classes.legend_item_description}
                                >
                                    Average Player Index for all of the team's
                                    players at the position. Average is
                                    pro-rated over the ES 5V5 TOI of the
                                    players. The colour of the indicator shows
                                    the percentile rank of the team's players
                                    average.
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </HelpModal>
    );
};

export default TeamPlayerIndexLegend;
