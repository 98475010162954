import React from "react";
import classes from "./RadioButtonGroup.module.css";

const RadioButtonGroup = ({
    options,
    selectedValue,
    onValueChange,
    direction = "vertical",
}) => {
    const handleChange = (value) => {
        if (value !== selectedValue) {
            onValueChange(value);
        }
    };

    return (
        <div className={`${classes.container} ${classes[direction]}`}>
            {options.map((option) => (
                <label key={option.value} className={classes.label}>
                    <input
                        type="radio"
                        name="radioGroup"
                        value={option.value}
                        checked={option.value === selectedValue}
                        onChange={() => handleChange(option.value)}
                        className={classes.radio}
                    />
                    <span className={classes.customRadioButton}></span>
                    {option.label}
                </label>
            ))}
        </div>
    );
};

export default RadioButtonGroup;
