import { useCallback, useEffect } from "react";
import classes from "./ModalDialog.module.css";
import DialogButton from "../buttons/DialogButton";
import Modal from "../Modal";

export const DialogControl = ({
    label,
    isOptional,
    singleLine = false,
    children,
}) => {
    return (
        <div className={classes.dialog_row}>
            <div
                className={
                    classes.dialog_control +
                    (singleLine ? " " + classes.single_line : "")
                }
            >
                <div className={classes.dialog_control_label}>
                    <div className={classes.dialog_control_label_main}>
                        {label}
                    </div>
                    <div className={classes.dialog_control_label_decoration}>
                        {isOptional && "Optional"}
                    </div>
                </div>
                <div className={classes.dialog_control_input}>{children}</div>
            </div>
        </div>
    );
};

export const DialogPostButtonsControl = ({
    handlePost,
    handleCancel,
    isPostDisabled,
    postLabel = "Post",
}) => {
    return (
        <DialogControl>
            <div className={classes.dialog_buttons}>
                {/* Buttons */}
                <DialogButton
                    label={postLabel}
                    onClick={handlePost}
                    disabled={isPostDisabled}
                />
                <DialogButton label={"Cancel"} onClick={handleCancel} />
            </div>
        </DialogControl>
    );
};

export const ModalDialog = ({ title, isOpen, onClose, children }) => {
    return (
        <Modal isOpen={isOpen} onClose={onClose} visibleOverflow={true}>
            <div className={classes.dialog_container}>
                <div className={classes.dialog_title}>{title}</div>
                {children}
            </div>
        </Modal>
    );
};

export const PostModalDialog = ({
    title,
    isOpen,
    handlePost,
    handleCancel,
    isPostDisabled,
    postLabel = "Post",
    children,
}) => {
    // Function to handle key press
    const handleKeyPress = useCallback(
        (event) => {
            if (isOpen && event.key === "Enter" && !isPostDisabled) {
                handlePost();
            }
        },
        [isPostDisabled, handlePost, isOpen]
    );

    useEffect(() => {
        // Add key press listener
        document.addEventListener("keydown", handleKeyPress);

        // Cleanup listener
        return () => {
            document.removeEventListener("keydown", handleKeyPress);
        };
    }, [isPostDisabled, handleKeyPress]); // Depend on isPostDisabled to re-bind event listener when it changes

    return (
        <ModalDialog title={title} isOpen={isOpen}>
            {children}
            <DialogPostButtonsControl
                handlePost={handlePost}
                handleCancel={handleCancel}
                isPostDisabled={isPostDisabled}
                postLabel={postLabel}
            />
        </ModalDialog>
    );
};
