import { useState, useEffect, useCallback, useMemo } from "react";

const getMetricPositionDrilldownOptions = (
    metricTree,
    metricName,
    position
) => {
    return metricTree[metricName].componentMetrics.filter((componentMetric) => {
        return metricTree[componentMetric].positionScope.includes(position);
    });
};

const useMetricsDrilldownBreadcrumbManager = (
    metricTree,
    rootMetric,
    onMetricContextChange,
    onStackResetHandler,
    transitionWait = 200
) => {
    const [primaryPosition, setPrimaryPosition] = useState(null);

    const [metricStack, setMetricStack] = useState({
        stack: [rootMetric],
        breadcrumbStack: [rootMetric],
        activeMetric: rootMetric,
        isOffTreeMetric: false,
        isCompositeMetric:
            getMetricPositionDrilldownOptions(
                metricTree,
                rootMetric,
                primaryPosition
            ).length > 0,
    });

    const [transitionMetricStack, setTransitionMetricStack] = useState({
        stack: null,
        breadcrumbStack: null,
        activeMetric: null,
        isOffTreeMetric: false,
        isCompositeMetric: false,
    });

    const initiateSingleMetricTransition = useCallback(
        (metricName, isOffTreeMetric = false) => {
            if (metricName !== metricStack.activeMetric) {
                let newStack = [...metricStack.stack];
                if (!isOffTreeMetric) {
                    if (newStack.includes(metricName)) {
                        // If the metric is already in the main stack, then we remove
                        // all metrics after it.
                        newStack = newStack.slice(
                            0,
                            newStack.indexOf(metricName) + 1
                        );
                    } else if (
                        // If the metric is one of the current component metrics, then
                        // we replace the last metric in the stack with the new metric.
                        !metricStack.isCompositeMetric
                    ) {
                        newStack = newStack.slice(0, -1);
                        newStack.push(metricName);
                    } else {
                        // The current metric is a composite metric, so we add
                        // it to the stack to drill down.
                        newStack.push(metricName);
                    }
                }

                const newMetricStack = {
                    stack: newStack,
                    breadcrumbStack: isOffTreeMetric
                        ? metricStack.breadcrumbStack
                        : getMetricPositionDrilldownOptions(
                              metricTree,
                              metricName,
                              primaryPosition
                          ).length > 0
                        ? newStack
                        : newStack.slice(0, -1),
                    activeMetric: metricName,
                    isOffTreeMetric: isOffTreeMetric,
                    isCompositeMetric: isOffTreeMetric
                        ? metricStack.isCompositeMetric
                        : getMetricPositionDrilldownOptions(
                              metricTree,
                              metricName,
                              primaryPosition
                          ).length > 0,
                };

                setTransitionMetricStack(() => {
                    return newMetricStack;
                });
                setTimeout(() => {
                    setMetricStack(() => {
                        return { ...newMetricStack };
                    });
                    setTransitionMetricStack({
                        stack: null,
                        breadcrumbStack: null,
                        activeMetric: null,
                        isOffTreeMetric: false,
                    });
                    onMetricContextChange &&
                        onMetricContextChange({ ...newMetricStack });
                }, transitionWait);
            }
        },
        [
            metricStack,
            transitionWait,
            metricTree,
            primaryPosition,
            onMetricContextChange,
        ]
    );

    const initiateFullMetricStackTransition = useCallback(
        (fullMetricStack, activeMetric) => {
            const drilldownOptions = getMetricPositionDrilldownOptions(
                metricTree,
                fullMetricStack[fullMetricStack.length - 1],
                primaryPosition
            );

            const newContext = {
                stack: fullMetricStack,
                breadcrumbStack:
                    drilldownOptions.length > 0
                        ? fullMetricStack
                        : fullMetricStack.slice(0, -1),
                activeMetric: activeMetric,
                isOffTreeMetric:
                    activeMetric ===
                    fullMetricStack[fullMetricStack.length - 1],
                isCompositeMetric: drilldownOptions.length > 0,
            };

            setTransitionMetricStack(newContext);

            // Delay the update to metricStack to reflect the transition after a specified wait
            setTimeout(() => {
                setMetricStack(newContext);
                // Callback to notify about the transition completion, if necessary
                onMetricContextChange && onMetricContextChange(newContext);
                setTransitionMetricStack({
                    stack: null,
                    breadcrumbStack: null,
                    activeMetric: null,
                    isOffTreeMetric: false,
                });
            }, transitionWait);
        },
        [transitionWait, metricTree, primaryPosition, onMetricContextChange]
    );

    // Every metric in the main stack must be available for the player's position
    const metricStackValidForPosition = useMemo(
        () =>
            metricStack.stack &&
            metricStack.stack.every(
                (metricName) =>
                    metricTree[metricName].positionScope &&
                    metricTree[metricName].positionScope.includes(
                        primaryPosition
                    )
            ),
        [metricStack.stack, metricTree, primaryPosition]
    );

    useEffect(() => {
        if (primaryPosition) {
            // If any metric in the main stack is not available for the player's
            // position, then we reset the stack to Player Index.
            if (!metricStackValidForPosition) {
                const newContext = {
                    stack: [rootMetric],
                    breadcrumbStack: [rootMetric],
                    activeMetric: rootMetric,
                    isOffTreeMetric: false,
                    isCompositeMetric:
                        metricTree[rootMetric].componentMetrics.filter(
                            (componentMetric) => {
                                return metricTree[
                                    componentMetric
                                ].positionScope.includes(primaryPosition);
                            }
                        ).length > 0,
                };

                setMetricStack(newContext);
                onMetricContextChange && onMetricContextChange(newContext);
                onStackResetHandler && onStackResetHandler(rootMetric);
            }

            // Validate that the isComposite flag is still valid for the new position
            else if (
                metricTree[metricStack.stack[metricStack.stack.length - 1]]
            ) {
                const newIsCompositeMetric =
                    metricTree[
                        metricStack.stack[metricStack.stack.length - 1]
                    ].componentMetrics.filter((componentMetric) => {
                        return metricTree[
                            componentMetric
                        ].positionScope.includes(primaryPosition);
                    }).length > 0;

                if (metricStack.isCompositeMetric !== newIsCompositeMetric) {
                    setMetricStack((currentCtx) => {
                        const newContext = {
                            ...currentCtx,
                            isCompositeMetric: newIsCompositeMetric,
                        };
                        onMetricContextChange &&
                            onMetricContextChange(newContext);

                        return newContext;
                    });
                }
            }
        }
    }, [
        primaryPosition,
        metricStack.stack,
        metricStack.isCompositeMetric,
        metricStackValidForPosition,
        metricTree,
        rootMetric,
        onMetricContextChange,
        onStackResetHandler,
    ]);

    return {
        metricStack,
        transitionMetricStack,
        initiateSingleMetricTransition,
        initiateFullMetricStackTransition,
        setPrimaryPosition,
    };
};

export default useMetricsDrilldownBreadcrumbManager;
