import { NumericIndicator } from "../../indicators";
import {
    PlayerScoutingProjectionPill,
} from "../../playertile/Playertile";
import { LegendSection, LegendItem } from "../../../controls/Legend";

const ScoutingLegendSection = () => {
    return (
        <LegendSection
            title="Scouting"
            description="Scouting summary indicators. NHL projection, and combine data summary when available."
        >
            <LegendItem
                symbol={
                    <PlayerScoutingProjectionPill scoutingProjection="D8" />
                }
                symbolWidth="80px"
                description="Consensus projection from scouts. Can range from F1-F15, D1-D10, G1-G5."
            />
            <LegendItem
                symbol={
                    <NumericIndicator
                        value={"#.#"}
                        label={"ohs"}
                        isExample={true}
                    />
                }
                symbolWidth="80px"
                description="Offensive Hockey Sense, average of scouts' ratings. Values range from 1.0 to 5.0."
            />
            <LegendItem
                symbol={
                    <NumericIndicator
                        value={"#.#"}
                        label={"sk"}
                        isExample={true}
                    />
                }
                symbolWidth="80px"
                description="Skating, average of scouts' ratings. Values range from 1.0 to 5.0."
            />
            <LegendItem
                symbol={
                    <NumericIndicator
                        value={"#.##"}
                        label={"tas"}
                        isExample={true}
                    />
                }
                symbolWidth="80px"
                description="Combine data summary, if available. Consists in the Total Athleticism Score (coloured according to percentile rank), and actual rank if top 10."
            />
        </LegendSection>
    );
};

export default ScoutingLegendSection;
