import { uiActions } from "./ui-slice";
import { fetchData } from "../utils";
import { seasonsActions } from "./seasons-slice";

let ongoingRequests = {
    fetchSeasons: null, // This will be either null or a Promise
};

// Fetches all seasons from the API
export const fetchSeasons = () => {
    return async (dispatch) => {
        if (!ongoingRequests.fetchSeasons) {
            ongoingRequests.fetchSeasons = fetchData(
                process.env.REACT_APP_HABSHUB_API_BASE_URL +
                    "hockey_analytics/seasons/",
                "Could not fetch seasons data!"
            )
                .then((seasonsData) => {
                    dispatch(seasonsActions.updateSeasons(seasonsData));
                    ongoingRequests.fetchSeasons = null;
                    return seasonsData;
                })
                .catch((error) => {
                    dispatch(
                        uiActions.showNotification({
                            status: "error",
                            title: "Error!",
                            message: "Fetching all seasons data failed!",
                        })
                    );
                    ongoingRequests.fetchSeasons = null;
                    throw error;
                });
        }

        return ongoingRequests.fetchSeasons;
    };
};
