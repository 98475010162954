export const LAYOUT_LIMITS = {
    HEIGHT: {
        SHORT: 835,
        IPAD_MIN: 1112,
        IPHONE_MIN: 812,
    },
    WIDTH: {
        NARROW: 568,
        WIDE: 992,
        IPAD_MIN: 810,
        IPHONE_MIN: 375,
    },
};
