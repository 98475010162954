import { PercentileClassIndicator, NumericIndicator } from "../../indicators";
import { LegendSection, LegendItem } from "../../../controls/Legend";

const SkatersIndicatorsSection = ({ tradStats = false }) => {
    return (
        <LegendSection title="Skaters Indicators">
            {tradStats && (
                <>
                    <LegendItem
                        symbol={
                            <NumericIndicator
                                value={"##"}
                                label={"gp"}
                                isExample={true}
                            />
                        }
                        symbolWidth="80px"
                        description="Number of games played in current league."
                    />
                    <LegendItem
                        symbol={
                            <>
                                <NumericIndicator
                                    value={"#"}
                                    label={"g"}
                                    isExample={true}
                                />
                                <NumericIndicator
                                    value={"#"}
                                    label={"a"}
                                    isExample={true}
                                />
                                <NumericIndicator
                                    value={"#"}
                                    label={"p"}
                                    isExample={true}
                                />
                            </>
                        }
                        symbolWidth="80px"
                        description="Number of goals, assists and points in current league."
                    />
                    <LegendItem
                        symbol={
                            <NumericIndicator
                                value={"#"}
                                label={"±"}
                                isExample={true}
                            />
                        }
                        symbolWidth="80px"
                        description="Plus / minus of player in current league."
                    />
                    <LegendItem
                        symbol={
                            <NumericIndicator
                                value={"#"}
                                label={"pim"}
                                isExample={true}
                            />
                        }
                        symbolWidth="80px"
                        description="Penalty minutes in current league."
                    />
                </>
            )}
            {!tradStats && (
                <>
                    <LegendItem
                        symbol={
                            <div
                                style={{
                                    marginLeft: "-7px",
                                    marginRight: "-10px",
                                }}
                            >
                                <NumericIndicator
                                    value={"#.##"}
                                    percentile={0.5}
                                    isExample={true}
                                />
                            </div>
                        }
                        description="Points per 60, all MP."
                    />
                    <LegendItem
                        symbol={
                            <PercentileClassIndicator
                                value={0.5}
                                label={"o"}
                                diff_value={0.5}
                                decorator={{
                                    value: true,
                                    label: "*",
                                }}
                                isExample={true}
                            />
                        }
                        description="Offensive performance: ES 5v5 offense generating plays. * indicates the player outproduced his usage offensively."
                    />
                    <LegendItem
                        symbol={
                            <PercentileClassIndicator
                                value={0.5}
                                label={"d"}
                                diff_value={0.5}
                                decorator={{
                                    value: true,
                                    label: "*",
                                }}
                                isExample={true}
                            />
                        }
                        description="Defensive performance: ES 5v5 offense generating plays against allowed while on ice. * indicates the player outperformed his usage defensively."
                    />
                    <LegendItem
                        symbol={
                            <div style={{ marginLeft: "8px" }}>
                                <PercentileClassIndicator
                                    value={0.6}
                                    diff_value={0.5}
                                    isExample={true}
                                />
                            </div>
                        }
                        description="Consistency: a border around the performance indicator shows that the player has a high / low percentage of quality starts compared to his general performance."
                    />
                    <LegendItem
                        symbol={
                            <PercentileClassIndicator
                                label={"m"}
                                isExample={true}
                            />
                        }
                        description="Player matchup strength, based on strength of oppostion differential. It goes from red (most difficult) to yellow (easiest)"
                    />
                    <LegendItem
                        symbol={
                            <PercentileClassIndicator
                                label={"z"}
                                isExample={true}
                            />
                        }
                        description="Player zone start usage. It goes from red (highest DZ starts percentage) to yellow (highest OZ starts percentage)."
                    />
                </>
            )}
            <LegendItem
                symbol={
                    <img
                        src="/static/static/dashboards/5pillar.png"
                        alt="5 pillar player icon"
                    ></img>
                }
                description="5-pillar player."
            />
            <LegendItem
                symbol={
                    <img
                        src="/static/static/dashboards/4pillar.png"
                        alt="4 pillar player icon"
                    ></img>
                }
                description="4-pillar player."
            />
        </LegendSection>
    );
};

export default SkatersIndicatorsSection;
