import classes from "./indicators.module.css";
import { getPerformanceTierFromPercentile, formatValue } from "../utils";

const getPercentileClass = (percentile) => {
    var tier_class_name = classes.no_tier;

    if (percentile !== null) {
        tier_class_name =
            classes[
                getPerformanceTierFromPercentile(percentile).tier + "_tier"
            ];
    }

    return tier_class_name;
};

const DotIndicator = ({ small = false, additionalClasses }) => {
    return (
        <div
            className={
                classes.dot_indicator +
                (small ? " " + classes.small_dot : "") +
                " " +
                additionalClasses
            }
        ></div>
    );
};

const LateralityIndicator = ({ label }) => {
    return <div className={classes.laterality_indicator}>{label}</div>;
};

export const LeftLateralityIndicator = () => {
    return <LateralityIndicator label="L" />;
};

export const RightLateralityIndicator = () => {
    return <LateralityIndicator label="R" />;
};

export const ContractClauseDotIndicator = ({ clause, inverseColors }) => {
    return (
        <DotIndicator
            small={true}
            additionalClasses={
                classes[clause + "_clause"] +
                (inverseColors ? " " + classes.inverse : "")
            }
        />
    );
};

// StatusValueIndicator
const StatusValueIndicator = ({
    statusValue,
    small = false,
    flipped = false,
    isExample,
}) => {
    const tierClass =
        statusValue &&
        (statusValue.level === "ok"
            ? classes.ok_status_level
            : statusValue.level === "warning"
            ? classes.warning_status_level
            : classes.not_ok_status_level);

    const displayValue = tierClass ? " " : "-";
    const percentileClass = isExample ? classes.example : tierClass;

    const indicatorContent = (displayValue, percentileClass) => {
        return (
            <>
                <span className={classes.indicator_label}>
                    {statusValue && statusValue.label}{" "}
                </span>
                <div
                    className={classes.single_indicator + " " + percentileClass}
                >
                    {displayValue}
                </div>
            </>
        );
    };

    return (
        <div
            className={
                classes.indicator +
                " " +
                classes.status_value_indicator +
                (!small ? " " + classes.large : "") +
                (flipped ? " " + classes.flipped : "") +
                " " +
                classes.percentile_class_indicator
            }
        >
            {displayValue && indicatorContent(displayValue, percentileClass)}
        </div>
    );
};

// PercentileClassIndicator
const PercentileClassIndicator = ({
    value,
    label,
    decorator,
    diff_value,
    inverseColors,
    isExample,
    pillLayout = false,
}) => {
    const displayValue = value ? " " : "-";
    const percentileClass = isExample
        ? classes.example
        : getPercentileClass(value);
    const diffPercentileClass =
        diff_value !== undefined
            ? isExample
                ? classes.example
                : getPercentileClass(diff_value)
            : null;

    const diffPercentileExample =
        isExample &&
        diff_value &&
        getPercentileClass(value) !== getPercentileClass(diff_value);

    const indicatorContent = (
        displayValue,
        percentileClass,
        diffPercentileClass,
        decorator,
        pillLayout
    ) => {
        if (diffPercentileClass) {
            return (
                <div className={classes.decorated_indicator}>
                    <span className={classes.indicator_label}>{label}</span>
                    <div
                        className={
                            classes.consistency_indicator +
                            " " +
                            (diffPercentileExample ||
                            percentileClass !== diffPercentileClass
                                ? diffPercentileClass
                                : classes.no_tier)
                        }
                    >
                        <div
                            className={
                                classes.single_indicator + " " + percentileClass
                            }
                        >
                            {displayValue}
                        </div>
                    </div>
                    {decorator && (
                        <div className={classes.decorator}>
                            {decorator.value && decorator.label}
                        </div>
                    )}
                </div>
            );
        } else {
            return (
                <>
                    <span className={classes.indicator_label}>{label} </span>
                    <div
                        className={
                            classes.single_indicator +
                            " " +
                            percentileClass +
                            (pillLayout ? " " + classes.pill_layout : "")
                        }
                    >
                        {displayValue}
                    </div>
                </>
            );
        }
    };

    return (
        <div
            className={
                classes.indicator +
                " " +
                classes.percentile_class_indicator +
                (inverseColors ? " " + classes.inverse : "")
            }
        >
            {displayValue &&
                indicatorContent(
                    displayValue,
                    percentileClass,
                    diffPercentileClass,
                    decorator,
                    pillLayout
                )}
        </div>
    );
};

// NumericIndicator
const NumericIndicator = ({
    value,
    percentile,
    precision,
    fixedWidth,
    canBeNegative,
    label,
    inverseColors,
    isExample,
    isWarning,
}) => {
    const displayValue = formatValue(value, precision, false);
    const percentileClass = isExample
        ? classes.example
        : isWarning
        ? classes.warning
        : percentile
        ? getPercentileClass(percentile)
        : classes.no_tier;
    const widthClass = !fixedWidth
        ? ""
        : [3, "3"].includes(precision)
        ? classes.four_wide
        : canBeNegative && [2, "2"].includes(precision)
        ? classes.threeplus_wide
        : [2, "2"].includes(precision)
        ? classes.three_wide
        : canBeNegative && precision === "00"
        ? classes.twoplus_wide
        : precision === "00"
        ? classes.two_wide
        : canBeNegative && precision === "0"
        ? classes.oneplus_wide
        : precision === "0"
        ? classes.one_wide
        : "";

    return (
        <div
            className={
                classes.indicator +
                " " +
                classes.numeric +
                (inverseColors ? " " + classes.inverse : "")
            }
        >
            {label && <div className={classes.indicator_label}>{label}</div>}
            {displayValue && (
                <div
                    className={
                        classes.numericmetric +
                        " " +
                        percentileClass +
                        " " +
                        widthClass
                    }
                >
                    {displayValue}
                </div>
            )}
        </div>
    );
};

// NumericButtonIndicator
const NumericButtonIndicator = ({
    value,
    label,
    precision,
    isPercentage = false,
    color = "var(--tw-gray-500)",
    distinctLabelColor = null,
    bgColor,
    tight = false,
    small = false,
    onClick,
}) => {
    const labelColor = distinctLabelColor ? distinctLabelColor : color;
    const displayValue = formatValue(value, precision, isPercentage);

    return (
        <div
            className={
                classes.numericbutton +
                (tight ? " " + classes.tight : "") +
                (tight && !bgColor ? " " + classes.no_fill : "") +
                (small ? " " + classes.small : "") +
                (onClick ? " " + classes.clickable : "")
            }
            style={{
                backgroundColor: bgColor ? bgColor : "#fff",
                color: color,
            }}
        >
            {label && (
                <div
                    className={classes.numericbuttonlabel}
                    style={{
                        color: labelColor,
                    }}
                >
                    {label}
                </div>
            )}
            {displayValue && (
                <div
                    className={
                        classes.numericbuttonmetric +
                        (label || small ? "" : " " + classes.big)
                    }
                >
                    {displayValue}
                </div>
            )}
        </div>
    );
};

// TieredNumericButtonIndicator
const TieredNumericButtonIndicator = ({
    value,
    label,
    percentile,
    tierClass,
    precision,
    isPercentage = false,
    inverseColors,
    onClick,
    isExample,
}) => {
    const displayValue = formatValue(value, precision, isPercentage);
    const percentileClass = isExample
        ? classes.example
        : tierClass
        ? classes[tierClass + "_tier"]
        : getPercentileClass(percentile);

    return (
        <div
            className={
                classes.numericbutton +
                (inverseColors ? " " + classes.inverse : "") +
                " " +
                percentileClass +
                (onClick ? " " + classes.clickable : "")
            }
        >
            {label && <div className={classes.numericbuttonlabel}>{label}</div>}
            {displayValue && (
                <div
                    className={
                        classes.numericbuttonmetric +
                        (label || displayValue.length > 5
                            ? ""
                            : " " + classes.big)
                    }
                >
                    {displayValue}
                </div>
            )}
        </div>
    );
};

// IndicatorGroup
const IndicatorGroup = ({
    consistency_percentile,
    indicators,
    inverseColors,
    isExample,
}) => {
    const percentileClass = isExample
        ? classes.example
        : getPercentileClass(consistency_percentile);

    return (
        <div
            className={
                classes.indicator +
                " " +
                classes.indicator_group +
                (inverseColors ? " " + classes.inverse : "")
            }
        >
            <div
                className={
                    classes.consistency_indicator + " " + percentileClass
                }
            >
                {indicators.map((indicator) => {
                    return (
                        <div
                            className={classes.inner_indicator}
                            key={indicator.label}
                        >
                            <PercentileClassIndicator
                                value={indicator.value}
                                label={indicator.label}
                                isExample={isExample}
                            />
                        </div>
                    );
                })}
            </div>
        </div>
    );
};

export {
    StatusValueIndicator,
    PercentileClassIndicator,
    NumericIndicator,
    NumericButtonIndicator,
    TieredNumericButtonIndicator,
    IndicatorGroup,
};
