import classes from "./Toggle.module.css";

const Toggle = ({ id, isChecked, onToggle, disabled }) => {
    return (
        <label
            className={
                classes.switch + (disabled ? " " + classes.disabled : "")
            }
        >
            <input
                id={id}
                type="checkbox"
                checked={isChecked}
                onChange={onToggle}
                disabled={disabled}
            />
            <span className={classes.slider + " " + classes.round}></span>
        </label>
    );
};

export default Toggle;
