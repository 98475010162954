import React, { useState, useEffect } from "react";
import classes from "./OrgHeader.module.css";
import MainHeader from "./MainHeader";
import { LAYOUT_LIMITS } from "./constants";

function OrgHeader({
    fullTitle,
    mediumTitle,
    shortTitle,
    bgColor,
    imgUrl,
    imgPosition,
    onOpen,
    backLink,
    parentWidth,
    parentHeight,
}) {
    const [widthLayout, setWidthLayout] = useState("");

    useEffect(() => {
        if (parentWidth < LAYOUT_LIMITS.WIDTH.NARROW) {
            setWidthLayout(classes.narrow);
        } else if (parentHeight < LAYOUT_LIMITS.HEIGHT.SHORT) {
            setWidthLayout(classes.wide_short);
        } else if (
            parentWidth >= LAYOUT_LIMITS.WIDTH.WIDE &&
            parentHeight >= LAYOUT_LIMITS.HEIGHT.SHORT
        ) {
            setWidthLayout(classes.wide);
        } else {
            setWidthLayout("");
        }
    }, [parentWidth, parentHeight]);

    return (
        <MainHeader
            bgColor={bgColor}
            onOpen={onOpen}
            backLink={backLink}
            parentWidth={parentWidth}
            parentHeight={parentHeight}
        >
            <div className={`${classes.org_content_header} ${widthLayout}`}>
                <img
                    className={classes.org_content_header_logo}
                    src={imgUrl}
                    alt="Org logo"
                    style={imgPosition ? { objectPosition: imgPosition } : {}}
                />
                <div
                    className={
                        classes.org_content_header_title + " " + classes.full
                    }
                >
                    {fullTitle}
                </div>
                <div
                    className={
                        classes.org_content_header_title + " " + classes.medium
                    }
                >
                    {mediumTitle ? mediumTitle : fullTitle}
                </div>
                <div
                    className={
                        classes.org_content_header_title + " " + classes.mobile
                    }
                >
                    {shortTitle ? shortTitle : fullTitle}
                </div>
            </div>
        </MainHeader>
    );
}

export default OrgHeader;
