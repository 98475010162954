import { useNavigate } from "react-router-dom";
import classes from "./BackLinkButton.module.css";

const BackLinkButton = ({ link, darkMode = false }) => {
    const navigate = useNavigate();
    return (
        <div
            className={
                classes.back_link_button + (darkMode ? " " + classes.dark : "")
            }
            onClick={() => {
                navigate(link);
            }}
        >
            <span className="material-symbols-outlined">arrow_back</span>
        </div>
    );
};

export default BackLinkButton;
