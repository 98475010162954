import { useState } from "react";
import { Modal } from "./Modal";
import classes from "./HelpModal.module.css";

const HelpModal = ({ children, zIndex = 1000 }) => {
    const [isOpen, setIsOpen] = useState(false);

    return (
        <div className={classes.container}>
            <button
                className={classes.helpButton}
                style={{ zIndex: zIndex }}
                onClick={() => setIsOpen(true)}
            >
                <div className={classes.button_label}>?</div>
            </button>
            <Modal
                isOpen={isOpen}
                onClose={() => setIsOpen(false)}
                zIndex={zIndex}
            >
                {children}
            </Modal>
        </div>
    );
};

export default HelpModal;
