import { useState, useEffect, useCallback } from "react";
import useHttp from "./use-http";
import { useDispatch } from "react-redux";
import { fetchSystemConfiguration, fetchUserSettings } from "../store/ui-slice";

const COOLDOWN_PERIOD = 30000; // 30 seconds

// Shared state and refs across all hook instances
const globalState = {
    user: null,
    authChecked: false,
    isCheckingAuth: false,
    lastCheckTime: 0,
    checkAuthPromise: null,
};

function useAuth() {
    const dispatch = useDispatch();
    const { error, sendRequest } = useHttp();
    const [, forceRender] = useState({});

    const isAuthenticated = !error && globalState.user !== null;

    const checkAuth = useCallback(() => {
        const currentTime = Date.now();
        if (
            globalState.isCheckingAuth ||
            currentTime - globalState.lastCheckTime < COOLDOWN_PERIOD
        ) {
            return globalState.checkAuthPromise || Promise.resolve();
        }

        globalState.isCheckingAuth = true;
        globalState.lastCheckTime = currentTime;

        globalState.checkAuthPromise = new Promise((resolve) => {
            sendRequest(
                {
                    url:
                        process.env.REACT_APP_HABSHUB_API_BASE_URL +
                        "users/me/",
                },
                (userData, err) => {
                    if (err) {
                        window.location.href =
                            process.env.REACT_APP_HABSHUB_WEB_BASE_URL +
                            "accounts/login/"; // Redirect to Django login page
                        return null;
                    }

                    globalState.user = userData;
                    globalState.authChecked = true;
                    globalState.isCheckingAuth = false;
                    globalState.checkAuthPromise = null;
                    forceRender({}); // Trigger re-render in all instances

                    if (!err) {
                        dispatch(fetchSystemConfiguration());
                        dispatch(fetchUserSettings());
                    }

                    resolve();
                }
            );
        });

        return globalState.checkAuthPromise;
    }, [sendRequest, dispatch]);

    // Trigger authentication check on mount if not checked
    useEffect(() => {
        const currentTime = Date.now();
        if (
            (!globalState.authChecked ||
                currentTime - globalState.lastCheckTime > COOLDOWN_PERIOD) &&
            !globalState.isCheckingAuth
        ) {
            checkAuth();
        }
    }, [checkAuth]);

    const checkPermission = useCallback((permission) => {
        if (globalState.user) {
            return (
                globalState.user.permissions.includes(permission) ||
                globalState.user.is_superuser ||
                globalState.user.is_staff
            );
        }
        return false;
    }, []);

    return {
        isAuthenticated,
        isLoading: globalState.isCheckingAuth,
        authChecked: globalState.authChecked,
        user: globalState.user,
        checkPermission,
    };
}

export default useAuth;
