import { useMemo, useState, useRef, useCallback, useEffect } from "react";
import { useSelector } from "react-redux";
import classes from "./PlayerDevelopmentPriorityView.module.css";
import { timeSince, shortDateString, wasEdited } from "../utils";
import IconButton from "../controls/buttons/IconButton";
import { PlayerIndexMetrics } from "../constants";
import PlayerMetricSeasonTrendChart from "./PlayerMetricSeasonTrendChart";
import ExpandableSection from "../layout/ExpandableSection";

function PlayerDevelopmentPriorityView({
    developmentPriority,
    isSelected,
    sessions,
    editClickHandler,
    closeClickHandler,
    selectClickHandler,
    addSessionClickHandler,
    addNoteClickHandler,
    expandMetricClickHandler,
}) {
    const players = useSelector((state) => state.players.players);
    const player = players && players[developmentPriority.player_id];
    const chartMargins = useMemo(() => {
        return [
            10,
            20,
            24,
            developmentPriority &&
            developmentPriority.performance_metric_name !== "" &&
            PlayerIndexMetrics[developmentPriority.performance_metric_name]
                .isPercentage
                ? 43
                : 38,
        ];
    }, [developmentPriority]);

    const defaultChartWidth = 480;
    const defaultChartHeight = 220;
    const defaultMargins = 80;

    const [chartWidth, setChartWidth] = useState(defaultChartWidth);
    const [chartHeight, setChartHeight] = useState(defaultChartHeight);

    const containerRef = useRef(null);

    const checkWidth = useCallback(() => {
        const width = containerRef.current?.offsetWidth;
        setChartWidth(
            width - defaultMargins >= defaultChartWidth
                ? defaultChartWidth
                : Math.max(0, width - defaultMargins)
        );
        setChartHeight(defaultChartHeight);
    }, [
        containerRef,
        setChartWidth,
        setChartHeight,
        defaultChartWidth,
        defaultChartHeight,
        defaultMargins,
    ]);

    useEffect(() => {
        if (containerRef.current) {
            const resizeObserver = new ResizeObserver(() => {
                checkWidth();
            });
            resizeObserver.observe(containerRef.current);

            // Call once on mount to set initial sizes
            checkWidth();

            return () => {
                resizeObserver.disconnect();
            };
        }
    }, [checkWidth]);

    const seasons = useSelector((state) => state.seasons.seasons);
    const developmentPrioritySeason =
        seasons && seasons[developmentPriority.season_id];

    const minDate = useMemo(() => {
        // Find the earliest session date or the developmentPriority start date.
        // If that is earlier than sept 1 of the season start year,
        // find the start of the month of the earliest date.
        const seasonStartYear =
            developmentPrioritySeason &&
            developmentPrioritySeason.start_year;
        const seasonStartDate = new Date(seasonStartYear + "-09-01");

        const earliestDate = sessions
            ? new Date(
                  Math.min(
                      ...sessions.map((session) => new Date(session.date)),
                      new Date(developmentPriority.start_date)
                  )
              )
            : new Date(developmentPriority.start_date);

        return earliestDate < seasonStartDate
            ? new Date(earliestDate.getFullYear(), earliestDate.getMonth(), 1)
            : null;
    }, [developmentPrioritySeason, sessions, developmentPriority]);

    const devEvents = useMemo(() => {
        const events = [];
        events.push({
            date: new Date(developmentPriority.start_date),
            event_type: "development_priority_start",
        });

        if (developmentPriority.end_date) {
            events.push({
                date: new Date(developmentPriority.end_date),
                event_type: "development_priority_end",
            });
        }

        if (sessions) {
            sessions.forEach((session) => {
                events.push({
                    date: new Date(session.date),
                    event_type: "session",
                });
            });
        }

        return events;
    }, [developmentPriority, sessions]);

    return (
        <div className={classes.player_development_priority_container}>
            <div className={classes.player_development_priority_content}>
                <div className={classes.player_development_priority_details}>
                    <div className={classes.player_development_priority_header}>
                        <div
                            className={
                                classes.player_development_priority_title
                            }
                        >
                            {developmentPriority.title}
                        </div>
                        <div
                            className={
                                classes.player_development_priority_summary
                            }
                        >
                            <div
                                className={
                                    classes.player_development_priority_stats
                                }
                            >
                                <div
                                    className={
                                        classes.player_development_priority_stat_line
                                    }
                                >
                                    Started
                                    {" " +
                                        shortDateString(
                                            developmentPriority.start_date
                                        ) +
                                        " • " +
                                        timeSince(
                                            developmentPriority.start_date
                                        ) +
                                        wasEdited(
                                            developmentPriority.created_at,
                                            developmentPriority.modified_at
                                        )}
                                </div>
                                {developmentPriority.end_date && (
                                    <div
                                        className={
                                            classes.player_development_priority_stat_line
                                        }
                                    >
                                        {"Ended " +
                                            shortDateString(
                                                developmentPriority.end_date
                                            ) +
                                            " • " +
                                            timeSince(
                                                developmentPriority.end_date
                                            )}
                                    </div>
                                )}
                                <div
                                    className={
                                        classes.player_development_priority_stat_line
                                    }
                                >
                                    {sessions && sessions.length > 0 && (
                                        <div>
                                            {sessions.length +
                                                " session" +
                                                (sessions.length > 1
                                                    ? "s"
                                                    : "")}
                                        </div>
                                    )}
                                    {(!sessions || sessions.length === 0) && (
                                        <div>No sessions</div>
                                    )}
                                </div>
                            </div>
                            {selectClickHandler && (
                                <div className={classes.filter_sessions_button}>
                                    <div className={classes.control}>
                                        <IconButton
                                            isSelected={isSelected}
                                            isDisabled={
                                                !sessions ||
                                                sessions.length === 0
                                            }
                                            materialIcon={"visibility"}
                                            onClick={() => {
                                                selectClickHandler(
                                                    developmentPriority
                                                );
                                            }}
                                        />
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                    <div className={classes.player_development_priority_text}>
                        {developmentPriority.description}
                    </div>
                    {developmentPriority.performance_metric_name !== "" && (
                        <ExpandableSection
                            label={
                                PlayerIndexMetrics[
                                    developmentPriority.performance_metric_name
                                ].label
                            }
                            onExpandToggle={expandMetricClickHandler}
                            border={true}
                        >
                            <div
                                className={
                                    classes.player_development_priority_chart_container
                                }
                                ref={containerRef}
                            >
                                <PlayerMetricSeasonTrendChart
                                    playerId={developmentPriority.player_id}
                                    competitionId={
                                        developmentPriority.competition_id
                                    }
                                    currentTeamId={
                                        developmentPriority.competition_id === 1
                                            ? player?.nhl_rights_owner_team_id
                                            : developmentPriority.competition_id ===
                                              2
                                            ? player?.ahl_contract_team_id
                                            : null
                                    }
                                    seasonId={developmentPriority.season_id}
                                    metric={
                                        developmentPriority.performance_metric_name
                                    }
                                    width={chartWidth}
                                    height={chartHeight}
                                    margin={chartMargins}
                                    autoScaleYAxis={
                                        developmentPriority &&
                                        PlayerIndexMetrics[
                                            developmentPriority
                                                .performance_metric_name
                                        ].autoScale
                                    }
                                    autoScaleYAxisMax={
                                        developmentPriority &&
                                        PlayerIndexMetrics[
                                            developmentPriority
                                                .performance_metric_name
                                        ].autoScaleMax
                                    }
                                    isPercentage={
                                        developmentPriority &&
                                        PlayerIndexMetrics[
                                            developmentPriority
                                                .performance_metric_name
                                        ].isPercentage
                                    }
                                    developmentEvents={devEvents}
                                    minDate={minDate}
                                    border={false}
                                />
                            </div>
                        </ExpandableSection>
                    )}
                </div>
            </div>
            {developmentPriority && (
                <div
                    className={
                        classes.player_development_priority_controls_container
                    }
                >
                    <div className={classes.controls_group}>
                        {closeClickHandler && (
                            <div className={classes.control}>
                                <IconButton
                                    label={"Complete"}
                                    materialIcon={"check_circle"}
                                    onClick={() => {
                                        closeClickHandler(developmentPriority);
                                    }}
                                />
                            </div>
                        )}
                        {editClickHandler && (
                            <div className={classes.control}>
                                <IconButton
                                    label={"Edit"}
                                    materialIcon={"edit"}
                                    onClick={() => {
                                        editClickHandler(developmentPriority);
                                    }}
                                />
                            </div>
                        )}
                    </div>
                    <div className={classes.controls_group}>
                        {addSessionClickHandler && (
                            <div className={classes.control}>
                                <IconButton
                                    label="Session"
                                    materialIcon={"calendar_add_on"}
                                    onClick={() => {
                                        addSessionClickHandler(
                                            developmentPriority
                                        );
                                    }}
                                />
                            </div>
                        )}
                        {addNoteClickHandler && (
                            <div className={classes.control}>
                                <IconButton
                                    label="Note"
                                    materialIcon={"note_add"}
                                    onClick={() => {
                                        addNoteClickHandler(
                                            developmentPriority
                                        );
                                    }}
                                />
                            </div>
                        )}
                    </div>
                </div>
            )}
        </div>
    );
}

export default PlayerDevelopmentPriorityView;
