import { TrendIndicator } from "../../../controls/DirectionalMarkerIndicators";
import { LegendSection, LegendItem } from "../../../controls/Legend";

const PerformanceTrendsSection = () => {
    return (
        <LegendSection
            title="Performance Trends"
            description="Performance trends show how the player is trending overall (over the last 5 games), compared to the entire season. For skaters, the overall trend is based on the pillars, so it's a combinations of slot shots/plays, possession driving plays, puck recoveries, and defensive touches. For goalies, it is based on actual goals allowed to expected."
        >
            <LegendItem
                symbol={<TrendIndicator size={12} isTrendingUp={true} />}
                description="Player is trending up over the last 5 games."
            />
            <LegendItem
                symbol={<TrendIndicator size={12} isTrendingUp={false} />}
                description="Player is trending down over the last 5 games."
            />
        </LegendSection>
    );
};

export default PerformanceTrendsSection;
