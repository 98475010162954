import { useSelector } from "react-redux";
import classes from "./PlayerSearchResultsList.module.css";
import { get_age_at_date } from "../utils";
import { mp_track } from "../mixpanel";

const player_search_result_list_mp_track = (player, query, event, properties = null) => {
    properties = {
        ...properties,
        query: query,
        player_id: player.id,
        player_name: player.known_name,
    };

    mp_track(event, properties);
};

const PlayerSearchResultsList = ({
    query,
    searchResults,
    maxListLength,
    onResultSelected,
    onViewAllSelected,
    lightMode = false,
}) => {
    const teams = useSelector((state) => state.teams.teams);
    const competitions = useSelector(
        (state) => state.competitions.competitions
    );

    const handleSearchResultSelected = (result) => {
        player_search_result_list_mp_track(result, query, "Player Search Result Selected");
        onResultSelected && onResultSelected(result.id)
    }

    // Keyboard navigation for search results
    const handleKeyDown = (event, result) => {
        if (event.key === "Enter") {
            handleSearchResultSelected(result);
        }
    };

    const now = new Date(Date.now());
    const listLength = maxListLength || searchResults.length;

    return (
        <div className={classes.resultsList}>
            {searchResults.slice(0, listLength).map((result, index) => {
                const player_dob = new Date(
                    Date.parse(result.birth_date + "T00:00:00")
                );
                result.age = get_age_at_date(player_dob, now);
                return (
                    <div
                        key={result.id}
                        tabIndex={0}
                        className={
                            classes.search_result_item +
                            (lightMode ? " " + classes.light_mode : "")
                        }
                        onClick={() =>
                            handleSearchResultSelected(result)
                        }
                        onKeyDown={(event) => handleKeyDown(event, result)}
                        role="button"
                        aria-pressed="false"
                    >
                        <div className={classes.playerDetails}>
                            <div className={classes.playerName}>
                                {result.known_name}
                            </div>
                            <div className={classes.playerBio}>
                                {result.position +
                                    " - " +
                                    result.birth_date +
                                    " (" +
                                    result.age +
                                    "yo)"}
                            </div>
                        </div>
                        <div className={classes.iconDecorator}>
                            {result.nhl_rights_owner_team_id && (
                                <img
                                    alt="NHL Rights Owner Team Logo"
                                    className={
                                        result.is_on_active_roster
                                            ? classes.active
                                            : classes.inactive
                                    }
                                    src={
                                        teams[result.nhl_rights_owner_team_id]
                                            .logo_url
                                    }
                                />
                            )}
                            {!result.nhl_rights_owner_team_id &&
                                result.has_nhl_experience && (
                                    <img
                                        alt="NHL Experience Logo"
                                        src={competitions[1].logo_url}
                                    />
                                )}
                            {!result.nhl_rights_owner_team_id &&
                                result.is_draft_eligible && (
                                    <img
                                        alt="Draft Eligible Logo"
                                        src={
                                            "/static/static/dashboards/2024_NHL_entry_draft_logo.png"
                                        }
                                    />
                                )}
                            {!result.nhl_rights_owner_team_id &&
                                !result.has_nhl_experience &&
                                result.has_ahl_experience && (
                                    <img
                                        alt="AHL Experience Logo"
                                        src={competitions[2].logo_url}
                                    />
                                )}
                        </div>
                    </div>
                );
            })}
            {onViewAllSelected &&
                (!listLength || searchResults.length > listLength) && (
                    <div
                        className={
                            classes.search_result_item +
                            " " +
                            classes.view_all +
                            (lightMode ? " " + classes.light_mode : "")
                        }
                        onClick={onViewAllSelected}
                        onKeyDown={(event) => {
                            if (event.key === "Enter") onViewAllSelected();
                        }}
                        tabIndex={0}
                        role="button"
                        aria-pressed="false"
                    >
                        View all results ({searchResults.length})
                    </div>
                )}
        </div>
    );
};

export default PlayerSearchResultsList;
