import classes from "./PlayerSeasonPerformanceView.module.css";
import { useState, useEffect, useMemo, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import { fetchCompetitionSeasonDatastash } from "../../store/competitions-actions.js";
import { fetchPlayerSeasonDatastash } from "../../store/players-actions.js";
import {
    getPrimaryPosition,
    getValuePerformanceTier,
    formatToiSeconds,
    getMetricContent,
} from "../../utils";
import { TieredNumericButtonIndicator } from "../indicators.js";
import VerticalBarIndicator from "../../controls/VerticalBarIndicator";
import VerticalSliderIndicator from "../../controls/VerticalSliderIndicator";
import vertical_bar_classes from "../../controls/VerticalBarIndicator.module.css";
import PlayerMetricSeasonTrendChart from "../PlayerMetricSeasonTrendChart";
import PlayerMetricCareerProgressionChart from "../PlayerMetricCareerProgressionChart";
import useMetricsDrilldownBreadcrumbManager from "../../hooks/use-metrics-drilldown-breadcrumb-manager";
import { PlayerIndexMetrics, MIN_ES_5V5_THRESHOLD } from "../../constants";
import useTradeMarkersManager from "../../hooks/use-trade-markers-manager";
import MetricTopPlayersList from "../MetricTopPlayersList";

import { mp_track } from "../../mixpanel";
import MainBox from "../../layout/MainBox";

const player_season_performance_view_mp_track = (
    event,
    player,
    competition,
    season,
    properties = null
) => {
    properties = {
        ...properties,
        player_id: player.id,
        player_name: player.known_name,
        competition_id: competition.id,
        competition_name: competition.display_name,
        season_id: season.id,
        season_name: season.name,
    };

    mp_track(event, properties);
};

const getMetricContentFromLatest = (metricName, container) => {
    return (
        container &&
        getMetricContent(metricName, container.metrics, container.latest)
    );
};

const getMetricTierClassFromValue = (
    metricName,
    playerValue,
    competitionContainer
) => {
    const competitionValue =
        competitionContainer &&
        getMetricContent(
            metricName,
            competitionContainer.metrics,
            competitionContainer.latest.thresholds
        );

    return (
        competitionContainer &&
        competitionValue &&
        getValuePerformanceTier(
            playerValue,
            competitionValue,
            competitionContainer.tier_categories
        ).tier
    );
};

const getMetricTierClassFromLatest = (
    metricName,
    playerContainer,
    competitionContainer
) => {
    const metricContent = getMetricContentFromLatest(
        metricName,
        playerContainer
    );

    return (
        metricContent &&
        competitionContainer?.latest &&
        getMetricTierClassFromValue(
            metricName,
            metricContent.seasonal,
            competitionContainer
        )
    );
};

const computePeersAverage = (metricName, playersMetrics) => {
    const peersAverage = playersMetrics.reduce(
        (positionAcc, playerAndMetrics) => {
            const playerMetricSeasonal =
                playerAndMetrics.metrics.latest &&
                playerAndMetrics.metrics.metrics.indexOf(metricName) >= 0
                    ? playerAndMetrics.metrics.latest[
                          playerAndMetrics.metrics.metrics.indexOf(metricName)
                      ].seasonal
                    : 0;
            const playerToi = playerAndMetrics.metrics.es_5v5_toi
                ? playerAndMetrics.metrics.es_5v5_toi
                : 0;
            return {
                total: positionAcc.total + playerMetricSeasonal * playerToi,
                toi: positionAcc.toi + playerToi,
            };
        },
        { total: 0, toi: 0 }
    );
    return peersAverage.total / peersAverage.toi;
};

function MetricDrillDownBreadCrumbsIndicator({
    metricStack,
    activeMetric,
    transitionToMetric,
    unselectingAll,
    playerMetrics,
    competitionMetricThresholds,
    onClickHandler,
    inverseColors,
}) {
    const finalStack =
        transitionToMetric && !metricStack.includes(transitionToMetric)
            ? [...metricStack, transitionToMetric]
            : metricStack;
    // If transitionToMetric was already in the stack, then any metric after it in the stack are transitioning
    const transitioningMetrics =
        metricStack &&
        (transitionToMetric && !metricStack.includes(transitionToMetric)
            ? transitionToMetric
            : metricStack.slice(
                  metricStack.indexOf(transitionToMetric) + 1,
                  metricStack.length - 1
              ));

    return (
        <div className={classes.breadcrumb_indicators}>
            {finalStack &&
                finalStack.map((metricName) => {
                    return (
                        <div
                            className={
                                classes.single_indicator_container +
                                " " +
                                classes.breadcrumb_indicator_container +
                                " " +
                                classes.transition_indicator +
                                (transitionToMetric &&
                                transitioningMetrics.includes(metricName)
                                    ? " " + classes.transitioning
                                    : "") +
                                " " +
                                (!unselectingAll &&
                                ((!transitionToMetric &&
                                    activeMetric === metricName) ||
                                    (transitionToMetric &&
                                        transitionToMetric === metricName))
                                    ? classes.highlight
                                    : classes.clickable)
                            }
                            key={metricName}
                            onClick={() => onClickHandler(metricName)}
                        >
                            <div
                                className={
                                    classes.single_indicator_header +
                                    " " +
                                    classes.breadcrumb_indicator_header
                                }
                            >
                                {PlayerIndexMetrics[metricName].label}
                            </div>
                            <TieredNumericButtonIndicator
                                value={
                                    getMetricContentFromLatest(
                                        metricName,
                                        playerMetrics
                                    )?.seasonal
                                }
                                tierClass={getMetricTierClassFromLatest(
                                    metricName,
                                    playerMetrics,
                                    competitionMetricThresholds
                                )}
                                isPercentage={
                                    PlayerIndexMetrics[metricName].isPercentage
                                }
                                precision={
                                    PlayerIndexMetrics[metricName].decimalPlaces
                                }
                                inverseColors={inverseColors}
                            />
                        </div>
                    );
                })}
        </div>
    );
}

const PlayerSeasonPerformanceView = ({
    playerId,
    seasonId,
    competitionId,
    metricContext,
    peersData,
    highlightDates,
    selectedHighlightDate,
    onHighlightDateSelected,
    onSeasonSelectionHandler,
    onMetricContextChange,
    wideLayout = false,
    careerMode = false,
    isLoadingHandler,
}) => {
    const trendsChartsLayoutWidth = 370;
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();

    const defaultMetric = "Player Index";
    const transitionWait = 200;

    const [activePlayerId, setActivePlayerId] = useState(playerId);
    const player = useSelector(
        (state) => state.players.players[activePlayerId]
    );
    const playerPrimaryPosition = player && getPrimaryPosition(player.position);
    const players_sds = useSelector((state) => state.players.seasondatastashes);

    const [seasonTrendLoading, setSeasonTrendLoading] = useState(false);
    const [careerProgressionLoading, setCareerProgressionLoading] =
        useState(false);

    const [activeSeasonId, setActiveSeasonId] = useState(seasonId);
    const season = useSelector(
        (state) => state.seasons.seasons[activeSeasonId]
    );

    const [activeCompetitionId, setActiveCompetitionId] =
        useState(competitionId);
    const competition = useSelector(
        (state) => state.competitions.competitions[activeCompetitionId]
    );
    const competitions_sds = useSelector(
        (state) => state.competitions.seasondatastashes
    );

    const teams = useSelector((state) => state.teams.teams);
    const playerAHLTeamId =
        player &&
        player.nhl_rights_owner_team_id &&
        teams[player.nhl_rights_owner_team_id]?.affiliations[0];

    const [metricContextStaging, setMetricContextStaging] = useState({
        needsUpdate: false,
        metricContext: metricContext,
    });

    const handleMetricContextChange = useCallback(
        (newMetricContext) => {
            setMetricContextStaging({
                needsUpdate: true,
                metricContext: newMetricContext,
            });
        },
        [setMetricContextStaging]
    );

    useEffect(() => {
        if (metricContextStaging.needsUpdate) {
            onMetricContextChange &&
                onMetricContextChange(metricContextStaging.metricContext);
            setMetricContextStaging((prev) => {
                return ({
                    ...prev,
                    needsUpdate: false,
                });
            });
        }
    }, [metricContextStaging, setMetricContextStaging, onMetricContextChange]);

    useEffect(() => {
        setTimeout(() => {
            setActivePlayerId(playerId);
        }, transitionWait);
    }, [playerId]);

    useEffect(() => {
        setTimeout(() => {
            setActiveSeasonId(seasonId);
        }, transitionWait);
    }, [seasonId]);

    useEffect(() => {
        setTimeout(() => {
            setActiveCompetitionId(competitionId);
        }, transitionWait);
    }, [competitionId]);

    const [
        activePlayerLatestPositionMetrics,
        setActivePlayerLatestPositionMetrics,
    ] = useState(null);

    const player_latest_position_metrics_complete =
        players_sds &&
        players_sds[playerId] &&
        players_sds[playerId].latest_position_metrics &&
        players_sds[playerId].latest_position_metrics[seasonId];

    const player_latest_position_metrics =
        player_latest_position_metrics_complete &&
        players_sds[playerId].latest_position_metrics[seasonId][competitionId];
    useEffect(() => {
        if (playerId && seasonId && !player_latest_position_metrics) {
            dispatch(
                fetchPlayerSeasonDatastash(
                    playerId,
                    seasonId,
                    "latest_position_metrics"
                )
            );
        }
    }, [playerId, seasonId, player_latest_position_metrics, dispatch]);

    useEffect(() => {
        if (
            activePlayerId === playerId &&
            activeSeasonId === seasonId &&
            activeCompetitionId === competitionId &&
            player_latest_position_metrics
        ) {
            setActivePlayerLatestPositionMetrics(
                player_latest_position_metrics
            );
        }
    }, [
        activePlayerId,
        playerId,
        activeSeasonId,
        seasonId,
        activeCompetitionId,
        competitionId,
        player_latest_position_metrics,
    ]);

    // The drill down panel does not use the data series itself, but needs to
    // know if it is available in order to display the chart or not.
    const player_position_metrics_series_complete =
        players_sds &&
        players_sds[playerId] &&
        players_sds[playerId].position_metrics_series &&
        players_sds[playerId].position_metrics_series[seasonId];

    const player_position_metrics_series =
        player_position_metrics_series_complete &&
        players_sds[playerId].position_metrics_series[seasonId][competitionId];
    useEffect(() => {
        if (playerId && seasonId && !player_position_metrics_series_complete) {
            dispatch(
                fetchPlayerSeasonDatastash(
                    playerId,
                    seasonId,
                    "position_metrics_series"
                )
            );
        }
    }, [playerId, seasonId, player_position_metrics_series_complete, dispatch]);

    const competition_latest_position_metrics_tiers_thresholds =
        player &&
        competitions_sds &&
        competitions_sds[activeCompetitionId] &&
        competitions_sds[activeCompetitionId]
            .latest_position_metrics_tiers_thresholds &&
        competitions_sds[activeCompetitionId]
            .latest_position_metrics_tiers_thresholds[activeSeasonId] &&
        competitions_sds[activeCompetitionId]
            .latest_position_metrics_tiers_thresholds[activeSeasonId][
            playerPrimaryPosition
        ];

    useEffect(() => {
        if (
            competitionId &&
            seasonId &&
            !competition_latest_position_metrics_tiers_thresholds
        ) {
            dispatch(
                fetchCompetitionSeasonDatastash(
                    competitionId,
                    seasonId,
                    "latest_position_metrics_tiers_thresholds"
                )
            );
        }
    }, [
        competitionId,
        seasonId,
        competition_latest_position_metrics_tiers_thresholds,
        dispatch,
    ]);

    const {
        metricStack,
        transitionMetricStack,
        initiateSingleMetricTransition,
        initiateFullMetricStackTransition,
        setPrimaryPosition,
    } = useMetricsDrilldownBreadcrumbManager(
        PlayerIndexMetrics,
        defaultMetric,
        handleMetricContextChange,
        null,
        transitionWait
    );

    useEffect(() => {
        if (playerPrimaryPosition) {
            setPrimaryPosition(playerPrimaryPosition);
        }
    }, [playerPrimaryPosition, setPrimaryPosition]);

    const handleUsageMetricClick = (metricName) => {
        if (metricName !== metricStack.activeMetric) {
            player_season_performance_view_mp_track(
                "View Player Index Drilldown - Usage Click",
                player,
                competition,
                season,
                {
                    metric: metricName,
                }
            );
            initiateSingleMetricTransition(metricName, true);
        }
    };

    const handleBreadcrumbMetricClick = (metricName) => {
        if (metricName !== metricStack.activeMetric) {
            initiateSingleMetricTransition(metricName);
            player_season_performance_view_mp_track(
                "View Player Index Drilldown - Breadcumb Click",
                player,
                competition,
                season,
                {
                    metric: metricName,
                }
            );
        }
    };

    const handleDrilldownMetricClick = useCallback(
        (metricName) => {
            if (metricName !== metricStack.activeMetric) {
                initiateSingleMetricTransition(metricName);
                player_season_performance_view_mp_track(
                    "View Player Index Drilldown - Drilldown Click",
                    player,
                    competition,
                    season,
                    {
                        metric: metricName,
                    }
                );
            }
        },
        [
            competition,
            player,
            season,
            metricStack,
            initiateSingleMetricTransition,
        ]
    );

    useEffect(() => {
        if (
            metricContextStaging.metricContext &&
            metricContextStaging.metricContext.stack &&
            metricContextStaging.metricContext.activeMetric &&
            metricContextStaging.metricContext.activeMetric !== metricStack.activeMetric
        ) {
            initiateFullMetricStackTransition(
                metricContextStaging.metricContext.stack,
                metricContextStaging.metricContext.activeMetric
            );
        }
    }, [metricContextStaging, metricStack, initiateFullMetricStackTransition]);

    const [breakdownThresholds, setBreakdownThresholds] = useState(null);

    useEffect(() => {
        if (
            activePlayerId === playerId &&
            activeSeasonId === seasonId &&
            activeCompetitionId === competitionId
        ) {
            const newBreakdownThresholds =
                competition_latest_position_metrics_tiers_thresholds &&
                // Compute the max value of the breakdown metrics
                // (i.e. the max threshold value of the component metrics of the active breakdown
                // metric)
                metricStack.breadcrumbStack &&
                metricStack.breadcrumbStack.length > 0 &&
                PlayerIndexMetrics[
                    metricStack.breadcrumbStack[
                        metricStack.breadcrumbStack.length - 1
                    ]
                ].componentMetrics.reduce(
                    (acc, metricName) => {
                        if (!PlayerIndexMetrics[metricName].maxValue) {
                            const metricIdx =
                                competition_latest_position_metrics_tiers_thresholds.metrics.indexOf(
                                    metricName
                                );
                            if (metricIdx > -1) {
                                if (
                                    PlayerIndexMetrics[metricName]
                                        .independentMaxValue
                                ) {
                                    const minThreshold = Math.min(
                                        ...competition_latest_position_metrics_tiers_thresholds
                                            .latest.thresholds[metricIdx]
                                    );

                                    const maxThreshold = Math.max(
                                        ...competition_latest_position_metrics_tiers_thresholds
                                            .latest.thresholds[metricIdx]
                                    );

                                    // Max is the absolute max between the min and max thresholds
                                    const absMax = Math.max(
                                        Math.abs(minThreshold),
                                        Math.abs(maxThreshold)
                                    );
                                    acc[metricName] = {
                                        max: absMax,
                                    };
                                    acc[metricName].isSigned = minThreshold < 0;
                                } else {
                                    acc.grouped = Math.max(
                                        acc.grouped,
                                        Math.max(
                                            ...competition_latest_position_metrics_tiers_thresholds
                                                .latest.thresholds[metricIdx]
                                        )
                                    );
                                }
                            }
                        }

                        return acc;
                    },
                    { grouped: 0 }
                );
            setBreakdownThresholds(newBreakdownThresholds);
        }
    }, [
        activePlayerId,
        playerId,
        activeSeasonId,
        seasonId,
        activeCompetitionId,
        competitionId,
        competition_latest_position_metrics_tiers_thresholds,
        metricStack.activeMetric,
        metricStack.breadcrumbStack,
    ]);

    const [tradeMarkerDates, handleNewTradeDateRange, resetTradeMarkers] =
        useTradeMarkersManager();

    useEffect(() => {
        if (player && competition && season) {
            resetTradeMarkers();
        }
    }, [player, competition, season, resetTradeMarkers]);

    const isLoading = useMemo(() => {
        return (
            (activeCompetitionId &&
                !competition_latest_position_metrics_tiers_thresholds) ||
            !player_position_metrics_series_complete ||
            !player_latest_position_metrics_complete ||
            seasonTrendLoading ||
            (player_latest_position_metrics_complete &&
                careerProgressionLoading)
        );
    }, [
        activeCompetitionId,
        competition_latest_position_metrics_tiers_thresholds,
        player_position_metrics_series_complete,
        player_latest_position_metrics_complete,
        seasonTrendLoading,
        careerProgressionLoading,
    ]);

    const inverseColors =
        activePlayerLatestPositionMetrics?.es_5v5_toi <
        MIN_ES_5V5_THRESHOLD[playerPrimaryPosition];

    useEffect(() => {
        isLoadingHandler && isLoadingHandler(isLoading);
    }, [isLoading, isLoadingHandler]);

    useEffect(() => {
        if (
            player_position_metrics_series_complete &&
            !player_position_metrics_series
        ) {
            setSeasonTrendLoading(false);
        }
    }, [
        player_position_metrics_series_complete,
        player_position_metrics_series,
    ]);

    const chartMargins = useMemo(() => {
        return [
            10,
            20,
            24,
            metricStack.activeMetric &&
            PlayerIndexMetrics[metricStack.activeMetric].isPercentage
                ? 43
                : 38,
        ];
    }, [metricStack]);

    const metricTopPlayers =
        competition_latest_position_metrics_tiers_thresholds &&
        competition_latest_position_metrics_tiers_thresholds.top_players &&
        metricStack.activeMetric &&
        getMetricContent(
            metricStack.activeMetric,
            competition_latest_position_metrics_tiers_thresholds.metrics,
            competition_latest_position_metrics_tiers_thresholds.top_players
        );

    return (
        <>
            {player_latest_position_metrics_complete &&
                !player_latest_position_metrics && (
                    <div className={classes.no_data}>
                        <MainBox>No data available.</MainBox>
                    </div>
                )}
            <div
                className={
                    classes.performance_content +
                    (wideLayout ? " " + classes.wide : "")
                }
            >
                {player_latest_position_metrics_complete &&
                    player_latest_position_metrics && (
                        <>
                            <div
                                className={classes.performance_content_section}
                            >
                                <div className={classes.button_indicators}>
                                    <div className={classes.base_indicators}>
                                        <div
                                            className={
                                                classes.base_indicators_header
                                            }
                                        >
                                            Usage
                                        </div>
                                        <div
                                            className={
                                                classes.indicators_container
                                            }
                                        >
                                            {playerPrimaryPosition !== "G" && (
                                                <>
                                                    <div
                                                        className={
                                                            classes.single_indicator_container +
                                                            " " +
                                                            classes.breadcrumb_indicator_container +
                                                            " " +
                                                            ((!transitionMetricStack.activeMetric &&
                                                                metricStack.activeMetric ===
                                                                    "SOOD") ||
                                                            (transitionMetricStack.activeMetric &&
                                                                transitionMetricStack.activeMetric ===
                                                                    "SOOD")
                                                                ? classes.highlight
                                                                : classes.clickable)
                                                        }
                                                        onClick={() =>
                                                            handleUsageMetricClick(
                                                                "SOOD"
                                                            )
                                                        }
                                                    >
                                                        <div
                                                            className={
                                                                classes.single_indicator_header +
                                                                " " +
                                                                classes.usage_indicator_header
                                                            }
                                                        >
                                                            Matchup
                                                        </div>
                                                        <TieredNumericButtonIndicator
                                                            value={
                                                                getMetricContentFromLatest(
                                                                    "SOOD",
                                                                    activePlayerLatestPositionMetrics
                                                                )?.seasonal
                                                            }
                                                            tierClass={
                                                                activePlayerLatestPositionMetrics &&
                                                                competition_latest_position_metrics_tiers_thresholds &&
                                                                getMetricTierClassFromLatest(
                                                                    "SOOD",
                                                                    activePlayerLatestPositionMetrics,
                                                                    competition_latest_position_metrics_tiers_thresholds
                                                                )
                                                            }
                                                            inverseColors={
                                                                inverseColors
                                                            }
                                                            precision={2}
                                                        />
                                                    </div>
                                                    <div
                                                        className={
                                                            classes.single_indicator_container +
                                                            " " +
                                                            classes.breadcrumb_indicator_container +
                                                            " " +
                                                            ((!transitionMetricStack.activeMetric &&
                                                                metricStack.activeMetric ===
                                                                    "OZ Starts Percentage") ||
                                                            (transitionMetricStack.activeMetric &&
                                                                transitionMetricStack.activeMetric ===
                                                                    "OZ Starts Percentage")
                                                                ? classes.highlight
                                                                : classes.clickable)
                                                        }
                                                        onClick={() =>
                                                            handleUsageMetricClick(
                                                                "OZ Starts Percentage"
                                                            )
                                                        }
                                                    >
                                                        <div
                                                            className={
                                                                classes.single_indicator_header +
                                                                " " +
                                                                classes.usage_indicator_header
                                                            }
                                                        >
                                                            OZ Starts
                                                        </div>
                                                        <TieredNumericButtonIndicator
                                                            value={
                                                                getMetricContentFromLatest(
                                                                    "OZ Starts Percentage",
                                                                    activePlayerLatestPositionMetrics
                                                                )?.seasonal
                                                            }
                                                            precision={1}
                                                            inverseColors={
                                                                inverseColors
                                                            }
                                                            isPercentage={true}
                                                            tierClass={getMetricTierClassFromLatest(
                                                                "OZ Starts Percentage",
                                                                activePlayerLatestPositionMetrics,
                                                                competition_latest_position_metrics_tiers_thresholds
                                                            )}
                                                        />
                                                    </div>
                                                    <div
                                                        className={
                                                            classes.single_indicator_container +
                                                            " " +
                                                            classes.breadcrumb_indicator_container +
                                                            " " +
                                                            ((!transitionMetricStack.activeMetric &&
                                                                metricStack.activeMetric ===
                                                                    "SOT Percentage") ||
                                                            (transitionMetricStack.activeMetric &&
                                                                transitionMetricStack.activeMetric ===
                                                                    "SOT Percentage")
                                                                ? classes.highlight
                                                                : classes.clickable)
                                                        }
                                                        onClick={() =>
                                                            handleUsageMetricClick(
                                                                "SOT Percentage"
                                                            )
                                                        }
                                                    >
                                                        <div
                                                            className={
                                                                classes.single_indicator_header +
                                                                " " +
                                                                classes.usage_indicator_header
                                                            }
                                                        >
                                                            SOT%
                                                        </div>
                                                        <TieredNumericButtonIndicator
                                                            value={
                                                                getMetricContentFromLatest(
                                                                    "SOT Percentage",
                                                                    activePlayerLatestPositionMetrics
                                                                )?.seasonal
                                                            }
                                                            precision={1}
                                                            inverseColors={
                                                                inverseColors
                                                            }
                                                            isPercentage={true}
                                                            tierClass={
                                                                activePlayerLatestPositionMetrics &&
                                                                competition_latest_position_metrics_tiers_thresholds &&
                                                                getMetricTierClassFromLatest(
                                                                    "SOT Percentage",
                                                                    activePlayerLatestPositionMetrics,
                                                                    competition_latest_position_metrics_tiers_thresholds
                                                                )
                                                            }
                                                        />
                                                    </div>
                                                    <div
                                                        className={
                                                            classes.single_indicator_container +
                                                            " " +
                                                            classes.breadcrumb_indicator_container +
                                                            " " +
                                                            ((!transitionMetricStack.activeMetric &&
                                                                metricStack.activeMetric ===
                                                                    "All MP TOI Seconds per Game") ||
                                                            (transitionMetricStack.activeMetric &&
                                                                transitionMetricStack.activeMetric ===
                                                                    "All MP TOI Seconds per Game")
                                                                ? classes.highlight
                                                                : classes.clickable)
                                                        }
                                                        onClick={() =>
                                                            handleUsageMetricClick(
                                                                "All MP TOI Seconds per Game"
                                                            )
                                                        }
                                                    >
                                                        <div
                                                            className={
                                                                classes.single_indicator_header +
                                                                " " +
                                                                classes.usage_indicator_header
                                                            }
                                                        >
                                                            ES 5v5 TOI
                                                        </div>
                                                        <TieredNumericButtonIndicator
                                                            value={formatToiSeconds(
                                                                activePlayerLatestPositionMetrics?.es_5v5_toi
                                                            )}
                                                            tierClass={
                                                                activePlayerLatestPositionMetrics &&
                                                                competition_latest_position_metrics_tiers_thresholds &&
                                                                getMetricTierClassFromLatest(
                                                                    "All MP TOI Seconds per Game",
                                                                    activePlayerLatestPositionMetrics,
                                                                    competition_latest_position_metrics_tiers_thresholds
                                                                )
                                                            }
                                                            inverseColors={
                                                                inverseColors
                                                            }
                                                        />
                                                    </div>
                                                </>
                                            )}
                                            {playerPrimaryPosition === "G" && (
                                                <div
                                                    className={
                                                        classes.single_indicator_container +
                                                        " " +
                                                        classes.breadcrumb_indicator_container
                                                    }
                                                >
                                                    <div
                                                        className={
                                                            classes.single_indicator_header +
                                                            " " +
                                                            classes.usage_indicator_header
                                                        }
                                                    >
                                                        ES 5v5 TOI
                                                    </div>
                                                    <div
                                                        className={
                                                            classes.standalone_indicator_value +
                                                            " " +
                                                            classes.standalone_indicator
                                                        }
                                                    >
                                                        {formatToiSeconds(
                                                            activePlayerLatestPositionMetrics?.es_5v5_toi
                                                        )}
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </div>
                                <div className={classes.button_indicators}>
                                    <MetricDrillDownBreadCrumbsIndicator
                                        metricStack={
                                            metricStack.breadcrumbStack
                                        }
                                        activeMetric={metricStack.activeMetric}
                                        transitionToMetric={
                                            transitionMetricStack.isCompositeMetric &&
                                            transitionMetricStack.activeMetric
                                        }
                                        unselectingAll={
                                            transitionMetricStack.activeMetric &&
                                            !transitionMetricStack.isCompositeMetric
                                        }
                                        playerMetrics={
                                            activePlayerLatestPositionMetrics
                                        }
                                        competitionMetricThresholds={
                                            competition_latest_position_metrics_tiers_thresholds
                                        }
                                        onClickHandler={
                                            handleBreadcrumbMetricClick
                                        }
                                        inverseColors={inverseColors}
                                    />
                                </div>
                                <div className={classes.metric_breakdown}>
                                    <div
                                        className={
                                            classes.metric_breakdown_header +
                                            " " +
                                            classes.transition_indicator +
                                            (transitionMetricStack.activeMetric &&
                                            transitionMetricStack.isCompositeMetric
                                                ? " " + classes.transitioning
                                                : "")
                                        }
                                    >
                                        {metricStack.activeMetric &&
                                            PlayerIndexMetrics[
                                                metricStack.activeMetric
                                            ].label}{" "}
                                        Breakdown
                                    </div>
                                    <div
                                        className={
                                            classes.metric_breakdown_content
                                        }
                                    >
                                        {metricStack.breadcrumbStack &&
                                            metricStack.breadcrumbStack.length >
                                                0 &&
                                            PlayerIndexMetrics[
                                                metricStack.breadcrumbStack[
                                                    metricStack.breadcrumbStack
                                                        .length - 1
                                                ]
                                            ].componentMetrics
                                                // We need to filter for only metrics that have
                                                // scope for the player's position
                                                .filter((metricName) => {
                                                    return (
                                                        !PlayerIndexMetrics[
                                                            metricName
                                                        ].positionScope ||
                                                        PlayerIndexMetrics[
                                                            metricName
                                                        ].positionScope.includes(
                                                            playerPrimaryPosition
                                                        )
                                                    );
                                                })
                                                .map((metricName) => {
                                                    const maxValue =
                                                        PlayerIndexMetrics[
                                                            metricName
                                                        ].maxValue
                                                            ? PlayerIndexMetrics[
                                                                  metricName
                                                              ].maxValue
                                                            : PlayerIndexMetrics[
                                                                  metricName
                                                              ]
                                                                  .independentMaxValue
                                                            ? breakdownThresholds &&
                                                              breakdownThresholds[
                                                                  metricName
                                                              ] &&
                                                              breakdownThresholds[
                                                                  metricName
                                                              ].max
                                                            : breakdownThresholds?.grouped;

                                                    const isSigned =
                                                        PlayerIndexMetrics[
                                                            metricName
                                                        ].independentMaxValue
                                                            ? breakdownThresholds &&
                                                              breakdownThresholds[
                                                                  metricName
                                                              ] &&
                                                              breakdownThresholds[
                                                                  metricName
                                                              ].isSigned
                                                            : false;

                                                    const peersAverage =
                                                        peersData &&
                                                        computePeersAverage(
                                                            metricName,
                                                            peersData
                                                        );

                                                    return (
                                                        <div
                                                            className={
                                                                classes.metric_container +
                                                                " " +
                                                                classes.transition_indicator +
                                                                (transitionMetricStack.activeMetric &&
                                                                transitionMetricStack.isCompositeMetric
                                                                    ? " " +
                                                                      classes.transitioning
                                                                    : "") +
                                                                " " +
                                                                ((!(
                                                                    transitionMetricStack.activeMetric &&
                                                                    !transitionMetricStack.isCompositeMetric
                                                                ) &&
                                                                    metricStack.activeMetric ===
                                                                        metricName) ||
                                                                (transitionMetricStack.activeMetric &&
                                                                    !transitionMetricStack.isCompositeMetric &&
                                                                    transitionMetricStack.activeMetric ===
                                                                        metricName)
                                                                    ? classes.highlight
                                                                    : classes.clickable)
                                                            }
                                                            key={metricName}
                                                            onClick={() =>
                                                                handleDrilldownMetricClick(
                                                                    metricName
                                                                )
                                                            }
                                                        >
                                                            <div
                                                                className={
                                                                    classes.vertical_bar_container
                                                                }
                                                            >
                                                                {peersData && (
                                                                    <VerticalSliderIndicator
                                                                        title={
                                                                            "Avg " +
                                                                            (PlayerIndexMetrics[
                                                                                metricName
                                                                            ]
                                                                                .isPercentage
                                                                                ? (
                                                                                      peersAverage *
                                                                                      100
                                                                                  ).toFixed(
                                                                                      PlayerIndexMetrics[
                                                                                          metricName
                                                                                      ]
                                                                                          .decimalPlaces
                                                                                  )
                                                                                : peersAverage.toFixed(
                                                                                      PlayerIndexMetrics[
                                                                                          metricName
                                                                                      ]
                                                                                          .decimalPlaces
                                                                                  ))
                                                                        }
                                                                        value={
                                                                            isSigned
                                                                                ? (peersAverage /
                                                                                      maxValue) *
                                                                                      0.5 +
                                                                                  0.5
                                                                                : peersAverage /
                                                                                  maxValue
                                                                        }
                                                                        isSigned={
                                                                            isSigned
                                                                        }
                                                                        tierClass={
                                                                            getMetricTierClassFromValue(
                                                                                metricName,
                                                                                computePeersAverage(
                                                                                    metricName,
                                                                                    peersData
                                                                                ),
                                                                                competition_latest_position_metrics_tiers_thresholds
                                                                            ) +
                                                                            "_tier"
                                                                        }
                                                                    />
                                                                )}
                                                                <VerticalBarIndicator
                                                                    value={
                                                                        getMetricContentFromLatest(
                                                                            metricName,
                                                                            activePlayerLatestPositionMetrics
                                                                        )
                                                                            ?.seasonal
                                                                    }
                                                                    maxValue={
                                                                        maxValue
                                                                    }
                                                                    isSigned={
                                                                        isSigned
                                                                    }
                                                                    tierClass={
                                                                        vertical_bar_classes[
                                                                            getMetricTierClassFromLatest(
                                                                                metricName,
                                                                                activePlayerLatestPositionMetrics,
                                                                                competition_latest_position_metrics_tiers_thresholds
                                                                            ) +
                                                                                "_tier"
                                                                        ]
                                                                    }
                                                                    includeLabel={
                                                                        true
                                                                    }
                                                                    isPercentage={
                                                                        PlayerIndexMetrics[
                                                                            metricName
                                                                        ]
                                                                            .isPercentage
                                                                    }
                                                                    decimalPlaces={
                                                                        PlayerIndexMetrics[
                                                                            metricName
                                                                        ]
                                                                            .decimalPlaces
                                                                    }
                                                                    labelColor={
                                                                        "var(--tw-gray-500)"
                                                                    }
                                                                    inverseColors={
                                                                        inverseColors
                                                                    }
                                                                />
                                                            </div>
                                                            <div
                                                                className={
                                                                    classes.metric_header
                                                                }
                                                            >
                                                                {
                                                                    PlayerIndexMetrics[
                                                                        metricName
                                                                    ].label
                                                                }
                                                            </div>
                                                        </div>
                                                    );
                                                })}
                                    </div>
                                </div>
                            </div>
                            <div
                                className={classes.performance_content_section}
                            >
                                {careerMode && (
                                    <div className={classes.metric_trend}>
                                        <div
                                            className={
                                                classes.metric_trend_header +
                                                " " +
                                                classes.transition_indicator +
                                                (transitionMetricStack.activeMetric
                                                    ? " " +
                                                      classes.transitioning
                                                    : "")
                                            }
                                        >
                                            {PlayerIndexMetrics[
                                                metricStack.activeMetric
                                            ].label +
                                                " " +
                                                competition?.display_name +
                                                " Career Progression"}
                                        </div>
                                        <PlayerMetricCareerProgressionChart
                                            playerId={playerId}
                                            competitionId={competitionId}
                                            selectedSeasonId={
                                                onSeasonSelectionHandler &&
                                                seasonId
                                            }
                                            onSeasonSelectionHandler={
                                                onSeasonSelectionHandler
                                            }
                                            metric={
                                                (transitionMetricStack.activeMetric &&
                                                    transitionMetricStack.activeMetric) ||
                                                metricStack.activeMetric
                                            }
                                            width={trendsChartsLayoutWidth - 80}
                                            height={200}
                                            margin={chartMargins}
                                            autoScaleYAxis={
                                                PlayerIndexMetrics[
                                                    metricStack.activeMetric
                                                ].autoScale
                                            }
                                            autoScaleYAxisMax={
                                                PlayerIndexMetrics[
                                                    metricStack.activeMetric
                                                ].autoScaleMax
                                            }
                                            isPercentage={
                                                PlayerIndexMetrics[
                                                    metricStack.activeMetric
                                                ].isPercentage
                                            }
                                            isSeconds={
                                                PlayerIndexMetrics[
                                                    metricStack.activeMetric
                                                ].isSeconds
                                            }
                                            isLoadingHandler={
                                                setCareerProgressionLoading
                                            }
                                        />
                                    </div>
                                )}
                                {competition &&
                                    !(
                                        player_position_metrics_series_complete &&
                                        !player_position_metrics_series
                                    ) && (
                                        <div className={classes.metric_trend}>
                                            <div
                                                className={
                                                    classes.metric_trend_header +
                                                    " " +
                                                    classes.transition_indicator +
                                                    (transitionMetricStack.activeMetric
                                                        ? " " +
                                                          classes.transitioning
                                                        : "")
                                                }
                                            >
                                                {metricStack.activeMetric &&
                                                    PlayerIndexMetrics[
                                                        metricStack.activeMetric
                                                    ].label +
                                                        " " +
                                                        competition.display_name +
                                                        " " +
                                                        season?.shorthand +
                                                        " Trend"}
                                            </div>
                                            <PlayerMetricSeasonTrendChart
                                                playerId={playerId}
                                                competitionId={competitionId}
                                                currentTeamId={
                                                    competitionId === 1
                                                        ? player?.nhl_rights_owner_team_id
                                                        : competitionId === 2
                                                        ? playerAHLTeamId
                                                        : null
                                                }
                                                seasonId={seasonId}
                                                metric={
                                                    (transitionMetricStack.activeMetric &&
                                                        transitionMetricStack.activeMetric) ||
                                                    metricStack.activeMetric
                                                }
                                                width={
                                                    trendsChartsLayoutWidth - 80
                                                }
                                                height={220}
                                                margin={chartMargins}
                                                autoScaleYAxis={
                                                    metricStack.activeMetric &&
                                                    PlayerIndexMetrics[
                                                        metricStack.activeMetric
                                                    ].autoScale
                                                }
                                                autoScaleYAxisMax={
                                                    metricStack.activeMetric &&
                                                    PlayerIndexMetrics[
                                                        metricStack.activeMetric
                                                    ].autoScaleMax
                                                }
                                                isPercentage={
                                                    metricStack.activeMetric &&
                                                    PlayerIndexMetrics[
                                                        metricStack.activeMetric
                                                    ].isPercentage
                                                }
                                                isSeconds={
                                                    metricStack.activeMetric &&
                                                    PlayerIndexMetrics[
                                                        metricStack.activeMetric
                                                    ].isSeconds
                                                }
                                                displayTeamAverage={
                                                    metricStack.activeMetric &&
                                                    !PlayerIndexMetrics[
                                                        metricStack.activeMetric
                                                    ].hideTeamAverage
                                                }
                                                tradeMarkerDates={
                                                    tradeMarkerDates
                                                }
                                                tradeDateRangeHandler={
                                                    handleNewTradeDateRange
                                                }
                                                isLoadingHandler={
                                                    setSeasonTrendLoading
                                                }
                                                highlightDates={highlightDates}
                                                selectedHighlightDate={
                                                    selectedHighlightDate
                                                }
                                                onHighlightDateSelected={
                                                    onHighlightDateSelected
                                                }
                                            />
                                        </div>
                                    )}
                                {!careerMode && metricTopPlayers && (
                                    <div className={classes.metric_trend}>
                                        <div
                                            className={
                                                classes.metric_top_ten_header +
                                                " " +
                                                classes.transition_indicator +
                                                (transitionMetricStack.activeMetric
                                                    ? " " +
                                                      classes.transitioning
                                                    : "")
                                            }
                                        >
                                            {"Top 10 " +
                                                (playerPrimaryPosition === "F"
                                                    ? "Forwards"
                                                    : playerPrimaryPosition ===
                                                      "D"
                                                    ? "Defencemen"
                                                    : "Goalies")}
                                        </div>
                                        <div
                                            className={
                                                classes.metric_top_ten +
                                                " " +
                                                classes.transition_indicator +
                                                (transitionMetricStack.activeMetric
                                                    ? " " +
                                                      classes.transitioning
                                                    : "")
                                            }
                                        >
                                            <MetricTopPlayersList
                                                competitionId={
                                                    activeCompetitionId
                                                }
                                                seasonId={activeSeasonId}
                                                primaryPosition={
                                                    playerPrimaryPosition
                                                }
                                                metricName={
                                                    metricStack.activeMetric
                                                }
                                                onPlayerClick={(
                                                    rankedPlayer,
                                                    index
                                                ) => {
                                                    player_season_performance_view_mp_track(
                                                        "View Player Index Drilldown - Top 10 Player Click",
                                                        player,
                                                        competition,
                                                        season,
                                                        {
                                                            metric: metricStack.activeMetric,
                                                            position:
                                                                playerPrimaryPosition,
                                                            target_player_id:
                                                                rankedPlayer.player_id,
                                                            target_player_name:
                                                                rankedPlayer.known_name,
                                                            target_player_rank:
                                                                index + 1,
                                                        }
                                                    );
                                                    navigate(
                                                        "/app/players/" +
                                                            rankedPlayer.player_id +
                                                            "/performance?competitionId=" +
                                                            competitionId +
                                                            "&seasonId=" +
                                                            seasonId +
                                                            "&metricStack=" +
                                                            metricStack.stack +
                                                            "&selectedMetric=" +
                                                            metricStack.activeMetric +
                                                            "&backNavOrigin=" +
                                                            encodeURIComponent(
                                                                location.pathname +
                                                                    location.search
                                                            )
                                                    );
                                                }}
                                            />
                                        </div>
                                    </div>
                                )}
                            </div>
                        </>
                    )}
            </div>
        </>
    );
};

export default PlayerSeasonPerformanceView;
