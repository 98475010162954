import { useEffect, useMemo, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { fetchTeamDatastash } from "../store/teams-actions";
import { fetchNHLTeamReserveListPlayers } from "../store/players-actions";
import { getPrimaryPosition } from "../utils";

export const primaryPositions = ["F", "D", "G"];

const DEFAULT_TIER_SELECTION_OPTIONS = [
    "NHL",
    "OFF_ROSTER",
    "AHL",
    "JCE",
    "All",
];

function useTeamTieredPlayers(
    initialTeamId,
    tierSelectionOptions = DEFAULT_TIER_SELECTION_OPTIONS,
    activeTiersOnly = false,
    splitInjuredReserve = false
) {
    const dispatch = useDispatch();

    const [isLoading, setIsLoading] = useState(false);

    const [teamId, setTeamId] = useState(initialTeamId);
    const [playersData, setPlayersData] = useState(null);

    const players = useSelector((state) => state.players.players);

    const teams_reserveListPlayerIds = useSelector(
        (state) => state.teams.reserveListPlayerIds[teamId]
    );

    const teams_datastashes = useSelector((state) => state.teams.datastashes);
    const depth_chart = useMemo(
        () =>
            teams_datastashes &&
            teams_datastashes[teamId] &&
            teams_datastashes[teamId].depth_chart,
        [teams_datastashes, teamId]
    );

    const all_requiredPlayerIds = useMemo(() => {
        // Ensure teams_reserveListPlayerIds is an array, even when null
        const safeTeamsReserveListPlayerIds = teams_reserveListPlayerIds || [];

        return safeTeamsReserveListPlayerIds;
    }, [teams_reserveListPlayerIds]);

    const playersMissing = useMemo(
        () =>
            !teams_reserveListPlayerIds ||
            teams_reserveListPlayerIds.length === 0 ||
            all_requiredPlayerIds.some((playerId) => !players[playerId]),
        [teams_reserveListPlayerIds, all_requiredPlayerIds, players]
    );

    const players_jce_leagues = useMemo(() => {
        if (depth_chart) {
            return Object.values(depth_chart.position_groups).reduce(
                (player_map, tier) => {
                    Object.values(tier).forEach((group) => {
                        group.forEach((player) => {
                            if (player.cur_jce_team) {
                                player_map[player.id] = player.cur_jce_team;
                            }
                        });
                    });
                    return player_map;
                },
                {}
            );
        }
        return {};
    }, [depth_chart]);

    const tieredPlayers = useMemo(() => {
        if (teams_reserveListPlayerIds) {
            return tierSelectionOptions
                .filter(
                    (tier) =>
                        tier !== "All" &&
                        (splitInjuredReserve || tier !== "OFF_ROSTER")
                )
                .map((tier) => {
                    return {
                        tier: tier,
                        data: primaryPositions.map((position) => {
                            return {
                                position: position,
                                players: teams_reserveListPlayerIds
                                    .filter((playerId) => {
                                        return (
                                            players[playerId] &&
                                            getPrimaryPosition(
                                                players[playerId].position
                                            ) === position &&
                                            (!players[playerId].is_tryout ||
                                                players[playerId].is_in_ahl)
                                        );
                                    })
                                    .map((playerId) => {
                                        const player = players[playerId] &&
                                            (tier !== "JCE" ||
                                                players_jce_leagues[
                                                    playerId
                                                ]) && {
                                                ...players[playerId],
                                                targetCompetition:
                                                    tier === "NHL" ||
                                                    tier === "OFF_ROSTER"
                                                        ? 1
                                                        : tier === "AHL"
                                                        ? 2
                                                        : players_jce_leagues[
                                                              playerId
                                                          ]
                                                        ? players_jce_leagues[
                                                              playerId
                                                          ].competition.id
                                                        : null,
                                                JCECompetition:
                                                    players_jce_leagues[
                                                        playerId
                                                    ]?.competition,
                                            };
                                        return {
                                            player,
                                        };
                                    })
                                    .filter((player) => {
                                        if (!player.player) {
                                            return false;
                                        }

                                        const activeTier =
                                            // If we are not splitting injured reserve,
                                            // we consider players on injured reserve to be
                                            // part of the NHL tier.
                                            !splitInjuredReserve &&
                                            player.player.depth_tier ===
                                                "OFF_ROSTER"
                                                ? "NHL"
                                                : player.player.depth_tier;

                                        return (
                                            player.player.targetCompetition &&
                                            (!activeTiersOnly ||
                                                tier === activeTier) &&
                                            (player.player.depth_tier ===
                                                "OFF_ROSTER" ||
                                                tier !== "OFF_ROSTER")
                                        );
                                    }),
                            };
                        }),
                    };
                });
        }
        return [];
    }, [
        activeTiersOnly,
        splitInjuredReserve,
        teams_reserveListPlayerIds,
        players_jce_leagues,
        players,
        tierSelectionOptions,
    ]);

    const tieredPlayersData = useMemo(() => {
        if (tieredPlayers) {
            return tieredPlayers.map((tier) => {
                return {
                    tier: tier.tier,
                    data: tier.data.map((positionGroup) => {
                        return {
                            position: positionGroup.position,
                            players: positionGroup.players
                                .map((player) => {
                                    return {
                                        player: player.player,
                                        data:
                                            player.player?.targetCompetition &&
                                            playersData &&
                                            playersData[player.player.id] &&
                                            playersData[player.player.id][
                                                player.player.targetCompetition
                                            ],
                                    };
                                })
                                .filter((player) => {
                                    return player.data;
                                }),
                        };
                    }),
                };
            });
        }
        return [];
    }, [tieredPlayers, playersData]);

    // Fetch required data if it's missing
    useEffect(() => {
        if (!depth_chart) {
            dispatch(fetchTeamDatastash(teamId, "depth_chart"));
            setIsLoading(true);
        }
    }, [teamId, depth_chart, dispatch]);

    useEffect(() => {
        if (playersMissing) {
            // If any players from the team reserve list are missing from
            // the players store, fetch the teams's reserve list
            dispatch(fetchNHLTeamReserveListPlayers(teamId, true));
            setIsLoading(true);
        }
    }, [teamId, playersMissing, dispatch]);

    useEffect(() => {
        if (depth_chart && !playersMissing) {
            setIsLoading(false);
        }
    }, [depth_chart, playersMissing, dispatch]);

    return {
        setTeamId,
        setPlayersData,
        tieredPlayers,
        tieredPlayersData,
        isLoading,
    };
}

export default useTeamTieredPlayers;
