import { createSlice } from "@reduxjs/toolkit";

const playerStatusCategoriesSlice = createSlice({
    name: "playerstatuscategories",
    initialState: {
        categories: {},
    },
    reducers: {
        updatePlayerStatusCategories(state, action) {
            const playerStatusCategoriesList = action.payload;

            playerStatusCategoriesList.forEach((category) => {
                state.categories[category.id] = category;
            });
        },
    },
});

export const playerStatusCategoriesActions = playerStatusCategoriesSlice.actions;

export default playerStatusCategoriesSlice;
