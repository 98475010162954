import Legend, {
    LegendBlock,
    LegendSection,
    LegendItem,
} from "../../controls/Legend";

const PreScoutTileLegend = ({
    metrics,
    metric = null,
    zIndex = 1000,
    isOpen = null,
    onClose = null,
}) => {
    const title = metrics && metrics.length > 1 ? "Pre Scout Metrics" : null;
    const description =
        metrics && metrics.length > 1
            ? "The Pre-Scout Report offers a comprehensive evaluation of your target team's last 15 games, focusing on key performance metrics against various opponents. For each metric the report identifies the top three opposing teams that have been either most or least successful against the scouted team."
            : null;

    const metricsArray = metric ? [metric] : metrics ? metrics : [];

    return (
        <Legend
            zIndex={zIndex}
            isOpen={isOpen}
            onClose={onClose}
            showLegendTitle={!onClose}
        >
            <LegendBlock>
                <LegendSection title={title} description={description}>
                    {metricsArray.map((metric) => (
                        <LegendItem
                            key={metric.metric}
                            header={metric.metric.replace("{}", "team")}
                            description={metric.description}
                        />
                    ))}
                </LegendSection>
            </LegendBlock>
        </Legend>
    );
};

export default PreScoutTileLegend;
