import classes from "./PlayerTeamSelector.module.css";

const PlayerTeamSelector = ({ player, teams, handleTeamChange }) => {
    return (
        <div className={classes.player_team_selector}>
            <span>{player.known_name}</span>
            <select
                value={player.nhl_drafted_team_id || "none"}
                onChange={(e) => handleTeamChange(e, player)}
            >
                <option value="none">No team selected</option>
                {teams.map((team) => (
                    <option key={team.id} value={team.id}>
                        {team.display_name}
                    </option>
                ))}
            </select>
        </div>
    );
};

export default PlayerTeamSelector;
