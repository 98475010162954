import { createSlice } from "@reduxjs/toolkit";

const competitionsSlice = createSlice({
    name: "competitions",
    initialState: {
        competitions: {},
        datastashes: {},
        seasondatastashes: {},
        completeseasondatastashes: {},
        activeTeams: {},
    },
    reducers: {
        updateCompetition(state, action) {
            const competition = action.payload;
            state.competitions[competition.id] = competition;
        },
        updateCompetitions: (state, action) => {
            action.payload.forEach((competition) => {
                state.competitions[competition.id] = competition;
            });
        },
        updateCompetitionActiveTeams(state, action) {
            const competitionActiveTeams = action.payload;
            state.activeTeams[competitionActiveTeams.competitionId] =
                competitionActiveTeams.activeTeams;
        },
        updateCompetitionDatastash(state, action) {
            const { competitionId, datastashName, data } = action.payload;
            if (!state.datastashes[competitionId]) {
                state.datastashes[competitionId] = {};
            }
            state.datastashes[competitionId][datastashName] = data;
        },
        updateCompetitionsDatastash(state, action) {
            const { datastashName, data } = action.payload;

            data.forEach((datastash) => {
                const { competition_id: competitionId, data } = datastash;
                if (!state.datastashes[competitionId]) {
                    state.datastashes[competitionId] = {};
                }
                state.datastashes[competitionId][datastashName] = data;
            });
        },
        updateCompetitionSeasonDatastash(state, action) {
            const { competitionId, datastashName, seasonId, data } =
                action.payload;
            if (!state.seasondatastashes[competitionId]) {
                state.seasondatastashes[competitionId] = {};
            }
            if (!state.seasondatastashes[competitionId][datastashName]) {
                state.seasondatastashes[competitionId][datastashName] = {};
            }
            state.seasondatastashes[competitionId][datastashName][seasonId] =
                data;
        },
        updateCompetitionSeasonsDatastash(state, action) {
            const { competitionId, datastashName, data } = action.payload;

            data.forEach((datastash) => {
                const {
                    competition_id: competitionId,
                    season_id: seasonId,
                    data,
                } = datastash;
                if (!state.seasondatastashes[competitionId]) {
                    state.seasondatastashes[competitionId] = {};
                }
                if (!state.seasondatastashes[competitionId][datastashName]) {
                    state.seasondatastashes[competitionId][datastashName] = {};
                }
                state.seasondatastashes[competitionId][datastashName][seasonId] =
                    data;
            });

            if (!state.completeseasondatastashes[competitionId]) {
                state.completeseasondatastashes[competitionId] = {};
            }
            state.completeseasondatastashes[competitionId][datastashName] = true;
        },
        updateCompetitionsSeasonsDatastash(state, action) {
            const { datastashName, data } = action.payload;

            data.forEach((datastash) => {
                const {
                    competition_id: competitionId,
                    season_id: seasonId,
                    data,
                } = datastash;
                if (!state.seasondatastashes[competitionId]) {
                    state.seasondatastashes[competitionId] = {};
                }
                if (!state.seasondatastashes[competitionId][datastashName]) {
                    state.seasondatastashes[competitionId][datastashName] = {};
                }
                state.seasondatastashes[competitionId][datastashName][
                    seasonId
                ] = data;
            });
        },
    },
});

export const competitionsActions = competitionsSlice.actions;

export default competitionsSlice;
