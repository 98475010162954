import { uiActions } from "./ui-slice";
import { fetchData } from "../utils";
import { teamsActions } from "./teams-slice";

let ongoingRequests = {
    fetchTeam: {},
    fetchTeams: {},
    fetchTeamDatastash: {},
    fetchTeamsDatastash: {},
    fetchTeamSeasonDatastash: {},
    fetchTeamsSeasonsDatastash: {},
    fetchTeamsDeadCapCommitments: {},
};

// Fetches a single team from the API
export const fetchTeam = (teamId) => {
    return async (dispatch) => {
        if (!ongoingRequests.fetchTeam[teamId]) {
            ongoingRequests.fetchTeam[teamId] = fetchData(
                process.env.REACT_APP_HABSHUB_API_BASE_URL +
                    "hockey_analytics/teams/" +
                    teamId +
                    "/",
                "Could not fetch team data!"
            )
                .then((teamData) => {
                    dispatch(teamsActions.updateTeam(teamData));
                    delete ongoingRequests.fetchTeam[teamId];
                    return teamData;
                })
                .catch((error) => {
                    dispatch(
                        uiActions.showNotification({
                            status: "error",
                            title: "Error!",
                            message: `Fetching team data for team ${teamId} failed!`,
                        })
                    );
                    delete ongoingRequests.fetchTeam[teamId];
                    throw error;
                });
        }

        return ongoingRequests.fetchTeam[teamId];
    };
};

// Fetches all teams from the API
export const fetchTeams = (teamIds = null) => {
    // Create a unique key for each teamIds. seasonIds list and datastashName combination
    const key = `ids-${(teamIds ? teamIds : "").join(",")}`;

    return async (dispatch) => {
        if (!ongoingRequests.fetchTeams[key]) {
            const params = [
                teamIds && teamIds.map((id) => `team_ids=${id}`).join("&"),
            ]
                .filter(Boolean)
                .join("&");

            ongoingRequests.fetchTeams[key] = fetchData(
                process.env.REACT_APP_HABSHUB_API_BASE_URL +
                    "hockey_analytics/teams/" +
                    (params ? `?${params}` : ""),
                "Could not fetch teams data!"
            )
                .then((teamsData) => {
                    teamsData.forEach((team) => {
                        dispatch(teamsActions.updateTeam(team));
                    });
                    delete ongoingRequests.fetchTeams[key];
                    return teamsData;
                })
                .catch((error) => {
                    dispatch(
                        uiActions.showNotification({
                            status: "error",
                            title: "Error!",
                            message: "Fetching teams data failed!",
                        })
                    );
                    delete ongoingRequests.fetchTeams[key];
                    throw error;
                });
        }

        return ongoingRequests.fetchTeams[key];
    };
};

// Fetches a given datastash for a single team from the API
export const fetchTeamDatastash = (teamId, datastashName) => {
    return async (dispatch) => {
        const key = `${teamId}-${datastashName}`; // Create a unique key for each teamId and datastashName combination

        if (!ongoingRequests.fetchTeamDatastash[key]) {
            ongoingRequests.fetchTeamDatastash[key] = fetchData(
                process.env.REACT_APP_HABSHUB_API_BASE_URL +
                    "hockey_analytics/datastashes/teams/" +
                    datastashName +
                    "/?team_ids=" +
                    teamId,
                "Could not fetch team data!"
            )
                .then((data) => {
                    const dataPlayload = data.length > 0 ? data[0].data : [];
                    dispatch(
                        teamsActions.updateTeamDatastash({
                            teamId: teamId,
                            datastashName: datastashName,
                            data: dataPlayload,
                        })
                    );
                    delete ongoingRequests.fetchTeamDatastash[key];
                    return dataPlayload;
                })
                .catch((error) => {
                    dispatch(
                        uiActions.showNotification({
                            status: "error",
                            title: "Error!",
                            message: `Fetching team data stash ${datastashName} for team ${teamId} failed!`,
                        })
                    );
                    delete ongoingRequests.fetchTeamDatastash[key];
                    throw error;
                });
        }

        return ongoingRequests.fetchTeamDatastash[key];
    };
};

// Fetches a given datastash for all teams from the API
export const fetchTeamsDatastash = (datastashName) => {
    return async (dispatch) => {
        const key = datastashName;

        if (!ongoingRequests.fetchTeamsDatastash[key]) {
            ongoingRequests.fetchTeamsDatastash[key] = fetchData(
                process.env.REACT_APP_HABSHUB_API_BASE_URL +
                    "hockey_analytics/datastashes/teams/" +
                    datastashName +
                    "/",
                "Could not fetch teams data!"
            )
                .then((data) => {
                    const dataPlayload = data;
                    dispatch(
                        teamsActions.updateTeamsDatastash({
                            datastashName: datastashName,
                            data: dataPlayload,
                        })
                    );
                    delete ongoingRequests.fetchTeamsDatastash[key];
                    return dataPlayload;
                })
                .catch((error) => {
                    dispatch(
                        uiActions.showNotification({
                            status: "error",
                            title: "Error!",
                            message: `Fetching all teams data stash ${datastashName} failed!`,
                        })
                    );
                    delete ongoingRequests.fetchTeamsDatastash[key];
                    throw error;
                });
        }

        return ongoingRequests.fetchTeamsDatastash[key];
    };
};

export const fetchTeamSeasonDatastash = (teamId, seasonId, datastashName) => {
    return async (dispatch) => {
        // Create a unique key for each teamId, seasonId and datastashName combination
        const key = `${teamId}-${seasonId}-${datastashName}`;

        if (!ongoingRequests.fetchTeamSeasonDatastash[key]) {
            ongoingRequests.fetchTeamSeasonDatastash[key] = fetchData(
                process.env.REACT_APP_HABSHUB_API_BASE_URL +
                    "hockey_analytics/seasondatastashes/teams/" +
                    datastashName +
                    "/?team_ids=" +
                    teamId +
                    "&season_ids=" +
                    seasonId,
                "Could not fetch team data!"
            )
                .then((data) => {
                    const dataPlayload = data.length > 0 ? data[0].data : [];
                    dispatch(
                        teamsActions.updateTeamSeasonDatastash({
                            teamId: teamId,
                            datastashName: datastashName,
                            seasonId: seasonId,
                            data: dataPlayload,
                        })
                    );
                    delete ongoingRequests.fetchTeamSeasonDatastash[key];
                    return dataPlayload;
                })
                .catch((error) => {
                    dispatch(
                        uiActions.showNotification({
                            status: "error",
                            title: "Error!",
                            message: `Fetching team data stash ${datastashName} for team ${teamId}, season ${seasonId} failed!`,
                        })
                    );
                    delete ongoingRequests.fetchTeamSeasonDatastash[key];
                    throw error;
                });
        }

        return ongoingRequests.fetchTeamSeasonDatastash[key];
    };
};

export const fetchTeamsSeasonsDatastash = (
    datastashName,
    teamIds = null,
    seasonIds = null
) => {
    return async (dispatch) => {
        // Create a unique key for each teamIds. seasonIds list and datastashName combination
        const key = `${teamIds ? teamIds.join(",") : ""}-${
            seasonIds ? seasonIds.join(",") : ""
        }-${datastashName}`;

        if (!ongoingRequests.fetchTeamsSeasonsDatastash[key]) {
            const params = [
                teamIds && teamIds.map((id) => `team_ids=${id}`).join("&"),
                seasonIds &&
                    seasonIds.map((id) => `season_ids=${id}`).join("&"),
            ]
                .filter(Boolean)
                .join("&");

            ongoingRequests.fetchTeamsSeasonsDatastash[key] = fetchData(
                process.env.REACT_APP_HABSHUB_API_BASE_URL +
                    "hockey_analytics/seasondatastashes/teams/" +
                    datastashName +
                    (params ? `?${params}` : ""),
                "Could not fetch teams data!"
            )
                .then((data) => {
                    dispatch(
                        teamsActions.updateTeamsSeasonsDatastash({
                            datastashName: datastashName,
                            data: data, // As we are receiving array of objects for all team and season ids
                        })
                    );
                    delete ongoingRequests.fetchTeamsSeasonsDatastash[key];
                    return data;
                })
                .catch((error) => {
                    dispatch(
                        uiActions.showNotification({
                            status: "error",
                            title: "Error!",
                            message: `Fetching team data stash ${datastashName} for ${
                                teamIds
                                    ? "teams " + teamIds.join(",")
                                    : "all teams"
                            }, ${
                                seasonIds
                                    ? "seasons " + seasonIds.join(",")
                                    : "all seasons"
                            } failed!`,
                        })
                    );
                    delete ongoingRequests.fetchTeamsSeasonsDatastash[key];
                    throw error;
                });
        }

        return ongoingRequests.fetchTeamsSeasonsDatastash[key];
    };
};

// Fetches a single team dead cap commitments from the API
export const fetchTeamsDeadCapCommitments = (teamId) => {
    return async (dispatch) => {
        if (!ongoingRequests.fetchTeamsDeadCapCommitments[teamId]) {
            ongoingRequests.fetchTeamsDeadCapCommitments[teamId] = fetchData(
                process.env.REACT_APP_HABSHUB_API_BASE_URL +
                    "hockey_analytics/teams/" +
                    teamId +
                    "/deadcapcommitments/",
                "Could not fetch team dead cap commitments data!"
            )
                .then((teamData) => {
                    dispatch(
                        teamsActions.updateTeamDeadCapCommitments(
                            teamData,
                            teamId
                        )
                    );
                    delete ongoingRequests.fetchTeamsDeadCapCommitments[teamId];
                    return teamData;
                })
                .catch((error) => {
                    dispatch(
                        uiActions.showNotification({
                            status: "error",
                            title: "Error!",
                            message: `Fetching team dead cap commitments data for team ${teamId} failed!`,
                        })
                    );
                    delete ongoingRequests.fetchTeamsDeadCapCommitments[teamId];
                    throw error;
                });
        }

        return ongoingRequests.fetchTeamsDeadCapCommitments[teamId];
    };
};
