import classes from "./CapHitIndicator.module.css";
import { useSalaryCap } from "../hooks/use-salary-cap";
import { formatSalaryString } from "../utils";

export const TOP_CAP_HIT = 14000000.0;

export const BaseSalaryNumber = ({ salary, isNonRoster, isExample }) => {
    return (
        <span
            className={
                classes.cap_hit_base_salary +
                (isNonRoster ? " " + classes.off_cap : "")
            }
        >
            {isExample && salary}
            {!isExample && formatSalaryString(salary)}
        </span>
    );
};

export const BonusNumber = ({ bonus, isNonRoster, isExample }) => {
    return (
        <span
            className={
                classes.cap_hit_bonus +
                (isNonRoster ? " " + classes.off_cap : "")
            }
        >
            {" +"}
            {isExample && bonus}
            {!isExample && formatSalaryString(bonus)}
        </span>
    );
};

export const AhlSalaryNumber = ({ salary, isExample }) => {
    return (
        <span className={classes.ahl_salary}>
            {" "}
            {isExample && salary}
            {!isExample && formatSalaryString(salary)}
        </span>
    );
};

export const CapHitLabel = ({
    annualAverageValue,
    bonus,
    ahlSalary,
    isNonRoster,
}) => {
    return (
        <div className={classes.cap_hit_label}>
            {annualAverageValue && (
                <BaseSalaryNumber
                    salary={annualAverageValue}
                    isNonRoster={isNonRoster}
                />
            )}
            {bonus && bonus > 0 && (
                <BonusNumber bonus={bonus} isNonRoster={isNonRoster} />
            )}
            {ahlSalary && <AhlSalaryNumber salary={ahlSalary} />}
        </div>
    );
};

export const CapHitBar = ({
    annualAverageValue,
    capBase,
    buriedCap,
    bonus,
    ahlSalary,
    isNonRoster,
    includeLabel,
}) => {
    const { CURRENT_BURIED_CAP_THRESHOLD } = useSalaryCap();

    return (
        <div className={classes.cap_hit_bar_container}>
            {capBase > 0 && (
                <div
                    className={
                        classes.cap_hit_bar +
                        " " +
                        classes.base_salary +
                        (isNonRoster &&
                        annualAverageValue > CURRENT_BURIED_CAP_THRESHOLD
                            ? " " + classes.buried_cap_penalty
                            : "") +
                        (bonus && bonus > 0 ? " " + classes.with_bonus : "")
                    }
                    style={{
                        width: `calc((100%${includeLabel ? " - 32px" : ""}) * ${
                            capBase / TOP_CAP_HIT
                        })`,
                    }}
                ></div>
            )}
            {buriedCap > 0 && (
                <div
                    className={
                        classes.cap_hit_bar +
                        " " +
                        classes.base_salary +
                        " " +
                        classes.off_cap +
                        (capBase > 0 ? " " + classes.buried_cap : "") +
                        (bonus && bonus > 0 ? " " + classes.with_bonus : "")
                    }
                    style={{
                        width: `calc((100%${includeLabel ? " - 32px" : ""}) * ${
                            buriedCap / TOP_CAP_HIT
                        })`,
                    }}
                ></div>
            )}
            {bonus && bonus > 0 && (
                <div
                    className={
                        classes.cap_hit_bar +
                        " " +
                        classes.bonus +
                        (isNonRoster ? " " + classes.off_cap : "")
                    }
                    style={{
                        width: `calc((100%${includeLabel ? " - 32px" : ""}) * ${
                            bonus / TOP_CAP_HIT
                        })`,
                    }}
                ></div>
            )}
            {includeLabel && (
                <CapHitLabel
                    annualAverageValue={annualAverageValue}
                    bonus={bonus}
                    ahlSalary={ahlSalary}
                    isNonRoster={isNonRoster}
                />
            )}
        </div>
    );
};

const CapHitIndicator = ({ player, capHit, inverseColors }) => {
    const { getPlayerCapBase, getPlayerBuriedCap } = useSalaryCap();

    const cap_base = getPlayerCapBase(player, capHit);
    const buried_cap = getPlayerBuriedCap(player, capHit);

    return (
        <div className={classes.cap_hit_indicator}>
            <CapHitBar
                annualAverageValue={capHit}
                capBase={cap_base}
                buriedCap={buried_cap}
                bonus={player.current_bonus_annual_average_value}
                ahlSalary={player.current_ahl_salary}
                isNonRoster={player.is_cleared_non_roster}
                includeLabel={true}
            />
        </div>
    );
};

export default CapHitIndicator;
