import { TriangleDirectionalMarker } from "./DirectionalMarkerIndicators";
import classes from "./VerticalSliderIndicator.module.css";

function VerticalSliderIndicator({
    title,
    value,
    tierClass,
    isSigned = false,
    isExample,
}) {
    return (
        <>
            <div
                className={
                    classes.vertical_slider_container + " " + classes.left
                }
                style={{
                    bottom: `calc(((100% - ${isSigned ? 40 : 20}px) * ${
                        isExample ? 0.5 : value
                    }) + ${isSigned ? 20 : 0}px)`,
                }}
            >
                <TriangleDirectionalMarker
                    tierClass={tierClass}
                    direction={"right"}
                    size={10}
                />
            </div>
            <div
                className={
                    classes.vertical_slider_container + " " + classes.right
                }
                style={{
                    bottom: `calc(((100% - ${isSigned ? 40 : 20}px) * ${
                        isExample ? 0.5 : value
                    }) + ${isSigned ? 20 : 0}px)`,
                }}
            >
                <div className={classes.title}>{title}</div>
            </div>
        </>
    );
}

export default VerticalSliderIndicator;
