import React, { useEffect, useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";
import { fetchPlayerDatastash } from "../store/players-actions";
import { fetchCompetition } from "../store/competitions-actions";
import MainBox from "../layout/MainBox";
import {
    PlayerTileBox,
    GamesPlayedStat,
    GoalieSavePercentageStat,
    GoalieGoalsAgainstAvgStat,
    GoalieShutoutsStat,
    SkaterPointsStats,
    SkaterPlusMinusStat,
    SkaterPenaltyMinutesStat,
    GoalieSavePercentageIndicator,
    GoaliePerformanceIndicator,
    SkaterPointsIndicator,
    SkaterPerformanceIndicator,
    SkaterUsageIndicator,
    PlayerPerformanceDecorators,
} from "./playertile/Playertile";
import useMultiResourceLoader from "../hooks/use-multi-resource-loader";

import classes from "./PlayerSeasonsSummary.module.css";
import ptClasses from "./playertile/Playertile.module.css";
import { max } from "lodash";

const PlayerFullSeasonTile = ({
    player,
    season,
    competition,
    slMetrics,
    epStats,
    isSelected,
    onClick,
}) => {
    return (
        <PlayerTileBox
            mainClass={slMetrics.games_played > 0 && ptClasses.basetile}
            isSelected={isSelected}
            onClick={onClick}
        >
            <div className={classes.season_tile_line}>
                <div className={classes.season_tile_header}>
                    <div className={classes.season_tile_label}>
                        {season?.name || competition.name}
                    </div>
                    <div className={classes.season_tile_gp}>
                        <GamesPlayedStat
                            metrics={slMetrics}
                            ep_stats={epStats}
                        />
                    </div>
                </div>
            </div>
            {epStats && player.position === "G" && (
                <div className={classes.season_tile_line}>
                    {(!slMetrics || slMetrics.games_played === 0) && (
                        <GoalieSavePercentageStat
                            metrics={slMetrics}
                            ep_stats={epStats}
                        />
                    )}
                    <GoalieGoalsAgainstAvgStat ep_stats={epStats} />
                    <GoalieShutoutsStat ep_stats={epStats} fixedWidth={false} />
                </div>
            )}
            {epStats && player.position !== "G" && (
                <div className={classes.season_tile_line}>
                    <SkaterPointsStats ep_stats={epStats} />
                    {epStats?.PM !== null && (
                        <SkaterPlusMinusStat ep_stats={epStats} />
                    )}
                    <SkaterPenaltyMinutesStat ep_stats={epStats} />
                </div>
            )}
            {slMetrics &&
                slMetrics.games_played > 0 &&
                player.position === "G" && (
                    <div
                        className={
                            classes.season_tile_line +
                            " " +
                            classes.performance_metrics_line
                        }
                    >
                        <GoalieSavePercentageIndicator
                            player={player}
                            metrics={slMetrics.metrics}
                        />
                        <GoaliePerformanceIndicator
                            player={player}
                            metrics={slMetrics.metrics}
                        />
                    </div>
                )}
            {slMetrics &&
                slMetrics.games_played > 0 &&
                player.position !== "G" && (
                    <div
                        className={
                            classes.season_tile_line +
                            " " +
                            classes.performance_metrics_line
                        }
                    >
                        <SkaterPointsIndicator
                            player={player}
                            metrics={slMetrics.metrics}
                            fixedWidth={true}
                        />
                        <SkaterPerformanceIndicator
                            player={player}
                            metrics={slMetrics.metrics}
                        />
                        <div className={classes.indicators_container}>
                            <SkaterUsageIndicator
                                player={player}
                                metrics={slMetrics.metrics}
                            />
                        </div>
                        <div className={classes.pillars_adjustments}>
                            <PlayerPerformanceDecorators player={player} />
                        </div>
                    </div>
                )}
        </PlayerTileBox>
    );
};

const PlayerSeasonsSummary = ({
    playerId,
    isLoadingHandler,
    selectedSeasonId,
    selectedCompetitionId,
    onSeasonCompetitionClick,
    narrowLayout = false,
}) => {
    const { addLoader } = useMultiResourceLoader(isLoadingHandler);
    const dispatch = useDispatch();
    const player_datastashes = useSelector(
        (state) => state.players.datastashes[playerId]
    );
    const player = useSelector((state) => state.players.players[playerId]);
    const competitions = useSelector(
        (state) => state.competitions.competitions
    );
    const seasons = useSelector((state) => state.seasons.seasons);

    const career_performance_summary =
        playerId &&
        player_datastashes &&
        player_datastashes.career_performance_summary;

    useEffect(() => {
        if (playerId && !career_performance_summary) {
            addLoader("career_performance_summary", () =>
                dispatch(
                    fetchPlayerDatastash(playerId, "career_performance_summary")
                )
            );
        }
    }, [playerId, career_performance_summary, dispatch, addLoader]);

    const competitionsPlayed = useMemo(() => {
        return career_performance_summary &&
            career_performance_summary.competitions
            ? career_performance_summary.competitions.map((competition) => {
                  return competition.id;
              })
            : [];
    }, [career_performance_summary]);

    const competitionsPlayedAvailable =
        career_performance_summary &&
        (!career_performance_summary.competitions ||
            competitionsPlayed.every((competitionId) => {
                return competitions[competitionId];
            }));

    useEffect(() => {
        if (!competitionsPlayedAvailable) {
            competitionsPlayed.forEach((competitionId) => {
                if (!competitions[competitionId]) {
                    addLoader(`competition_${competitionId}`, () =>
                        dispatch(fetchCompetition(competitionId))
                    );
                }
            });
        }
    }, [
        competitionsPlayed,
        competitionsPlayedAvailable,
        competitions,
        dispatch,
        addLoader,
    ]);

    const tournamentSeasons = useMemo(() => {
        const tournamentSeasons = {};

        if (
            competitionsPlayedAvailable &&
            career_performance_summary &&
            career_performance_summary.competitions
        ) {
            const extra_competitions = (
                career_performance_summary.extra_competitions || []
            ).map((competition) => {
                return {
                    ...competition,
                    name: competition.league_name,
                    is_tournament: true,
                };
            });

            // Concatenate competitions and extra competitions
            const all_competitions = [
                ...career_performance_summary.competitions,
                ...extra_competitions,
            ];

            all_competitions.forEach((competition) => {
                if (
                    // Extra competitions will not have a competition.id,
                    // and are all considered tournaments
                    !competition.id ||
                    competitions[competition.id].is_tournament
                ) {
                    competition.seasons.forEach((season) => {
                        if (!tournamentSeasons[season.season_id]) {
                            tournamentSeasons[season.season_id] = [];
                        }

                        tournamentSeasons[season.season_id].push({
                            id: competition.id,
                            name: competition.name,
                            // Find the season with season.season_id in the competition.seasons array
                            games_played: season.games_played,
                            season: season,
                            shorthand: competition.shorthand,
                        });
                    });
                }
            });
        }

        return Object.keys(tournamentSeasons).length > 0
            ? Object.keys(tournamentSeasons)
                  // Sort seasons by id, reversed
                  .sort((a, b) => {
                      if (a < b) {
                          return 1;
                      } else if (a > b) {
                          return -1;
                      } else {
                          return 0;
                      }
                  })
                  .map((seasonId) => {
                      return {
                          id: parseInt(seasonId),
                          name: seasons[seasonId]
                              ? seasons[seasonId].name
                              : seasonId,
                          competitions: tournamentSeasons[seasonId],
                      };
                  })
            : [];
    }, [
        competitionsPlayedAvailable,
        career_performance_summary,
        competitions,
        seasons,
    ]);

    return (
        <>
            {(!player ||
                !career_performance_summary ||
                !competitionsPlayedAvailable) && <div>Loading...</div>}
            {player &&
                career_performance_summary &&
                competitionsPlayedAvailable &&
                competitionsPlayed.length === 0 && (
                    <MainBox>
                        <div className={classes.no_data}>No Data</div>
                    </MainBox>
                )}
            {player &&
                seasons &&
                career_performance_summary &&
                competitionsPlayedAvailable &&
                competitionsPlayed.length > 0 &&
                career_performance_summary.competitions
                    .filter((competition) => {
                        return (
                            !competitions[competition.id].is_tournament &&
                            competition.seasons.length > 0 &&
                            competition.seasons.some((season) => {
                                return (
                                    season.games_played > 0 ||
                                    season.eliteprospects_stats?.regular.GP > 0
                                );
                            })
                        );
                    })
                    .map((competition) => {
                        return (
                            <MainBox key={competition.id}>
                                <div
                                    className={
                                        classes.competition_section +
                                        (narrowLayout
                                            ? " " + classes.narrow
                                            : "")
                                    }
                                >
                                    <div className={classes.competition_header}>
                                        <img
                                            src={competition.logo_src}
                                            alt="League Logo"
                                        />
                                        <div
                                            className={
                                                classes.competition_label
                                            }
                                        >
                                            {competition.name}
                                        </div>
                                    </div>
                                    <div className={classes.seasons_group}>
                                        {competition.seasons
                                            .filter((season) => {
                                                return (
                                                    season.games_played > 0 ||
                                                    season.eliteprospects_stats
                                                        ?.regular.GP > 0
                                                );
                                            })
                                            .map((season) => {
                                                return (
                                                    <PlayerFullSeasonTile
                                                        key={season.season_id}
                                                        player={{
                                                            ...season,
                                                            id: player.id,
                                                            position:
                                                                player.position,
                                                        }}
                                                        season={
                                                            seasons[
                                                                season.season_id
                                                            ]
                                                        }
                                                        slMetrics={season}
                                                        epStats={
                                                            season
                                                                .eliteprospects_stats
                                                                ?.regular
                                                        }
                                                        isSelected={
                                                            season.season_id ===
                                                                selectedSeasonId &&
                                                            competition.id ===
                                                                selectedCompetitionId
                                                        }
                                                        onClick={
                                                            onSeasonCompetitionClick &&
                                                            season.games_played >
                                                                0
                                                                ? () =>
                                                                      onSeasonCompetitionClick(
                                                                          season.season_id,
                                                                          competition.id
                                                                      )
                                                                : null
                                                        }
                                                    />
                                                );
                                            })}
                                    </div>
                                </div>
                            </MainBox>
                        );
                    })}
            {player && tournamentSeasons.length > 0 && (
                <>
                    <div className={classes.subsection_header}>
                        Other Leagues and Tournaments
                    </div>
                    {tournamentSeasons.map((season) => {
                        return (
                            <MainBox key={season.name}>
                                <div
                                    className={
                                        classes.tournament_season_section +
                                        (narrowLayout
                                            ? " " + classes.narrow
                                            : "")
                                    }
                                >
                                    <div
                                        className={
                                            classes.tournament_season_header
                                        }
                                    >
                                        <div
                                            className={
                                                classes.tournament_season_label
                                            }
                                        >
                                            {season.name}
                                        </div>
                                    </div>
                                    <div className={classes.competitions_group}>
                                        {season.competitions
                                            .filter((competition) => {
                                                return (
                                                    competition.games_played >
                                                        0 ||
                                                    competition.season
                                                        .eliteprospects_stats
                                                        .regular?.GP > 0
                                                );
                                            })
                                            .sort((a, b) => {
                                                // Compare the max of season.games_played and ep_stats.GP
                                                if (
                                                    max([
                                                        a.season?.games_played,
                                                        a.season
                                                            .eliteprospects_stats
                                                            ?.regular?.GP,
                                                    ]) <
                                                    max([
                                                        b.season?.games_played,
                                                        b.season
                                                            .eliteprospects_stats
                                                            ?.regular?.GP,
                                                    ])
                                                ) {
                                                    return 1;
                                                } else if (
                                                    max([
                                                        a.season?.games_played,
                                                        a.season
                                                            .eliteprospects_stats
                                                            ?.regular?.GP,
                                                    ]) >
                                                    max([
                                                        b.season?.games_played,
                                                        b.season
                                                            .eliteprospects_stats
                                                            ?.regular?.GP,
                                                    ])
                                                ) {
                                                    return -1;
                                                } else {
                                                    return 0;
                                                }
                                            })
                                            .map((competition) => {
                                                return (
                                                    <PlayerFullSeasonTile
                                                        key={
                                                            competition.id ||
                                                            competition.name
                                                        }
                                                        player={{
                                                            ...competition.season,
                                                            position:
                                                                player.position,
                                                        }}
                                                        competition={
                                                            competition
                                                        }
                                                        slMetrics={
                                                            competition.season
                                                        }
                                                        epStats={
                                                            competition.season
                                                                .eliteprospects_stats
                                                                ?.regular
                                                        }
                                                        isSelected={
                                                            competition.season
                                                                .season_id ===
                                                                selectedSeasonId &&
                                                            competition.id ===
                                                                selectedCompetitionId
                                                        }
                                                        onClick={
                                                            onSeasonCompetitionClick &&
                                                            competition.season
                                                                .games_played >
                                                                0
                                                                ? () =>
                                                                      onSeasonCompetitionClick(
                                                                          competition
                                                                              .season
                                                                              .season_id,
                                                                          competition.id
                                                                      )
                                                                : null
                                                        }
                                                    />
                                                );
                                            })}
                                    </div>
                                </div>
                            </MainBox>
                        );
                    })}
                </>
            )}
        </>
    );
};

export default PlayerSeasonsSummary;
