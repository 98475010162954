import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { fetchCompetitionSeasonDatastash } from "../store/competitions-actions";
import useMultiResourceLoader from "../hooks/use-multi-resource-loader";

import classes from "./PlayerMetricSeasonTrendChart.module.css";
import TimeSeriesChart from "../controls/TimeSeriesChart";

const TIER_BANDS_COLORS = {
    elite: "var(--tw-yellow-400)",
    top: "var(--tw-green-500)",
    high: "var(--ch-blue-500)",
    mid: "var(--tw-slate-400)",
    low: "var(--ch-red-500)",
};

const CompetitionMetricSeasonTiersTrendChart = ({
    competitionId,
    seasonId,
    primaryPosition,
    metric,
    width,
    height,
    margin,
    autoScaleYAxis = false,
    isLoadingHandler,
}) => {
    const { addLoader } = useMultiResourceLoader(isLoadingHandler);

    const dispatch = useDispatch();

    const competition_seasondatastashes = useSelector(
        (state) => state.competitions.seasondatastashes[competitionId]
    );
    const position_metrics_series_tiers_thresholds =
        competition_seasondatastashes &&
        competition_seasondatastashes.position_metrics_series_tiers_thresholds &&
        competition_seasondatastashes.position_metrics_series_tiers_thresholds[
            seasonId
        ];

    const metrics_series_tiers_thresholds =
        position_metrics_series_tiers_thresholds &&
        position_metrics_series_tiers_thresholds[
            primaryPosition
        ].metrics.includes(metric) &&
        position_metrics_series_tiers_thresholds[primaryPosition];

    useEffect(() => {
        if (competitionId && !position_metrics_series_tiers_thresholds) {
            addLoader("position_metrics_series_tiers_thresholds", () =>
                dispatch(
                    fetchCompetitionSeasonDatastash(
                        competitionId,
                        seasonId,
                        "position_metrics_series_tiers_thresholds"
                    )
                )
            );
        }
    }, [
        dispatch,
        competitionId,
        seasonId,
        position_metrics_series_tiers_thresholds,
        addLoader,
    ]);

    // We may do this later...
    const no_data = false;

    const [positionThresholdBands, setPositionThresholdBands] = useState([]);

    useEffect(() => {
        if (metrics_series_tiers_thresholds && !no_data) {
            const metricIndex =
                metrics_series_tiers_thresholds.metrics.indexOf(metric);
            const newPositionThresholdBands = [];

            // Create an object with an array for each category as keys
            const categories =
                metrics_series_tiers_thresholds.tier_categories &&
                metrics_series_tiers_thresholds.tier_categories.reduce(
                    (acc, cur) => {
                        acc[cur] = {
                            active: false,
                            name: cur,
                            color: TIER_BANDS_COLORS[cur]
                                ? TIER_BANDS_COLORS[cur]
                                : "#000",
                            data: [],
                        };
                        return acc;
                    },
                    {}
                );

            const negativeDirectionality =
                metrics_series_tiers_thresholds.series &&
                metrics_series_tiers_thresholds.series.length > 0 &&
                metrics_series_tiers_thresholds.series[0].thresholds[
                    metricIndex
                ][0] <
                    metrics_series_tiers_thresholds.series[0].thresholds[
                        metricIndex
                    ][1];

            metrics_series_tiers_thresholds.series.forEach((d) => {
                d.thresholds[metricIndex].forEach((hidx_val, idx) => {
                    if (idx > 0) {
                        const lidx_val = d.thresholds[metricIndex][idx - 1];
                        const category =
                            metrics_series_tiers_thresholds.tier_categories[
                                idx - 1
                            ];
                        const min = negativeDirectionality
                            ? lidx_val
                            : hidx_val;
                        const max = negativeDirectionality
                            ? hidx_val
                            : lidx_val;

                        categories[category].data.push({
                            x: new Date(d.date + "T00:00:00"),
                            y_min: min,
                            y_max: max,
                        });
                    }
                });
            });

            Object.keys(categories).forEach((k) => {
                if (categories[k].data.length > 0) {
                    newPositionThresholdBands.push(categories[k]);
                }
            });

            setPositionThresholdBands(newPositionThresholdBands);
        }
    }, [
        metrics_series_tiers_thresholds,
        no_data,
        competitionId,
        seasonId,
        metric,
    ]);

    return (
        <>
            {no_data && <div className={classes.no_data}>No Data</div>}
            {positionThresholdBands.length === 0 && (
                <div className={classes.no_data}>Loading...</div>
            )}
            {!no_data && (
                <TimeSeriesChart
                    width={width}
                    height={height}
                    margin={margin}
                    minY={autoScaleYAxis ? null : 0}
                    maxY={autoScaleYAxis ? null : 1}
                    stepsY={4}
                    bands={positionThresholdBands}
                />
            )}
        </>
    );
};

export default CompetitionMetricSeasonTiersTrendChart;
