import classes from "./TextInput.module.css";

const TextInput = ({
    placeholder,
    value,
    handleInputChange,
    readOnly,
    mode,
    multiline,
    ...additionalProps
}) => {
    const inputClass = `${classes.text_input} ${
        mode === "dark" ? classes.dark_mode : classes.light_mode
    } ${multiline ? classes.multiline : ""}`;
    const componentProps = {
        type: "text",
        placeholder,
        value,
        onChange: handleInputChange,
        className: inputClass,
        readOnly,
        ...(readOnly && { style: { backgroundColor: "inherit" } }),
        ...additionalProps,
    };

    return multiline ? (
        <textarea {...componentProps} />
    ) : (
        <input {...componentProps} />
    );
};

export default TextInput;
