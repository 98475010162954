import { useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import classes from "./PlayerHeader.module.css";
import SquareButton from "../../controls/buttons/SquareButton";
import { useSalaryCap } from "../../hooks/use-salary-cap";
import {
    format_full_birth_location,
    formatSalaryString,
    getPlayerFreeAgencySeasonYear,
} from "../../utils";
import {
    NumericButtonIndicator,
    LeftLateralityIndicator,
    RightLateralityIndicator,
} from "../indicators";
import {
    PlayerWaiversExemptionPill,
    GamesPlayedStat,
    GoalieSavePercentageIndicator,
    GoaliePerformanceIndicator,
    GoalieGoalsAgainstAvgStat,
    GoalieShutoutsStat,
    SkaterPointsIndicator,
    SkaterPerformanceIndicator,
    SkaterUsageIndicator,
    SkaterPointsStats,
    SkaterPlusMinusStat,
    SkaterPenaltyMinutesStat,
    PlayerPerformanceDecorators,
} from "../playertile/Playertile";
import {
    FREE_AGENCY_CLASS_COLORS,
    FREE_AGENCY_CLASS_LABELS,
    CURRENT_DRAFT,
} from "../../constants";
import useAuth from "../../hooks/use-auth";

const PlayerHeader = ({
    player,
    trad_stats,
    metrics,
    narrowLayout = false,
    allowUseDraftedTeam = false,
    showPlayerViewButton = true,
    showContractDetails = true,
    performanceViewContext = null,
}) => {
    const navigate = useNavigate();
    const location = useLocation();
    const { getPlayerCapBase, getPlayerBuriedCap } = useSalaryCap();

    const { checkPermission } = useAuth();
    const canViewLegacyCareerTrendsPage = checkPermission(
        "core.can_view_legacy_career_trends_page"
    );
    const canViewContractInformation = checkPermission(
        "core.can_view_contract_information"
    );

    const competitions = useSelector(
        (state) => state.competitions.competitions
    );
    const teams = useSelector((state) => state.teams.teams);

    const playerAgencies = useSelector((state) => state.players.playeragencies);
    const playerAgents = useSelector((state) => state.players.playeragents);

    const playerAgent = player && playerAgents[player.playeragent_id];
    const playerAgency =
        playerAgent && playerAgencies[playerAgent.playeragency_id];

    const playerTeam =
        player &&
        (player.nhl_rights_owner_team_id
            ? teams[player.nhl_rights_owner_team_id]
            : player.ahl_contract_team_id
            ? teams[player.ahl_contract_team_id]
            : allowUseDraftedTeam && player.nhl_drafted_team_id
            ? teams[player.nhl_drafted_team_id]
            : null);

    const playerCompetition =
        player &&
        (player.has_nhl_experience
            ? competitions[1]
            : player.has_ahl_experience
            ? competitions[2]
            : null);

    const logoUrl =
        player &&
        (playerTeam
            ? playerTeam.logo_url
            : playerCompetition
            ? playerCompetition.logo_url
            : player.is_draft_eligible
            ? CURRENT_DRAFT.logo_url
            : null);

    const hasContract =
        player && (player.contract_years > 0 || player.contract_extension > 0);

    const capBase = getPlayerCapBase(
        player,
        player?.current_base_annual_average_value
    );
    const buriedCap = getPlayerBuriedCap(
        player,
        player?.current_base_annual_average_value
    );

    const capHit = buriedCap > 0 ? buriedCap : capBase;
    const capHitLabel =
        player &&
        (player.is_cleared_non_roster
            ? "Off Cap"
            : buriedCap > 0
            ? "Buried Cap"
            : "Cap Hit");
    const capHitColor =
        player &&
        (player.is_cleared_non_roster
            ? "var(--ch-blue-300)"
            : "var(--ch-blue-800)");

    const freeAgencySeasonYear = getPlayerFreeAgencySeasonYear(player);
    const freeAgencySeason =
        freeAgencySeasonYear +
        "-" +
        (freeAgencySeasonYear + 1).toString().slice(-2);

    // If performanceViewContext is provided, use it by making every attribute
    // a URL parameter for the performance view page. So, we need to transform
    // the performanceViewContext object into a URL query string.
    const performanceViewParamsString =
        performanceViewContext && Object.keys(performanceViewContext).length > 0
            ? "?" +
              Object.keys(performanceViewContext)
                  .filter((key) => performanceViewContext[key] !== null)
                  .map((key) => key + "=" + performanceViewContext[key])
                  .join("&") +
              "&backNavOrigin=" +
              encodeURIComponent(location.pathname + location.search)
            : "";

    return (
        <div
            className={
                classes.player_header +
                (narrowLayout ? " " + classes.narrow : "")
            }
        >
            <div
                className={
                    classes.player_header_element + " " + classes.player_bio
                }
            >
                <div className={classes.player_name}>
                    {player && player.known_name}
                    {player && showPlayerViewButton && (
                        <>
                            <div
                                className={
                                    classes.player_performance_view_button
                                }
                            >
                                <SquareButton
                                    label="analytics"
                                    useMaterialIcon={true}
                                    onClick={() =>
                                        navigate(
                                            "/app/players/" +
                                                player.id +
                                                "/performance/" +
                                                performanceViewParamsString
                                        )
                                    }
                                />
                            </div>
                            {canViewLegacyCareerTrendsPage && (
                                <button
                                    onClick={() =>
                                        (window.location =
                                            "/dashboards/player_career_trends/" +
                                            player.id)
                                    }
                                    className={
                                        classes.player_career_trends_button
                                    }
                                    style={{
                                        border: "none",
                                        background: "none",
                                    }}
                                >
                                    📈
                                </button>
                            )}
                        </>
                    )}
                </div>
                <div className={classes.player_details}>
                    <div className={classes.player_position_container}>
                        <div className={classes.player_position}>
                            {player && player.position}
                        </div>
                    </div>
                    {player && player.laterality && (
                        <div className={classes.player_laterality}>
                            {player.laterality === "L" ? (
                                <LeftLateralityIndicator />
                            ) : (
                                <RightLateralityIndicator />
                            )}
                        </div>
                    )}
                </div>
                <div className={classes.player_birth_info}>
                    <div className={classes.player_birth_info_element}>
                        {player &&
                            player.birth_date + " (" + player.age + " yo)"}
                    </div>
                    <div className={classes.player_birth_info_element}>
                        {player && format_full_birth_location(player)}
                    </div>
                </div>
                {showContractDetails && canViewContractInformation && (
                    <div className={classes.player_agency}>
                        {player && playerAgent && (
                            <div className={classes.player_agent_name}>
                                {playerAgent.first_name +
                                    " " +
                                    playerAgent.last_name}
                            </div>
                        )}
                        {player && playerAgency && (
                            <div className={classes.player_agency_name}>
                                {playerAgency.name}
                            </div>
                        )}
                    </div>
                )}
                {(trad_stats || metrics) && (
                    <div className={classes.performance_tile}>
                        <div className={classes.performance_tile_column}>
                            {trad_stats && trad_stats.GP > 0 && (
                                <div className={classes.performance_tile_row}>
                                    <div
                                        className={
                                            classes.performance_tile_item
                                        }
                                    >
                                        <GamesPlayedStat
                                            metrics={metrics}
                                            ep_stats={trad_stats}
                                        />
                                    </div>
                                </div>
                            )}
                            {metrics && metrics.games_played > 0 && (
                                <div className={classes.performance_tile_row}>
                                    <div
                                        className={
                                            classes.performance_tile_item
                                        }
                                    >
                                        {player.position === "G" && (
                                            <GoalieSavePercentageIndicator
                                                player={player}
                                                metrics={metrics}
                                            />
                                        )}
                                        {player.position !== "G" && (
                                            <SkaterPointsIndicator
                                                player={player}
                                                metrics={metrics}
                                            />
                                        )}
                                    </div>
                                </div>
                            )}
                        </div>
                        {player.position === "G" && (
                            <div className={classes.performance_tile_column}>
                                {trad_stats && trad_stats.GP > 0 && (
                                    <div
                                        className={classes.performance_tile_row}
                                    >
                                        <div
                                            className={
                                                classes.performance_tile_item +
                                                " " +
                                                classes.goalie_performance_trad_stats
                                            }
                                        >
                                            <GoalieGoalsAgainstAvgStat
                                                ep_stats={trad_stats}
                                            />
                                            <GoalieShutoutsStat
                                                ep_stats={trad_stats}
                                            />
                                        </div>
                                    </div>
                                )}
                                {metrics && metrics.games_played > 0 && (
                                    <div
                                        className={classes.performance_tile_row}
                                    >
                                        <div
                                            className={
                                                classes.performance_tile_item +
                                                " " +
                                                classes.no_margin
                                            }
                                        >
                                            <GoaliePerformanceIndicator
                                                player={player}
                                                metrics={metrics}
                                            />
                                        </div>
                                    </div>
                                )}
                            </div>
                        )}
                        {player.position !== "G" && (
                            <>
                                <div
                                    className={classes.performance_tile_column}
                                >
                                    {trad_stats && trad_stats.GP > 0 && (
                                        <div
                                            className={
                                                classes.performance_tile_row
                                            }
                                        >
                                            <div
                                                className={
                                                    classes.performance_tile_item
                                                }
                                            >
                                                <SkaterPointsStats
                                                    ep_stats={trad_stats}
                                                />
                                            </div>
                                        </div>
                                    )}
                                    {metrics && metrics.games_played > 0 && (
                                        <div
                                            className={
                                                classes.performance_tile_row
                                            }
                                        >
                                            <div
                                                className={
                                                    classes.performance_tile_item +
                                                    " " +
                                                    classes.no_margin
                                                }
                                            >
                                                <SkaterPerformanceIndicator
                                                    player={player}
                                                    metrics={metrics}
                                                />
                                            </div>
                                        </div>
                                    )}
                                </div>
                                <div
                                    className={classes.performance_tile_column}
                                >
                                    {trad_stats && trad_stats.GP > 0 && (
                                        <div
                                            className={
                                                classes.performance_tile_row
                                            }
                                        >
                                            <div
                                                className={
                                                    classes.performance_tile_item
                                                }
                                            >
                                                <SkaterPlusMinusStat
                                                    ep_stats={trad_stats}
                                                />
                                            </div>
                                            <div
                                                className={
                                                    classes.performance_tile_item
                                                }
                                            >
                                                <SkaterPenaltyMinutesStat
                                                    ep_stats={trad_stats}
                                                />
                                            </div>
                                        </div>
                                    )}
                                    {metrics && metrics.games_played > 0 && (
                                        <div
                                            className={
                                                classes.performance_tile_row
                                            }
                                        >
                                            <div
                                                className={
                                                    classes.performance_tile_item
                                                }
                                            >
                                                <SkaterUsageIndicator
                                                    player={player}
                                                    metrics={metrics}
                                                />
                                            </div>
                                            <div
                                                className={
                                                    classes.performance_tile_item
                                                }
                                            >
                                                <div
                                                    className={
                                                        classes.pillar_adjustment_container +
                                                        (trad_stats
                                                            ? ""
                                                            : " " +
                                                              classes.no_trad_stats)
                                                    }
                                                >
                                                    <PlayerPerformanceDecorators
                                                        player={player}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </>
                        )}
                    </div>
                )}
            </div>
            <div
                className={
                    classes.player_header_element +
                    " " +
                    classes.player_contract_details
                }
            >
                {showContractDetails &&
                    canViewContractInformation &&
                    hasContract && (
                        <>
                            <NumericButtonIndicator
                                value={formatSalaryString(capHit, false)}
                                label={capHitLabel}
                                color={"var(--tw-gray-50)"}
                                bgColor={capHitColor}
                            />
                            {player.current_bonus_annual_average_value > 0 && (
                                <NumericButtonIndicator
                                    value={formatSalaryString(
                                        player.current_bonus_annual_average_value,
                                        false
                                    )}
                                    label={"Bonus"}
                                    color={"var(--tw-green-600)"}
                                    tight={true}
                                    small={true}
                                />
                            )}
                            {player.current_ahl_salary > 0 && (
                                <NumericButtonIndicator
                                    value={formatSalaryString(
                                        player.current_ahl_salary,
                                        false
                                    )}
                                    label={"AHL"}
                                    tight={true}
                                    small={true}
                                />
                            )}
                            <div className={classes.spacer}></div>
                            {player.projected_free_agency_class && (
                                <NumericButtonIndicator
                                    value={freeAgencySeason}
                                    label={
                                        FREE_AGENCY_CLASS_LABELS[
                                            player.projected_free_agency_class
                                        ]
                                    }
                                    bgColor={
                                        FREE_AGENCY_CLASS_COLORS[
                                            player.projected_free_agency_class
                                        ]
                                    }
                                />
                            )}
                            {!player.requires_waivers && (
                                <div className={classes.waivers_pill_container}>
                                    <PlayerWaiversExemptionPill />
                                </div>
                            )}
                        </>
                    )}
            </div>
            <div
                className={
                    classes.player_header_element +
                    " " +
                    classes.player_org_logo
                }
            >
                {logoUrl && (
                    <img
                        className={classes.org_logo}
                        alt="Org Logo"
                        src={logoUrl}
                    ></img>
                )}
            </div>
        </div>
    );
};

export default PlayerHeader;
