import classes from "./NavButtons.module.css";
import NavMenuOpenButton from "./NavMenuOpenButton";
import BackLinkButton from "./BackLinkButton";

const NavButtons = ({ openMenu, backLink, darkMode = false }) => {
    return (
        <div className={classes.nav_buttons_container}>
            {openMenu && (
                <div className={classes.nav_menu_open_button_container}>
                    <NavMenuOpenButton onClick={openMenu} darkMode={darkMode} />
                </div>
            )}
            {backLink && (
                <div className={classes.back_link_button_container}>
                    <BackLinkButton link={backLink} darkMode={darkMode} />
                </div>
            )}
        </div>
    );
};

export default NavButtons;
