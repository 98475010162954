import {
    PercentileClassIndicator,
    NumericIndicator,
    IndicatorGroup,
} from "../../indicators";
import { LegendSection, LegendItem } from "../../../controls/Legend";

const SYMBOL_WIDTH = "52px";

const GoaliesIndicatorsSection = ({ tradStats = false }) => {
    return (
        <LegendSection title="Goalies Indicators">
            {tradStats && (
                <>
                    <LegendItem
                        symbol={
                            <NumericIndicator
                                value={"##"}
                                label={"gp"}
                                isExample={true}
                            />
                        }
                        symbolWidth="80px"
                        description="Number of games played in current league."
                    />
                    <LegendItem
                        symbol={
                            <NumericIndicator
                                value={"#.###"}
                                label={"sv"}
                                isExample={true}
                            />
                        }
                        symbolWidth="80px"
                        description="Save percentage in current league."
                    />
                    <LegendItem
                        symbol={
                            <NumericIndicator
                                value={"#.##"}
                                label={"gaa"}
                                isExample={true}
                            />
                        }
                        symbolWidth="80px"
                        description="Goals against average in current league."
                    />
                    <LegendItem
                        symbol={
                            <NumericIndicator
                                value={"#"}
                                label={"so"}
                                isExample={true}
                            />
                        }
                        symbolWidth="80px"
                        description="Shutouts in current league."
                    />
                </>
            )}
            {!tradStats && (
                <>
                    <LegendItem
                        symbol={
                            <NumericIndicator
                                value={"#.###"}
                                isExample={true}
                            />
                        }
                        symbolWidth={SYMBOL_WIDTH}
                        description="ES 5v5 Save %"
                    />
                    <LegendItem
                        symbol={
                            <IndicatorGroup
                                indicators={[{ label: "es" }, { label: "pk" }]}
                                isExample={true}
                            />
                        }
                        symbolWidth={SYMBOL_WIDTH}
                        description="Performance: ES 5v5 and PK goals allowed vs expected. The border indicates overall consistency, based on percentage of quality starts."
                    />
                    <LegendItem
                        symbol={
                            <PercentileClassIndicator
                                label={"sq"}
                                isExample={true}
                            />
                        }
                        symbolWidth={SYMBOL_WIDTH}
                        description="ES 5v5 shot quality against. High means goalie faced higher quality shots."
                    />
                </>
            )}
        </LegendSection>
    );
};

export default GoaliesIndicatorsSection;
